import React, { useState, useEffect } from 'react';
import { Modal, InputNumber, Row, Col, Button, Divider, Spin, message, Select } from 'antd';
import { useHistory } from 'react-router';
import handlingMessage from '../utils/handlingMessage';
import { SyncOutlined, LoadingOutlined } from '@ant-design/icons';
import { State, States } from 'sigt';
import { connect } from 'react-redux';
import { updatePetro, updateDolar, updateEuro } from '../redux/actions/coins';
import axios from 'axios';
import Petro from './Icons/Petro';
const server = process.env.REACT_APP_SERVER_URL;

const Scale: React.FC<ScaleProps> = ({ thm, petro, updatePetro, updateDolar, updateEuro, dolar, euro, auth }) => {
  const [visible, setVisible] = useState(true);
  const [loading, setLoading] = useState<{ [id: string]: boolean }>({});
  const [petroBs, setPetrobs] = useState<number | undefined>();
  const [ut, setUT] = useState<number | undefined>();
  const [maxAmount, setMaxAmount] = useState<number | undefined>();
  const [euroBs, setEurobs] = useState<number | undefined>();
  const [dolarBs, setDolarbs] = useState<number | undefined>();
  const [currenctSelected, setCurrencySelected] = useState<string>('');

  const [scales, setScales] = useState<{ id: number; descripcion: string; indicador: string | number }[]>([]);
  const history = useHistory();

  useEffect(() => {
    if (petro && ut) setPetrobs(+(((petro ?? 0) / 100) * (ut ?? 0)).toFixed(2));
  }, [petro, ut]);

  const getScales = async () => {
    try {
      const response = await axios.get(`${server}/scales`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      setScales(response.data?.scales);
      message.success(response.data?.message || 'Baremo de tarifas obtenido!');
    } catch (e) {
      message.error(e.response?.data?.error || e.response?.data?.message || 'Error al obtener tasas y limites');
    }
  };

  const getUT = async () => {
    try {
      const response = await axios.get(`${server}/scales/getUT`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      console.log(response.data, 'resopnse');
      setUT(response.data?.ut);
      // message.success(response.data?.message || 'Tasa de UTE obtenida!');
    } catch (e) {
      // message.error(e.response?.data?.error || e.response?.data?.message || 'Error al obtener tasa de UTE!');
    }
  };

  const onChangeValue = (id: number, value: number) => {
    const _scales = [...scales];
    const sIndex = _scales.findIndex((e) => e.id === id);
    const _scale = _scales[sIndex];
    _scale.indicador = value;
    _scales[sIndex] = _scale;
    setScales(_scales);
  };

  //   useEffect(() => {
  //     getScales();
  //   }, []);

  useEffect(() => {
    getUT();
  }, []);

  useEffect(() => {
    if (dolar) setDolarbs(dolar);
  }, [dolar]);

  useEffect(() => {
    if (euro) setEurobs(euro);
  }, [euro]);

  const updateValue = async (id: number, indicador: number | string) => {
    setLoading({ ...loading, [id]: true });
    try {
      const response = await axios.put(
        `${server}/scales/${id}`,
        { indicador },
        { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
      );
      message.success(response.data?.message || 'Baremo de tarifas obtenido!');
    } catch (e) {
      message.error(e.response?.data?.error || e.response?.data?.message || 'Error al obtener tasas y limites');
    } finally {
      setLoading({ ...loading, [id]: false });
    }
  };

  const updateUT = async (coin: string, ut: number | string) => {
    setLoading({ ...loading, [coin]: true });
    try {
      const response = await axios.put(
        `${server}/scales/ut`,
        { ut },
        { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
      );
      message.success(response.data?.message || 'Tasa de UTE actualizada!');
    } catch (e) {
      message.error(e.response?.data?.error || e.response?.data?.message || 'Error al actualizar tasa de UTE ');
    } finally {
      setLoading({ ...loading, [coin]: false });
    }
  };

  const handleCancel = () => {
    setVisible(false);
    history.goBack();
  };

  const handleSubmit = (coin: string) => {
    setLoading({ ...loading, [coin]: true });
    handlingMessage({
      action: () => updatePetro(maxAmount ?? petroBs, currenctSelected),
      key: `update${coin}`,
      loadingMessage: `Actualizando...`,
      cb: () => {
        setLoading({ ...loading, [coin]: false });
      },
    });
  };

  const handleSubmitCoins = (coinName: string, coinValue: number | undefined, update: Function) => {
    setLoading({ ...loading, [coinName]: true });
    handlingMessage({
      action: () => update(coinValue),
      key: `update${coinName}`,
      loadingMessage: `Actualizando ${coinName}...`,
      cb: () => {
        setLoading({ ...loading, [coinName]: false });
      },
    });
  };

  const handleChangeMaxCurrency = (value: number) => {
    if (value) {
      const newAmount = Number(((value * 100) / (ut ?? 0)).toFixed(2));
      setMaxAmount(newAmount);
      setPetrobs(value);
    }
  };

  return (
    <React.Fragment>
      <div style={{ height: '100%', width: '100%', backgroundColor: 'white' }}></div>
      <Modal
        width={600}
        bodyStyle={{ height: '80vh', overflowY: 'scroll' }}
        centered
        visible={visible}
        footer={null}
        closable
        maskClosable
        onCancel={handleCancel}
        title='Actualizar Tasas'
      >
        {ut ? (
          <>
            <Divider orientation='left'>Moneda mas alta</Divider>
            <Row justify='space-between' style={{ marginLeft: 20 }} gutter={[16, 16]}>
              <Col span={12}>
                <label>Moneda</label>
                <Select style={{ width: '100%' }} onChange={(value: any) => setCurrencySelected(value)}>
                  <Select.Option value='dolar'>Dólar</Select.Option>
                  <Select.Option value='euro'>Euro</Select.Option>
                </Select>
              </Col>
              <Col span={12}>
                <label>Valor</label>
                <InputNumber
                  style={{ width: '100%' }}
                  value={petroBs}
                  formatter={(value) => `Bs ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value) => (value ? value.replace(/Bs\s?|(,*)/g, '') : '')}
                  onChange={(value: any) => handleChangeMaxCurrency(value)}
                />
              </Col>
              <Col span={24} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                <Button loading={loading.PETRO} type='primary' icon={<SyncOutlined />} onClick={() => handleSubmit('PETRO')}>
                  Actualizar
                </Button>
              </Col>
            </Row>
            <Divider orientation='left'>Dolar</Divider>
            <Row justify='space-between' style={{ marginLeft: 20 }} gutter={[16, 16]}>
              <Col>
                <InputNumber
                  style={{ width: '100%' }}
                  value={dolarBs}
                  formatter={(value) => `Bs ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value) => (value ? value.replace(/Bs\s?|(,*)/g, '') : '')}
                  onChange={(value) => {
                    if (value) setDolarbs(value);
                  }}
                />
              </Col>
              <Col>
                <Button
                  loading={loading.DOLAR}
                  type='primary'
                  icon={<SyncOutlined />}
                  onClick={() => handleSubmitCoins('DOLAR', dolarBs, updateDolar)}
                >
                  Actualizar
                </Button>
              </Col>
            </Row>
            <Divider orientation='left'>Euro</Divider>
            <Row justify='space-between' style={{ marginLeft: 20 }} gutter={[16, 16]}>
              <Col>
                <InputNumber
                  style={{ width: '100%' }}
                  value={euroBs}
                  formatter={(value) => `Bs ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value) => (value ? value.replace(/Bs\s?|(,*)/g, '') : '')}
                  onChange={(value) => {
                    if (value) setEurobs(value);
                  }}
                />
              </Col>
              <Col>
                <Button
                  loading={loading.EURO}
                  type='primary'
                  icon={<SyncOutlined />}
                  onClick={() => handleSubmitCoins('EURO', euroBs, updateEuro)}
                >
                  Actualizar
                </Button>
              </Col>
            </Row>
            {/* <Divider orientation='left'>UTE</Divider>
            <Row justify='space-between' style={{ marginLeft: 20 }} gutter={[16, 16]}>
              <Col>
                <InputNumber
                  style={{ width: '100%' }}
                  value={ut}
                  formatter={(value) => ` ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '% de Petro')}
                  parser={(value) => (value ? value.replace(/Bs\s?|(,*)/g, '') : '')}
                  onChange={(value) => {
                    if (value) setUT(value);
                  }}
                />
              </Col>
              <Col>
                <Button loading={loading.UT} type='primary' icon={<SyncOutlined />} onClick={() => updateUT('UT', ut || 0)}>
                  Actualizar
                </Button>
              </Col>
            </Row> */}
            {/* {scales.map((e) => (
              <>
                <Divider orientation='left'>{e.descripcion}</Divider>
                <Row justify='space-between' style={{ marginLeft: 20 }} gutter={[16, 16]}>
                  <Col>
                    <InputNumber
                      min={0}
                      style={{ width: '100%' }}
                      value={typeof e.indicador === 'number' ? e.indicador : parseFloat(e.indicador)}
                      onChange={(value) => {
                        if (value) onChangeValue(e.id, value);
                      }}
                    />
                  </Col>
                  <Col>
                    <Button
                      loading={loading[e.id]}
                      type='primary'
                      icon={<SyncOutlined />}
                      onClick={() => updateValue(e.id, e.indicador)}
                    >
                      Actualizar
                    </Button>
                  </Col>
                </Row>
              </>
            ))} */}
          </>
        ) : (
          <Spin
            className='custom-spinner'
            indicator={<LoadingOutlined style={{ fontSize: 40, color: thm.primaryColor }} spin />}
            size='large'
          />
        )}
      </Modal>
    </React.Fragment>
  );
};

const mapStateToProps = (state: State) => ({
  petro: state.coin.petro,
  thm: state.thm,
  dolar: state.coin.dolar,
  euro: state.coin.euro,
});

export default connect(mapStateToProps, { updatePetro, updateDolar, updateEuro })(Scale);

interface ScaleProps {
  auth: States.Auth;
  petro: number;
  thm: States.ThemeColors;
  euro: number;
  dolar: number;
  updatePetro: (number, string) => Promise<any>;
  updateDolar: (number) => Promise<any>;
  updateEuro: (number) => Promise<any>;
}
