import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { State, States, Taxpayer, Institucion, TramitesDisponibles, Bill } from 'sigt';
import { useWindowDimensions } from '../../utils/hooks';
import { Form, Card, Divider, Typography, Row, Col, List, message, Descriptions, Collapse, Button, Select, Modal, Spin, Empty, Input } from 'antd';
import { CheckOutlined, InfoCircleTwoTone, LoadingOutlined, SearchOutlined } from '@ant-design/icons';
import axios from 'axios';
import moment from 'moment';
import { setProcedure } from '../../redux/actions/procedures';
import { useHistory } from 'react-router';
import LiqueurDataFields from './components/LiqueurDataFields';

import '@ant-design/compatible/assets/index.css';
import '../../assets/css/components/FormGenerator.css';
import OrdinanceBill from '../OrdinanceBill';

const server = process.env.REACT_APP_SERVER_URL;

const LiqueurForm : React.FC<LiqueurFormProps> = ({thm, auth, institutions, setProcedure }) =>{
  const [taxpayer, setTaxpayer] = useState<Taxpayer|null>(null);
  const [loading, setLoading] = useState(false);
  const [activeKey, setActiveKey] = useState('0');
  const [selectedTaxpayer, setSelectedTaxpayer] = useState<Sucursal|null>(null);
  const [selectedProcedure, setSelectedProcedure] = useState<TramitesDisponibles|null>(null);
  const [idTramite, setIdTramite] = useState<number>(29);
  const [hasVehicles, setHasVehicles] = useState(false);
  const [created, setCreated] = useState<boolean>(false);
  const [tipoDocumento, setTipoDocumento] = useState<string>('J');
  const [documento, setDocumento] = useState<string|undefined>();
  const [bill, setBill] = useState<Bill>();
  const { width } = useWindowDimensions();
  const history = useHistory();
  const [form] = Form.useForm();
  const procedures = institutions?.find(i => i.id === 9)?.tramitesDisponibles?.filter((e)=> e.id >= 29 && e.id <= 35);

  const formatNumber = (s: string) => `(${s.slice(0, 3)}) ${s.slice(3, 6)}-${s.slice(6)}`;
  const formatCurrency = (number: number) => new Intl.NumberFormat('de-DE').format(number);

  const getRims = async () => {
    if (documento && typeof +documento === 'number' && !Number.isNaN(+documento)) {
        try {
        setLoading(true);
        const response = await axios.get<{ contribuyentes: Taxpayer[] }>(`${server}/settlements/search/taxPayer?doc=${documento}&pref=${tipoDocumento}`,
          { headers: { Authorization: `Bearer ${auth.token}` } });
        setTaxpayer(response.data.contribuyentes[0]);
      } catch(e) {
        message.error(e.response?.data?.message || 'Error al obtener referencias de inmuebles')
      } finally {
        setLoading(false);
      }
    }else return message.error('Debe ingresar un documento valido');
  };

  useEffect(() => {
    if(taxpayer?.usuarios && taxpayer?.usuarios.length > 0) form.setFieldsValue({ usuario: taxpayer.usuarios[0].id });
    // eslint-disable-next-line
  }, [taxpayer]);

  const tipoAddon = (
    <Select defaultValue='V' value={tipoDocumento} onChange={(e) => setTipoDocumento(e)}>
      <Select.Option value='V'>V</Select.Option>
      <Select.Option value='E'>E</Select.Option>
      <Select.Option value='J'>J</Select.Option>
      <Select.Option value='G'>G</Select.Option>
      <Select.Option value='P'>P</Select.Option>
      <Select.Option value='C'>C</Select.Option>
    </Select>
  );

  useEffect(()=>{
    const idTramite = form.getFieldValue('tipoTramite');
    if (idTramite){
      setSelectedProcedure(procedures?.find(s => s.id === idTramite) || null)
    }
    //eslint-disable-next-line
  },[form])

  const handleSubmit = async () => {
    const licType = {
      'LC': { horario:'LUN. A DOM. DE ONCE AM A TRES AM (11:00 AM A 03:00 AM)', clasificacion:'EXPENDIO DE CONSUMO' },
      'LE': { horario:'LUN. A SAB. DE NUEVE AM A DIEZ PM (09:00 AM A 10:00 PM), Y DOMINGO DE NUEVE DE LA MAÑANA A CUATRO DE LA TARDE (9:00 AM A 4:00 PM)', clasificacion:'EXPENDIO DE LICORES' },
      'LD': { horario:'LUN. A SAB. DE 06:00 A.M A 08:00PM (06:00 A.M A 08:00PM)', clasificacion:'DISTRIBUCIÓN DE LICORES' },
      'LT': { clasificacion: 'EXPENDIO DE CONSUMO TEMPORAL' }
    };
    if (!bill || bill?.totalBs === 0) return message.error('Debe agregar items al presupuesto que debera ser pagado por el usuario.')
    const { tipoTramite, ...datos } = await form.validateFields();
    setLoading(true);
    if(tipoTramite !== 32) datos.horario = tipoTramite === 29 || tipoTramite === 33 ? licType['LE'].horario : tipoTramite === 30 || tipoTramite === 34 ?  licType['LD'].horario : licType['LC'].horario;
    datos.clasificacion = datos.clasificacion = tipoTramite === 29 || tipoTramite === 33 ? licType['LE'].clasificacion : tipoTramite === 30 || tipoTramite === 34 ? licType['LD'].clasificacion : tipoTramite === 32 ? licType['LT'] : licType['LC'].clasificacion;
    datos.costo = bill?.totalBs;
    if(!hasVehicles) datos.vehiculos = [];
    const tramite = {
      datos,
      tipoTramite,
      bill
    };
    try {
      const resp = await axios.post(`${server}/procedures/init`, { tramite }, { headers: { Authorization: `Bearer ${auth?.token}` }});
      message.success('Tramite creado exitosamente');
      setProcedure(resp.data.tramite);
      setCreated(true);
      form.resetFields();
      history.push('/dashboard/bandejas');
    } catch(e) {
      message.error( e.response && e.response?.data?.message ? e.response?.data?.message : 'Error en el servidor');
    } finally { setLoading(false) }
  }

  const initialValues = {
    tipoTramite: 29,
    contribuyente: auth?.user?.contribuyente,
    denomComercial: selectedTaxpayer?.denomComercial,
    representanteLegal: selectedTaxpayer?.nombreRepresentante,
    referenciaMunicipal: selectedTaxpayer?.referenciaMunicipal,
    telefonoMovil: selectedTaxpayer?.telefono,
    telefono: selectedTaxpayer?.telefono,
    documento: taxpayer?.documento,
    tipoDocumento: taxpayer?.tipoDocumento || 'J',
    tipoDocumentoRepresentante: 'V',
    poseiaLicencia: false,
    razonSocial: taxpayer?.razonSocial
  };

  return <Card style={{ height: '100%' }} title={'Licencia de Licores'} bodyStyle={{ height: 'calc(100% - 88px)', overflowY: 'scroll', overflowX: 'hidden' }}
  headStyle={{ height: 64, backgroundColor: thm.primaryColor, padding: width < 992 ? '0 10px' : '0 20px', color: 'white'}}>
    {(loading) && 
      <Col span={24} style={{ display: 'flex', marginTop: 150, alignItems: 'center', justifyContent: 'center' }}>
        <Spin tip='Obteniendo referencias...' indicator={<LoadingOutlined style={{ fontSize: 50, color: thm.primaryColor, margin: '15px 0px' }} />} />
      </Col>}
    {(!taxpayer && !loading) && <Row gutter={[8,16]}>
      <Col span={24}>
        <Typography.Text strong>
          <InfoCircleTwoTone style={{ marginRight: 8 }}/>Ingrese el documento del contribuyente que solicita el trámite.&nbsp;
        </Typography.Text>
      </Col>
      <Col md={8} xs={24}><Input placeholder='Documento de Identidad' addonBefore={tipoAddon} value={documento} onChange={e => setDocumento(e.target.value)} /></Col>
      <Col xs={24}><Button type='primary' icon={<SearchOutlined/>} onClick={() => getRims()}>Buscar</Button></Col>
    </Row>} 
    {(taxpayer && !selectedTaxpayer && !loading) &&
      <React.Fragment>
        <Row>
          <Col span={24}>
            <Typography.Text strong>
              <InfoCircleTwoTone style={{marginRight: 8}}/>Por favor seleccione la sucursal con la que desea solicitar el tramite.&nbsp;
            </Typography.Text>
          </Col>
        </Row>
        <Typography.Title level={4} style={{ fontSize: 16, marginTop: 15 }}>Referencias Municipales (Documento de Identidad.)</Typography.Title>
        <Row gutter={[8,8]}>
          <Col span={24} style={{ marginTop: 20 }}>
            {taxpayer?.sucursales? 
              <Collapse activeKey={activeKey} onChange={c => setActiveKey(c as string)}>
              {taxpayer?.sucursales.filter((s) => !s.referenciaMunicipal.startsWith('AR')).map((r,i) => 
                <Collapse.Panel header={`${r.referenciaMunicipal} - ${r.denomComercial}`} key={i} forceRender extra={<Button size='small' type='primary' icon={<CheckOutlined/>} onClick={() => setSelectedTaxpayer(r)}>Seleccionar</Button>}>
                  <Descriptions bordered column={width > 1200 ? 2 : 1} layout={width > 768 ? 'horizontal' : 'vertical'}>
                    <Descriptions.Item label='Denominación Comercial'>{r.denomComercial || 'N/A'}</Descriptions.Item>
                    <Descriptions.Item label='Nombre del Representante'>{r.nombreRepresentante || 'N/A'}</Descriptions.Item>
                    <Descriptions.Item label='Fecha de Aprobación'>{r.fechaAprobacion ? moment(r.fechaAprobacion).format('DD/MM/YYYY') : 'N/A'}</Descriptions.Item>
                    <Descriptions.Item label='Estado de la Licencia'>{r.estadoLicencia || 'No Definido'}</Descriptions.Item>
                    <Descriptions.Item label='Correo Electrónico'>{r.email || 'N/A'}</Descriptions.Item>
                    <Descriptions.Item label='Teléfono'>{r.telefono ? `+58 ${formatNumber(r.telefono)}` : 'N/A'}</Descriptions.Item>
                    <Descriptions.Item label='Credito Fiscal'>Bs. {formatCurrency(parseFloat(r.creditoFiscal || '0'))}</Descriptions.Item>
                  </Descriptions>
                </Collapse.Panel>)}
              </Collapse>
              : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description='No posee sucursales'/>}
          </Col>
          <Col><Button onClick={() => { setTaxpayer(null); setDocumento(undefined); }}>Atrás</Button></Col>
        </Row>
      </React.Fragment>}
    {(taxpayer && selectedTaxpayer) && <>
      <Form scrollToFirstError onFinish={handleSubmit} initialValues={initialValues} form={form} layout='vertical' style={{ padding: width < 992 ? '0 10px' : '0px 20px' }} onValuesChange={(c, v) => { form.setFieldsValue(v);}}>
        <Divider orientation='left' style={{ marginLeft: -30, marginBottom: 0 }}>
          <Typography.Title ellipsis level={4} style={{ marginLeft: 5 }}>
            Usuario de S.U.T. 
          </Typography.Title>
        </Divider>
        <Row gutter={24}>
          <Col xl={8} xs={24}>
            <Form.Item name='usuario' label='Usuario de S.U.T.' rules={[{ required: true, message: 'Debe incluir el correo del usuario de S.U.T. Obligatoriamente' }]}>
              <Select placeholder='Seleccione el usuario'>
                {taxpayer.usuarios?.map(u => 
                  <Select.Option key={u.id} value={u.id}>{u.correo}</Select.Option>  
                )}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Divider orientation='left' style={{ marginLeft: -30 }}>
          <Typography.Title level={4}>Tipo de Trámite</Typography.Title>
        </Divider>
        <Row gutter={24}>
          <Col xs={24} xl={12}>
            <Form.Item label='Trámite' name='tipoTramite' rules={[{required: true, message: 'Debe ingresar seleccionar un trámite'}]}>
              <Select onChange={(id:number) => setIdTramite(id)}>
                {procedures?.map((t) => <Select.Option value={t.id} key={t.id}>
                  {t.titulo}
                </Select.Option> )}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <LiqueurDataFields form={form} hasVehicles={hasVehicles} setHasVehicles={setHasVehicles} selectedProcedureId={idTramite || 0}/>
        <Divider orientation='left' style={{ marginLeft: -30 }}>
          <Typography.Title level={4}>Recaudos</Typography.Title>
        </Divider>
        <List style={{ marginBottom: 20, width: '100%' }} bordered dataSource={selectedProcedure?.recaudos} size='small' renderItem={item => (
          <List.Item>
            <Typography.Text strong>
              {item.nombreCompleto}
              {<Typography.Text strong style={{ color: '#ff4d4f' }}>&nbsp;FISICO</Typography.Text>}
            </Typography.Text>
          </List.Item>
        )}/>
        <Divider orientation='left' style={{ marginLeft: -20 }}>
          <Typography.Title level={4}>Presupuesto</Typography.Title>
        </Divider>
        <OrdinanceBill procedureType={idTramite} handleSetBill={setBill} />
        <Button style={{ marginTop: 16 }} loading={loading} type='primary' htmlType='submit'>Iniciar Tramite</Button>
      </Form>
      <Modal footer={null} centered title={!created ? 'Procesando tramite...' : 'Tramite creado'} visible={loading} onOk={() => { setLoading(false) }} >
        <Row justify='center' align='middle'><Spin tip='Creando tramite...' indicator={<LoadingOutlined style={{ fontSize: 40, color: thm.primaryColor }} />} /></Row>
        {created && <Typography.Text strong>Tramite creado exitosamente!</Typography.Text>}
      </Modal>
    </>}
  </Card>;
}


const mapStateToProps = (state: State) => ({ thm: state.thm, auth: state.auth, institutions: state.inst.institutions, });

export default connect(mapStateToProps, { setProcedure })(LiqueurForm);

interface LiqueurFormProps {
  auth: States.Auth
  thm: States.ThemeColors
  institutions: Institucion[] | null
  setProcedure: Function
}

interface Sucursal {
  actualizado: boolean
  denomComercial: string
  email: string
  fechaAprobacion: string
  id: string
  nombreRepresentante: string
  referenciaMunicipal: string
  telefono: string
}