import React from 'react';
import { Select } from 'antd';

interface IProcessObject {
  id: number;
  nombre: ProcessName;
}

type ProcessName = 'Aprobar' | 'Inspeccionar' | 'Corregir';
type ProcessNameServer = 'inspeccion' | 'correccion' | 'revision' | null;

export const processNameSelector = (process: ProcessName): ProcessNameServer => {
  if (process === 'Aprobar') return 'revision';
  if (process === 'Corregir') return 'correccion';
  if (process === 'Inspeccionar') return 'inspeccion';
  return null;
};

const ProcessSelect: React.FC<ProcessSelectProps> = ({ value, onChange, disabled = false }) => {
  const process: IProcessObject[] = [
    { id: 1, nombre: 'Aprobar' },
    { id: 2, nombre: 'Inspeccionar' },
    { id: 3, nombre: 'Corregir' },
  ];
  return (
    <Select placeholder={'Seleccione el Proceso'} value={value} onChange={onChange} disabled={disabled}>
      {process.map((p) => (
        <Select.Option value={p.nombre} key={p.id}>
          {p.nombre}
        </Select.Option>
      ))}
    </Select>
  );
};

export default ProcessSelect;

interface ProcessSelectProps {
  value?: any;
  onChange?: (s: any) => any;
  disabled?: boolean;
}
