import React, { useState, useEffect, useRef } from 'react';
import { States, State } from 'sigt';
import { connect } from 'react-redux';
import { Card, Table, message, Row, Col, Form, Input, Select, Button, Tooltip, Spin, Typography } from 'antd';
import { useWindowDimensions } from '../../utils/hooks';
import axios from 'axios';
import Highlighter from 'react-highlight-words';
import { ColumnsType } from 'antd/lib/table';
import { SearchOutlined, EyeOutlined, PlusOutlined, DeleteOutlined, LoadingOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router';
import { Utils } from '../../utils/validators';
const server = process.env.REACT_APP_SERVER_URL;

const RegisterCondomium: React.FC<RegisterCondomiumProps> = ({ thm, auth }) => {
  const [loading, setLoading] = useState(false);
  const [creating, setCreating] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [condominiums, setCondominiums] = useState<Condomium[]>([]);
  const [tipoDocumento, setTipoDocumento] = useState('J');
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const inputRef = useRef<any>(null);

  const history = useHistory();
  const { width } = useWindowDimensions();
  const [form] = Form.useForm();

  const tipoAddon = (
    <Select defaultValue='V' value={tipoDocumento} onChange={(e) => setTipoDocumento(e)}>
      <Select.Option value='V'>V</Select.Option>
      <Select.Option value='E'>E</Select.Option>
      <Select.Option value='J'>J</Select.Option>
      <Select.Option value='G'>G</Select.Option>
      <Select.Option value='P'>P</Select.Option>
    </Select>
  );

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = clearFilters => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={inputRef}
          placeholder='Buscar'
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type='primary'
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size='small'
          style={{ width: 90, marginRight: 8 }}
        >
          Buscar
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size='small' style={{ width: 90 }}>
          Restablecer
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined style={{ color: filtered ? thm.primaryColor : undefined }} />
    ),
    onFilter: (value, record) => 
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        if (inputRef.current) {
          inputRef.current?.select();
        }
      }
    },
    render: text =>  searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#1e72c675', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
    ),
  });

  const columns: ColumnsType<Condomium> = [
    {
      title: 'Razón Social', 
      dataIndex: 'razonSocial',
      ...getColumnSearchProps('razonSocial'),
    },
    {
      title: 'Documento de Identidad',
      dataIndex: 'documento',
      ...getColumnSearchProps('documento')
    },
    {
      title: 'Acciones',
      dataIndex: 'idCondominio',
      render: (id) => <>{!deleting ? <span>
        <Tooltip title='Ver Propietarios'>
          <Button type='link' icon={<EyeOutlined />} onClick={() => history.push(`./ver/${id}`)} style={{ marginRight: 8, border: 'none' }} />
        </Tooltip>
        <Tooltip title='Eliminar Condominio'>
          <Button type='link' icon={<DeleteOutlined />} onClick={() => deleteCondominium(id) } style={{ border: 'none' }}/>
        </Tooltip>
      </span> : <Spin indicator={<LoadingOutlined />} style={{ marginLeft: 20 }} />}</>
    }
  ];

  useEffect(() => {
    fetchCondominiums();
    // eslint-disable-next-line
  }, []);

  const fetchCondominiums = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${server}/condominium/`, { headers: { Authorization: `Bearer ${auth.token}` } });
      setCondominiums(response.data.data);
    } catch(e) {
      message.error(e.response?.data?.message || 'Error al obtener condominios');
    } finally {
      setLoading(false);
    }
  };

  const addCondominium = async () => {
    const values = await form.validateFields();
    if(!tipoDocumento) return message.error('Debe incluir el tipo de documento');
    setCreating(true);
    try {
      const response = await axios.post(`${server}/condominium/`, { type_doc: tipoDocumento, doc: values.documento }, { headers: { Authorization: `Bearer ${auth.token}` } });
      message.success('Condominio registrado de manera exitosa');
      setCondominiums([response.data.data, ...condominiums]);
    } catch(e) { 
      message.error(e.response?.data?.message || 'Error al agregar condominio');
    } finally {
      setCreating(false);
    }
  };

  const deleteCondominium = async (id: number) => {
    setDeleting(true)
    try {
      const response = await axios.delete(`${server}/condominium/${id}`, { headers: { Authorization: `Bearer ${auth.token}` } });
      message.success('Condominio eliminado de manera exitosa');
      const _condominiums = condominiums.filter((c) => c.idCondominio !== id);
      setCondominiums(_condominiums);
    } catch(e) { 
      message.error(e.response?.data?.message || 'Error al eliminar condominio');
    } finally {
      setDeleting(false);
    }
  };

  return (
    <Card style={{ height: '100%' }} title='Condominios' bodyStyle={{ height: 'calc(100% - 88px)', overflowY: 'scroll', overflowX: 'hidden', border: '1px solid #f0f0f0' }}
      headStyle={{ height: 64, backgroundColor: thm.primaryColor, padding: width < 1200 ? '0 10px' : '0 20px', color: 'white'}}>
      <Form scrollToFirstError layout='vertical' form={form}>
        <Row gutter={24}>
          <Col xs={24} xl={8}>
            <Form.Item name='documento' label='Documento de Identidad' rules={[{ required: true, message: 'Debe ingresar el documento de identidad' }]} normalize={Utils.normalize.isCNumber}>
              <Input placeholder='Documento de Identidad' addonBefore={tipoAddon} />
            </Form.Item>
          </Col>
          <Col xs={24} xl={8}>
            <Button loading={creating} style={{ marginTop: width < 1200 ? 0 : 40 }} onClick={() => addCondominium()} type='primary' icon={<PlusOutlined/>}>
              Agregar
            </Button>
          </Col>
        </Row>
        <Typography.Text strong >Total de Condominios registrados: {condominiums?.length || 0}</Typography.Text>
        <Table loading={loading} columns={columns} dataSource={condominiums} rowKey={({ idCondominio }) => idCondominio}/>
      </Form>
    </Card>
  );
};

const mapStateToProps = (state: State) => ({ thm: state.thm, auth: state.auth });

export default connect(mapStateToProps)(RegisterCondomium);

interface RegisterCondomiumProps {
  thm: States.ThemeColors
  auth: States.Auth
}

interface Condomium { 
  idCondominio: number;
  documento: string;
  razonSocial: string;
}