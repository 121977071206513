import React from 'react';
import { Select } from 'antd';

const AgentSelect: React.FC<AgentSelectProps> = ({ onChange, value, placeholder, disabled = false }) => {
  return (
    <Select value={value} onChange={onChange} placeholder={placeholder} disabled={disabled}>
      <Select.Option value='Aplica'>Agente de Rentención</Select.Option>
      <Select.Option value='No aplica'>Contribuyente</Select.Option>
    </Select>
  );
};

export default AgentSelect;

interface AgentSelectProps {
  onChange?: (e: any) => any
  value?: any
  placeholder?: string
  disabled?: boolean
}