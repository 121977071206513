import { Categoria, States, VehicleType } from "sigt";
import { VehiclesType, SET_BRANDS, SET_TYPES, SET_VEHICLES, SET_VEHICLE, DELETE_VEHICLE, AUTH_USER_LOGOUT, AuthLogoutAction } from "../actions/actionsTypes";

const getSubcategories = (arr) => {
  const pivot : VehicleType[] = [...arr];
  let subCategories: Categoria['subcategorias'] = [];
  pivot.forEach(el => {
    el.categorias.forEach((cat) => {
      const _subcategories = cat.subcategorias.map((sc) => ({...sc, tipo: el.id}));
      subCategories = [...subCategories, ..._subcategories];
    });
  });
  return subCategories;
}

const initialState: States.Vehicles = {
  vehicles: [],
  brands: [],
  types: [],
  subCategories: []
};

export default (state = initialState, action: VehiclesType | AuthLogoutAction): States.Vehicles => {
  switch(action.type) {
    case SET_VEHICLES: return {...state, vehicles: action.payload };
    case SET_VEHICLE: return {...state, vehicles: [...state.vehicles, action.payload ]};
    case DELETE_VEHICLE:{
      const _vehicles = [...state.vehicles].filter(v => v.id !== action.payload);
      return {...state, vehicles: _vehicles};
    }
    case SET_BRANDS: return {...state, brands: action.payload };
    case SET_TYPES:
      return {...state, types: action.payload, subCategories: getSubcategories(action.payload) };
    case AUTH_USER_LOGOUT:
      return {...state, vehicles: []};
    default: return state;
  };
};