import React, { useEffect, useState, Fragment } from 'react';
import { Card, Form, Input, Button, Row, Col, Select, Modal, Drawer } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import { Utils } from '../../../utils/validators';
import { connect } from 'react-redux';
import { signIn, signUp, editUser } from '../../../redux/actions/auth';
import { createOfficial, editOfficial } from '../../../redux/actions/official';
import { State, States, Institucion, TramitesDisponibles } from 'sigt';
import { useLocation } from 'react-router';
import handlingMessage from '../../../utils/handlingMessage';
import { fetchInstitutions } from '../../../services/institutions';
import { UserOutlined, PhoneFilled } from '@ant-design/icons';
// import { useWindowDimensions } from "../../../utils/hooks";

const RegisterForm: React.FC<UserFormProps> = ({
  thm,
  signIn,
  auth,
  editUser,
  createOfficial,
  ofc,
  editOfficial,
  signUp,
  modal,
  inst,
  drawer,
}) => {
  // asdas
  const { Option } = Select;
  const history = useHistory();
  const location = useLocation();
  const [options, setOptions] = useState<Institucion[]>([]);
  const [institution, setInstitution] = useState<number | null>(null);
  const [procedures, setProcedures] = useState<TramitesDisponibles[] | undefined>([]);
  const useQuery = () => new URLSearchParams(location.search);
  const queryParams = useQuery();
  const params: any = useParams();
  const [form] = Form.useForm();
  const [nacionalidad, setNacionalidad] = useState('V');
  const [typeUser, setTypeUser] = useState<number | undefined>();
  const [disable, setDisable] = useState(false);
  // const { width } = useWindowDimensions();
  const [visible, setVisible] = useState<boolean>(true);
  const [cargo, setCargo] = useState<number | undefined>();
  const [descripcion, setDescripcion] = useState<string | undefined>();

  //id de tramites que se pueden ser cancelados en caja de sedemat
  const prcdPayableBySedemat: number[] = [];
  const cashierSedemat: number[] = [22, 25, 40, 41];

  //moods
  const editFuncionario = location.pathname.includes('/dashboard/funcionarios/editar');
  const createFuncionario = location.pathname.includes('/dashboard/funcionarios/crear');
  const dashboard = location.pathname.includes('/dashboard');
  const registro = location.pathname.includes('/registro');
  const editarPerfil = location.pathname.endsWith('/editarPerfil');

  const title = editFuncionario
    ? 'Editar Funcionario'
    : createFuncionario
    ? 'Crear Funcionario'
    : registro
    ? 'Registro'
    : editarPerfil
    ? 'Editar Perfil'
    : null;

  useEffect(() => {
    if (!dashboard) {
      const oauth = queryParams.get('oauth') ? queryParams.get('oauth') : '';
      const name = queryParams.get('name') ? queryParams.get('name') : '';
      setDisable(oauth ? true : false);
      form.setFieldsValue({
        nombreUsuario: oauth,
        nombreCompleto: name,
      });
    }
    if (editFuncionario && params?.id && ofc.officials.length > 0) {
      const official = ofc.officials?.find((ele) => ele.id === parseInt(params?.id));
      if (official?.nacionalidad) setNacionalidad(official.nacionalidad);
      setTypeUser(official?.tipoUsuario);
      setCargo(official?.cargo);
      setDescripcion(official?.descripcion);
      //se filtran los tramites los tramites que pueden ser pagados por los cajeros para que no aperezca en las opciones seleccionadas
      form.setFieldsValue(
        official ? { ...official, permisos: official.permisos?.filter((p) => !prcdPayableBySedemat.includes(p)) } : {}
      );
      form.setFieldsValue({ cargo: official?.cargo });
    } else if (editFuncionario) {
      history.push('/dashboard/funcionarios');
    } else if (editarPerfil) {
    } else {
      getInstitutions();
    }
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (editarPerfil) {
      const { user } = auth;
      if (user?.nacionalidad) setNacionalidad(user.nacionalidad);
      form.setFieldsValue({ ...user });
    }
    //eslint-disable-next-line
  }, [auth.user]);

  useEffect(() => {
    if (auth.user?.tipoUsuario === 2) {
      const data = inst.institutions?.find((ele) => ele.id === auth.user?.institucion?.id)?.tramitesDisponibles;
      setProcedures(data);
    }
    //eslint-disable-next-line
  }, [procedures]);

  useEffect(() => {
    if (auth.token && !dashboard) {
      history.push('/dashboard');
    }
    // eslint-disable-next-line
  }, [auth.token, location.pathname]);

  useEffect(() => {
    form.resetFields(['cargo']);
    // eslint-disable-next-line
  }, [institution, typeUser]);

  const renderWrapper = () => {
    if (drawer)
      return (
        <Drawer
          title={title}
          maskClosable
          width={320}
          onClose={() => setVisible(false)}
          afterVisibleChange={(visible) => {
            if (!visible) history.goBack();
          }}
          visible={visible}
        >
          {content}
        </Drawer>
      );

    if (modal)
      return (
        <Modal
          title={title}
          maskClosable
          footer={null}
          width={900}
          visible={visible}
          centered
          style={{ maxHeight: '700px' }}
          afterClose={() => history.goBack()}
          onCancel={() => setVisible(false)}
        >
          {content}
        </Modal>
      );

    return (
      <Card
        title={title || undefined}
        style={{ width: !dashboard ? 550 : undefined }}
        bodyStyle={{ padding: dashboard ? '30px 50px' : '24px' }}
        headStyle={dashboard ? { backgroundColor: thm.primaryColor, color: '#fff' } : {}}
      >
        {content}
      </Card>
    );
  };
  const comparePassword = (rule, value, callback) => {
    if (value && value !== form.getFieldValue('password')) {
      return Promise.reject('Las contraseñas no coinciden!');
    } else {
      return Promise.resolve();
    }
  };
  const compareEmail = (rule, value, callback) => {
    if (value && value !== form.getFieldValue('nombreUsuario')) {
      return Promise.reject('Los correos no coinciden!');
    } else {
      return Promise.resolve();
    }
  };
  const cedSelector = (
    <Select disabled={editarPerfil ? true : false} value={nacionalidad} onChange={setNacionalidad}>
      <Option value='V'>V-</Option>
      <Option value='E'>E-</Option>
      <Option value='J'>J-</Option>
      <Option value='G'>G-</Option>
      <Option value='C'>C-</Option>
    </Select>
  );

  const getInstitutions = async () => {
    if (auth.user?.tipoUsuario === 1) {
      const { instituciones } = await fetchInstitutions(auth.token);
      setOptions(instituciones);
    }
  };

  const handleSubmit = async () => {
    const values = await form.validateFields();
    values.nacionalidad = nacionalidad;
    const key = queryParams.get('token') ? queryParams.get('token') : null;
    const typeUser = ofc.officials?.find((ele) => ele.id === parseInt(params?.id))?.tipoUsuario;
    values.tipoUsuario = values.tipoUsuario ? values.tipoUsuario : typeUser;
    if (auth.user?.tipoUsuario === 2) {
      values.institucion = auth.user.institucion.id;
      if (auth.user.institucion.id === 9 && cashierSedemat.includes(values.cargo || 0)) {
        values.permisos = [...values.permisos, ...prcdPayableBySedemat];
      }
    }
    handlingMessage({
      action: () =>
        key
          ? signIn(values, key)
          : !dashboard
          ? signUp(values)
          : editFuncionario
          ? editOfficial(values, auth.token, params?.id)
          : editarPerfil
          ? editUser({ id: auth.user?.id, ...values })
          : createOfficial(values, auth.token, auth.user?.tipoUsuario),
      key: 'submit',
      loadingMessage: 'Realizando operacion...',
      cb: (data) => {
        if (editarPerfil && data.status === 200) history.goBack();
        if (editFuncionario && data.status === 200) history.goBack();
        if (createFuncionario && data.status === 201) form.resetFields();
      },
    });
  };
  const renderCharge = () => {
    const id = institution;
    const info = [ 
      { id: 3, descripcion: 'Funcionario', cargos: [ 
        { id: 24, descripcion: 'Funcionario General'}, 
        { id: 32, descripcion: 'Validador de Contribuyentes'},
        { id: 97, descripcion: 'Fiscal'},
        { id: 99, descripcion: 'Funcionario Certificador'}, 
        { id: 100, descripcion: 'Funcionario 1x1000'},
        { id: 101, descripcion: 'Jefe de Control de Timbres Fiscales'},
        { id: 102, descripcion: 'Jefe de Minas'}, 
        { id: 103, descripcion: 'Consultoría Jurídica'}, 
        { id: 104, descripcion: 'Jefe de Licores'},  
        { id: 106, descripcion: 'Funcionario Soporte'},  
        { id: 107, descripcion: 'Jefe de Intimaciones'},  
      ] },
      { id: 5, descripcion: 'Superintendente', cargos: [ {id: 56,  descripcion: 'Superintendente General'}, {id: 105,  descripcion: 'Intendente'} ] },
      { id: 6, descripcion: 'Director', cargos: [ {id: 35,  descripcion: 'Director de Recaudación'} ] },
      { id: 2, descripcion: 'Administrador', cargos: [ {id: 98,  descripcion: 'Administrador del Sistema'} ] },
    ];

    const charges = info
    const data =
      auth.user?.tipoUsuario === 1
        ? charges?.filter((el) => el.cargos.length > 0 && el.id === 2)
        : charges?.filter((el) => el.cargos.length > 0 && el.id === typeUser);
      
    if (data) {
      return (
        <Col xs={24} sm={24} md={12}>
          <Form.Item rules={[{ required: true, message: 'Por favor ingresar Cargo!' }]} name='cargo'>
            <Select placeholder='Cargo'>
              {data?.map((option) =>
                option.cargos.map(
                  (option) =>
                    (
                      <Option key={option.id} value={option.id}>
                        {option.descripcion}
                      </Option>
                    )
                )
              )}
            </Select>
          </Form.Item>
        </Col>
      );
    }
  };
  const renderFunctFields = () => {
    const password = editFuncionario ? (
      (auth.user?.tipoUsuario === 2 || auth.user?.tipoUsuario === 5) ? (
        <>
          <Col xs={24} sm={24} md={12}>
            <Form.Item rules={[{ required: true, message: 'Por favor ingresar tipo de usuario!' }]} name='tipoUsuario'>
              <Select placeholder='Tipo de Usuario' onChange={(value: number) => setTypeUser(value)}>
                <Option value={3}>Funcionario</Option>
                <Option value={5}>Superintendente</Option>
                <Option value={6}>Director</Option>
                <Option value={2}>Administrador</Option>
              </Select>
            </Form.Item>
          </Col>
          {renderCharge()}
        </>
      ) : null
    ) : (
      <>
        {editarPerfil ? null : (
          <>
            <Col xs={24} sm={24} md={12}>
              <Form.Item
                normalize={Utils.normalize.notDotTab}
                rules={[{ required: true, message: 'Por favor ingresar contraseña!' }, { validator: Utils.validators.password }]}
                name='password'
              >
                <Input.Password maxLength={16} placeholder='Contraseña' />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12}>
              <Form.Item
                rules={[{ required: true, message: 'Por favor corfirmar tu contraseña!' }, { validator: comparePassword }]}
                name='confirm'
              >
                <Input.Password maxLength={16} placeholder='Confirmar Contraseña' />
              </Form.Item>
            </Col>
          </>
        )}
        {auth.user?.tipoUsuario === 1 ? (
          <>
            <Col xs={24} sm={24} md={12}>
              <Form.Item rules={[{ required: true, message: 'Por favor ingresar institucion!' }]} name='institucion'>
                <Select onChange={(value: number) => setInstitution(value)} placeholder='Institucion'>
                  {options?.map((option) => (
                    <Option key={option.id} value={option.id}>
                      {option.nombreCorto} - {option.nombreCompleto}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            {renderCharge()}
          </>
        ) : (auth.user?.tipoUsuario === 2 || auth.user?.tipoUsuario === 5)? (
          <>
            <Col xs={24} sm={24} md={12}>
              <Form.Item rules={[{ required: true, message: 'Por favor ingresar tipo de usuario!' }]} name='tipoUsuario'>
                <Select placeholder='Tipo de Usuario' onChange={(value: number) => setTypeUser(value)}>
                  <Option value={3}>Funcionario</Option>
                  <Option value={5}>Supeintendente</Option>
                  <Option value={6}>Director</Option>
                  <Option value={2}>Administrador</Option>
                </Select>
              </Form.Item>
            </Col>
            {renderCharge()}
          </>
        ) : null}
      </>
    );

    return (
      <Row gutter={16}>
        {password}
        { (auth.user?.tipoUsuario === 2 || auth.user?.tipoUsuario === 5) &&
        // (typeUser === 3) &&
        auth.user?.institucion?.id !== 6 &&
        auth.user?.institucion?.id !== 7 ? ( typeUser === 3 ? 
          (
          <>
            <Col xs={24} sm={24} md={12}>
              <Form.Item
                rules={[{ required: auth.user?.institucion?.id !== 9 ? true : false, message: 'Por favor ingresar permisos!' }]}
                name='permisos'
              >
                <Select mode='multiple' placeholder='Tramites disponibles para el Funcionario'>
                    <Option key={27} value={27}>
                      {'Registro de Contribuyentes'}
                    </Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12}>
              <Form.Item
                rules={[{ required: false }]}
                name='descripcion'
              >
                <Input.TextArea rows={4} placeholder='Descripción del Funcionario' value={descripcion} onChange={(e) => setDescripcion(e.target.value)} />
              </Form.Item>
            </Col>
          </>
        ) : (
          <>
          <Col xs={24} sm={24} md={12}>
            <Form.Item
              rules={[{ required: false }]}
              name='descripcion'
            >
              <Input.TextArea rows={4} placeholder='Descripción del Funcionario' value={descripcion} onChange={(e) => setDescripcion(e.target.value)} />
            </Form.Item>
          </Col>
        </>
        )
        ): null}
      </Row>
    );
  };

  const user = (
    <>
      {!editFuncionario && (
        <Row gutter={16}>
          <Col flex={1}>
            <Form.Item
              normalize={Utils.normalize.toLower}
              rules={[{ required: true, message: 'Por favor ingresar correo!' }, { validator: Utils.validators.email }]}
              name='nombreUsuario'
            >
              <Input
                style={{textTransform:'uppercase'}}
                prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                placeholder='Correo'
                disabled={editarPerfil || disable}
              />
            </Form.Item>
          </Col>
          {!editarPerfil && (
            <Col flex={1}>
              <Form.Item
                normalize={Utils.normalize.toLower}
                rules={[{ required: true, message: 'Por favor ingresar correo!' }, { validator: compareEmail }]}
                name='confirnNombreUsuario'
              >
                <Input
                  prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                  placeholder='Repetir Correo'
                  disabled={editarPerfil || disable}
                />
              </Form.Item>
            </Col>
          )}
        </Row>
      )}
      <Row gutter={16}>
        {editFuncionario && (
          <Col flex={1}>
            <Form.Item
              normalize={Utils.normalize.toLower}
              rules={[{ required: true, message: 'Por favor ingresar correo!' }, { validator: Utils.validators.email }]}
              name='nombreUsuario'
            >
              <Input
                prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                placeholder='Correo'
                disabled={editarPerfil || disable}
              />
            </Form.Item>
          </Col>
        )}
        <Col flex={1}>
          <Form.Item
            rules={[{ required: true, message: dashboard ? 'Por favor ingresa Nombre!' : 'Por favor ingresa tu Razon Social!' }]}
            name='nombreCompleto'
            normalize={Utils.normalize.toUpperName}
          >
            <Input
              prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder={dashboard ? 'Nombre' : 'Razon Social'}
            />
          </Form.Item>
        </Col>
      </Row>
    </>
  );

  const content = (
    <Form className='login-form' onFinish={handleSubmit} form={form}>
      {user}
      <Row gutter={16}> 
        <Col xl={12} xs={24}>
          <Form.Item
            rules={[
              { required: true, message: 'Por favor ingresar tu documento de identidad!' },
            ]}
            normalize={Utils.normalize.isCNumber}
            name='cedula'
          >
            <Input
              prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder='RIF o CI'
              disabled={editarPerfil}
              addonBefore={cedSelector}
              maxLength={10}
            />
          </Form.Item>
        </Col>
        <Col xl={12} xs={24}>
          <Form.Item
            rules={[{ required: true, message: 'Por favor ingresar tu telefono!' }, { validator: Utils.validators.phoneNumber }]}
            normalize={Utils.normalize.isNumber}
            name='telefono'
            validateTrigger='onBlur'
          >
            <Input
              maxLength={10}
              addonBefore='+58'
              prefix={<PhoneFilled style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder='Telefono'
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col flex={1}>
          <Form.Item rules={[{ required: true, message: 'Por favor ingresar tu direccion!' }]} name='direccion'>
            <Input.TextArea maxLength={250} style={{ resize: 'none' }} placeholder='Direccion' />
          </Form.Item>
        </Col>
      </Row>
      {renderFunctFields()}
      <Row justify='center' style={{ textAlign: 'center' }} align='middle' gutter={16}>
        {!modal ? (
          <Col xl={12}>
            <Button style={{ width: '100%' }} onClick={() => history.goBack()}>
              Atrás
            </Button>
          </Col>
        ) : null}
        <Col xl={12}>
          <Button style={{ width: '100%' }} type='primary' htmlType='submit'>
            {!createFuncionario ? (registro ? 'Registrarse' : 'Guardar Datos') : 'Crear Usuario'}
          </Button>
        </Col>
      </Row>
    </Form>
  );

  form.setFieldsValue({ cargo: cargo });

  return <Fragment>{renderWrapper()}</Fragment>;
};

const mapStateToProps = ({ auth, ofc, inst, thm }: State) => ({ thm, auth, ofc, inst });

export default connect(mapStateToProps, { signIn, createOfficial, editOfficial, signUp, editUser })(RegisterForm);

interface UserFormProps {
  createOfficial: Function;
  signIn: Function;
  signUp: Function;
  editOfficial: Function;
  editUser: Function;
  auth: States.Auth;
  ofc: States.Officials;
  inst: States.Institutions;
  thm: States.ThemeColors;
  modal?: boolean;
  drawer?: boolean;
}
