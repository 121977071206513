import React, { useEffect, useState } from 'react';
import GetTaxpayerInfo from '../../Taxpayer/components/GetTaxpayerInfo';
import {
  Card,
  Form,
  Divider,
  Typography,
  Row,
  Col,
  Select,
  Input,
  Button,
  message,
  Checkbox,
  Tooltip,
  InputNumber,
  DatePicker,
} from 'antd';
import { useWindowDimensions } from '../../../utils/hooks';
import { useParams, useHistory } from 'react-router';
import { States, State, Taxpayer } from 'sigt';
import { connect, useSelector } from 'react-redux';
import moment from 'moment';
import MultipleTransactions from '../../Forms/components/MultipleTransactions';
import axios from 'axios';
import { round } from 'lodash';
import { from } from 'rxjs';
import { DownloadOutlined } from '@ant-design/icons';
const { RangePicker } = DatePicker;
const server = process.env.REACT_APP_SERVER_URL;

const SkipedFineSettlements: React.FC<SkipedFineSettlementsProps> = ({ thm, branches, banks, institutions, auth }) => {
  const [data, setData] = useState<any>({});
  const [sectors, setSectors] = useState<any>([]);
  const [selectedSector, setSelectedSector] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [esVigente, setEsVigente] = useState(true);
  const [files, setFiles] = useState<{ [P: string]: File } | null>(null);
  const [conInteres, setConInteres] = useState(true);
  const [conRecargo, setConRecargo] = useState(false);
  const [interes, setInteres] = useState(0);
  const [recargo, setRecargo] = useState(0);

  const { width } = useWindowDimensions();
  const { id } = useParams<{ id: any }>();
  const history = useHistory();
  const [form] = Form.useForm();
  const disabledDate = (current) => current > moment().endOf('day');

  const selectedInstitution = institutions?.find((i) => i.id === 9);
  const petro = useSelector((state: State) => state.coin.petro);
  const ut = useSelector((state: State) => state.ut.ut);

  const taxPayer: Taxpayer = (history.location.state as any)?.contribuyente || auth?.user?.contribuyente;
  // const showAll = auth.user?.institucion?.id === 9;
  const showAll = true;
  const cpuBranches = ['111', '123', '124'];
  const cantPay = [103];
  const cantSett = [0];

  useEffect(() => {
    if (auth?.user?.tipoUsuario === 4 && !id) {
      if (auth?.user?.contribuyente?.id) {
        history.push(`/dashboard/impuestos/tributoOmitido/${auth?.user?.contribuyente?.id}`);
      } else {
        history.push('/dashboard');
      }
    }
  }, []);

  useEffect(() => {
    form.setFieldsValue({ monto: round((form.getFieldValue('ut') || 0) * round((petro * ut) / 100, 2), 2) });
    let nuevoMonto = round(Number(form.getFieldValue('ut')) * round((petro * ut) / 100, 2), 2);
    let interes = 0;
    let recargo = 0;
    if (conInteres === true) {
      interes = form.getFieldValue('mora') || 0;
      form.setFieldsValue({ utinteres: round(interes * 100 / round((petro * ut), 2), 2) });
    }
    if (conRecargo === true) {
      recargo = round(((nuevoMonto || 0) + interes) * 0.1, 2);
    }
    setInteres(interes);
    setRecargo(recargo);
  }, [data.ut, data.mora, conInteres, conRecargo]);

  // useEffect(() => {
  //   if ( auth?.user?.tipoUsuario !== 4) {
  //     setEsVigente(false);
  //   }
  // },[])

  useEffect(() => {
    if (!(history.location.state as any)?.contribuyente || (history.location.state as any)?.contribuyente?.id !== id) {
      history.push('/dashboard/impuestos/tributoOmitido');
    }
    if (auth.user?.institucion?.nombreCorto !== 'SAGAS' && auth.user?.institucion?.nombreCompleto !== 'CPU') {
      history.goBack();
    }
    // eslint-disable-next-line
  }, []);

  const submit = async () => {
    let values = await form.validateFields();
    const checkF = (i) => {
      return files ? files.hasOwnProperty(`Comprobante${i + 1}`) : false;
    };
    let notP: Array<any> = [];
    if (values.pagos && values.pagos?.length > 0) {
      values.pagos.map((p, i) => {
        p.i = i;
        p.idInstitucionBanco = p.destino;
        p.destino = banks?.find(
          (b) => b.id === selectedInstitution?.cuentasBancarias?.find((c) => c.id === p.destino)?.banco
        )?.id;
        return p;
      });
      let filterP = values.pagos.filter((p) => p.metodoPago !== 'PUNTO DE VENTA');
      if (filterP && filterP.length > 0) {
        filterP.map((p, i) => {
          p.check = checkF(p.i);
          notP.push(p);
        });
      }
    }
    const hasAllMandatoryTakings =
      auth?.user?.tipoUsuario === 4
        ? esVigente
          ? true
          : notP && notP?.length > 0
          ? !notP.some((p, i) => p.check === false)
          : true
        : true;
    const ingresado = esVigente
      ? 0
      : round(
          values.pagos.reduce((prev, current) => prev + current.costo, 0),
          2
        );
    console.log(esVigente, 'VIGENTE');
    console.log(ingresado, 'ingresado');
    console.log(round(values.monto, 2), 'rounded');
    if (!esVigente && round(values.monto + interes + recargo, 2) > ingresado) {
      message.error('El monto de los pagos debe ser igual o mayor al monto del tributo');
    } else if (!hasAllMandatoryTakings && auth?.user?.tipoUsuario === 4) {
      message.error('Debe ingresar un comprobante por cada pago');
    } else {
      if (values.subramo === '') {
        delete values.subramo;
      }
      if (values.aplicacion === '') {
        delete values.aplicacion;
      }
      const ramo = (showAll ? branches : branches.filter((b) => cpuBranches.includes(b.codigo || '')))
        .filter((b) => b.liquidacionEspecial)
        .filter((b) => b.sectorID === sectors.find((s) => s.descripcion === selectedSector)?.id_sector_ramo)
        .filter((element) => element.descripcion === values.ramo)[0]?.id;
      values.fechaCancelada = moment(new Date(), 'DD/MM/YYYY');
      values.fechaCancelada = {
        month: values.fechaCancelada.locale('es').format('MMMM'),
        year: parseInt(values.fechaCancelada.locale('es').format('YYYY')),
      };
      // values.ut = round((round((values.monto || 0),2)) * round(100/(petro * ut),2),2);
      values.monto = +(+values.monto / petro).toFixed(8);
      values.impuestos = [
        {
          fechaCancelada: values.fechaCancelada,
          ramo: 969,
          subramo: values.subramo,
          monto: values.monto,
          aplicacion: values.aplicacion,
        },
      ];
      values.tipoDocumento = taxPayer.tipoDocumento;
      values.documento = taxPayer.documento;
      // values.from = values.fechaNotificada[0];
      // values.to = values.fechaNotificada[1];
      values.tipoContribuyente = taxPayer.tipoContribuyente;
      values.rim = values.rim ? values.rim : taxPayer.documento;
      values.esTributoOmitido = true;
      values.recargo = recargo;
      if (!esVigente) {
        values.pagos
          .filter((p) => p)
          .forEach((p) => {
            Object.keys(p).forEach((k) => {
              if (moment.isMoment(p[k])) {
                p[k] = p[k].toISOString();
              }
            });
          });
      }
      if (values.pagos) {
        for (let i = 0; i < values.pagos.length; i++) {
          values.pagos[i] = { ...values.pagos[i], id_usuario: auth?.user?.id };
        }
      }
      values.esVigente = esVigente;
      // delete values.metros;
      // delete values.sector;
      // delete values.ut;
      // delete values.unidades;
      setLoading(true);
      try {
        const response = await axios.post(`${server}/settlements/special`, values, {
          headers: { Authorization: `Bearer ${auth.token}` },
        });
        const fd = new FormData();
        let fileCount = 0;
        if (files)
          Object.keys(files).forEach((k) => {
            fd.append('recaudos', files[k]);
            fileCount++;
          });
        if (fileCount > 0) {
          const resp = await axios.post(`${server}/uploads/special/support/${response.data?.solicitud?.id}`, fd, {
            headers: { Authorization: `Bearer ${auth.token}` },
          });
        }
        message.success('Tributo registrado de manera exitosa');
        if (auth?.user?.tipoUsuario !== 4) history.push('/dashboard/impuestos/tributoOmitido');
        else history.push('/dashboard');
        if (response.status === 201 && response.data.solicitud.recibo) {
          const win = window.open(response.data.solicitud.recibo, '_blank');
          win?.focus();
        }
        form.resetFields();
        history.push('/dashboard/impuestos/pagar');
      } catch (e) {
        message.error(e.response?.data?.message || 'Error al registrar tributo');
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <Card
      style={{ height: '100%' }}
      title='Tributo Omitido'
      bodyStyle={{ height: 'calc(100% - 88px)', overflowY: 'scroll', overflowX: 'hidden', border: '1px solid #f0f0f0' }}
      headStyle={{ height: 64, backgroundColor: thm.primaryColor, padding: width < 1200 ? '0 10px' : '0 20px', color: 'white' }}
    >
      {!id && <GetTaxpayerInfo />}
      {id && (
        <Form
          form={form}
          layout='vertical'
          onFinish={submit}
          scrollToFirstError
          onValuesChange={(c, v) => {
            form.setFieldsValue(v);
            setData({ ...v });
          }}
        >
          {(auth?.user?.tipoUsuario !== 4 ||
            (auth?.user?.tipoUsuario === 4 && (auth?.user?.contribuyente?.sucursales?.length || 0) > 1)) && (
            <Divider orientation='left' style={{ marginLeft: -20, marginBottom: 0 }}>
              <Typography.Title ellipsis level={4} style={{ marginLeft: 5 }}>
                Sucursal
              </Typography.Title>
            </Divider>
          )}
          {(auth?.user?.tipoUsuario !== 4 ||
            (auth?.user?.tipoUsuario === 4 && (auth?.user?.contribuyente?.sucursales?.length || 0) > 1)) && (
            <Row gutter={24}>
              <Col xs={24} xl={24}>
                <Form.Item label='Sucursal' name='rim' rules={[{ required: false, message: 'Debe ingresar la sucursal' }]}>
                  <Select placeholder='Seleccione la sucursal'>
                    {taxPayer?.sucursales?.map((s) => (
                      <Select.Option value={s.referenciaMunicipal} key={s.id}>
                        {s.referenciaMunicipal} - {s.denomComercial} - {s.email} - {s.nombreRepresentante} - {s.direccion}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          )}
          <Divider orientation='left' style={{ marginLeft: -20, marginBottom: 0 }}>
            <Typography.Title ellipsis level={4} style={{ marginLeft: 5 }}>
              Tributo Omitido
            </Typography.Title>
          </Divider>
          <Row gutter={24}>
            {/* <Col xs={24} xl={8}>
              <Form.Item
                label='Periodo Sancionado'
                rules={[{ required: true, message: 'Por favor seleccionar periodo.' }]}
                name='rango'
              >
                <RangePicker format='DD/MM/YYYY' style={{ width: '100%' }} disabledDate={disabledDate} />
              </Form.Item>
            </Col> */}
            <Col xs={24} xl={8}>
              <Form.Item
                label='Fecha de Notificación'
                rules={[{ required: true, message: 'Por favor seleccionar fecha.' }]}
                name='fechaNotificada'
              >
                <DatePicker format='DD/MM/YYYY' style={{ width: '100%' }} disabledDate={disabledDate} />
              </Form.Item>
            </Col>
            <Col xs={24} xl={8}>
              <Form.Item
                label='Número del Acta de Determinación'
                name='determinacion'
                rules={[{ required: true, message: 'Debe ingresar el número del acta de determinación' }]}
              >
                <Input placeholder='Número del Acta de Determinación' />
              </Form.Item>
            </Col>
            <Col xs={24} xl={16}>
              <Form.Item label='Descripción' name='desc' rules={[{ required: true, message: 'Debe especificar la descripción' }]}>
                <Input placeholder='Descripción' />
              </Form.Item>
            </Col>
            <Col xs={24} xl={8}>
              <Form.Item
                label='Monto Bs'
                name='monto'
                rules={[{ required: true, message: 'Debe ingresar el monto del tributo' }]}
              >
                <InputNumber
                  placeholder='Monto'
                  // formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value) => (value ? value.replace(/Bs\s?|(,*)/g, '') : '')}
                  style={{ width: '100%', fontSize: 18 }}
                  disabled={true}
                />
              </Form.Item>
            </Col>
            <Col xs={24} xl={8}>
              <Form.Item label='Monto VTCMMV' name='ut'>
                <InputNumber
                  placeholder='Monto VTCMMV'
                  // formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  // parser={value => value ? value.replace(/Bs\s?|(,*)/g, '') : ''}
                  style={{ width: '100%', fontSize: 18 }}
                />
              </Form.Item>
            </Col>
            {/* <Col xs={24} xl={8}>
              <Form.Item
                label='Interes por Mora'
                name='mora'
                rules={[{ required: true, message: 'Debe ingresar el interes por mora' }]}
              >
                <InputNumber
                  placeholder='Mora'
                  // formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value) => (value ? value.replace(/Bs\s?|(,*)/g, '') : '')}
                  style={{ width: '100%', fontSize: 18 }}
                />
              </Form.Item>
            </Col> */}
            {/* <Col xs={24} xl={8}>
              <Form.Item label='Monto Interes VTCMMV' name='utinteres'>
                <InputNumber
                  placeholder='Monto Interes VTCMMV'
                  // formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  // parser={value => value ? value.replace(/Bs\s?|(,*)/g, '') : ''}
                  style={{ width: '100%', fontSize: 18 }}
                  disabled={true}
                />
              </Form.Item>
            </Col> */}
          </Row>
          {/* {showAll && <Checkbox checked={!esVigente} onChange={(e) => setEsVigente(!e.target.checked)}>Incluir Pago</Checkbox>} */}
          {showAll && !cantPay.includes(auth.user?.institucion?.cargo?.id || 0) && (
            <Button type='primary' onClick={() => setEsVigente(!esVigente)}>
              {esVigente ? 'Incluir Pago' : 'No Incluir Pago'}
            </Button>
          )}
          {!esVigente && showAll && (
            <>
              <Divider orientation='left' style={{ marginLeft: -20, marginBottom: 0 }}>
                <Typography.Title ellipsis level={4} style={{ marginLeft: 5 }}>
                  Datos de Pago
                </Typography.Title>
                {/* <Row>
                  <Checkbox checked={conRecargo} onChange={(e) => setConRecargo(!conRecargo)}>
                    Incluir Tasa de Recargo
                  </Checkbox>
                </Row> */}
                <Row>
                  <Typography.Text>{`Debe pagar: ${(form.getFieldValue('monto') || 0) + interes + recargo} Bs.`}</Typography.Text>
                </Row>
              </Divider>
              <MultipleTransactions
                selectedInstitution={institutions?.find((i) => i.id === 9)}
                form={form}
                data={data}
                maxCred={0}
                files={files}
                setFiles={setFiles}
                percentTax={false}
                mineralTax={false}
                bomberos={false}
                peajes={false}
              />
            </>
          )}
          <Row gutter={12} style={{ marginTop: 10, display: 'flex', justifyContent: 'space-between' }}>
            <Col>
              {auth.user?.tipoUsuario !== 4 && (
                <Button
                  onClick={() =>
                    history.push('/dashboard/impuestos/tributoOmitido', {
                      contribuyentes: (history.location.state as any)?.contribuyentes,
                    })
                  }
                >
                  Atrás
                </Button>
              )}
            </Col>
            {auth.user?.tipoUsuario !== 4 &&
              (!cantSett.includes(auth.user?.institucion?.cargo?.id || 0) ||
                (cantSett.includes(auth.user?.institucion?.cargo?.id || 0) && !esVigente)) && (
                <Row gutter={12}>
                  <Col>
                    <Button loading={loading} type='primary' onClick={form.submit}>
                      {esVigente ? 'Liquidar Multa' : 'Pagar Multa'}
                    </Button>
                  </Col>
                </Row>
              )}
          </Row>
        </Form>
      )}
    </Card>
  );
};

const mapStateToProps = (state: State) => ({
  thm: state.thm,
  branches: state.brch.branches,
  banks: state.bk.banks,
  institutions: state.inst.institutions,
  auth: state.auth,
});

export default connect(mapStateToProps)(SkipedFineSettlements);

interface SkipedFineSettlementsProps {
  thm: States.ThemeColors;
  branches: States.Branches['branches'];
  banks: States.Banks['banks'];
  institutions: States.Institutions['institutions'];
  auth: States.Auth;
}
