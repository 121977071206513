import React from 'react';
import { Select } from 'antd';
import moment from 'moment';

const MonthDeclareSelect: React.FC<MonthDeclareSelectProps> = ({ onChange, value, placeholder, disabled = false }) => {
  let months: MonthArray[] = [];
  for(let i=0; i<12; i++){
    months.push({value: i, name: `${moment().month(i).locale('ES').format('MMMM')}`});
  };
    
  return (
    <Select value={ typeof value === 'object' ? value._i : value} onChange={onChange} placeholder={placeholder} disabled={disabled}>
      { months.map((d) => {
        return <Select.Option value={d.value}>{d.name}</Select.Option>
      })}
    </Select>
  );
};

export default MonthDeclareSelect;

interface MonthDeclareSelectProps {
  onChange?: (e: any) => any
  value?: any
  placeholder?: string
  disabled?: boolean
}

interface MonthArray {
  value: number
  name: string
}