import React, { useState, useEffect, useRef } from 'react';
import { States, State } from 'sigt';
import { connect, useSelector } from 'react-redux';
import { useWindowDimensions } from '../../../utils/hooks';
import {
  Card,
  Table,
  message,
  Divider,
  Typography,
  Tag,
  Button,
  Modal,
  Form,
  Row,
  Col,
  DatePicker,
  Select,
  Input,
  Alert,
} from 'antd';
import axios from 'axios';
import { ColumnsType } from 'antd/lib/table';
import moment, { Moment } from 'moment';
import Highlighter from 'react-highlight-words';
import { SearchOutlined, CalendarOutlined, CheckOutlined, MinusCircleFilled } from '@ant-design/icons';
import { Utils } from '../../../utils/validators';
const server = process.env.REACT_APP_SERVER_URL;
const canApprove = [56,90,98,99,100];

// BOD, BANESCO Y BNC
const sedematBanks = [1, 2, 3, 14, 22, 19, 23, 25, 11, 12, 35, 6];

const minerals = [499,500,501,502,503,504,505,506,507,508,509,510,511,512,513,5114];

const formatCurrency = (number: number) => new Intl.NumberFormat('de-DE').format(number);

const AmendDeclarationsZero: React.FC<AmendDeclarationsZeroProps> = ({ thm, auth }) => {
  const [requests, setRequests] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [tipoDocumento, setTipoDocumento] = useState('J');
  const [rejectModalVisible, setRejectModalVisible] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColum] = useState('');
  const searchInput = useRef<any>(null);
  const [keys, setKeys] = useState<Moment[]>([]);

  const { width } = useWindowDimensions();
  const [form] = Form.useForm();
  const [searchForm] = Form.useForm();

  const [selectedRequest, setSelectedRequest] = useState<any>(null);
  const [drequest, setDRequest] = useState<any>(null);

  const handleDel = (request) => {
    setDRequest(request);
    setRejectModalVisible(true);
  }

  const tipoAddon = (
    <Select defaultValue='J' value={tipoDocumento} onChange={(e) => setTipoDocumento(e)}>
      <Select.Option value='V'>V</Select.Option>
      <Select.Option value='E'>E</Select.Option>
      <Select.Option value='J'>J</Select.Option>
      <Select.Option value='G'>G</Select.Option>
      <Select.Option value='P'>P</Select.Option>
      <Select.Option value='C'>C</Select.Option>
    </Select>
  );

  const getState = (estado) => {
    if (estado === 'ingresardatos') return { name: 'En espera de pago', color: 'cyan' };
    else if (estado === 'validando') return { name: 'Validando declaración', color: 'orange' };
    else if (estado === 'finalizado') return { name: 'Aprobado', color: 'green' };
    else return { name: 'Error', color: 'error' };
  };

  const searchByDoc = async () => {
    const values = await searchForm.validateFields();
    if (!tipoDocumento) return message.error('Debe seleccionar un tipo de documento');
    setLoading(true);
    try {
      const response = await axios.get(`${server}/banks//payment/declarations/zeroByDoc?type_doc=${tipoDocumento}&doc=${values.documento}`, {
        headers: { Authorization: `Bearer ${auth.token}` },
      });
      setRequests(response.data.data);
    } catch (e) {
      message.error(e.response?.data?.message || 'Error al conseguir las solicitudes por pagar');
    } finally {
      setLoading(false);
    }
  };

  const approveProcedure = async (request) => {
    setLoading(true);
    try {
      await axios.put(
        `${server}/banks/approveDeclarationZero`,
        { id: request.id },
        { headers: { Authorization: `Bearer ${auth.token}` } }
      );
      message.success('Declaración aprobada de manera exitosa.');
      await fetchRequests();
      setSelectedRequest(null);
    } catch (e) {
      message.error(e.response?.data?.message || 'Error al aprobar declaración.');
    } finally {
      setLoading(false);
    }
  };

  const reverseOperation = async () => {
    if(drequest){
      setLoading(true);
      try {
        const values = await form.validateFields();
        const response = await axios.patch(`${server}/settlements/incomplete/${drequest.id}`, values, {
          headers: { Authorization: `Bearer ${auth.token}` },
        });
        message.info('Declaración rechazada.');  
        await fetchRequests();
        setRejectModalVisible(false);
      } catch (e) {
        message.error(e.response?.data?.message || 'Error al rechazar declaración.');
      } finally {
        setLoading(false);
      }
    }
  };

  const getColumnFilterDate = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      setKeys(selectedKeys);
      return (
        <Row style={{ width: 250, padding: 8 }} gutter={[8, 8]}>
          <Col span={24}>
            <DatePicker.RangePicker
              style={{ width: '100%' }}
              format='DD-MM-YYYY'
              placeholder={['Fecha inicio ', 'Fecha fin']}
              size='small'
              onChange={(values) => {
                setSelectedKeys(values);
              }}
              value={selectedKeys}
            />
          </Col>
          <Col span={12}>
            <Button
              style={{ width: '100%' }}
              type='primary'
              icon={<SearchOutlined />}
              size='small'
              onClick={() => {
                confirm();
              }}
            >
              Buscar
            </Button>
          </Col>
          <Col span={12}>
            <Button style={{ width: '100%' }} onClick={() => clearFilters()} size='small'>
              Restablecer
            </Button>
          </Col>
        </Row>
      );
    },
    filterIcon: (filtered) => <CalendarOutlined style={{ color: filtered ? thm.primaryColor : undefined }} />,
    onFilter: (value, record) => {
      return (
        moment(record[dataIndex]).utc().format('DD-MM-YYYY') === keys[0]?.utc().format('DD-MM-YYYY') ||
        moment(record[dataIndex]).utc().isBetween(keys[0]?.utc(), keys[1]?.utc(), undefined, '[]')
      );
    },
  });

  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder='Buscar'
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type='primary'
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size='small'
          style={{ width: 90, marginRight: 8 }}
        >
          Buscar
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size='small' style={{ width: 90 }}>
          Restablecer
        </Button>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined onClick={() => {}} style={{ color: filtered ? thm.primaryColor : undefined }} />,
    onFilter: (value, record) => record[dataIndex]?.toString()?.toLowerCase()?.includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        if (searchInput.current) {
          searchInput.current?.select();
        }
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#1e72c675', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColum(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const columns: ColumnsType<any> = [
    {
      title: 'Documento',
      dataIndex: 'documento',
      ...getColumnSearchProps('documento'),
      render: (doc, record) => (
        <span>
          {record.tipoDocumento}-{doc}
        </span>
      ),
    },
    {
      title: 'Contribuyente',
      dataIndex: 'razon_social',
      ...getColumnSearchProps('razon_social'),
      render: (razon_social) => (
        <span>
          {razon_social}
        </span>
      ),
    },
    {
      title: 'Fecha de la Solicitud',
      dataIndex: 'fechaSolicitud',
      ...getColumnFilterDate('fechaSolicitud'),
      render: (fecha) => <span>{moment(fecha).utc().format('DD/MM/YYYY')}</span>,
    },
    {
      title: 'Periodo',
      dataIndex: 'periodo',
      render: (periodo, record) => <span>{`${periodo}`}</span>,
    },
    {
      title: 'Motivo de Solicitud',
      dataIndex: 'descripcion',
      // filters,
      // onFilter: (tipo, record) => record.tipoSolicitud.toLowerCase().indexOf((tipo as string).toLowerCase()) === 0,
      ...getColumnSearchProps('descripcion'),
      render: (descripcion) => <b>{descripcion}</b>,
    },
    {
      title: 'Municipio',
      dataIndex: 'municipio',
      // filters,
      // onFilter: (tipo, record) => record.tipoSolicitud.toLowerCase().indexOf((tipo as string).toLowerCase()) === 0,
      ...getColumnSearchProps('municipio'),
      render: (descripcion) => <b>{descripcion}</b>,
    },
    {
      title: 'Estado',
      dataIndex: 'state',
      render: (value) => (
        <Tag style={{ width: '100%', textAlign: 'center' }} color={getState(value).color}>
          {getState(value).name}
        </Tag>
      ),
    },
    {
      title: 'Monto',
      dataIndex: 'monto',
      ...getColumnSearchProps('monto'),
      sorter: (a, b) => b.monto - a.monto,
      render: (monto) => <span>Bs. {formatCurrency(parseFloat(monto))}</span>,
    },
    {
      title: 'Acciones',
      dataIndex: 'id',
      width: '35%',
      render: (_, record) =>
        !record.aprobado ? (
          <Col>
            <Row gutter={24} style={{paddingBottom:'5px'}}>
              {canApprove.includes(auth?.user?.institucion?.cargo?.id || 0) && (
                <Col>
                  <Button type='primary' icon={<CheckOutlined />} onClick={() => setSelectedRequest(record)}>
                    Aprobar
                  </Button>
                </Col>
              )}
              {canApprove.includes(auth?.user?.institucion?.cargo?.id || 0) && (
                <Col>
                  <Button type='primary' icon={<MinusCircleFilled />} onClick={() => handleDel(record)}>
                    Rechazar
                  </Button>
                </Col>
              )}
            </Row>
            <Row gutter={24}>
              {record.url_archivo_soporte &&             
                <Col>
                  <Button type='primary' target='_blank' href={record.url_archivo_soporte || ''}>
                    Comprobante
                  </Button>
                </Col>
              }
            </Row>
          </Col>
        ) : null,
    },
  ];

  useEffect(() => {
    fetchRequests();
    //eslint-disable-next-line
  }, []);

  const fetchRequests = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${server}/banks/payment/declarations/zero`, { headers: { Authorization: `Bearer ${auth.token}` } });
      setRequests(response.data.data || []);
      console.log(response.data.data);
    } catch (e) {
      message.error(e.response?.data?.message || 'Error al conseguir las declaraciones por validar.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card
      style={{ height: '100%' }}
      title='Certificación de Declaraciones en 0'
      bodyStyle={{ height: 'calc(100% - 88px)', overflowY: 'scroll', overflowX: 'hidden', border: '1px solid #f0f0f0' }}
      headStyle={{ height: 64, backgroundColor: thm.primaryColor, padding: width < 992 ? '0 10px' : '0 10px', color: 'white' }}
    >
      <Divider orientation='left' style={{ marginLeft: 0, marginBottom: 10, marginTop: -5 }}>
        <Typography.Title level={4}>Solicitudes Pendientes por Validación</Typography.Title>
      </Divider>
      <Form layout='vertical' style={{ marginBottom: 20 }} form={searchForm} onFinish={() => searchByDoc()}>
        <Row gutter={24}>
          <Col xs={24} xl={8}>
            <Form.Item
              name='documento'
              label='Documento de Identidad'
              rules={[{ required: true, message: 'Debe ingresar el documento de identidad' }]}
              normalize={Utils.normalize.isCNumber}
            >
              <Input placeholder='Documento de Identidad' addonBefore={tipoAddon} />
            </Form.Item>
          </Col>
          <Col xs={24} xl={3}>
            <Button
              disabled={loading}
              style={{ marginTop: width < 1200 ? 0 : 40, width: '100%' }}
              type='primary'
              htmlType='submit'
              icon={<SearchOutlined />}
            >
              Buscar
            </Button>
          </Col>
        </Row>
      </Form>
      <Table
        bordered
        loading={loading}
        columns={columns}
        dataSource={requests.map((r) => {
          r.key = r.id;
          return r;
        })}
        pagination={{ pageSize: 8 }}
      />
      <Modal
        visible={!!selectedRequest}
        onCancel={() => setSelectedRequest(null)}
        title='Aprobar Declaración'
        width={width < 992 ? '100%' : '40%'}
        okButtonProps={{ icon: <CheckOutlined />, title: 'Corregir', loading }}
        onOk={() => approveProcedure(selectedRequest)}
      >
        <Row>
          <Col span={24}>
            <Alert
              message='¿Está seguro de que desea aprobar la declaración? Esta acción no puede ser reversada.'
              type='warning'
              style={{ fontWeight: 'bold' }}
            />
          </Col>
        </Row>
      </Modal>
      <Modal
        width='400px'
        bodyStyle={{ height: '45vh', overflowY: 'scroll' }}
        centered
        visible={rejectModalVisible}
        footer={null}
        closable
        title='Rechazar Declaración'
        maskClosable
        onCancel={() => setRejectModalVisible(false)}
      >
        <Form form={form} layout='vertical' onFinish={reverseOperation}>
          <Row style={{ paddingLeft: 16 }} gutter={16}>
            <Col span={16}>
              <Form.Item
                label='Motivo de rechazo'
                name='description'
                rules={[{ required: true, message: 'Debe especificar el motivo de rechazo' }]}
              >
                <Input.TextArea placeholder='Motivo de rechazo' />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Alert
                message='¿Está seguro de que desea rechazar la declaración? Esta acción no puede ser reversada.'
                type='warning'
                style={{ fontWeight: 'bold' }}
              />
            </Col>
          </Row>
          <Row style={{ paddingLeft: 16 }} gutter={16}>
            <Col span={16}>
              <Button type='primary' htmlType='submit'>
                Rechazar
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    </Card>
  );
};

const mapStateToProps = (state: State) => ({ thm: state.thm, auth: state.auth });

export default connect(mapStateToProps)(AmendDeclarationsZero);

interface AmendDeclarationsZeroProps {
  thm: States.ThemeColors;
  auth: States.Auth;
}
