import { States } from 'sigt';
import { CoinsType, SET_PETRO, SET_CURRENCY, SET_EURO, SET_DOLAR } from '../actions/actionsTypes';

const initialState: States.Coins = {
  petro: 0,
  dolar: 0,
  euro: 0,
  moneda: 'euro',
};

export default (state = initialState, action: CoinsType): States.Coins => {
  switch (action.type) {
    case SET_PETRO:
      return { ...state, petro: action.payload };
    case SET_CURRENCY:
      return { ...state, moneda: action.payload };
    case SET_EURO:
      return { ...state, euro: action.payload };
    case SET_DOLAR:
      return { ...state, dolar: action.payload };
    default:
      return state;
  }
};
