import React, { useState, useEffect, useRef } from 'react';
import { States, State } from 'sigt';
import { connect, useSelector } from 'react-redux';
import { useWindowDimensions } from '../../../utils/hooks';
import {
  Card,
  Table,
  message,
  Divider,
  Typography,
  Tag,
  Badge,
  Button,
  Modal,
  Form,
  Row,
  Col,
  DatePicker,
  Select,
  Input,
  Alert,
} from 'antd';
import axios from 'axios';
import { ColumnsType } from 'antd/lib/table';
import moment, { Moment } from 'moment';
import Highlighter from 'react-highlight-words';
import { EditFilled, PlusCircleFilled, SearchOutlined, CalendarOutlined, CheckOutlined, MinusCircleFilled } from '@ant-design/icons';
import { Utils } from '../../../utils/validators';
import { fetchRequests } from '../../../redux/actions/requests';
const server = process.env.REACT_APP_SERVER_URL;

// BOD, BANESCO Y BNC
const sedematBanks = [1, 2, 3, 14, 22, 19, 23, 25, 11, 12, 35, 6];

const minerals = [391,392,393,491];

const formatCurrency = (number: number) => new Intl.NumberFormat('de-DE').format(number);

const AmendCredit: React.FC<AmendCreditProps> = ({ thm, auth }) => {
  const [requests, setRequests] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [adding, setAdding] = useState(0);
  const [requesting, setRequesting] = useState(false);
  const [tipoDocumento, setTipoDocumento] = useState('J');
  const [modalVisible, setModalVisible] = useState(false);
  const [idC, setIdC] = useState();

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColum] = useState('');
  const searchInput = useRef<any>(null);
  const [keys, setKeys] = useState<Moment[]>([]);

  const banks = useSelector((state: State) => state.bk.banks);

  const { width } = useWindowDimensions();
  const [form] = Form.useForm();
  const [searchForm] = Form.useForm();

  const tipoAddon = (
    <Select defaultValue='J' value={tipoDocumento} onChange={(e) => setTipoDocumento(e)}>
      <Select.Option value='V'>V</Select.Option>
      <Select.Option value='E'>E</Select.Option>
      <Select.Option value='J'>J</Select.Option>
      <Select.Option value='G'>G</Select.Option>
      <Select.Option value='P'>P</Select.Option>
      <Select.Option value='C'>C</Select.Option>
    </Select>
  );

  const getState = (estado) => {
    if (estado === 'ingresardatos') return { name: 'En espera de pago', color: 'cyan' };
    else if (estado === 'enproceso') return { name: 'En proceso', color: 'cyan' };
    else if (estado === 'validando') return { name: 'Validando pago', color: 'orange' };
    else if (estado === 'finalizado') return { name: 'Aprobado', color: 'green' };
    else return { name: 'Error', color: 'error' };
  };

  const searchByDoc = async () => {
    const values = await searchForm.validateFields();
    if (!tipoDocumento) return message.error('Debe seleccionar un tipo de documento');
    setLoading(true);
    try {
      const response = await axios.get(`${server}/banks/procedurePayments?type_doc=${tipoDocumento}&doc=${values.documento}`, {
        headers: { Authorization: `Bearer ${auth.token}` },
      });
      setRequests(response.data.data);
    } catch (e) {
      message.error(e.response?.data?.message || 'Error al conseguir las solicitudes por pagar');
    } finally {
      setLoading(false);
    }
  };

  const getColumnFilterDate = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      setKeys(selectedKeys);
      return (
        <Row style={{ width: 250, padding: 8 }} gutter={[8, 8]}>
          <Col span={24}>
            <DatePicker.RangePicker
              style={{ width: '100%' }}
              format='DD-MM-YYYY'
              placeholder={['Fecha inicio ', 'Fecha fin']}
              size='small'
              onChange={(values) => {
                setSelectedKeys(values);
              }}
              value={selectedKeys}
            />
          </Col>
          <Col span={12}>
            <Button
              style={{ width: '100%' }}
              type='primary'
              icon={<SearchOutlined />}
              size='small'
              onClick={() => {
                confirm();
              }}
            >
              Buscar
            </Button>
          </Col>
          <Col span={12}>
            <Button style={{ width: '100%' }} onClick={() => clearFilters()} size='small'>
              Restablecer
            </Button>
          </Col>
        </Row>
      );
    },
    filterIcon: (filtered) => <CalendarOutlined style={{ color: filtered ? thm.primaryColor : undefined }} />,
    onFilter: (value, record) => {
      return (
        moment(record[dataIndex]).utc().format('DD-MM-YYYY') === keys[0]?.utc().format('DD-MM-YYYY') ||
        moment(record[dataIndex]).utc().isBetween(keys[0]?.utc(), keys[1]?.utc(), undefined, '[]')
      );
    },
  });

  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder='Buscar'
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type='primary'
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size='small'
          style={{ width: 90, marginRight: 8 }}
        >
          Buscar
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size='small' style={{ width: 90 }}>
          Restablecer
        </Button>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined onClick={() => {}} style={{ color: filtered ? thm.primaryColor : undefined }} />,
    onFilter: (value, record) => record[dataIndex]?.toString()?.toLowerCase()?.includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        if (searchInput.current) {
          searchInput.current?.select();
        }
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#1e72c675', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColum(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  console.log(requests.filter((r) => r.tipoSolicitud !== 'IMPUESTO'));

  const approveCredit = async () => {
    try {
      const { resolucion } = await form.validateFields();
      const newCredit = {id: idC, state: 'aprobado', resolution: resolucion }
      const response = await axios.post(`${server}/settlements/credit/update`, {...newCredit}, { headers: { Authorization: `Bearer ${auth.token}` } });
      message.success('Compensación aprobada');
      setModalVisible(false);
      fetchRequests();
    } catch (e) {
      message.error(e.response?.data?.message || 'Error al aprobar la compensación');
    } 
  }
  const handleApproval = (id) => {
    setIdC(id);  
    setModalVisible(true);
  };

  const rejectCredit = async (idC) => {
    try {
      const newCredit = {id: idC, state: 'rechazado' }
      const response = await axios.post(`${server}/settlements/credit/update`, {...newCredit}, { headers: { Authorization: `Bearer ${auth.token}` } });
      message.success('Compensación rechazada');
      fetchRequests();
    } catch (e) {
      message.error(e.response?.data?.message || 'Error al rechazar la compensación');
    } 
  }
  
  const filters = [
    { value: 'impuesto', text: <span style={{ paddingRight: 35 }}>Impuesto</span> },
    { value: 'convenio', text: <span style={{ paddingRight: 35 }}>Convenio</span> },
    { value: 'retencion', text: <span style={{ paddingRight: 35 }}>Retención</span> },
    { value: 'tramite', text: <span style={{ paddingRight: 35 }}>Trámite</span> },
  ];

  const columns: ColumnsType<any> = [
    {
      title: 'Documento',
      dataIndex: 'documento',
      ...getColumnSearchProps('documento'),
      render: (doc, record) => (
        <span>
          {record.tipo_documento}-{doc}
        </span>
      ),
    },
    {
      title: 'Contribuyente',
      dataIndex: 'razon_social',
      ...getColumnSearchProps('razon_social'),
      render: (razon_social) => (
        <span>
          {razon_social}
        </span>
      ),
    },
    {
      title: 'Fecha de la Solicitud',
      dataIndex: 'fecha_creacion',
      ...getColumnFilterDate('fecha_creacion'),
      render: (fecha_creacion) => <span>{moment(fecha_creacion).utc().format('DD/MM/YYYY')}</span>,
    },
    {
      title: 'Comprobante de la Solicitud',
      dataIndex: 'recibo',
      render: (recibo) => 
        <Button type='primary' target='_blank' href={recibo}>
         Comprobante
        </Button>,
    },
    {
      title: 'Estado',
      dataIndex: 'estado',
      render: (value) => (
        <Tag style={{ width: '100%', textAlign: 'center' }} color={getState(value).color}>
          {getState(value).name}
        </Tag>
      ),
    },
    {
      title: 'Monto',
      dataIndex: 'credito',
      ...getColumnSearchProps('credito'),
      sorter: (a, b) => b.credito - a.credito,
      render: (credito) => <span>Bs. {formatCurrency(parseFloat(credito))}</span>,
    },
    {
      title: 'Acciones',
      dataIndex: 'id_credito_fiscal',
      render: (id, record) =>
        <Row>
          <Button type='primary' onClick={()=>handleApproval(id)} icon={<PlusCircleFilled />}>
            Aprobar
          </Button>
          <Button type='primary' onClick={()=>rejectCredit(id)} icon={<MinusCircleFilled />} >
            Rechazar
          </Button>
        </Row>
    },
  ];

  useEffect(() => {
    fetchRequests();
    //eslint-disable-next-line
  }, []);

  const fetchRequests = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${server}/settlements/process/credit`, { headers: { Authorization: `Bearer ${auth.token}` } });
      setRequests(response.data.credit);
      console.log(response.data);
    } catch (e) {
      message.error(e.response?.data?.message || 'Error al conseguir las compensaciones');
    } finally {
      setLoading(false);
    }
  };

  const addPayment = async (id: number) => {
    const values = await form.validateFields();
    setRequesting(true);
    try {
      const body = {
        ...values,
        fechaDePago: values.fechaDePago.toISOString(),
        id,
      };
      const response = await axios.post(`${server}/banks/payment/`, body, { headers: { Authorization: `Bearer ${auth.token}` } });
      message.success('Pago registrado de manera exitosa');
      const _requests = [...requests];
      const rIndex = _requests.findIndex((r) => r.id === id);
      _requests[rIndex].pagos = [..._requests[rIndex].pagos, { ...response.data.data[0], request: id }];
      setAdding(0);
    } catch (e) {
    } finally {
      setRequesting(false);
    }
  };

  return (
    <Card
      style={{ height: '100%' }}
      title='Certificación de Compensaciones'
      bodyStyle={{ height: 'calc(100% - 88px)', overflowY: 'scroll', overflowX: 'hidden', border: '1px solid #f0f0f0' }}
      headStyle={{ height: 64, backgroundColor: thm.primaryColor, padding: width < 992 ? '0 10px' : '0 10px', color: 'white' }}
    >
      <Divider orientation='left' style={{ marginLeft: 0, marginBottom: 10, marginTop: -5 }}>
        <Typography.Title level={4}>Solicitudes Pendientes por Validación</Typography.Title>
      </Divider>
      {/* <Form layout='vertical' style={{ marginBottom: 20 }} form={searchForm} onFinish={() => searchByDoc()}>
        <Row gutter={24}>
          <Col xs={24} xl={8}>
            <Form.Item
              name='documento'
              label='Documento de Identidad'
              rules={[{ required: true, message: 'Debe ingresar el documento de identidad' }]}
              normalize={Utils.normalize.isCNumber}
            >
              <Input placeholder='Documento de Identidad' addonBefore={tipoAddon} />
            </Form.Item>
          </Col>
          <Col xs={24} xl={3}>
            <Button
              disabled={loading}
              style={{ marginTop: width < 1200 ? 0 : 40, width: '100%' }}
              type='primary'
              htmlType='submit'
              icon={<SearchOutlined />}
            >
              Buscar
            </Button>
          </Col>
        </Row>
      </Form> */}
      <Table
        bordered
        loading={loading}
        columns={columns}
        // expandable={{
        //   expandedRowRender: (record) => (
        //     <PaymentTable
        //       dataSource={record.pagos.map((p) => {
        //         p.request = record.id;
        //         p.type = record.tipoSolicitud;
        //         return p;
        //       })}
        //       requests={requests}
        //       setRequests={setRequests}
        //     />
        //   ),
        // }}
        dataSource={requests.map((r) => {
          r.key = r.id;
          return r;
        })}
        pagination={{ pageSize: 8 }}
      />
      <Modal
        visible={!!adding}
        title='Anexar Pago'
        onCancel={() => setAdding(0)}
        width={width < 992 ? '100%' : '40%'}
        okButtonProps={{ icon: <PlusCircleFilled />, title: 'Anexar', loading: requesting }}
        onOk={() => addPayment(adding)}
      >
        <Form layout='vertical' form={form}>
          <Row gutter={24}>
            <Col xs={24} xl={12}>
              <Form.Item
                label='Fecha de Pago'
                name='fechaDePago'
                rules={[{ required: true, message: 'Debe ingresar la fecha de pago' }]}
              >
                <DatePicker placeholder='Fecha de Pago' style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col xs={24} xl={12}>
              <Form.Item
                label='Referencia'
                name='referencia'
                rules={[{ required: true, message: 'Debe ingresar la referencia' }]}
              >
                <Input placeholder='Referencia' maxLength={20}/>
              </Form.Item>
            </Col>
            <Col xs={24} xl={12}>
              <Form.Item label='Banco' name='banco' rules={[{ required: true, message: 'Debe ingresar el banco' }]}>
                <Select
                  showSearch
                  filterOption={(value: string, option) => option?.title?.includes(value.toUpperCase())}
                  placeholder='Banco'
                >
                  {/* {banks
                    .filter((b) => sedematBanks.includes(b.id))
                    .map((b) => (
                      <Select.Option value={b.id} key={b.id}>
                        {b.nombre}
                      </Select.Option>
                    ))} */}
                  <Select.Option value={11}>Banco de Venezuela S.A.C.A. Banco Universal</Select.Option>
                  <Select.Option value={3}>Banco Nacional de Crédito</Select.Option>
                  <Select.Option value={14}>Banco del Tesoro </Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} xl={12}>
              <Form.Item label='Monto' name='monto' rules={[{ required: true, message: 'Debe ingresar el monto' }]}>
                <Input placeholder='Monto' />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
      <Modal
        width='400px'
        bodyStyle={{ height: '45vh', overflowY: 'scroll' }}
        centered
        visible={modalVisible}
        footer={null}
        closable
        title='Aprobar Compensación'
        maskClosable
        onCancel={() => setModalVisible(false)}
      >
        <Form form={form} layout='vertical' onFinish={approveCredit}>
          <Row style={{ paddingLeft: 16 }} gutter={16}>
            <Col span={16}>
              <Form.Item
                label='Nro de Resolución'
                name='resolucion'
                rules={[{ required: true, message: 'Debe especificar el Nro de Resolución' }]}
              >
                <Input placeholder='Nro de Resolución' />
              </Form.Item>
            </Col>
          </Row>
          <Row style={{ paddingLeft: 16 }} gutter={16}>
            <Col span={16}>
              <Button type='primary' htmlType='submit'>
                Aprobar
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    </Card>
  );
};

const mapStateToProps = (state: State) => ({ thm: state.thm, auth: state.auth });

export default connect(mapStateToProps)(AmendCredit);

interface AmendCreditProps {
  thm: States.ThemeColors;
  auth: States.Auth;
}
