import React from "react";
import { List, Avatar, Badge, Skeleton } from "antd";
import moment from 'moment';
import { Notificacion } from "sigt";
import { useHistory } from "react-router";

const NotificationCard: React.FC<NotificationCardProps> = ({ notification, loading, onClickItem }) => {
  const { Item } = List;
  const { Meta } = Item;
  const history = useHistory();
  const StatusConfig: colorStatus = {
    iniciado: {color: '#000000a6', name: 'Iniciado'},
    validando: { color: '#fa8c16', name: 'Validando pago' },
    ingresardatos: { color: '#13c2c2', name: 'En espera de pago' },
    finalizado: { color: '#52c41a', name: 'Finalizado' },
    enproceso: { color: '#2f54eb', name: 'En proceso' },
    enrevision: { color: '#a0d911', name: 'En Revision'},
    porrevisar: { color: '#13c2c2', name: 'Por Revisar' },
    atendido: { color: '#fa8c16', name: 'Atendido' },
    visto: { color: '#000000a6', name: 'Visto' },
    aprobado: { color: '#52c41a', name: 'Aprobado' },
    negado: { color: '#f5222d', name: 'Negado' }
  };
  const date = (createDate: string) => {
    const today = new Date().toLocaleDateString();
    const date = new Date(createDate).toLocaleDateString();
    return date === today ? moment(createDate).format('h:mm a'):moment(createDate).format('DD/MM/YYYY, h:mm a');
  }
  const selectedNoft = (id, concepto) => {
    onClickItem();
    (concepto === 'MULTA') ? history.push(`/dashboard/bandejas?tipo=multas&codigoMulta=${id}`) : history.push(`/dashboard/bandejas?codigoTramite=${id}`); 
  }
  return (
    <Item key={notification.id}>
      <Skeleton avatar title={false} loading={loading} active>
        <Meta
          avatar={
            <Avatar
              style={{ marginTop: "1.3vh", backgroundColor:StatusConfig[notification.tramite.estado]?.color }}
            />
          }
          title={notification.descripcion}
          description={
          <div style={{cursor:'pointer'}} onClick={()=> selectedNoft(notification.tramite.id, notification.concepto)}>
            <span>{notification.tramite.nombreTramiteCorto}</span><br />
            {notification.concepto !== 'IMPUESTO' ? (<span>Codigo: {notification.concepto === 'MULTA'? notification.tramite.codigoMulta :notification.tramite.codigoTramite}<br /></span>): null}
            <span>Estado: {StatusConfig[notification.tramite.estado]?.name}</span><br />
            <span>{date(notification.fechaCreacion)}</span>
          </div>
          }
        />
        {!notification.status && <Badge status='processing' />}
      </Skeleton>
    </Item>
  );
};

export default NotificationCard;
interface colorStatus {
  [status: string]: {
    name: StatusProcedure;
    color: string;
  };
}

interface NotificationCardProps {
  loading: boolean
  notification: Notificacion
  onClickItem: () => void
}

declare type StatusProcedure = 'Validando pago' | 'En espera de pago' | 'En Revision' | 'Finalizado' | 'En proceso' | 'Iniciado' | 'Por Revisar' | 'Atendido' | 'Visto' | 'Aprobado' | 'Negado';
