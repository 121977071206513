import React, { useState, useEffect, useContext } from 'react';
import { Badge, Popover, List, Button } from 'antd';
import { connect, useDispatch } from 'react-redux';
import NotificationCard from './NotificationCard';
// import { SocketContext } from '../../services/ServiceContext';
import {
  hasNewNotifications,
  fetchNotifications,
  sendNotification,
  markNotificationsAsRead,
} from './../../redux/actions/notifications';
import * as R from 'ramda';
import '../../assets/css/components/Notification.css';
import { BellOutlined } from '@ant-design/icons';
import { State, States, Notificacion, Procedure, Fine, Liquidacion, Collections, Inspection } from 'sigt';
// import { SocketService } from '../../services/SocketService';
import {
  changeProcedureStatus,
  changeProcedureData,
  setProcedure,
  changeFineStatus,
  changeFineCertificate,
  setFine,
  setSupport,
} from '../../redux/actions/procedures';
import { setTax, changeTaxData } from '../../redux/actions/taxes';
import { SET_USER, AUTH_USER_LOGOUT, AUTH_USER_BLOCK } from '../../redux/actions/actionsTypes';
import { editCollection, editInspection, setInspection } from '../../redux/actions/charges';

const NotificationList: React.FC<any> = ({ data, loaders, onClickItem }) => {
  const { initLoading, limit, onLoadMore } = loaders;
  const [loading, setLoading] = useState(true);
  const loadMore =
    !initLoading && !loading && !limit ? (
      <div
        style={{
          textAlign: 'center',
          marginTop: 12,
          height: 32,
          lineHeight: '32px',
        }}
      >
        <Button type='link' onClick={onLoadMore}>
          cargar mas
        </Button>
      </div>
    ) : null;

  useEffect(() => {
    setTimeout(() => setLoading(false), 1500);
  }, []);

  return (
    <List
      loading={initLoading}
      className='notification-list'
      dataSource={data}
      style={{ width: '25vw', maxHeight: '60vh', minHeight: '10vh', overflowY: 'auto', marginLeft: '.6vw' }}
      size='small'
      loadMore={loadMore}
      renderItem={(item: Notificacion) => <NotificationCard {...{ notification: item, loading: loading, onClickItem }} />}
    />
  );
};

const NotificationBell: React.FC<NotificationProps> = ({
  notf,
  auth,
  sendNotification,
  setSupport,
  hasNewNotifications,
  fetchNotifications,
  markNotificationsAsRead,
  changeProcedureStatus,
  changeProcedureData,
  setProcedure,
  changeFineStatus,
  changeFineCertificate,
  setFine,
  setTax,
  changeTaxData,
  editCollection,
  editInspection,
  setInspection,
}) => {
  const [show, setNotificationShow] = useState(false); //shows badge
  const [initLoading, setInitLoading] = useState(true); //shows spinner when fetching data
  const [clicked, setClicked] = useState(false); //indicator for first fetch
  const [aux, setAux] = useState<Notificacion[]>([]); //aux list for pagination
  const [count, setCount] = useState(0); //actual pagination count
  const [limit, setLimit] = useState(false); //verifies max limit of notifications
  // const socketAux = useContext(SocketContext);
  // const [socket, setSocket] = useState<SocketService>(Object);
  const [visiblePopover, setVisiblePopover] = useState(false);
  const { list, hasNotifications } = notf;
  const dispatch = useDispatch();
  const augment = 5;
  useEffect(() => {
    if (clicked && initLoading) {
      setTimeout(() => {
        setInitLoading(false);
      }, 1000);
      handleLoadMore();
    }
    //eslint-disable-next-line
  }, [list]);

  useEffect(() => {
    setLimit(aux.length >= list.length);
    // setSocket(socketAux);
    // if(auth.token && (Object.keys(socket).length > 0)){
    //   socket.on("SEND_NOTIFICATION", (socket: Notificacion) => {
    //     handleNewNotification(socket);
    //   });
    //   return () => socket.off("SEND_NOTIFICATION");
    //   }
    //eslint-disable-next-line
  }, [aux, auth.token]);

  // useEffect(()=>{
  //   if(auth.token && (Object.keys(socket).length > 0)){
  //     socket.on("UPDATE_PROCEDURE", (socket:Procedure) => {
  //       changeProcedureStatus(socket.id, socket.estado)
  //       changeProcedureData(socket.id, socket);
  //     });
  //     socket.on("CREATE_PROCEDURE", (socket:Procedure) => {
  //       if(auth.user?.institucion.cargo.id === 24){
  //         if(socket.tipoTramite === 37) setSupport(socket);
  //         else if(![27, 39, 40].includes(socket.tipoTramite)){
  //           console.log(socket, ![27, 39, 40].includes(socket.tipoTramite))
  //           setProcedure(socket)
  //         }
  //       }else{
  //         setProcedure(socket);
  //       }
  //     });
  //     socket.on("UPDATE_FINING", (socket: Fine) => {
  //       changeFineStatus(socket.id, socket.estado);
  //       if(socket.certificado) changeFineCertificate(socket.id, socket.certificado);
  //     });
  //     socket.on("CREATE_FINING", (socket: Fine) => {
  //       setFine(socket);
  //     });
  //     socket.on("BLOCKED_USER", (socket) => {
  //       signOut();
  //     });
  //     socket.on("CREATE_APPLICATION", (socket) => {
  //       socket?.liquidaciones.map((item) =>{
  //         const data:Liquidacion = {
  //           id: item.id,
  //           fecha: item.fecha,
  //           ramo: item.ramo,
  //           monto: item.monto,
  //           montoPetro: item.montoPetro,
  //           aprobado: socket.aprobado,
  //           pagado: socket.pagado,
  //           estado: item.estado
  //         }
  //         setTax(data)
  //       })
  //       // changeFineStatus(socket.id, socket.estado);
  //       // if(socket.certificado) changeFineCertificate(socket.id, socket.certificado);
  //     });
  //     socket.on("UPDATE_APPLICATION", (socket) => {
  //       socket?.liquidaciones.map((item) =>{
  //         const data:Liquidacion = {
  //           id: item.id,
  //           fecha: item.fecha,
  //           ramo: item.ramo,
  //           monto: item.monto,
  //           montoPetro: item.montoPetro,
  //           aprobado: socket.aprobado,
  //           pagado: socket.pagado,
  //           recibo: item.recibo,
  //           certificado: item.certificado
  //         }
  //         changeTaxData(data.id,data)
  //       })
  //       // setFine(socket);
  //     });

  //     socket.on("UPDATE_CHARGING", (socket) => {
  //       // console.log(socket);
  //       editCollection(socket)
  //     });

  //     socket.on("NEW_FISCALIZATION", (socket) => {
  //       // console.log(socket);
  //       setInspection(socket);
  //     });

  //     socket.on("UPDATE_FISCALIZATION", (socket) => {
  //       // console.log(socket);
  //       editInspection(socket);
  //     });
  //   }
  //   // eslint-disable-next-line
  // },[auth.token, socket]);

  useEffect(() => {
    setNotificationShow(hasNotifications);
    setAux(list);
    //eslint-disable-next-line
  }, []);

  const handleNewNotification = (notf: Notificacion) => {
    sendNotification(notf);
    hasNewNotifications(!notf.status);
    setAux([notf, ...aux]);
    setCount(count + 1);
    setNotificationShow(true);
  };

  const handleLoadMore = () => {
    const condition = count + augment >= list.length;
    condition ? setAux([...aux, ...list.slice(count, list.length)]) : setAux([...aux, ...list.slice(count, count + augment)]);
    condition ? setCount(list.length) : setCount(count + augment);
  };
  const signOut = () => {
    dispatch({ type: SET_USER, payload: { user: null, token: null } });
    dispatch({ type: AUTH_USER_LOGOUT });
    dispatch({ type: AUTH_USER_BLOCK, payload: 'Su usuario ha sido bloqueado' });
    localStorage.clear();
    localStorage.setItem('blockMessage', 'Su usuario ha sido bloqueado');
  };

  const handleFirstNotifications = () => {
    if (auth.token) {
      fetchNotifications(auth.token);
      return true;
    } else {
      return true;
    }
  };
  const handleCleanupNotifications = () => {
    if (auth.token && hasNotifications) markNotificationsAsRead(auth.token);
    hasNewNotifications(false);
    const newList = R.clone(aux);
    newList.map((el) => {
      el.status = true;
      return el;
    });
    setAux([...newList]);
    setNotificationShow(false);
  };

  const handleReadNotifications = (visible) => {
    setClicked(visible && !clicked ? handleFirstNotifications() : true);
    !visible ? handleCleanupNotifications() : (() => {})();
  };
  const handleVisibleNotf = () => {
    handleReadNotifications(!visiblePopover);
    setVisiblePopover(!visiblePopover);
  };
  return (
    <Popover
      placement='bottomLeft'
      arrowPointAtCenter
      overlayStyle={{ marginRight: '20vw' }}
      visible={visiblePopover}
      content={
        <NotificationList
          onClickItem={handleVisibleNotf}
          data={aux}
          loaders={{ initLoading, onLoadMore: handleLoadMore, limit }}
        />
      }
    >
      <span style={{ padding: '0 12px', height: '100%' }}>
        <Badge dot={show} style={{ width: '10px', height: '10px' }}>
          <div onClick={handleVisibleNotf} style={{ padding: 4, fontSize: 16, verticalAlign: 'middle', cursor: 'pointer' }}>
            <BellOutlined />
          </div>
        </Badge>
      </span>
    </Popover>
  );
};

const mapStateToProps = ({ notf, auth }: State) => ({ notf, auth });

const mapDispatchToProps = {
  hasNewNotifications,
  fetchNotifications,
  sendNotification,
  markNotificationsAsRead,
  changeProcedureStatus,
  changeProcedureData,
  setProcedure,
  changeFineStatus,
  changeFineCertificate,
  setFine,
  setTax,
  changeTaxData,
  setSupport,
  editCollection,
  setInspection,
  editInspection,
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationBell);

interface NotificationProps {
  hasNewNotifications: (hasNew: boolean) => void;
  fetchNotifications: (token: string) => Promise<void>;
  sendNotification: (data) => void;
  markNotificationsAsRead: (token: string) => Promise<void>;
  notf: States.Notifications;
  auth: States.Auth;
  setProcedure: (data: Procedure) => void;
  changeProcedureData: (id: number, data: Procedure) => void;
  changeProcedureStatus: (id: number, status: string) => void;
  setFine: (data: Fine) => void;
  changeFineStatus: (id: number, status: string) => void;
  changeFineCertificate: (id: number, certificate: string) => void;
  setTax: (data: Liquidacion) => void;
  setSupport: Function;
  changeTaxData: (id: number, data: Liquidacion) => void;
  editCollection: (data: Collections) => void;
  setInspection: (data: Inspection) => void;
  editInspection: (data: Inspection) => void;
}
