import React, { useEffect, useState } from 'react';
import { Modal, Row, Form, Col, Select, Button } from 'antd';
import handlingMessage from '../utils/handlingMessage';
import { States, State } from 'sigt';
import { connect } from 'react-redux';
import { getSurvey, sendSurvey } from '../services/survey';

const Survey: React.FC<SurveyProps> = ({ auth }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [res6, setRes6] = useState('');

  const statusSurvey = async () => {
    if (auth.user?.tipoUsuario === 4 && auth.user?.contribuyente?.tipoContribuyente === 'JURIDICO'){
      const data = await getSurvey(auth.user?.contribuyente?.id, auth.token);
      setVisible(!data.encuestado);
    }
  };

  useEffect(() => {
    if (auth) {
      statusSurvey();
    }
  }, [auth]);

  const handleSubmit = async () => {
    setLoading(true);
    const values = await form.validateFields();
    values.id_contribuyente = auth.user?.contribuyente?.id;
    values.respuesta2 = `${values.respuesta2}`;
    if (values.respuesta6 === 'Si') values.respuesta6 = `${values.respuesta6}: ${values.respuesta6Info}`;
    handlingMessage({
      action: () => {
        return sendSurvey(values, auth.token);
      },
      key: 'submit',
      loadingMessage: 'Realizando operacion...',
      cb: (data) => {
        if(data?.status === 200){
          setLoading(false);
          setVisible(false);
        }
      },
    });
  };

  const handleOmit = async () => {
    setLoading(true);
    // const values = await form.validateFields();
    let values = {
      id_contribuyente:auth.user?.contribuyente?.id,
      respuesta1: ' ',
      respuesta2: ' ',
      respuesta3: ' ',
      respuesta4: ' ',
      respuesta5: ' ',
      respuesta6: ' ',
      respuesta7: ' ',
    }
    // values.id_contribuyente = auth.user?.contribuyente?.id;
    // values.respuesta2 = `${values.respuesta2}`;
    // if (values.respuesta6 === 'Si') values.respuesta6 = `${values.respuesta6}: ${values.respuesta6Info}`;
    handlingMessage({
      action: () => {
        return sendSurvey(values, auth.token);
      },
      key: 'submit',
      loadingMessage: 'Realizando operacion...',
      cb: (data) => {
        if(data?.status === 200){
          setLoading(false);
          setVisible(false);
        }
      },
    });
  };

  return (
    <Modal
      title='Encuesta'
      footer={[
        <Button key='submit' loading={loading} type='primary' onClick={() => form.submit()}>
          Enviar
        </Button>,
        <Button key='submit' loading={loading} type='primary' onClick={() => handleOmit()}>
          Omitir
        </Button>,
      ]}
      visible={visible}
      bodyStyle={{ margin: '0 20px', overflowY: 'auto', height: '60vh' }}
    >
      <Form onFinish={handleSubmit} form={form} layout='vertical'>
        <Row>
          <Col span={24}>
            <Form.Item
              label='¿Cómo calificaría usted la situación actual de su empresa?'
              name='respuesta1'
              rules={[{ required: true, message: 'Debe indicar su situación actual' }]}
            >
              <Select placeholder='Situación de empresa' style={{ width: '100%' }}>
                <Select.Option value='Buena'>Buena</Select.Option>
                <Select.Option value='Regular'>Regular</Select.Option>
                <Select.Option value='Mala'>Mala</Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              label='En su opinión ¿Qué factores obstaculizan la plena ejecución de la actividad de su empresa?'
              name='respuesta2'
              rules={[{ required: true, message: 'Debe indicar los factores que obstaculizan la actividad de su empresa' }]}
            >
              <Select placeholder='Obstaculos' mode='multiple' style={{ width: '100%' }}>
                <Select.Option value='Debilidad de la demanda'>Debilidad de la demanda</Select.Option>
                <Select.Option value='Escasez de mano de obra calificada'>Escasez de mano de obra calificada</Select.Option>
                <Select.Option value='Alto costo de materia prima'>Alto costo de materia prima</Select.Option>
                <Select.Option value='Elevada carga tributaria municipal'>Elevada carga tributaria municipal</Select.Option>
                <Select.Option value='Escasez de capital'>Escasez de capital</Select.Option>
                <Select.Option value='Dificultades financieras'>Dificultades financieras</Select.Option>
                <Select.Option value='Aumento de la competencia'>Aumento de la competencia</Select.Option>
                <Select.Option value='Deficiencia de servicios públicos'>Deficiencia de servicios públicos</Select.Option>
                <Select.Option value='Escasez de combustible'>Escasez de combustible</Select.Option>
                <Select.Option value='Fallas en las telecomunicaciones'>Fallas en las telecomunicaciones</Select.Option>
                <Select.Option value='Medidas de restricción operativas por la Pandemia de la COVID-19'>Medidas de restricción operativas por la Pandemia de la COVID-19</Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              style={{ height: 'auto' }}
              label='Para sus decisiones de inversión, la situación política y social actual en el Municipio es:'
              name='respuesta3'
              rules={[{ required: true, message: 'Debe indicar Situación política y social ' }]}
            >
              <Select placeholder='Situación política y social ' style={{ width: '100%' }}>
                <Select.Option value='Favorable'>Favorable</Select.Option>
                <Select.Option value='Neutra'>Neutra</Select.Option>
                <Select.Option value='Desfavorable'>Desfavorable</Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              style={{ height: 'auto' }}
              label='¿Cómo espera que sea la situación de su empresa durante el próximo trimestre?'
              name='respuesta4'
              rules={[{ required: true, message: 'Debe indicar la situación esperada de su empresa' }]}
            >
              <Select placeholder='Situación política y social ' style={{ width: '100%' }}>
                <Select.Option value='Mejor'>Mejor</Select.Option>
                <Select.Option value='Igual'>Igual</Select.Option>
                <Select.Option value='Peor'>Peor</Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              label='¿Cómo cree usted que evolucionará la actividad económica del municipio en el próximo trimestre?'
              name='respuesta5'
              rules={[{ required: true, message: 'Debe indicar su perspectiva de la actividad económica' }]}
            >
              <Select placeholder='Perspectiva de la actividad económica' style={{ width: '100%' }}>
                <Select.Option value='Mejorará'>Mejorará</Select.Option>
                <Select.Option value='No variará'>No variará</Select.Option>
                <Select.Option value='Empeorará'>Empeorará</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label='¿La empresa planea efectuar inversiones en el próximo trimestre?'
              name='respuesta6'
              rules={[{ required: true, message: 'Debe indicar su plan para el próximo trimestre' }]}
            >
              <Select placeholder='Plan para el próximo trimestre' onChange={(value:string) => setRes6(value) } style={{ width: '100%' }}>
                <Select.Option value='Si'>Si</Select.Option>
                <Select.Option value='No'>No</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          {res6 === 'Si' && <Col span={24}>
            <Form.Item
              label='Indique en que rubro de la empresa'
              name='respuesta6Info'
              rules={[{ required: true, message: 'Debe indicar el rubro' }]}
            >
              <Select placeholder='Rubro'style={{ width: '100%' }} mode='multiple'>
                <Select.Option value='Inventario'>Inventario</Select.Option>
                <Select.Option value='Infraestructura'>Infraestructura</Select.Option>
                <Select.Option value='Capital Humano'>Capital Humano</Select.Option>
                <Select.Option value='Tecnología'>Tecnología</Select.Option>
                <Select.Option value='Maquinaria'>Maquinaria</Select.Option>
              </Select>
            </Form.Item>
          </Col>}
          <Col span={24}>
            <Form.Item
              label='En relación al número de empleados de su empresa, ¿Cuáles son sus perspectivas para los próximos meses?'
              name='respuesta7'
              rules={[{ required: true, message: 'Debe indicar su perspectiva de empleados' }]}
            >
              <Select placeholder='Perspectiva de empleados' style={{ width: '100%' }}>
                <Select.Option value='Decrecerá'>Decrecerá</Select.Option>
                <Select.Option value='Se mantendrá igual'>Se mantendrá igual</Select.Option>
                <Select.Option value='Aumentará'>Aumentará</Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
interface SurveyProps {
  auth: States.Auth;
}

const mapStateToProps = ({ auth, brch }: State) => ({ auth, brch });

export default connect(mapStateToProps)(Survey);
