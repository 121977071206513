import React, { useEffect } from 'react';
import { States, State } from 'sigt';
import { connect, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Card, Col, Row, Divider, Typography, Table, Button, Tag } from 'antd';
import { useWindowDimensions } from '../../utils/hooks';
import { CreditCardFilled, EyeFilled } from '@ant-design/icons';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import Petro from '../Icons/Petro';
import { getCurrency } from '../../utils/currency';

const PayTaxes: React.FC<PayTaxesProps> = ({ auth, thm, requests }) => {
  const history = useHistory();
  const { width } = useWindowDimensions();

  useEffect(() => {
    console.log(requests, 'requests');
  }, [requests]);

  const petro = useSelector((state: State) => state.coin.petro);

  const columns: ColumnsType<any> = [
    {
      title: 'Documento de Identidad',
      dataIndex: 'documento',
      render: (doc, r) => (
        <span>
          {r.tipoDocumento}-{doc}
        </span>
      ),
    },
    // {
    //   title: 'Documento de Identidad.',
    //   dataIndex: 'referenciaMunicipal',
    //   render: (rim) => <span>{rim ? rim : 'N/A'}</span>,
    // },
    {
      title: 'Tipo',
      dataIndex: 'tipo',
      render: (tipo) => <span>{tipo ? tipo : 'N/A'}</span>,
    },
    {
      title: 'Fecha',
      dataIndex: 'fecha',
      render: (fecha) => <span>{moment(fecha).utc().format('DD/MM/YYYY')}</span>,
    },
    {
      title: `Monto (${getCurrency()})`,
      dataIndex: 'montoPetro',
      render: (monto, record) => (
        <span>
          {monto ? monto : +(+record.monto / petro).toFixed(8)} <Petro />
        </span>
      ),
    },
    {
      title: 'Estado',
      dataIndex: 'monto',
      render: (_, record) => (
        <Tag style={{ width: '100%', textAlign: 'center' }} color={getState(record.estado).color}>
          {getState(record.estado).name}
        </Tag>
      ),
    },
    {
      title: 'Acciones',
      dataIndex: 'id',
      render: (id, tax) =>
        tax.estado === 'ingresardatos' ? (
          <Button icon={<CreditCardFilled />} type='primary' onClick={() => history.push(`/dashboard/impuestos/pagar/${id}`)}>
            Pagar
          </Button>
        ) : (
          tax.estado === 'finalizado' && (
            <Button icon={<EyeFilled />} type='primary' onClick={() => history.push(`/dashboard/impuestos/pagar/${id}`)}>
              Ver
            </Button>
          )
        ),
    },
  ];

  const getState = (estado) => {
    if (estado === 'ingresardatos') return { name: 'En espera de pago', color: 'cyan' };
    else if (estado === 'validando') return { name: 'Validando pago', color: 'warning' };
    else return { name: 'Solvente', color: 'green' };
  };

  useEffect(() => {
    if (auth.user?.tipoUsuario !== 4) history.push('/dashboard');
    // eslint-disable-next-line
  }, []);

  return (
    <Card
      style={{ height: '100%' }}
      title='Pagar Impuestos'
      bodyStyle={{ height: 'calc(100% - 88px)', overflowY: 'scroll', overflowX: 'hidden' }}
      headStyle={{ height: 64, backgroundColor: thm.primaryColor, padding: width < 992 ? '0 10px' : '0 20px', color: 'white' }}
    >
      <Row gutter={24}>
        <Divider orientation='left'>
          <Typography.Title level={4} style={{ marginLeft: 5 }}>
            Declaraciones
          </Typography.Title>
        </Divider>
        <Col span={24}>
          <Table
            columns={columns}
            dataSource={requests.map((r, key) => ({
              ...r,
              key,
            }))}
            bordered
          />
        </Col>
      </Row>
    </Card>
  );
};

const mapStateToProps = (state: State) => ({ auth: state.auth, thm: state.thm, requests: state.req.requests });

export default connect(mapStateToProps)(PayTaxes);

interface PayTaxesProps {
  auth: States.Auth;
  thm: States.ThemeColors;
  requests: States.Requests['requests'];
}
