import React from 'react';
import { Select } from 'antd';

const EntitySelect: React.FC<EntitySelectProps> = ({ onChange, value, placeholder, disabled = false }) => {
  return (
    <Select value={value} onChange={onChange} placeholder={placeholder} disabled={disabled}>
      <Select.Option value='PUBLICA'>Entidad Pública</Select.Option>
      <Select.Option value='FINANCIERA'>Entidad Financiera</Select.Option>
    </Select>
  );
};

export default EntitySelect;

interface EntitySelectProps {
  onChange?: (e: any) => any
  value?: any
  placeholder?: string
  disabled?: boolean
}