import React, { useRef, useState } from "react";
import { Table, Button, Col, Form, Card, Select, Input, message, Row, Tag } from "antd";
import { connect } from "react-redux";
import { State, States } from 'sigt';
import { ColumnsType } from "antd/lib/table";
import { useWindowDimensions } from "../../utils/hooks";
import { Utils } from "../../utils/validators";
import { useForm } from "antd/es/form/util";
import axios from 'axios';
import moment from "moment";
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from "@ant-design/icons";

const server = process.env.REACT_APP_SERVER_URL;

const formatCurrency = (number: number) => new Intl.NumberFormat('de-DE').format(number);

const TableRetentions: React.FC<PropsTableRetentions> = ({ thm, auth }) => {
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [nacionalidadRif, setNacionalidadRif] = useState('J');
  const [totalCredit, setTotalCredit] = useState();
  const [data,setData] = useState([]);
  const { width } = useWindowDimensions();
  const [form] = useForm();
  const inputRef = useRef<any>(null);
  const typesR = { 'C': 'Compra', 'S': 'Servicio', 'NC': 'Nota de Credito' };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = clearFilters => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={inputRef}
          placeholder='Buscar'
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type='primary'
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size='small'
          style={{ width: 90, marginRight: 8 }}
        >
          Buscar
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size='small' style={{ width: 90 }}>
          Restablecer
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined style={{ color: filtered ? thm.primaryColor : undefined }} />
    ),
    onFilter: (value, record) => 
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        if (inputRef.current) {
          inputRef.current?.select();
        }
      }
    },
    render: text =>  searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#1e72c675', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
    ),
  });

  const columns: ColumnsType<any> = [
    {
      title: 'RIF',
      dataIndex: 'rif',
      ...getColumnSearchProps('rif')
    },
    {
      title: 'RIM',
      dataIndex: 'rim',
      ...getColumnSearchProps('rim')
    },
    {
      title: 'Razon Social',
      dataIndex: 'razonSocial',
      ...getColumnSearchProps('razonSocial')
    },
    {
      title: 'Fecha',
      dataIndex: 'fecha',
      align: 'center',
      render: (fecha) => moment(fecha).format('DD/MM/YYYY')
    },
    {
      title: 'Tipo',
      dataIndex: 'tipoRetencion',
      filters: [{ value: 'C', text: 'Compra' }, { value: 'S', text: 'Servicio'}, { value: 'NC', text: 'Nota de Credito' }],
      onFilter: (value, record) => value === record.tipoRetencion,
      render: (tipo) => typesR[tipo] || ''
    },
    {
      title: 'Nº Factura',
      dataIndex: 'numeroFactura',
      ...getColumnSearchProps('numeroFactura')
    },
    {
      title: 'Monto',
      dataIndex: 'monto',
      align: 'center',
      render: value => `Bs. ${formatCurrency(value)}`
    },
    {
      title: 'Pagado',
      dataIndex: 'pagado',
      align: 'center',
      filters: [{ value: 'true', text: 'PAGADO' }, { value: 'false', text: 'ESPERA DE PAGO' }],
      onFilter: (value, record) => value === 'true' ? record.pagado === true : record.pagado === false,
      render: (pagado: boolean) => pagado ? <Tag color='green'>PAGADO</Tag> : <Tag color='cyan'>ESPERA DE PAGO</Tag>
    }
  ];

  const rifAddon = (
    <Select value={nacionalidadRif} onChange={(e) => setNacionalidadRif(e)}>
      <Select.Option value='J'>J</Select.Option>
      <Select.Option value='E'>E</Select.Option>
      <Select.Option value='V'>V</Select.Option>
      <Select.Option value ='G'>G</Select.Option>
      <Select.Option value='C'>C</Select.Option>
    </Select>
  );

  const onSubmit = async () => {
    const values = await form.validateFields();
    setLoading(true);
    const rif = auth.user?.tipoUsuario === 4 ? `${auth.user.contribuyente?.tipoDocumento}${auth.user.contribuyente?.documento}` : nacionalidadRif + values.documento; 
    try {
      const response = await axios.get(`${server}/retentions/charges/${rif}/${values.rim}`,{ headers: { Authorization: `Bearer ${auth.token}` } });
      setData(response.data?.retenciones?.cobros);
      setTotalCredit(response.data?.retenciones?.balanceTotal);      
    } catch (e) {
      message.error(e?.response?.data?.error || e?.response?.data?.message || 'Error en el servidor');
    }finally{
      setLoading(false);
    }
  }

  return (<Card style={{ height: '100%' }} title='Consulta de Retenciones' headStyle={{ backgroundColor: thm.primaryColor, padding: width < 992 ? '0 10px' : '0 20px', color: '#fff' }} bodyStyle={{ overflowY: 'auto', height:'90%' }}>
    <Form form={form} layout='vertical' onFinish={onSubmit}>
      <Row gutter={[16,16]} >
        {auth.user?.tipoUsuario !== 4 && <Col xs={24} md={12} xl={8}>
          <Form.Item label='R.I.F.' name='documento' normalize={Utils.normalize.isCNumber} rules={[{required: true, message: 'Ingrese un numero de documento'}]}>
            <Input placeholder='R.I.F.' addonBefore={rifAddon} />
          </Form.Item>
        </Col>}
        {/* <Col xs={24} md={12} xl={8}>
          <Form.Item label='Documento de Identidad.' name='rim' normalize={Utils.normalize.toUpper} rules={[{required: true, message: 'Ingrese un Documento de Identidad'}]}>
            <Input placeholder='Documento de Identidad.' />
          </Form.Item>
        </Col> */}
        <Col xl={8} xs={24}>
          <Button style={{ marginTop: width < 1200 ? 0 : 40 }} type='primary' htmlType='submit'>Consultar</Button>
        </Col>
      </Row>
    </Form>
    {data && <Table size='middle' pagination={{ pageSize: 6 }} loading={loading} columns={columns} dataSource={data} rowKey={e => e.id} bordered />}
  </Card>);
};
const mapStateToProps = ({ thm, auth }: State) => ({ thm, auth });

export default connect( mapStateToProps )(TableRetentions);

interface PropsTableRetentions {
  thm: States.ThemeColors;
  auth: States.Auth;
}

