import React, { CSSProperties } from 'react';
import { useSelector } from 'react-redux';
import { State } from 'sigt';

const initialStyle: CSSProperties = {
  width: 15,
  height: 15,
  objectFit: 'contain',
};

const Petro: React.FC<PetroProps> = ({ style = {}, ...props }) => {
  const monedaState = useSelector((state: State) => state.coin.moneda);
  const _styles = {
    ...initialStyle,
    ...style,
  };

  const ImageCurrency =
    monedaState === 'dolar' ? (
      <img src={require('../../assets/images/dolar.png')} style={_styles} alt='Dolar' {...props} />
    ) : (
      <img src={require('../../assets/images/euro.png')} style={_styles} alt='Euro' {...props} />
    );

  return ImageCurrency;
};

export default Petro;

interface PetroProps {
  style?: CSSProperties;
}
