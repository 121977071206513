import React, { useState } from 'react';
import { Card, Form, Row, Divider, Typography, Col, Input, Select, Button, message } from 'antd';
import { useWindowDimensions } from '../../../utils/hooks';
import { State, States } from 'sigt';
import { connect } from 'react-redux';
import { Utils } from '../../../utils/validators';
import axios from 'axios';
import { useHistory } from 'react-router';
import { setProcedure } from '../../../redux/actions/procedures';
const server = process.env.REACT_APP_SERVER_URL;

const OfficialPFForm: React.FC<OfficialPFFormProps> = ({ thm, destinations, setProcedure, auth }) => {
  const [nacionalidad, setNacionalidad] = useState<string>('V');
  const [data, setData] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(false);
  const formatCurrency = (number: number) => new Intl.NumberFormat('de-DE').format(number);

  const { width } = useWindowDimensions();

  const [form] = Form.useForm();
  const history = useHistory();

  const cedulaAddon = (
    <Select defaultValue='V' value={nacionalidad} onChange={(e) => setNacionalidad(e)}>
      <Select.Option value='V'>V</Select.Option>
      <Select.Option value='E'>E</Select.Option>
    </Select>
  );

  const handleSubmit = async () => {
    const values = await form.validateFields();
    values.monto = formatCurrency(destinations.find(d => d.destino === data.destino)?.montoCalculado || 0);
    values.fechaTramite = new Date();
    const datos = { ...values };
    const tramite = {
      datos,
      tipoTramite: 17,
      recaudos: []
    };
    setLoading(true);
    try {
      const resp = await axios.post(`${server}/procedures/init`, { tramite }, { headers: { Authorization: `Bearer ${auth.token}` }});
      if(resp.status === 201) {
        setLoading(false);
        message.success('Tramite creado exitosamente');
        setProcedure(resp.data.tramite);
        form.resetFields();
        history.push('/dashboard/bandejas');
      } else {
        setLoading(false);
        message.error('Error al generar tramite');
      }
    } catch(e) {
      message.error((e.response.data.message as string).includes('referencia ya existe') ? 'La referencia bancaria ya ha sido utilizada.' : 'Error en el servidor');
    } finally { setLoading(false) }
  };

  return (
    <Card style={{ height: '100%' }} title='Tasa de Salida de Pasajeros' bodyStyle={{ height: 'calc(100% - 88px)', overflowY: 'scroll', overflowX: 'hidden' }}
      headStyle={{ height: 64, backgroundColor: thm.primaryColor, padding: width < 992 ? '0 10px' : '0 20px', color: 'white'}}>
      <Form scrollToFirstError form={form} layout='vertical' style={{ padding: width < 992 ? '0 10px' : '0px 20px' }} onFinish={() => handleSubmit()}
        onValuesChange={(c, v) => { form.setFieldsValue(v); setData(v) }}>
        <Row gutter={24}>
          <Divider orientation='left' style={{ marginLeft: -20 }}>
            <Typography.Title level={4} style={{ marginLeft: 5 }}>
              Datos del Pasajero
            </Typography.Title>
          </Divider>
          <Col xs={24} xl={8}>
            <Form.Item label='Cedula' name='cedula' rules={[{ required: true, message: 'Debe ingresar el numero de cedula' }, 
              { validator: Utils.validators.cedula }]} normalize={Utils.normalize.isNumber}>
              <Input placeholder='Cedula' addonBefore={cedulaAddon} />
            </Form.Item>
          </Col>
          <Col xs={24} xl={8}>
            <Form.Item label='Nombre y Apellido' name='nombreCompleto' rules={[{ required: true, message: 'Debe ingresar el nombre del solicitante' }]}>
              <Input placeholder='Nombre y Apellido' />
            </Form.Item>
          </Col>
          <Col xs={24} xl={8}>
            <Form.Item label='Destino' name='destino' rules={[{ required: true, message: 'Debe ingresar el destino' }]}>
              <Select placeholder='Destino'>
                {destinations.filter(d => d.habilitado).map(d => (
                  <Select.Option value={d.destino} key={d.id}>{d.destino}</Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Divider orientation='left' style={{ marginLeft: -20 }}>
            <Typography.Title level={4} style={{ marginLeft: 5 }}>
              Datos de Pago
            </Typography.Title>
          </Divider>
          {data.destino && <Col span={24}>
            <Typography.Text strong type='danger'>
              La tasa de salida con destino {data.destino} tiene una costo de BsS. {formatCurrency(destinations.find(d => d.destino === data.destino)?.montoCalculado || 0)}
            </Typography.Text>
          </Col>}
          <Col xs={24} xl={6}>
            <Form.Item label='Método de Pago' name='metodo' rules={[{ required: true, message: 'Debe ingresar el método de pago' }]}>
              <Select placeholder='Método de Pago'>
                <Select.Option value='Efectivo'>Efectivo</Select.Option>
                <Select.Option value='Punto'>Punto de Venta</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          {data.metodo === 'Punto' && 
          <Col xs={24} xl={6}>
            <Form.Item label='Numero de Referencia' name='referencia' rules={[{ required: true, message: 'Debe ingresar el numero de referencia del punto de venta' }]}>
              <Input placeholder='Numero de Referencia' maxLength={20} />
            </Form.Item>
          </Col>}
        </Row>
      </Form>
      <Button loading={loading} type='primary' onClick={form.submit}>Generar Ticket</Button>
    </Card>
  );
};

const mapStateToProps = (state: State) => ({ thm: state.thm, destinations: state.cst.destinations, auth: state.auth });

export default connect(mapStateToProps, { setProcedure })(OfficialPFForm);

interface OfficialPFFormProps {
  thm: States.ThemeColors
  destinations: States.Costs['destinations']
  auth: States.Auth
  setProcedure: Function
}