import axios, { AxiosResponse } from "axios";
import { SetInstitutionAction, SET_INSTITUTIONS, ProceduresActionsTypes, TaxesTypes} from './actionsTypes';
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { Institucion, Procedure, Fine} from "sigt";
import { setProcedures, setFines, setSupports } from './procedures'
import { setTaxes } from "./taxes";
const server = process.env.REACT_APP_SERVER_URL;

export const setInstitutions = (institutions: Institucion[]): SetInstitutionAction => ({ type: SET_INSTITUTIONS, payload: institutions });

export const fetchAll = (token: string): ThunkAction<Promise<void>, {}, {}, SetInstitutionAction | ProceduresActionsTypes | TaxesTypes> => 
  async (dispatch: ThunkDispatch<{}, {}, SetInstitutionAction | ProceduresActionsTypes| TaxesTypes>): Promise<void> => {
    try {
      const response: AxiosResponse<FetchAllResponse> = await axios.get(`${server}/procedures`, { headers: { Authorization: `Bearer ${token}` } });
      dispatch(setProcedures(response.data.instanciasDeTramite));
      dispatch(setFines(response.data.instanciasDeMulta));
      dispatch(setTaxes(response.data.instanciasDeImpuestos));
      dispatch(setSupports(response.data.instanciasDeSoporte));
    } catch(error) {
      throw error;
    }
};

export const fetchInstitutions = (token: string): ThunkAction<Promise<void>, {}, {}, SetInstitutionAction> =>
  async (dispatch: ThunkDispatch<{}, {}, SetInstitutionAction>): Promise<void> => {
    try {
      const response = await axios.get(`${server}/options`, { headers: { Authorization: `Bearer ${token}` } });      dispatch(setInstitutions(response.data.options));
      dispatch(setInstitutions(response.data.options));
    } catch(error) {
      throw error;
    }
  };

interface FetchAllResponse {
  instanciasDeTramite: Procedure[];
  instanciasDeMulta: Fine[];
  instanciasDeImpuestos: any[];
  instanciasDeSoporte: any[];
}

interface FetchInstitutionsResponse {
  options: Institucion[];
}