import React, { useState, useEffect } from 'react';
import { Form, Row, Col, Select, Input, Button, message, Table, Tag, Tooltip } from 'antd';
import { State, States, Taxpayer } from 'sigt';
import { connect } from 'react-redux';
import { useWindowDimensions } from '../../../utils/hooks';
import { SearchOutlined, EyeOutlined, FileDoneOutlined } from '@ant-design/icons';
import axios from 'axios';
import { ColumnsType } from 'antd/lib/table';
import { useHistory, useLocation } from 'react-router';
import { Utils } from '../../../utils/validators';
import moment from 'moment';
const server = process.env.REACT_APP_SERVER_URL;

const GetTolls: React.FC<GetTollsProps> = ({ auth, thm }) => {
  const [tipoDocumento, setTipoDocumento] = useState('V');
  const [searching, setSearching] = useState(false);
  const [tolls, setTolls] = useState<any[]>([]);
  const [visible, setVisible] = useState(false);

  const [query, setQuery] = useState('');

  const { width } = useWindowDimensions();
  const [form] = Form.useForm();
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if((history.location.state as any)?.tolls) {
      setTolls((history.location.state as any)?.tolls)
    }
    //eslint-disable-next-line
  }, []);

  const columns: ColumnsType<any> = [
    {
      title: 'Funcionario',
      dataIndex: 'funcionario'
    },
    {
      title: 'Peaje',
      dataIndex: 'descripcionRamo',
    },
    {
      title: 'Monto BS.',
      dataIndex: 'monto',
    },
    {
      title: 'Fecha',
      dataIndex: 'fecha_liquidacion',
      render: (fecha) => <span>{moment(fecha).format('DD/MM/YYYY')}</span>
    },
    {
      title: "",
      key: "action",
      render: (_,record) => (<Tooltip title='Recibo' visible={visible}>
      <Button
        shape='round' 
        target='_blank' 
        href={record.recibo || ''} 
        icon={<FileDoneOutlined />} 
        onMouseOver={() => width > 440 ? setVisible(false) : setVisible(true)} 
        onMouseLeave={()=> setVisible(false)}
        size='small'>
        {width > 440 ? 'Recibo' : null}
      </Button>
    </Tooltip>)
    }
  ];

  const action = (peaje: any) => {
      history.push(`/dashboard/impuestos/peaje/${peaje.id_solicitud}`, { peaje, peajes: tolls })
  };

  const search = async () => {
    const values = await form.validateFields();
    const { codigo } = values;
    if(codigo) {
      try {
        setSearching(true);
        const params = `codigo=${codigo}`;
        const response = await axios.get(`${server}/settlements/tolls/info?${params}`,
          { headers: { Authorization: `Bearer ${auth.token}` } });
        setTolls(response.data.tolls);
      } catch(e) {
        message.error(e.response?.data?.message || 'Error al buscar peaje')
      } finally {
        setSearching(false);
      }
    } else {
      message.warn('Debe ingresar algun codigo de recibo')
    }
  };

  const fetchAllTolls = async () => {
      setSearching(true);
      try {
        const response = await axios.get(`${server}/settlements/tolls/info`, { headers: { Authorization: `Bearer ${auth.token}` } });
        setTolls(response.data.tolls);
      } catch (e) {
        message.error(e.response?.data?.message || 'Error al conseguir los peajes');
      } finally {
        setSearching(false);
      }
    
  };

  return (
    <Form layout='vertical' form={form}>
      <Row gutter={24}>
        <Col xs={24} xl={7}>
          <Form.Item label='Código de Recibo' name='codigo'>
            <Input placeholder='Código de Recibo'/>
          </Form.Item>
        </Col>
        <Col xl={6} xs={12}>
          <Button onClick={() => search()} style={{ marginTop: width < 1200 ? 0 : 38, marginBottom: width < 1200 ? 20 : 0 }} loading={searching} icon={<SearchOutlined />} type='primary'>Buscar</Button>
        </Col>
        <Col xl={6} xs={12}>
          <Button onClick={() => fetchAllTolls()} style={{ marginTop: width < 1200 ? 0 : 38, marginBottom: width < 1200 ? 20 : 0 }} loading={searching} icon={<SearchOutlined />} type='primary'>Mostrar todos</Button>
        </Col>
        <Col span={24}>
          <Table columns={columns} dataSource={tolls.map(t => {
            t.key = t.id;
            return t;
          })} bordered loading={searching} />
        </Col>
      </Row>
    </Form>
  );
};

const mapStateToProps = (state: State) => ({ auth: state.auth, thm: state.thm });

export default connect(mapStateToProps)(GetTolls);

interface GetTollsProps {
  auth: States.Auth
  thm: States.ThemeColors
}