import React, { createRef, RefObject, useEffect, useRef } from 'react';
import { Table, Form, Row, Col, Select, Input, DatePicker, Divider, Button, Typography, InputNumber, Card } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { States, State, Institucion } from 'sigt';
import { connect } from 'react-redux';
import { FormInstance } from 'antd/lib/form';
import moment from 'moment';
import { DeleteOutlined } from '@ant-design/icons';

const MultipleWireTransfers: React.FC<MultiplePaymentsProps> = ({ banks, selectedInstitution, form, files, setFiles, percentTax, mineralTax, bomberos, peajes }) => {
   // BOD, BANESCO Y BNC
  const sedematBanks = [1, 2, 3, 14, 22, 19, 23, 25, 11, 12 , 35, 6];
  const recaudosRef = useRef<RefObject<HTMLImageElement>[]>([]);
  
  useEffect(()=>{
    if( form.getFieldValue('pagos')?.length > recaudosRef.current?.length ) {
      let diff = form.getFieldValue('pagos')?.length - recaudosRef.current?.length
      for(let i=0; i<diff; i++){
        recaudosRef.current.push(createRef<HTMLImageElement>())    
      }
    }
    else if( form.getFieldValue('pagos')?.length < recaudosRef.current?.length ) {
      let diff = recaudosRef.current?.length - form.getFieldValue('pagos')?.length 
      for(let i=0; i<diff; i++){
        recaudosRef.current.splice(-1,1);  
      }
    }
  },[form]);

  const bankColumns: ColumnsType<any> = [
    {
      title: 'Banco',
      dataIndex: 'banco',
      key: 'banco',
      fixed: 'left'
    },
    {
      title: 'Numero de Cuenta',
      dataIndex: 'numeroCuenta',
      key: 'numeroCuenta'
    },
    {
      title: 'Titular',
      dataIndex: 'nombreTitular',
      key: 'nombreTitular'
    },
    {
      title: 'Documento de Identidad',
      dataIndex: 'documento',
      key: 'documento',
    }
  ];

  const handleBankChange = banco => {
    form.setFieldsValue({   
      banco
    });
  };

  const firstListItem = (fields, add) => {
    if(fields.length === 0) {
      // setTimeout(() => add(), 10);
      add();
    }
  }

  const handleFiles = (e, name: string, i: number) => {
    const file = e.target.files[0];
    if (file) {
      const fr = new FileReader();
      const blob = (file as File).slice(0, (file as File).size, file.type);
      setFiles({
        ...files,
        [name]: new File([blob], `${name}.${file.name.split('.')[file.name.split('.').length - 1]}`, { type: file.type }),
      });
      fr.readAsDataURL(file);
      fr.onload = (e) => {
        if (file.type.includes('image')) recaudosRef.current[i]?.current?.setAttribute('src', e.target?.result?.toString() || '');
        else if (file.type.includes('excel')) {
          recaudosRef.current[i]?.current?.setAttribute('src', require('../../../assets/images/excel.png'));
          recaudosRef.current[i]?.current?.style.setProperty('width', '80px');
          recaudosRef.current[i]?.current?.style.setProperty('height', '80px');
        } else if (file.type.includes('pdf')) {
          recaudosRef.current[i]?.current?.setAttribute('src', require('../../../assets/images/pdf.png'));
          recaudosRef.current[i]?.current?.style.setProperty('width', '80px');
          recaudosRef.current[i]?.current?.style.setProperty('height', '80px');
        } else {
          recaudosRef.current[i]?.current?.setAttribute('src', require('../../../assets/images/file.png'));
          recaudosRef.current[i]?.current?.style.setProperty('width', '80px');
          recaudosRef.current[i]?.current?.style.setProperty('height', '80px');
        }
      };
    }
  };

  const deleteFile = (name: string, i: number) => {
    const _files = Object.assign({}, files);
    delete _files[name];
    setFiles(_files);
    recaudosRef.current[i] = createRef();
  };

  return (
    <React.Fragment>
      <Table bordered pagination={false} style={{ marginBottom: 15 }} columns={bankColumns} dataSource={selectedInstitution?.cuentasBancarias?.filter((c) => !(Number(c.id) === 6 || Number(c.id) === 7)).filter(
          (b) => mineralTax ? b.usoRamo?.includes('Minerales') : ( percentTax ? b.usoRamo?.includes('2x100') : ( bomberos ? b.usoRamo?.includes('Bomberos') : ( peajes ? b.usoRamo?.includes('Peajes') : b.usoRamo?.includes('Timbres'))))
          ).filter((b) => b.metodoPago?.includes('TRANSFERENCIA') || b.metodoPago?.includes('DEPOSITO') ).map((c) => 
        ({numeroCuenta: c.numeroCuenta, 
        documento: `${(c.rif) ? 'RIF: ' : 'Cedula: '}${(c.rif || c.cedula)}`, nombreTitular: c.nombreTitular, banco: banks?.find(b => b.id === c.banco)?.nombre, key: c.id })
      )} />
      <Form.List name='pagos'>
        {(fields, { add, remove }) => (
          <div>
            {firstListItem(fields, add)}
            {fields.map((field, index) => { 
              const ref = React.createRef<HTMLInputElement>();
              return(
              <React.Fragment key={field.key}>
                <Divider style={{ margin: 5, marginLeft: -5 }} orientation='left'>
                  <Typography.Text strong style={{ marginLeft: 5 }}>
                    Transferencia {index + 1}
                  </Typography.Text>
                </Divider>
                <Form.Item required={true} key={field.key} style={{ width: '100%', margin: 0 }}>
                  <Row gutter={24} style={{ padding: '0 20px' }}>
                  <Col xl={8} xs={24}>
                      <Form.Item label='Método de Pago' name={[field.name, 'metodoPago']} rules={[{ required: true, message: 'Debe indicar el método de pago' }]}>
                        <Select placeholder='Método de Pago'>
                          <Select.Option value='TRANSFERENCIA'>Transferencia</Select.Option>
                          <Select.Option value='DEPOSITO'>Depósito</Select.Option>
                          {/* {(selectedReqType !== 'RETENCION' && maxCred && maxCred > 0)  && <Select.Option disabled={alreadyUsedCredit()} value='CREDITO_FISCAL'>Credito Fiscal por compesanción</Select.Option>}
                          {(selectedReqType !== 'RETENCION' && maxCredR && maxCredR > 0)  && <Select.Option disabled={alreadyUsedCreditR()} value='CREDITO_FISCAL_RETENCION'>Credito Fiscal por retención</Select.Option>} */}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col xs={24} xl={6}>
                      <Form.Item label='Banco' name={[field.name, 'banco']} fieldKey={field.fieldKey} rules={[{ required: true, message: 'Debe indicar el banco' }]}>
                        {banks ? 
                        <Select onChange={handleBankChange} placeholder='Banco'>
                          {banks?.map((b, i) =>
                            <Select.Option key={`b-${b.id}`} value={b.id}>{b.nombre}</Select.Option>
                          )}
                          {/* {banks?.filter((b) => selectedInstitution?.cuentasBancarias?.map((c) => c.banco).includes(b.id)).map((b, i) =>
                            <Select.Option key={`b-${b.id}`} value={b.id}>{b.nombre}</Select.Option>
                          )} */}
                          {/* <Select.Option value={11}>Banco de Venezuela S.A.C.A. Banco Universal</Select.Option> */}
                        </Select> : <div></div>}
                      </Form.Item>
                    </Col>
                    <Col xs={24} xl={8}>
                      <Form.Item label='Banco de Destino' name={[field.name, 'destino']} fieldKey={field.fieldKey} rules={[{ required: true, message: 'Debe indicar el banco' }]}>
                        {banks ? 
                        <Select placeholder='Banco de Destino'> onChange={handleBankChange}
                          {
                            selectedInstitution?.cuentasBancarias?.filter(
                              (b) => mineralTax ? b.usoRamo?.includes('Minerales') : ( percentTax ? b.usoRamo?.includes('2x100') : b.usoRamo?.includes('Timbres'))
                              ).filter((b) => b.metodoPago?.includes('TRANSFERENCIA') || b.metodoPago?.includes('DEPOSITO')).map((b, i) =>
                              <Select.Option key={`b-${b.id}`} value={b.id}>{ b.numeroCuenta?.slice(b.numeroCuenta?.length - 4) + ' - ' + banks.find((bank) => bank.id === b.banco)?.nombre}</Select.Option>
                            )
                          }
                        </Select> : <div></div>}
                      </Form.Item>
                    </Col>
                    <Col xs={24} xl={6}>
                      <Form.Item label='Numero de Referencia' name={[field.name, 'referencia']} fieldKey={field.fieldKey} rules={[{ required: true, message: 'Por favor ingrese el numero de referencia.' }]}>
                        <Input placeholder='Referencia' maxLength={20} />
                      </Form.Item>
                    </Col>
                    <Col xs={24} xl={6}>
                      <Form.Item label='Fecha de Pago' name={[field.name, 'fecha']} fieldKey={field.fieldKey} rules={[{ required: true, message: 'Por favor ingrese la fecha de pago.' }]}>
                        <DatePicker style={{ width: '100%' }} format={"DD-MM-YYYY"} disabledDate={(current) => current > moment().endOf('day')} />
                      </Form.Item>
                    </Col>
                    <Col xs={24} xl={6}>
                      <Form.Item label='Monto' name={[field.name, 'costo']} fieldKey={field.fieldKey} rules={[{ required: true, message: 'Por favor ingrese el monto del pago.' }]}>
                        <InputNumber formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} parser={value => value ? value.replace(/Bs\s?|(,*)/g, '') : ''} min={0} step={1} style={{ width: '100%' }} placeholder='Monto' />
                      </Form.Item>
                    </Col>
                  </Row>
                    {files && files.hasOwnProperty(`Comprobante${index+1}`) ? (
                      <Col xs={24} xl={6} key={index}>
                      <Card
                        cover={<img alt='recaudo' ref={recaudosRef.current[index]} />}
                        bodyStyle={{ padding: 10, display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                      >
                        <Typography.Text strong style={{ textAlign: 'center' }}>
                          {`Comprobante${index+1}`}
                        </Typography.Text>
                        <Button
                          type='link'
                          onClick={() => deleteFile(`Comprobante${index+1}`, index)}
                          style={{ textAlign: 'right', width: '100%', color: 'red' }}
                        >
                          Eliminar
                          <DeleteOutlined />
                        </Button>
                      </Card>
                    </Col>
                    ) : (
                    <Col xs={24} xl={6}>
                      <input
                      hidden
                      ref={ref}
                      type='file'
                      onChange={(e) => handleFiles(e,`Comprobante${index+1}` ,index)}
                    />
                    <Button type='primary' style={{ marginTop: 10, marginBottom: 10}} onClick={() => ref.current?.click()}>
                      Cargar comprobante
                    </Button>  
                    </Col> 
                    )}
                </Form.Item>
              </React.Fragment>
            )})}
            <Row gutter={[16, 16]} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              {fields.length > 1 && 
              <Col>
                <Button type='danger' onClick={() => remove(fields.length - 1)}>Eliminar Transferencia</Button>
              </Col>}
              <Col>
                <Button type='dashed' onClick={() => add()}>Agregar Transferencia</Button>
              </Col>
            </Row>
          </div>
        )}
      </Form.List>
    </React.Fragment>
  );
};

const mapStateToProps = (state: State) => ({ banks: state.bk.banks });

export default connect(mapStateToProps)(MultipleWireTransfers);

interface MultiplePaymentsProps {
  banks: States.Banks['banks']
  selectedInstitution?: Institucion
  form: FormInstance
  files?: any
  setFiles?: any
  percentTax?: boolean
  mineralTax?: boolean
  bomberos?: boolean
  peajes?: boolean
}