
import { Button, Card, Col, Form, Input, message, Modal, Row, Typography } from 'antd';
import React, { useState, useRef, createRef, RefObject } from 'react';
import { connect } from 'react-redux';
import { State } from 'sigt';
import { useWindowDimensions } from '../../utils/hooks';
import axios from 'axios';
import { useHistory } from 'react-router';
import { DeleteOutlined } from '@ant-design/icons';
const server = process.env.REACT_APP_SERVER_URL;

const CatTest = ({ thm, auth }) => {
  const { width } = useWindowDimensions();
  const [files, setFiles] = useState<{ [P: string]: File } | null>(null);
  const [form] = Form.useForm();
  const [modalVisible, setModalVisible] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [downloading, setDownloading] = useState(false);
  const history = useHistory();

  const recaudosRef = useRef<RefObject<HTMLImageElement>[]>([]);
  const ref = React.createRef<HTMLInputElement>();
  recaudosRef.current[0] = createRef<HTMLImageElement>();

  const handleFiles = (e, name: string, i: number) => {
    const file = e.target.files[0];
    if (file) {
      const fr = new FileReader();
      const blob = (file as File).slice(0, (file as File).size, file.type);
      setFiles({
        ...files,
        [name]: new File([blob], `${name}.${file.name.split('.')[file.name.split('.').length - 1]}`, { type: file.type }),
      });
      fr.readAsDataURL(file);
      fr.onload = (e) => {
        if (file.type.includes('image')) recaudosRef.current[i]?.current?.setAttribute('src', e.target?.result?.toString() || '');
        else if (file.type.includes('excel')) {
          recaudosRef.current[i]?.current?.setAttribute('src', require('../../assets/images/excel.png'));
          recaudosRef.current[i]?.current?.style.setProperty('width', '80px');
          recaudosRef.current[i]?.current?.style.setProperty('height', '80px');
        } else if (file.type.includes('pdf')) {
          recaudosRef.current[i]?.current?.setAttribute('src', require('../../assets/images/pdf.png'));
          recaudosRef.current[i]?.current?.style.setProperty('width', '80px');
          recaudosRef.current[i]?.current?.style.setProperty('height', '80px');
        } else {
          recaudosRef.current[i]?.current?.setAttribute('src', require('../../assets/images/file.png'));
          recaudosRef.current[i]?.current?.style.setProperty('width', '80px');
          recaudosRef.current[i]?.current?.style.setProperty('height', '80px');
        }
      };
    }
  };

  const deleteFile = (name: string, i: number) => {
    const _files = Object.assign({}, files);
    delete _files[name];
    setFiles(_files);
    recaudosRef.current[i] = createRef();
  };

  const handleFormFinish = async () => {
    // const hasAllMandatoryTakings = files
    // ? !selectedProcedure?.recaudos.filter((r) => r.obligatorio && !r.fisico).find((r) => !files.hasOwnProperty(r.nombreCorto))
    // : false;
    const fd = new FormData();
      if (files) Object.keys(files).forEach((k) => fd.append('recaudos', files[k]));
    
      try {
      const response = await axios.post(
        `${server}/uploads/book/book/user/${auth?.user?.id}`,
        fd,
        {
          headers: { Authorization: `Bearer ${auth?.token}` },
          // onUploadProgress: (progressEvent) => setProgress(Math.floor(progressEvent.loaded * 100)),
        }
      );
      if (response.status === 200) {
        message.success('Libro guardado exitosamente');
        form.resetFields();
        setFiles(null);
        history.push('/dashboard/bandejas');
      }
    } catch (e) {
      setLoading(false);
      message.error('Error al subir el Libro de Timbres.');
    }
  };

  const downloadTicket = async () => {
      if (!downloading) {
        setDownloading(true);
        const complete = message.loading('Generando Recibo...');
        try {
          const response = await axios.post(`${server}/settlements/receipt/480868/cat/cat`, null, {
            headers: { Authorization: `Bearer ${auth.token}` },
          });
          message.success('Recibo generado de manera exitosa.');
          window.open(response.data.url, '_blank');
        } catch (e) {
          message.error('Error al generar Recibo para la solicitud.');
        } finally {
          setDownloading(false);
          complete();
        }
      }
  };

  return (
    <React.Fragment>
      <Modal
        width='600px'
        bodyStyle={{ height: '45vh', overflowY: 'scroll' }}
        centered
        visible={modalVisible}
        footer={null}
        closable
        title='Actualizar Libro de Timbres'
        maskClosable
        onCancel={() => history.goBack()}
      >
        <Form form={form} layout='vertical' onFinish={handleFormFinish}>
          <Row style={{ paddingLeft: 16 }} gutter={16}>
            <Col span={24}>
              { !files ? ( 
                <Col
                  xs={24}
                  xl={24}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    marginBottom: 20,
                  }}
                >
                  <input
                    hidden
                    ref={ref}
                    type='file'
                    // accept={}
                    onChange={(e) => handleFiles(e, 'libro', 0)}
                  />
                  <Button type='primary' style={{ marginBottom: 10 }} onClick={() => ref.current?.click()}>
                    Subir Archivo
                  </Button>
                  <Typography.Text strong style={{ textAlign: 'center' }}>
                    {'Libro de Timbres'}
                  </Typography.Text>
                </Col>
              ) : (
                <Col xs={24} xl={24}>
                  <Card
                    cover={<img alt='recaudo' ref={recaudosRef.current[0]} />}
                    bodyStyle={{ padding: 10, display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                  >
                    <Typography.Text strong style={{ textAlign: 'center' }}>
                      {'Libro de Timbres'}
                    </Typography.Text>
                    <Button
                      type='link'
                      onClick={() => deleteFile('libro', 0)}
                      style={{ textAlign: 'right', width: '100%', color: 'red' }}
                    >
                      Eliminar
                      <DeleteOutlined />
                    </Button>
                  </Card>
                </Col>
              ) }
                            
            </Col>
          </Row>
          <Row style={{ paddingLeft: 16 }} gutter={16}>
            <Col span={16}>
              <Button
                type='primary'
                style={{ marginBottom: 10 }}
                loading={downloading}
                onClick={() => downloadTicket()}
                >
                PRUEBA CAT
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

const mapStateToProps = (state: State) => ({ thm: state.thm, auth: state.auth });

export default connect(mapStateToProps)(CatTest);