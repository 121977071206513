import React, { useState } from 'react';
import { Select, Card, Form, Row, Divider, Typography, Col, Input, InputNumber, message, Button } from 'antd';
import { useWindowDimensions } from '../../../utils/hooks';
import { State, States, Fine } from 'sigt';
import { connect } from 'react-redux';
import { Utils } from '../../../utils/validators';
import axios from 'axios';
import { setFine } from '../../../redux/actions/procedures';
import { useHistory } from 'react-router';
const server = process.env.REACT_APP_SERVER_URL;

const IMATicketForm: React.FC<IMATicketFormProps> = ({ thm, auth, setFine }) => {
  const [nacionalidad, setNacionalidad] = useState<string>('V');
  const [loading, setLoading] = useState<boolean>(false);

  const { width } = useWindowDimensions();
  const [form] = Form.useForm();
  const history = useHistory();
  
  const cedulaAddon = (
    <Select defaultValue='V' value={nacionalidad} onChange={(e) => setNacionalidad(e)}>
      <Select.Option value='V'>V</Select.Option>
      <Select.Option value='E'>E</Select.Option>
    </Select>
  );

  const handleSubmit = async () => {
    const values = await form.validateFields();
    const tramite = {
      tipoTramite: 19,
      cedula: values.cedula,
      nacionalidad,
      monto: values.utmm,
      datos: values
    };
    try {
      setLoading(true);
      const resp = await axios.post<{ multa: Fine }>(`${server}/fines/init`, { tramite }, { headers: { Authorization: `Bearer ${auth.token}` } });
      setFine(resp.data.multa);
      message.success('Multa asignada exitosamente');
      history.push('/dashboard/bandejas?tipo=multas');
    } catch(e) {
      message.error('Error al crear la multa');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card style={{ height: '100%' }} title='Crear Multa' bodyStyle={{ height: 'calc(100% - 88px)', overflowY: 'scroll', overflowX: 'hidden' }}
      headStyle={{ height: 64, backgroundColor: thm.primaryColor, padding: width < 992 ? '0 10px' : '0 20px', color: 'white'}}>
      <Form scrollToFirstError form={form} layout='vertical' style={{ padding: width < 992 ? '0 10px' : '0px 20px' }} onFinish={() => handleSubmit()}>
        <Row gutter={24}>
          <Divider orientation='left' style={{ marginLeft: -20 }}>
            <Typography.Title level={4} style={{ marginLeft: 5 }}>
              Datos del Infractor
            </Typography.Title>
          </Divider>
          <Col xs={24} xl={8}>
            <Form.Item label='Cedula' name='cedula' rules={[{ required: true, message: 'Debe ingresar el numero de cedula del infractor' }, 
              { validator: Utils.validators.cedula }]} normalize={Utils.normalize.isNumber}>
              <Input placeholder='Cedula' addonBefore={cedulaAddon} />
            </Form.Item>
          </Col>
          <Col xs={24} xl={8}>
            <Form.Item label='Nombre y Apellido' name='nombreCompleto' rules={[{ required: true, message: 'Debe ingresar el nombre del infractor' }]}>
              <Input placeholder='Nombre y Apellido' />
            </Form.Item>
          </Col>
          <Col xs={24} xl={8}>
            <Form.Item label='Teléfono' name='telefono' rules={[{ required: true, message: 'Debe ingresar el telefono del infractor' }, 
              { validator: Utils.validators.phoneNumber }]} normalize={Utils.normalize.isNumber}>
              <Input placeholder='Teléfono' maxLength={10} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label='Dirección' name='direccion' rules={[{ required: true, message: 'Debe ingresar la dirección del infractor' }]}>
              <Input placeholder='Dirección' />
            </Form.Item>
          </Col>
          <Divider orientation='left' style={{ marginLeft: -20 }}>
            <Typography.Title level={4} style={{ marginLeft: 5 }}>
              Motivo y Datos de Pago
            </Typography.Title>
          </Divider>
          <Col xs={24} xl={8}>
            <Form.Item label='Motivo' name='motivo' rules={[{ required: true, message: 'Debe ingresar el motivo de la infracción' }]}>
              <Input placeholder='Motivo' />
            </Form.Item>
          </Col>
          <Col xs={24} xl={6}>
            <Form.Item label='UTMM a Pagar' name='utmm' rules={[{ required: true, message: 'Debe ingresar las UTMM a pagar' }]}>
              <InputNumber placeholder='UTMM a Pagar' min={0} step={0.1} style={{ width: '100%' }} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Button loading={loading} type='primary' onClick={form.submit}>Crear Multa</Button>
    </Card>
  );
};

const mapStateToProps = (state: State) => ({ thm: state.thm, auth: state.auth });

export default connect(mapStateToProps, { setFine })(IMATicketForm);

interface IMATicketFormProps {
  thm: States.ThemeColors
  auth: States.Auth
  setFine: (fine: Fine) => void
}