import React, { useState, useEffect } from 'react';
import { Form, Row, Col, Select, Input, Button, message, Table, Tag } from 'antd';
import { State, States, Taxpayer } from 'sigt';
import { connect } from 'react-redux';
import { useWindowDimensions } from '../../../utils/hooks';
import { SearchOutlined, EyeOutlined } from '@ant-design/icons';
import axios from 'axios';
import { ColumnsType } from 'antd/lib/table';
import { useHistory, useLocation } from 'react-router';
import { Utils } from '../../../utils/validators';
import moment from 'moment';
const server = process.env.REACT_APP_SERVER_URL;

const GetFines: React.FC<GetFinesProps> = ({ auth, thm }) => {
  const [tipoDocumento, setTipoDocumento] = useState('V');
  const [searching, setSearching] = useState(false);
  const [fines, setFines] = useState<any[]>([]);

  const [query, setQuery] = useState('');

  const { width } = useWindowDimensions();
  const [form] = Form.useForm();
  const location = useLocation();
  const history = useHistory();

  const StatusConfig = {
    iniciado: { color: 'default', name: 'Iniciado' },
    validando: { color: 'warning', name: 'Validando pago' },
    ingresardatos: { color: 'cyan', name: 'En espera de pago' },
    finalizado: { color: 'green', name: 'Finalizado' },
    enproceso: { color: 'geekblue', name: 'En proceso' },
    enrevision: { color: 'lime', name: 'En Revision' },
    enrevision_analista: { color: 'lime', name: 'En Revision Gerente' },
    enrevision_gerente: { color: 'lime', name: 'En Revision Gerente General' },
    porrevisar: { color: 'cyan', name: 'Por Revisar' },
    atendido: { color: 'warning', name: 'Atendido' },
    visto: { color: 'default', name: 'Visto' },
    aprobado: { color: 'green', name: 'Aprobado' },
    pagocajero: { color: 'cyan', name: 'Pago por caja' },
    negado: { color: 'error', name: 'Negado' },
    error: { color: 'error', name: 'Error' },
    inspeccion: { color: 'magenta', name: 'Inspección' },
    rechazado: { color: 'error', name: 'Rechazado' },
    denegado: { color: 'black', name: 'Denegado' },
    encorreccion: { color: 'yellow', name: 'En Correccion' },
  };

  useEffect(() => {
    if((history.location.state as any)?.fines) {
      setFines((history.location.state as any)?.fines)
    }
    //eslint-disable-next-line
  }, []);

  const tipoAddon = (
    <Select defaultValue='V' value={tipoDocumento} onChange={(e) => setTipoDocumento(e)}>
      <Select.Option value='V'>V</Select.Option>
      <Select.Option value='E'>E</Select.Option>
      <Select.Option value='J'>J</Select.Option>
      <Select.Option value='G'>G</Select.Option>
      <Select.Option value='P'>P</Select.Option>
      <Select.Option value='C'>C</Select.Option>
    </Select>
  );

  const columns: ColumnsType<any> = [
    {
      title: 'Razón Social',
      dataIndex: 'razon_social'
    },
    {
      title: 'Rif',
      dataIndex: 'documento',
      render: (doc, record) => <span>{record.tipo_documento}-{doc}</span>
    },
    {
      title: 'Nro de Providencia',
      dataIndex: 'datos',
      render: (datos) => <span>{datos.providencia}</span>
    },
    {
      title: 'Fecha',
      dataIndex: 'fecha_liquidacion',
      render: (fecha) => <span>{moment(fecha).format('DD/MM/YYYY')}</span>
    },
    {
      title: 'Periodo',
      dataIndex: 'datos',
      render: (datos) => <span>{moment(datos.rango[0]).format('DD/MM/YYYY')}-{moment(datos.rango[1]).format('DD/MM/YYYY')}</span>
    },
    {
      title: 'Etapa del Proceso',
      dataIndex: 'etapa'
    },
    {
      title: 'Estado',
      dataIndex: 'state',
      render: (state) =>  
      <Tag style={{ width: '100%', textAlign: 'center' }} color={StatusConfig[state].color}>
        {StatusConfig[state].name}
      </Tag>
    },
    // {
    //   title: 'Tipo de Contribuyente',
    //   dataIndex: 'tipoContribuyente'
    // },
    // {
    //   title: 'Consultar',
    //   render: (_, multa) => (
    //     <Button type='primary' onClick={() => action(multa)} icon={<EyeOutlined />}>
    //       Ver Multa
    //     </Button>
    //   )
    // }
  ];

  const action = (multa: any) => {
      history.push(`/dashboard/impuestos/multa/${multa.id_solicitud}`, { multa, multas: fines })
  };

  const search = async () => {
    const values = await form.validateFields();
    const { doc, prov } = values;
    if(doc || prov) {
      try {
        setSearching(true);
        const params = doc && prov ? `doc=${doc}&prov=${prov}` : !prov && doc ? `doc=${doc}` : `prov=${prov}`;
        const response = await axios.get(`${server}/settlements/fines/info?${params}`,
          { headers: { Authorization: `Bearer ${auth.token}` } });
        setFines(response.data.fines);
      } catch(e) {
        message.error(e.response?.data?.message || 'Error al buscar sanciones')
      } finally {
        setSearching(false);
      }
    } else {
      message.warn('Debe ingresar algún parametro para la busqueda de sanciones')
    }
  };

  const fetchAllFines = async () => {
      setSearching(true);
      try {
        const response = await axios.get(`${server}/settlements/fines/info`, { headers: { Authorization: `Bearer ${auth.token}` } });
        setFines(response.data.fines);
      } catch (e) {
        message.error(e.response?.data?.message || 'Error al conseguir las sanciones');
      } finally {
        setSearching(false);
      }
    
  };

  return (
    <Form layout='vertical' form={form}>
      <Row gutter={24}>
        <Col xs={24} xl={7}>
          <Form.Item label='Documento de Identidad' name='doc' 
          normalize={Utils.normalize.isCNumber}
          >
            <Input placeholder='Documento de Identidad' addonBefore={tipoAddon}/>
          </Form.Item>
        </Col>
        <Col xs={24} xl={7}>
          <Form.Item label='Número de Providencia' name='prov'>
            <Input placeholder='Número de Providencia'/>
          </Form.Item>
        </Col>
        <Col xl={6} xs={12}>
          <Button onClick={() => search()} style={{ marginTop: width < 1200 ? 0 : 38, marginBottom: width < 1200 ? 20 : 0 }} loading={searching} icon={<SearchOutlined />} type='primary'>Buscar</Button>
        </Col>
        <Col xl={6} xs={12}>
          <Button onClick={() => fetchAllFines()} style={{ marginTop: width < 1200 ? 0 : 38, marginBottom: width < 1200 ? 20 : 0 }} loading={searching} icon={<SearchOutlined />} type='primary'>Mostrar todos</Button>
        </Col>
        <Col span={24}>
          <Table columns={columns} dataSource={fines.map(t => {
            t.key = t.id;
            return t;
          })} bordered loading={searching} />
        </Col>
      </Row>
    </Form>
  );
};

const mapStateToProps = (state: State) => ({ auth: state.auth, thm: state.thm });

export default connect(mapStateToProps)(GetFines);

interface GetFinesProps {
  auth: States.Auth
  thm: States.ThemeColors
}