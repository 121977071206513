import React from 'react';
import { Select } from 'antd';
import moment from 'moment';

const DateDeclareSelect: React.FC<DateDeclareSelectProps> = ({ onChange, value, placeholder, disabled = false }) => {
  let dates: number[] = [];
  let startDate = 2017;
  while (moment().year() >= startDate){
    dates.push(startDate);
    startDate++;
  }
  return (
    <Select value={ typeof value === 'object' ? value._i : value} onChange={onChange} placeholder={placeholder} disabled={disabled}>
      { dates.map((d) => {
        return <Select.Option value={d}>{d}</Select.Option>
      })}
    </Select>
  );
};

export default DateDeclareSelect;

interface DateDeclareSelectProps {
  onChange?: (e: any) => any
  value?: any
  placeholder?: string
  disabled?: boolean
}