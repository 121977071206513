import axios from 'axios';
const server = process.env.REACT_APP_SERVER_URL;

export const getUser = async (data, token) => {
  try {
    const params = new URLSearchParams(data)
    const response = await axios.get(`${server}/user/search?${params}`,{ headers: { Authorization: `Bearer ${token}` } });
    return response.data;
  } catch (error) {
    throw error;
  }
}
export const editUser = async (data, token, id) => {
  try {
    const body = {usuario: data}
    const response = await axios.put(`${server}/user/${id}`, body,{ headers: { Authorization: `Bearer ${token}` } });
    return response.data;
  } catch (error) {
    throw error;
  }
}
export const unLink = async (token, id) => {
  try {
    const response = await axios.patch(`${server}/user/contributor/${id}`, {},{ headers: { Authorization: `Bearer ${token}` } });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const blockUser = async (token, bloqueado, id) => {
  try {
    const response = await axios.patch(`${server}/user/block/${id}`, {bloqueado},{ headers: { Authorization: `Bearer ${token}` } });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const verifyNumber = async (cellphone, token, id) => {
  try {
    const response = await axios.post(`${server}/user/verify/${id}`, {cellphone},{ headers: { Authorization: `Bearer ${token}` } });
    return response.data;
  } catch (error) {
    throw error;
  }
}