import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Banco, State, States, Vehicle } from 'sigt';
import { Form, Card, Divider, Typography, Row, Col, message, Descriptions, Button, Select, Modal, Spin, Input, InputNumber, DatePicker, Table} from 'antd';
import { CheckOutlined, LoadingOutlined } from '@ant-design/icons';
import axios from 'axios';
import moment from 'moment';
import { setProcedure } from '../../../redux/actions/procedures';

import '@ant-design/compatible/assets/index.css';
import '../../../assets/css/components/FormGenerator.css';
import { useHistory, useParams } from 'react-router';
import { Utils } from '../../../utils/validators';
import { cloneDeep } from 'lodash';
import { useWindowDimensions } from '../../../utils/hooks';
import Paragraph from 'antd/lib/skeleton/Paragraph';

const server = process.env.REACT_APP_SERVER_URL;

const LiqueurForm : React.FC<LiqueurFormProps> = ({thm, auth, vhc, banks, institutions, setProcedure }) =>{
  const [loading, setLoading] = useState(false);
  const [created, setCreated] = useState<boolean>(false);
  const [nacionalidadSolicitante, setNacionalidadSolicitante] = useState('V');
  const [vehicle, setVehicle] = useState<Vehicle>();
  const history = useHistory();
  const [form] = Form.useForm();
  const { width } = useWindowDimensions();
  const { id } = useParams<{ id: any }>();
  
  const selectedInstitution = institutions?.find(i => i.id === 9);

  const formatCurrency = (number: number) => new Intl.NumberFormat('de-DE').format(number);

  useEffect(() => {
    form.setFieldsValue({
      solicitante: { 
        nombreCompleto: auth.user?.nombreCompleto,
        cedula: auth.user?.cedula,
        telefono: '0'+auth.user?.telefono,
        nacionalidad: auth.user?.nacionalidad
      }
    });
  },[auth.user, form])

  useEffect(() => {
    const vehicle = vhc.vehicles.find((v) => v.id === parseInt(id));
    setVehicle(vehicle);
    form.setFieldsValue({
      vehiculo: vehicle
    });
  },[id, vhc.vehicles, form])

  const handleBankChange = banco => {
    form.setFieldsValue({   
      banco
    });
  };

  const columns: any = [
    {
      title: 'Banco',
      dataIndex: 'banco',
      key: 'banco',
      fixed: 'left'
    },
    {
      title: 'Numero de Cuenta',
      dataIndex: 'numeroCuenta',
      key: 'numeroCuenta'
    },
    {
      title: 'Titular',
      dataIndex: 'nombreTitular',
      key: 'nombreTitular'
    },
    {
      title: 'Documento de Identidad',
      dataIndex: 'documento',
      key: 'documento',
    }
  ];

  const disabledDate = current => current > moment().endOf('day');

  const handleSubmit = async () => {
    const values = await form.validateFields();
    const pago = values.pago;
    if(moment.isMoment(pago.fecha)){
      pago.fecha = pago.fecha?.toISOString();
    }
    const subcategory = vhc.subCategories.find((sc) => sc.id === vehicle?.subcategoria);
    const subcategoryType = subcategory?.tipo;
    pago.costo = +(subcategory?.costo || '0');
    delete values.pago;
    const tramite = {
      datos: {...values, vehiculo: vehicle},
      pago,
      tipoTramite: subcategoryType === 2 ? 39 : 40 
    };

    setLoading(true);
    try {
      const resp = await axios.post(`${server}/procedures/init`, { tramite }, { headers: { Authorization: `Bearer ${auth?.token}` }});
      message.success('Pago de impuesto vehícular procesado exitosamente!');
      setProcedure(resp.data.tramite);
      setCreated(true);
      form.resetFields();
      history.push('/dashboard/bandejas');
    } catch(e) {
      message.error( e.response && e.response?.data?.message ? e.response?.data?.message : 'Error en el servidor');
    } finally { setLoading(false) }
  }
  
  const cedulaSolicitanteAddon = (<Form.Item noStyle name={['solicitante', 'nacionalidad']}>
      <Select open={false} defaultValue='V' value={nacionalidadSolicitante} onChange={(e) => setNacionalidadSolicitante(e)}>
        <Select.Option value='V'>V</Select.Option>
        <Select.Option value='E'>E</Select.Option>
      </Select>
    </Form.Item>
  );

  return <Card style={{ height: '100%' }} title={'Pago de impuesto vehícular'} bodyStyle={{ height: 'calc(100% - 88px)', overflowY: 'scroll', overflowX: 'hidden' }}
  headStyle={{ height: 64, backgroundColor: thm.primaryColor, padding: width < 992 ? '0 10px' : '0 20px', color: 'white'}}>
    <Form scrollToFirstError onFinish={handleSubmit} form={form} layout='vertical' style={{ padding: width < 992 ? '0 10px' : '0px 20px' }} onValuesChange={(c, v) => { form.setFieldsValue(v);}}>
      <Divider orientation='left' style={{ marginLeft: -30 }}>
        <Typography.Title level={4}>Datos del Contribuyente</Typography.Title>
      </Divider>
      <Row gutter={24}>
        <Col xs={24} xl={7}>
          <Form.Item label='Nombre y Apellido' name={['solicitante','nombreCompleto']} rules={[{ required: true, message: 'Debe ingresar el nombre del solicitante' }]}>
            <Input readOnly={true} placeholder='Nombre y Apellido' />
          </Form.Item>
        </Col>
        <Col xs={24} xl={7}>
          <Form.Item label='Cedula' name={['solicitante','cedula']} rules={[{ required: true, message: 'Debe ingresar el numero de cedula del solicitante' }, 
            { validator: Utils.validators.cedula }]} normalize={Utils.normalize.isNumber}>
            <Input readOnly={true} placeholder='Cedula' addonBefore={cedulaSolicitanteAddon} />
          </Form.Item>
        </Col>
        <Col xs={24} xl={7}>
          <Form.Item label='Telefono' name={['solicitante','telefono']} rules={[{ required: true, message: 'Debe ingresar el telefono del solicitante' }]}>
            <Input readOnly={true} placeholder='Telefono' maxLength={10}/>
          </Form.Item>
        </Col>
      </Row>
      <Divider orientation='left' style={{ marginLeft: -30 }}>
        <Typography.Title level={4}>Datos del Vehículo</Typography.Title>
      </Divider>
      <Row gutter={24}>
        <Col xl={8} xs={24}>
          <Form.Item label='Placa' name={['vehiculo', 'placa']}  normalize={Utils.normalize.toUpper} rules={[{ required: true, message: 'Debe ingresar el Placa' }]}>
            <Input readOnly={true} placeholder='Placa' />
          </Form.Item>
        </Col>
        <Col xl={8} xs={24}>
          <Form.Item label='Marca' name={['vehiculo', 'marca']} rules={[{ required: true, message: 'Debe seleccionar marca del vehículo.' }]}>
            <Input readOnly={true} placeholder='Marca' />
          </Form.Item>
        </Col>
        <Col xl={8} xs={24}>
          <Form.Item label='Modelo' name={['vehiculo', 'modelo']} rules={[{ required: true, message: 'Debe seleccionar modelo del vehículo.' }]}>
            <Input readOnly={true} placeholder='Modelo'/>
          </Form.Item>
        </Col>
        <Col xl={8} xs={24}>
          <Form.Item label='Año' name={['vehiculo', 'anio']} rules={[{ required: true, message: 'Debe ingresar año del vehículo.' }]}>
            <InputNumber readOnly={true} style={{ width: '100%' }} max={9999} min={1800} placeholder='Año'/>
          </Form.Item>
        </Col>
        <Col xl={8} xs={24}>
          <Form.Item label='Color' name={['vehiculo', 'color']} rules={[{ required: true, message: 'Debe ingresar color del vehículo.' }]}>
            <Input readOnly={true} placeholder='Color'/>
          </Form.Item>
        </Col>
        <Col xl={8} xs={24}>
          <Form.Item label='Tipo de Carroceria' name={['vehiculo', 'tipoCarroceria']} rules={[{ required: true, message: 'Debe ingresar el tipo del combustible' }]}>
            <Input readOnly={true} placeholder='Tipo de Carroceria' />
          </Form.Item>
        </Col>
        <Col xl={8} xs={24}>
          <Form.Item label='Clasificación' name={['vehiculo', 'subcategoria']} rules={[{ required: true, message: 'Debe seleccionar el sub-categoria de vehículo' }]}>
            <Select disabled={true} placeholder='Clasificación'>
              {vhc.subCategories.map((sc) => <Select.Option key={sc.id} value={sc.id}>{sc.descripcion}</Select.Option>)}
            </Select>
          </Form.Item>
        </Col>
        <Col xl={8} xs={24}>
          <Form.Item label='Serial Carroceria' name={['vehiculo', 'serialCarroceria']} rules={[{ required: true, message: 'Debe ingresar el serial de la carroceria' }]}>
            <Input readOnly={true} placeholder='Serial Carroceria' />
          </Form.Item>
        </Col>
        <Col xl={8} xs={24}>
          <Form.Item label='Tipo de Combustible' name={['vehiculo', 'tipoCombustible']} rules={[{ required: true, message: 'Debe ingresar el tipo del combustible' }]}>
            <Input readOnly={true} placeholder='Tipo de Combustible' />  
          </Form.Item>
        </Col>
      </Row>
      <Divider orientation='left' style={{ marginLeft: 0 }}>
          <Typography.Title level={4}>Total a Pagar</Typography.Title>
      </Divider>
      <Row gutter={[20,20]}>
        <Col span={24}>
          <Descriptions bordered>
            <Descriptions.Item label='Total'>Bs. {formatCurrency(+(+(vhc.subCategories.find(sc => sc.id === vehicle?.subcategoria)?.costo || '0')).toFixed(2))}</Descriptions.Item>
          </Descriptions>
        </Col>
      </Row>
      <Typography.Text strong>El monto de pago del impuesto vehicular tiene un total de <Typography.Text strong type='danger'>Bs. {formatCurrency(+(vhc.subCategories.find(sc => sc.id === vehicle?.subcategoria)?.costo || '0') )}. </Typography.Text>
        El monto de la transferencia debe coincidir exactamente con el costo del impuesto vehicular y debe ser realizada desde el mismo banco.
      </Typography.Text>
      <Table bordered style={{ margin: '20px 0px' }} pagination={false} columns={columns} dataSource={selectedInstitution?.cuentasBancarias?.map((c) => 
        ({ ...c, documento: `${(c.rif) ? 'RIF: ' : 'Cedula: '}${(c.rif || c.cedula)}`, banco: banks?.find(b => b.id === c.banco)?.nombre, key: c.id })
      )} />
      <Row gutter={24}>
        <Col xs={24} xl={8}>
          <Form.Item label='Banco' name={['pago','banco']} rules={[{ required: true, message: 'Debe indicar el banco' }]}>
            {banks ? <Select onChange={handleBankChange} placeholder='Banco'>
              {banks?.filter((b) => selectedInstitution?.cuentasBancarias?.map((c) => c.banco).includes(b.id)).map((b, i) =>
                <Select.Option key={`b-${b.id}`} value={b.id}>{b.nombre}</Select.Option>
              )}
            </Select> : <div></div>}
          </Form.Item>
        </Col>
        <Col xs={24} xl={8}>
          <Form.Item label='Numero de Referencia' name={['pago','referencia']} rules={[{ required: true, message: 'Por favor ingrese el numero de referencia.' }]}>
            <Input placeholder='Referencia' maxLength={20} />
          </Form.Item>
        </Col>
        <Col xs={24} xl={8}>
          <Form.Item label='Fecha de Pago' name={['pago','fecha']} rules={[{ required: true, message: 'Por favor ingrese la fecha de pago.' }]}>
            <DatePicker style={{ width: '100%' }} format={"DD-MM-YYYY"} disabledDate={disabledDate} />
          </Form.Item>
        </Col>
      </Row>
      <Button loading={loading} type='primary' htmlType='submit' icon={<CheckOutlined/>}>Procesar pago</Button>
    </Form>
    <Modal footer={null} centered title={!created ? 'Procesando pago...' : 'Pago procesado'} visible={loading} onOk={() => { setLoading(false) }} >
      <Row justify='center' align='middle'><Spin tip='Procesando pago...' indicator={<LoadingOutlined style={{ fontSize: 40, color: thm.primaryColor }} />} /></Row>
      {created && <Typography.Text strong>Pago procesado exitosamente!</Typography.Text>}
    </Modal>
  </Card>;
}


const mapStateToProps = (state: State) => ({ thm: state.thm, auth: state.auth, vhc: state.vhc, banks: state.bk.banks, institutions: state.inst.institutions });

export default connect(mapStateToProps, { setProcedure })(LiqueurForm);

interface LiqueurFormProps {
  auth: States.Auth
  thm: States.ThemeColors
  vhc: States.Vehicles
  banks: Banco[] | null
  setProcedure: Function
  institutions: States.Institutions['institutions']
}
