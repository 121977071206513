import React, { useState, useRef, useEffect } from 'react';
import { Card, Form, Row, Typography, Divider, Col, Input, Select, DatePicker, InputNumber, Button, message } from 'antd';
import { useWindowDimensions } from '../../../utils/hooks';
import { States, State, Fine } from 'sigt';
import { connect } from 'react-redux';
import { Utils } from '../../../utils/validators';
import { DeleteOutlined } from '@ant-design/icons';
import { setFine } from '../../../redux/actions/procedures';
import axios from 'axios';
import { useHistory } from 'react-router';
const server = process.env.REACT_APP_SERVER_URL;

const PoliceTicketForm: React.FC<PoliceTicketFormProps> = ({ thm, parish, setFine, auth }) => {
  const [nacionalidad, setNacionalidad] = useState<string>('V');
  const [file, setFile] = useState<File | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const inputRef = useRef<HTMLInputElement>(null);
  const boletaRef = useRef<HTMLImageElement>(null);

  const { width } = useWindowDimensions();
  const [form] = Form.useForm();
  const history = useHistory();

  const cedulaAddon = (
    <Select defaultValue='V' value={nacionalidad} onChange={(e) => setNacionalidad(e)}>
      <Select.Option value='V'>V</Select.Option>
      <Select.Option value='E'>E</Select.Option>
    </Select>
  );

  useEffect(() => {
    form.setFieldsValue({
      cedulaFuncionario: `${auth.user?.nacionalidad}-${auth.user?.cedula}`,
      nombreCompletoFuncionario: auth.user?.nombreCompleto,
    });
  }, [auth.user, form]);

  const handleFiles = (e) => {
    const file = e.target.files[0];
    if (file) {
      const fr = new FileReader();
      const blob = (file as File).slice(0, (file as File).size, 'image/png');
      setFile(new File([blob], 'BOLETA.png', { type: 'image/png' }));
      fr.readAsDataURL(file);
      fr.onload = (e) => {
        boletaRef.current?.setAttribute('src', e.target?.result?.toString() || '');
      };
    }
  };

  const handleSubmit = async () => {
    if (file) {
      const values = await form.validateFields();
      values.nacionalidad = nacionalidad;
      const tramite = {
        tipoTramite: 20,
        cedula: values.cedula,
        nacionalidad,
        monto: values.utmm,
        datos: values,
      };
      try {
        setLoading(true);
        const resp = await axios.post<{ multa: Fine }>(
          `${server}/fines/init`,
          { tramite },
          { headers: { Authorization: `Bearer ${auth.token}` } }
        );
        const fd = new FormData();
        fd.append('boleta', file);
        await axios.post(`${server}/uploads/finings/${resp.data.multa.codigoMulta}`, fd, {
          headers: { Authorization: `Bearer ${auth.token}` },
        });
        message.success('Boleta subida de manera exitosa');
        setFine(resp.data.multa);
        message.success('Multa asignada exitosamente');
        if (auth.user?.tipoUsuario === 4) history.push('/dashboard/bandejas?tipo=multas');
        else form.resetFields();
      } catch (e) {
        message.error('Error al crear la multa');
      } finally {
        setLoading(false);
      }
    } else {
      message.error('Debe subir la boleta digitalizada');
    }
  };

  return (
    <Card
      style={{ height: '100%' }}
      title='Crear Multa'
      bodyStyle={{ height: 'calc(100% - 88px)', overflowY: 'scroll', overflowX: 'hidden' }}
      headStyle={{ height: 64, backgroundColor: thm.primaryColor, padding: width < 992 ? '0 10px' : '0 20px', color: 'white' }}
    >
      <Form
        scrollToFirstError
        form={form}
        layout='vertical'
        style={{ padding: width < 992 ? '0 10px' : '0px 20px' }}
        onFinish={() => handleSubmit()}
      >
        <Row style={{ marginBottom: 24 }} gutter={24}>
          <Divider orientation='left' style={{ marginLeft: -20 }}>
            <Typography.Title level={4} style={{ marginLeft: 5 }}>
              Datos del Funcionario
            </Typography.Title>
          </Divider>
          <Col xs={24} xl={8}>
            <Form.Item
              label='Cedula'
              name='cedulaFuncionario'
              rules={[
                { required: true, message: 'Debe ingresar el numero de cedula del infractor' },
                { validator: Utils.validators.cedula },
              ]}
              normalize={Utils.normalize.isNumber}
            >
              <Input readOnly={true} placeholder='Cedula' />
            </Form.Item>
          </Col>
          <Col xs={24} xl={8}>
            <Form.Item
              label='Nombre y Apellido'
              name='nombreCompletoFuncionario'
              rules={[{ required: true, message: 'Debe ingresar el nombre del infractor' }]}
            >
              <Input readOnly={true} placeholder='Nombre y Apellido' />
            </Form.Item>
          </Col>
          <Divider orientation='left' style={{ marginLeft: -20 }}>
            <Typography.Title level={4} style={{ marginLeft: 5 }}>
              Datos del Infractor
            </Typography.Title>
          </Divider>
          <Col xs={24} xl={8}>
            <Form.Item
              label='Cedula'
              name='cedula'
              rules={[
                { required: true, message: 'Debe ingresar el numero de cedula del infractor' },
                { validator: Utils.validators.cedula },
              ]}
              normalize={Utils.normalize.isNumber}
            >
              <Input placeholder='Cedula' addonBefore={cedulaAddon} />
            </Form.Item>
          </Col>
          <Col xs={24} xl={8}>
            <Form.Item
              label='Nombre y Apellido'
              name='nombreCompleto'
              rules={[{ required: true, message: 'Debe ingresar el nombre del infractor' }]}
            >
              <Input placeholder='Nombre y Apellido' />
            </Form.Item>
          </Col>
          <Col xs={24} xl={8}>
            <Form.Item
              label='Teléfono'
              name='telefono'
              rules={[
                { required: true, message: 'Debe ingresar el telefono del infractor' },
                { validator: Utils.validators.phoneNumber },
              ]}
              normalize={Utils.normalize.isNumber}
            >
              <Input placeholder='Teléfono' maxLength={10} />
            </Form.Item>
          </Col>
          <Col xs={24} xl={12}>
            <Form.Item
              label='Dirección'
              name='direccion'
              rules={[{ required: true, message: 'Debe ingresar la dirección del infractor' }]}
            >
              <Input placeholder='Dirección' />
            </Form.Item>
          </Col>
          <Divider orientation='left' style={{ marginLeft: -20 }}>
            <Typography.Title level={4} style={{ marginLeft: 5 }}>
              Datos de la Infracción
            </Typography.Title>
          </Divider>
          <Col xs={24} xl={6}>
            <Form.Item
              label='Motivo'
              name='motivo'
              rules={[{ required: true, message: 'Debe ingresar el motivo de la infracción' }]}
            >
              <Select placeholder='Motivo'>
                <Select.Option value='Acccidente'>Accidente de Transito</Select.Option>
                <Select.Option value='Infracción'>Infracción</Select.Option>
                <Select.Option value='Citación'>Citación</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} xl={6}>
            <Form.Item
              label='Fecha y Hora'
              name='fechaHora'
              rules={[{ required: true, message: 'Debe ingresar la fecha y hora de la infracción' }]}
            >
              <DatePicker style={{ width: '100%' }} showTime showHour showMinute showSecond={false} format='DD/MM/YYYY hh:mm a' />
            </Form.Item>
          </Col>
          <Col xs={24} xl={12}>
            <Form.Item
              label='Lugar'
              name='lugar'
              rules={[{ required: true, message: 'Debe ingresar el lugar de la infracción' }]}
            >
              <Input placeholder='Lugar' />
            </Form.Item>
          </Col>
          <Col xs={24} xl={6}>
            <Form.Item
              label='Parroquia'
              name='parroquia'
              rules={[{ required: true, message: 'Debe ingresar la Parroquia en donde ocurrió la infracción' }]}
            >
              <Select placeholder='Parroquia'>
                {parish.map((p) => (
                  <Select.Option value={p.nombre} key={p.id}>
                    {p.nombre}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} xl={18}>
            <Form.Item label='Detalles de Infracción' name='detalle'>
              <Input placeholder='Detalles de Infracción' />
            </Form.Item>
          </Col>
          <Divider orientation='left' style={{ marginLeft: -20 }}>
            <Typography.Title level={4} style={{ marginLeft: 5 }}>
              Datos del Vehículo
            </Typography.Title>
          </Divider>
          <Col xs={24} xl={6}>
            <Form.Item label='Placa' name='placa' rules={[{ required: true, message: 'Debe ingresar la placa del vehículo' }]}>
              <Input placeholder='Placa' maxLength={7} />
            </Form.Item>
          </Col>
          <Col xs={24} xl={6}>
            <Form.Item label='Marca' name='marca' rules={[{ required: true, message: 'Debe ingresar la marca del vehículo' }]}>
              <Input placeholder='Marca' />
            </Form.Item>
          </Col>
          <Col xs={24} xl={6}>
            <Form.Item
              label='Modelo'
              name='modelo'
              rules={[{ required: true, message: 'Debe ingresar del modelo del vehículo' }]}
            >
              <Input placeholder='Modelo' />
            </Form.Item>
          </Col>
          <Col xs={24} xl={6}>
            <Form.Item label='Color' name='color' rules={[{ required: true, message: 'Debe ingresar el color del vehículo' }]}>
              <Input placeholder='Color' />
            </Form.Item>
          </Col>
          <Col xs={24} xl={6}>
            <Form.Item
              label='Año'
              name='ano'
              rules={[{ required: true, message: 'Debe ingresar el año del vehículo' }]}
              normalize={Utils.normalize.isNumber}
            >
              <Input placeholder='Año' maxLength={4} />
            </Form.Item>
          </Col>
          <Divider orientation='left' style={{ marginLeft: -20 }}>
            <Typography.Title level={4} style={{ marginLeft: 5 }}>
              Datos de Pago
            </Typography.Title>
          </Divider>
          <Col xs={24} xl={6}>
            <Form.Item label='Monto a Pagar' name='utmm' rules={[{ required: true, message: 'Debe ingresar las Monto a pagar' }]}>
              <InputNumber placeholder='Monto a Pagar' min={0} step={0.1} style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          <Divider orientation='left' style={{ marginLeft: -20 }}>
            <Typography.Title level={4} style={{ marginLeft: 5 }}>
              Boleta
            </Typography.Title>
          </Divider>
          {!file ? (
            <Col xs={24} xl={4} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
              <input hidden ref={inputRef} type='file' accept='image/*' onChange={(e) => handleFiles(e)} />
              <Button type='primary' style={{ marginBottom: 10 }} onClick={() => inputRef.current?.click()}>
                Subir Archivo
              </Button>
              <Typography.Text strong style={{ textAlign: 'center' }}>
                Boleta Digitalizada&nbsp;<span style={{ color: 'red' }}>*</span>
              </Typography.Text>
            </Col>
          ) : (
            <Col xs={24} xl={6}>
              <Card
                cover={<img alt='recaudo' ref={boletaRef} />}
                bodyStyle={{ padding: 10, display: 'flex', flexDirection: 'column', alignItems: 'center' }}
              >
                <Typography.Text strong style={{ textAlign: 'center' }}>
                  Boleta Digitalizada
                </Typography.Text>
                <Button type='link' onClick={() => setFile(null)} style={{ textAlign: 'right', width: '100%', color: 'red' }}>
                  Eliminar
                  <DeleteOutlined />
                </Button>
              </Card>
            </Col>
          )}
        </Row>
      </Form>
      <Button loading={loading} type='primary' onClick={form.submit}>
        Generar Multa
      </Button>
    </Card>
  );
};

const mapStateToProps = (state: State) => ({ thm: state.thm, parish: state.prsh.parish, auth: state.auth });

export default connect(mapStateToProps, { setFine })(PoliceTicketForm);

interface PoliceTicketFormProps {
  thm: States.ThemeColors;
  parish: States.Parish['parish'];
  setFine: (fine: Fine) => void;
  auth: States.Auth;
}
