import React from 'react';
import { Select } from 'antd';

const SolvencyEstateType: React.FC<SolvencyEstateTypeProps> = ({ value, onChange, placeholder }) => {
  return (
    <Select value={value} onChange={onChange} placeholder={placeholder}>
      <Select.Option value='terreno'>Terreno</Select.Option>
      <Select.Option value='terrenoConst'>Terreno con Construcción</Select.Option>
      <Select.Option value='construccion'>Construcción</Select.Option>
    </Select>
  );
};

export default SolvencyEstateType;

interface SolvencyEstateTypeProps {
  value: any
  onChange: (e: any) => any
  placeholder: string
};