import { RouteI, Institucion, User } from 'sigt';
import TableOfficials from './components/Tables/TableOfficial';
import TableCosts from './components/Tables/TableCost';
import ValidatePayments from './components/ValidatePayments';
import TableProcedure from './components/Tables/TableProcedure';
import Icon, {
  TeamOutlined,
  DollarOutlined,
  InboxOutlined,
  FolderOpenFilled,
  AlertOutlined,
  CreditCardFilled,
  FileAddOutlined,
  UserAddOutlined,
  FolderAddOutlined,
  BarChartOutlined,
  ReconciliationOutlined,
  ScheduleOutlined,
  UserOutlined,
  HomeOutlined,
  FireOutlined,
  PercentageOutlined,
  ImportOutlined,
  SwapOutlined,
  FileOutlined,
  ExceptionOutlined,
  BankOutlined,
  AppstoreAddOutlined,
  AccountBookOutlined,
  FolderViewOutlined,
  ContainerOutlined,
  CarOutlined,
  HomeFilled,
  CreditCardOutlined,
  FileSearchOutlined,
  AuditOutlined,
  LayoutFilled,
  CopyOutlined,
  UsergroupAddOutlined,
} from '@ant-design/icons';
import AffairsForm from './components/Forms/AffairsForm';
import TableOrdinance from './components/Tables/TableOrdinance';
import TableFiscalValue from './components/Tables/TableFiscalValues';
import TableDestination from './components/Tables/TableDestination';
import OfficialPFForm from './components/Forms/PassangerFee/OfficialPFForm';
import PoliceTicketForm from './components/Forms/Ticket/PoliceTicketForm';
import IMATicketForm from './components/Forms/Ticket/IMATicketForm';
import TableBankCalendar from './components/Tables/TableBankCalendar';
import DeclareAnyTaxes from './components/Taxes/DeclareAnyTaxes';
import PendingRequests from './components/Taxes/PendingRequests';
import BenefitTribTaxes from './components/Taxes/BenefitTrib';
import AgreementTaxPayer from './components/Taxes/BenefitTrib/AgreementTaxPayer';
import InternalRegister from './components/Taxes/Internal/InternalRegister';
import InternalLicense from './components/Taxes/Internal/InternalLicense';
import TaxPayerUrbanEstates from './components/UrbanEstates/TaxPayerUrbanEstates';
import RegisterProperty from './components/UrbanEstates/RegisterProperty';
import ConsultTaxpayer from './components/Taxpayer/ConsultTaxpayer';
import InternalLinking from './components/Taxes/Internal/InternalLinking';
import InternalImport from './components/Taxes/Internal/InternalImport';
import TaxpayerServices from './components/MunicipalServices/TaxpayerServices';
import ActivityChange from './components/Taxes/ActivityChange';
import Fixes from './components/Taxes/Fixes/Fixes';
import Retentions from './components/Taxes/Retentions';
import Agents from './components/Taxes/Agents';
import TableReceipt from './components/Tables/TableReceipt';
import SpecialSettlements from './components/Taxes/Internal/SpecialSettlements';
import AmendSettlements from './components/Taxes/Amendment/AmendSettlements';
import AmendPayments from './components/Taxes/Amendment/AmendPayments';
import TableEditUser from './components/Tables/TableEditUser';
import SMSettlements from './components/Taxes/SMSettlements';
import TableSupport from './components/Tables/TableSupport';
import InternalPayments from './components/Taxes/Internal/InternalPayments';
import SubstituteStatement from './components/Taxes/SubstituteStatement';
import SupplementaryStatement from './components/Taxes/SupplementaryStatement';
import TableRetentions from './components/Tables/TableRetentions';
import TableCharge from './components/Tables/TableCharge';
import TableInspection from './components/Tables/TableInspection';
import Wallets from './components/Charges/Wallets';
import Scale from './components/Scale';
import CertificadeForm from './components/Forms/CertificadeForm';
import CertificadeView from './components/Forms/CertificadeView';
import RegisterCodominium from './components/Condominiums/RegisterCodominium';
import TableCPUPayments from './components/Tables/TableCPUPayments';
import ConsultPenalty from './components/ConsultPenalty';
import LiqueurForm from './components/Forms/LiqueurForm';
import ConsultProperty from './components/UrbanEstates/ConsultProperty';
import Receipt from './components/Receipts/Receipt';
import TableBudget from './components/Tables/TableBudget';
import TableNASettlements from './components/Tables/TableNASettlements';
import TableMovements from './components/Tables/TableMovements';
import InternalBranchRegister from './components/Taxes/Internal/InternalBranchRegister';
import BookUpload from './components/Taxpayer/BookUpload';
import ConsultAgent from './components/Taxpayer/ConsultAgent';
import AmendCredit from './components/Taxes/Amendment/AmendCredit';
import MineralSettlements from './components/Taxes/Internal/MineralSettlements';
import RepairSettlement from './components/Taxes/Internal/RepairSettlement';
import ConsultRepairs from './components/Taxpayer/ConsultRepairs';
import FineSettlement from './components/Taxes/Internal/FineSettlement';
import ConsultFines from './components/Taxpayer/ConsultFines';
import cattest from './components/Taxpayer/cattest';
import TollSettlements from './components/Taxes/Internal/TollSettlements';
import ConsultToll from './components/Taxpayer/ConsultToll';
import FiremenFineSettlement from './components/Taxes/Internal/FiremenFineSettlement';
import ConsultFinesFiremen from './components/Taxpayer/ConsultFinesFiremen';
import BookDownload from './components/Taxpayer/BookDownload';
import SkipedFineSettlement from './components/Taxes/Internal/SkipedFineSettlement';
import ConsultTributeFines from './components/Taxpayer/ConsultTributeFines';
import AmendDeclarationsZero from './components/Taxes/Amendment/AmendDeclarationsZero';
import AmendDeclarations from './components/Taxes/Amendment/AmendDeclarations';
import VoucherDownload from './components/Taxpayer/VoucherDownload';

enum UserType {
  SuperUser = 1,
  OfficialAdmin,
  Official,
  External,
  OfficialChief,
  GeneralChief,
}

export enum CargoCpu {
  Administrador = 5,
  Funcionario,
  DirectorOMPU = 17,
  DirectorOMCAT,
  DirectorOMTU,
  AnalistaValuacionFiscal = 46,
  JefeValuacionFiscal,
  AnalistaCodigoCatastral = 93,
  JefeCodigoCatastral,
  AnalistaNomenclatura,
  JefeNomenclatura,
}

enum CargoSedemat {
  Cajero = 22,
  AnalistaPoliticasTributarias,
  Administrador,
  JefeDeCaja,
  GerenteCobranzas,
  GerentePoliticasTributarias,
  JefeServiciosMunicipales,
  JefeInmueblesUrbanos,
  JefeActividadesEconomicas,
  JefeAuditorias,
  JefeContribuyentesEspeciales,
  AnalistaCobranzas,
  Validador,
  GerenteGeneral,
  JefeDeCuadra,
  GerenteAdministracion,
  Soporte,
  JefeFiscalizacion,
  CajeroIntegral,
  CajeroE,
  GerenteRecaudacion,
  AnalistaLicores = 44,
  GerenteLicores,
  Auditor = 48,
  JefeDeTransacciones,
  AnalistaCondominios = 53,
  JefeDeZona,
  DirectorUrbanismo = 55,
  GerenteGeneralA,
  JefeContaduria = 89,
  Master,
  ConsultorLiquidaciones,
  Fiscal = 97,
  MasterAdmin,
  Certificador,
  DosPorCien,
  JefeTimbre,
  JefeMina,
  ConsultorJuridico,
  JefeLicor,
  Intendente,
  FunSoporte,
  JefeIntimaciones
}

const canSupport = [
  CargoSedemat.Administrador,
  CargoSedemat.Master,
  CargoSedemat.MasterAdmin,
  CargoSedemat.GerenteGeneralA,
  CargoSedemat.Soporte,
  CargoSedemat.GerenteCobranzas,
  CargoSedemat.GerentePoliticasTributarias,
  CargoSedemat.GerenteRecaudacion,
  CargoSedemat.CajeroE,
  CargoSedemat.FunSoporte,
];

export const getRoutes = (user: User, institutions: Institucion[]): RouteI[] => {
  const routes: RouteI[] = [];
  const moneySubmenu: any[] = [
    {
      path: 'finanzas/validacionDePagos',
      exact: true,
      title: 'Validación de Pagos',
      component: ValidatePayments,
    },
  ];

  if ((user.tipoUsuario === UserType.OfficialAdmin && user.institucion?.id !== 0 && user.institucion?.cargo?.id !== 98) || user.tipoUsuario === UserType.SuperUser) {
    if (UserType.OfficialAdmin === user.tipoUsuario) {
      // if (user.institucion.nombreCorto !== 'SEDETEMA') {
      //   moneySubmenu.push({
      //     path: 'finanzas/costosDeTramites',
      //     exact: true,
      //     title: 'Costos de Tramites',
      //     component: TableCosts,
      //   });
      // }

      if (user.institucion.nombreCorto === 'SEDEMAT') {
        moneySubmenu.push({
          path: 'fechaBancaria',
          exact: false,
          title: 'Fechas Bancarias',
          component: TableBankCalendar,
        });
        // moneySubmenu.push({
        //   path: 'finanzas/alicuotas',
        //   exact: true,
        //   title: 'Administrar Alicuotas',
        //   component: TableActivities,
        // });
      }

      if (user.institucion.nombreCorto === 'CPU') {
        moneySubmenu.push({
          path: 'finanzas/valoresFiscales',
          exact: true,
          title: 'Valores Fiscales',
          component: TableFiscalValue,
        });
      }
    }

    if ((user.tipoUsuario === UserType.OfficialAdmin && user.institucion.id === 9) || user.institucion?.cargo?.id === 35)
      moneySubmenu.push({
        path: 'finanzas/baremo',
        exact: false,
        title: 'Actualizar Tasas',
        component: Scale,
      });

    routes.push({
      path: 'finanzas',
      exact: true,
      icon: DollarOutlined,
      title: 'Finanzas',
      subMenu: moneySubmenu,
    });

    // if (UserType.OfficialAdmin === user.tipoUsuario && user.institucion.nombreCorto !== 'SEDETEMA')
    //   moneySubmenu.push({
    //     path: 'ordenanzas',
    //     exact: false,
    //     title: 'Ordenanzas',
    //     component: TableOrdinance,
    //   });

    if (UserType.OfficialAdmin === user.tipoUsuario && user.institucion.nombreCorto === 'SEDETEMA')
      moneySubmenu.push({
        path: 'destinos',
        exact: false,
        title: 'Tasas de Destinos',
        component: TableDestination,
      });
  }

  if (user.institucion?.nombreCorto === 'PMM') {
    routes.push({
      path: 'consultarMultas',
      exact: true,
      title: 'Consultar Impuesto de Vehiculos y Multas',
      icon: FileSearchOutlined,
      component: ConsultPenalty,
    });
  }

  //MENU DE RUTAS SEDEMAT
  if (user.institucion?.nombreCorto === 'SEDEMAT') {
    const havePrcd = [
      CargoSedemat.GerentePoliticasTributarias,
      CargoSedemat.GerenteGeneral,
      CargoSedemat.AnalistaPoliticasTributarias,
      CargoSedemat.AnalistaLicores,
      CargoSedemat.GerenteLicores,
      CargoSedemat.FunSoporte
    ];

    const subMenuCorrecciones: RouteI[] = [];

    const correccionPagos = {
      path: 'correcciones/pagos',
      exact: true,
      title: 'Certificación de Pagos',
      component: AmendPayments,
    };

    const correccionDeclaracionesCero = {
      path: 'correcciones/declaraciones/cero',
      exact: true,
      title: 'Certificación de Declaraciones en 0',
      component: AmendDeclarationsZero,
    };

    const correccionDeclaraciones = {
      path: 'correcciones/declaraciones',
      exact: true,
      title: 'Certificación de Declaraciones',
      component: AmendDeclarations,
    };

    const correccionCompensaciones = {
      path: 'compensaciones',
      exact: true,
      title: 'Certificación de Compensaciones',
      component: AmendCredit,
    };
    const menuSoporte = {
      path: 'soporte/inbox',
      exact: true,
      icon: ExceptionOutlined,
      title: 'Bandeja de Soporte',
      component: TableSupport,
    };
    const correccionUsuario = {
      path: 'correcciones/editar/usuario',
      exact: true,
      title: 'Editar Usuario de S.U.T.',
      component: TableEditUser,
    };
    const correcionContribuyente = {
      path: 'correcciones/editar/contribuyente',
      exact: true,
      title: 'Editar Contribuyente',
      component: ConsultTaxpayer,
    };
    const correcionAgente = {
      path: 'correcciones/editar/agente',
      exact: true,
      title: 'Editar Agente',
      component: ConsultAgent,
    };
    const correccionLiquidaciones = {
      path: ['correcciones/liquidaciones', 'correcciones/liquidaciones/:id'],
      exact: true,
      title: 'Corrección de Liquidaciones',
      component: AmendSettlements,
    };

    const correcionesAdministrativas = {
      path: 'correcciones',
      exact: true,
      icon: SwapOutlined,
      title: 'Correcciones Administrativas',
      subMenu: subMenuCorrecciones,
    };

    const asignarCarteras = {
      path: 'cobranzasFiscalizacion/carteras',
      exact: true,
      title: 'Carteras de Cobranzas',
      component: Wallets,
    };

    const cobranzas = {
      path: 'cobranzasFiscalizacion/cobranzas',
      exact: true,
      title: 'Cobranzas',
      component: TableCharge,
    };

    const fiscalizacion = {
      path: 'cobranzasFiscalizacion/fiscalizacion',
      exact: true,
      title: 'Fiscalización',
      component: TableInspection,
    };

    const registroSucursal = {
      path: 'contribuyente/registroSucursal',
      exact: true,
      title: 'Registro Sucursal',
      component: InternalBranchRegister,
    };

    const subMenuCobranzas: RouteI[] = [];

    const cobranzasFiscalizacion = {
      path: 'cobranzasFiscalizacion',
      exact: true,
      title:
        user.institucion.cargo.id === CargoSedemat.Administrador || user.institucion.cargo.id === CargoSedemat.Master 
          ? 'Cobranzas y Fiscalización'
          : user.institucion.cargo.id !== CargoSedemat.JefeFiscalizacion
          ? 'Cobranzas'
          : 'Fiscalización',
      icon: AccountBookOutlined,
      subMenu: subMenuCobranzas,
    };

    const subMenuReporteCaja = [
      {
        path: 'reportes/5',
        exact: false,
        title: 'Cierre de Caja',
      },
      {
        path: 'reportes/recibos',
        exact: false,
        title: 'Historico de Timbres Fiscales',
        component: TableReceipt,
      },
    ];

    const reporteInternoAcumulado = {
      path: 'reportes/1',
      exact: false,
      title: 'Interno de Recaudación Acumulada',
    };

    const reporteContribuyentesAtendidos = {
      path: 'reportes/25',
      exact: false,
      title: 'Contribuyentes Atendidos',
    };

    const reporteMinas = {
      path: 'reportes/26',
      exact: false,
      title: 'Minerales no Metálicos',
    };

    const reporteTimbres = {
      path: 'reportes/27',
      exact: false,
      title: 'Timbres Fiscales',
    };

    const reporteReparos = {
      path: 'reportes/28',
      exact: false,
      title: 'Reparos',
    };

    const reporteConvenios = {
      path: 'reportes/29',
      exact: false,
      title: 'Convenios',
    };
    const reporteConveniosPendientes = {
      path: 'reportes/30',
      exact: false,
      title: 'Convenios Pendientes',
    };
    const reporteMultas = {
      path: 'reportes/31',
      exact: false,
      title: 'Sanciones',
    };

    const reporteTimbresCivil = {
      path: 'reportes/32',
      exact: false,
      title: 'Timbres de Protección Civil',
    };

    const reporteMultasPendientes = {
      path: 'reportes/33',
      exact: false,
      title: 'Sanciones Pendientes',
    };

    const reporteReparosPendientes = {
      path: 'reportes/34',
      exact: false,
      title: 'Reparos Pendientes',
    };

    const reportePeajes = {
      path: 'reportes/35',
      exact: false,
      title: 'Peajes',
    };

    const reporteCierredeCaja = {
      path: 'reportes/5',
      exact: false,
      title: 'Cierre de Caja',
    };

    const reporteCierredeCajaTodas = {
      path: 'reportes/6',
      exact: false,
      title: 'Cierre de Todas las Cajas',
    };

    const reporteInternoAcumuladoPorMunicipio = {
      path: 'reportes/12',
      exact: false,
      title: 'Interno de Recaudación Acumulada Por Municipio',
    };

    const reporteAcumuladoAlcaldia = {
      path: 'reportes/2',
      exact: false,
      title: 'Acumulado para Alcaldía',
    };

    const reportePlanillasVigentes = {
      path: 'reportes/3',
      exact: false,
      title: 'Planillas Vigentes y Pagadas',
    };

    const reporteIVA = {
      path: 'reportes/4',
      exact: false,
      title: 'Reporte de I.V.A',
    };

    const reportIngresoTransf = {
      path: 'reportes/8',
      exact: false,
      title: 'Ingreso por transferencias',
    };

    const reporteCondo = {
      path: 'reportes/9',
      exact: false,
      title: 'Reporte Ingreso por Condominios',
    };

    const reporteTransfBanco = {
      path: 'reportes/10',
      exact: false,
      title: 'Ingreso por transferencias por banco',
    };

    const reporteCondoDisclosed = {
      path: 'reportes/11',
      exact: false,
      title: 'Reporte de Condominios por Liquidaciones',
    };

    const reporteContribuyentes = {
      path: 'reportes/13',
      exact: false,
      title: 'Reporte de Contribuyentes',
    };

    const reporteContribuyentesMunicipio = {
      path: 'reportes/14',
      exact: false,
      title: 'Reporte de Contribuyentes por Municipio',
    };

    const reporteInstituciones = {
      path: 'reportes/15',
      exact: false,
      title: 'Reporte de Instituciones',
    };

    const reporteEntidades = {
      path: 'reportes/16',
      exact: false,
      title: 'Reporte de Entidades Públicas y Financieras',
    };

    const reportePunto = {
      path: 'reportes/17',
      exact: false,
      title: 'Ingreso por punto de venta',
    };

    const reportePuntoBanco = {
      path: 'reportes/18',
      exact: false,
      title: 'Ingreso por punto de venta por banco',
    };

    const reporteDeposito = {
      path: 'reportes/19',
      exact: false,
      title: 'Ingreso por depósito',
    };

    const reporteDepositoBanco = {
      path: 'reportes/20',
      exact: false,
      title: 'Ingreso por depósito por banco',
    };

    const reportePagoGlobal = {
      path: 'reportes/21',
      exact: false,
      title: 'Ingreso por pagos global',
    };

    const reportePagoGlobalBanco = {
      path: 'reportes/22',
      exact: false,
      title: 'Ingreso por pagos global por banco',
    };

    const reporteMetodoSeleccion = {
      path: 'reportes/23',
      exact: false,
      title: 'Ingreso por Método de Pago',
    };

    const reporteAESeleccion = {
      path: 'reportes/24',
      exact: false,
      title: 'Reportes 1x1000',
    };

    const reporteConveniosPdf = {
      path: 'reportes/36',
      exact: false,
      title: 'Convenios (pdf)',
    };

    const reporteConveniosPendientesPdf = {
      path: 'reportes/37',
      exact: false,
      title: 'Convenios Pendientes (pdf)',
    };

    const reporteMultasPdf = {
      path: 'reportes/38',
      exact: false,
      title: 'Sanciones (pdf)',
    };

    const reporteMultasPendientesPdf = {
      path: 'reportes/39',
      exact: false,
      title: 'Sanciones Pendientes (pdf)',
    };

    const subMenuReportes: RouteI[] = [];

    const reportes = {
      path: 'reportes',
      exact: true,
      title: 'Reportes',
      icon: BarChartOutlined,
      subMenu: subMenuReportes,
    };

    const subMenuProcesosDirectivos: RouteI[] = [];

    const beneficiosTributables = {
      path: 'beneficiosTributables',
      exact: true,
      title: 'Beneficios Fiscales',
      component: BenefitTribTaxes,
    };

    const cambioAE = {
      path: 'contribuyente/cambioLicencia',
      exact: true,
      title: 'Cambio de Actividades Económicas',
      component: ActivityChange,
    };

    const reparos = {
      path: ['impuestos/reparo', 'impuestos/reparo/:id'],
      exact: true,
      title: 'Liquidar Reparos',
      component: RepairSettlement,
    };

    const consultaReparos = {
      path: 'impuestos/consultar/reparos',
      exact: true,
      title: 'Consultar Reparos',
      component: ConsultRepairs,
    };

    const multas = {
      path: ['impuestos/multa', 'impuestos/multa/:id'],
      exact: true,
      title: 'Sanciones',
      component: FineSettlement,
    };

    const consultaMultas = {
      path: 'impuestos/consultar/multas',
      exact: true,
      title: 'Consultar Sanciones',
      component: ConsultFines,
    };

    const multasBombero = {
      path: ['impuestos/multaBombero', 'impuestos/multaBombero/:id'],
      exact: true,
      title: 'Multas Servicios Emergentes',
      component: FiremenFineSettlement,
    };

    const consultaMultasBomberos = {
      path: 'impuestos/consultar/multasBombero',
      exact: true,
      title: 'Consultar Multas de Servicios Emergentes',
      component: ConsultFinesFiremen,
    };

    const tributosOmitidos = {
      path: ['impuestos/tributoOmitido', 'impuestos/tributoOmitido/:id'],
      exact: true,
      title: 'Tributos Omitidos',
      component: SkipedFineSettlement,
    };

    const consultaTributosOmitidos = {
      path: 'impuestos/consultar/tributosOmitidos',
      exact: true,
      title: 'Consultar Tributos Omitidos',
      component: ConsultTributeFines,
    };

    const agentesRetencion = {
      path: 'agentes',
      exact: true,
      title: 'Agentes de Retención',
      component: Agents,
    };

    const procesosDirectivos = {
      path: 'procesosDirectivos',
      exact: false,
      icon: ReconciliationOutlined,
      title: 'Procesos Directivos',
      subMenu: subMenuProcesosDirectivos,
    };

    const liquidarImpuestos = {
      path: 'impuestos/declarar',
      exact: false,
      title: 'Declarar Impuesto 1x1000',
      component: DeclareAnyTaxes,
    };

    const liquidarRetenciones = {
      path: 'impuestos/retenciones',
      exact: false,
      title: 'Declarar Retenciones',
      component: Retentions,
    };

    const bitacora = {
      path: 'procesos/bitacora',
      exact: false,
      title: 'Bitácora',
      component: TableMovements,
      icon: CopyOutlined
    };

    const pagosCpu = {
      path: 'tramites/pagosCpu',
      exact: true,
      title: 'Trámites CPU por pagar',
      component: TableCPUPayments,
      icon: CreditCardOutlined,
    };

    const subMenuImpuestos: RouteI[] = [];

    const liquidacionesEspeciales = {
      path: ['impuestos/especiales', 'impuestos/especiales/:id'],
      exact: true,
      title: 'Liquidaciones de Timbres y/o Tasas',
      component: SpecialSettlements,
    };

    const liquidacionesMinerales = {
      path: ['impuestos/minerales', 'impuestos/minerales/:id'],
      exact: true,
      title: 'Minerales no Metálicos',
      component: MineralSettlements,
    };

    const liquidacionesPeajes = {
      path: ['impuestos/peajes', 'impuestos/peajes/:id'],
      exact: true,
      title: 'Peajes',
      component: TollSettlements,
    };

    const liquidacionesVigentes = {
      path: 'liquidaciones/vigentes',
      exact: false,
      title: 'Liquidaciones Vigentes',
      component: TableNASettlements,
    };

    const liquidacionSustitiva = {
      path: 'impuestos/sustitutiva',
      exact: true,
      title: 'Declaración Sustitutiva',
      component: SubstituteStatement,
    };

    const liquidacionComplementaria = {
      path: 'impuestos/complementaria',
      exact: true,
      title: 'Declaración Complementaria',
      component: SupplementaryStatement,
    };

    const consultaDePagos = {
      path: 'pagos/consulta',
      exact: true,
      title: 'Consulta de Pagos',
      component: InternalPayments,
    };

    const consultaDePeajes = {
      path: 'peajes/consulta',
      exact: true,
      title: 'Consulta de Peajes',
      component: ConsultToll,
    };

    const consultaDeRetenciones = {
      path: 'retenciones/consulta',
      exact: true,
      title: 'Consulta de Retenciones',
      component: TableRetentions,
    };

    const liquidacionesPendientes = {
      path: 'impuestos/pendientes',
      exact: false,
      title: 'Liquidaciones Pendientes',
      component: PendingRequests,
    };

    const recibos = {
      path: 'recibos',
      exact: false,
      title: 'Consumir Timbre Fiscal',
      component: Receipt,
      icon: AuditOutlined,
    };

    const actualizarLibro = {
      path: 'recibos',
      exact: false,
      title: 'Actualizar Libro',
      component: BookUpload,
    };

    const pruebaCat = {
      path: 'cat',
      exact: false,
      title: 'CAT',
      component: cattest,
    };

    const pagoConvenios = {
      path: 'impuestos/convenios',
      exact: false,
      title: 'Convenios',
      component: AgreementTaxPayer,
    };

    const impuestos = {
      path: 'impuestos',
      exact: true,
      title: 'Liquidación de Impuestos',
      icon: FileAddOutlined,
      subMenu: subMenuImpuestos,
    };

    const certificados = {
      path: 'certificado',
      exact: true,
      title: 'Generar Certificados',
      icon: ContainerOutlined,
      subMenu: [
        {
          path: 'certificado/SM',
          exact: true,
          title: 'Servicios Municipales',
          component: CertificadeView,
        },
        {
          path: 'certificado/IU',
          exact: true,
          title: 'Inmueble Urbano',
          component: CertificadeView,
        },
        {
          path: 'certificado/EL/generar',
          exact: true,
          title: 'Licencia de Licores',
          component: CertificadeForm,
        },
      ],
    };

    const subMenuInmuebles: RouteI[] = [
      {
        path: 'inmueblesUrbanos/contribuyente',
        exact: true,
        title: 'Inmuebles por Contribuyente',
        component: TaxPayerUrbanEstates,
      },
    ];

    const inmuebles = {
      path: 'inmueblesUrbanos',
      exact: true,
      icon: HomeOutlined,
      title: 'Inmuebles Urbanos',
      subMenu: subMenuInmuebles,
    };

    const consultarInmueble = {
      path: 'inmueblesUrbanos/consultar',
      exact: false,
      title: 'Consultar Inmueble',
      component: ConsultProperty,
    };

    const registrarInmueble = {
      path: 'inmueblesUrbanos/registrar',
      exact: false,
      title: 'Registrar Inmueble',
      component: RegisterProperty,
    };

    const editarInmueble = {
      path: 'inmueblesUrbanos/editar',
      exact: false,
      title: 'Editar Inmueble',
      component: RegisterProperty,
    };

    const condominiums = {
      path: 'condominios',
      exact: true,
      icon: HomeFilled,
      title: 'Condominios',
      subMenu: [
        {
          path: 'condominios/registro',
          exact: true,
          title: 'Registro de Condominios',
          component: RegisterCodominium,
        },
      ],
    };

    const subMenuSM: RouteI[] = [
      // {
      //   path: 'serviciosMunicipales/contribuyente',
      //   exact: true,
      //   title: 'Servicios Municipales por Contribuyente',
      //   component: TaxpayerServices,
      // },
    ];

    const serviciosMunicipales = {
      // path: 'serviciosMunicipales',
      // exact: true,
      // icon: FireOutlined,
      // title: 'Servicios Municipales',
      // subMenu: subMenuSM,
    };

    const nuevosContribuyentes = {
      path: 'contribuyente',
      exact: true,
      title: 'Nuevos Contribuyentes',
      icon: UserAddOutlined,
      subMenu: [
        {
          path: 'contribuyente/registro',
          exact: true,
          title: 'Registro de Contribuyente',
          component: InternalRegister,
        },
        // {
        //   path: ['contribuyente/licencia', 'contribuyente/licencia/:id'],
        //   exact: true,
        //   title: 'Licencia de Actividades Económicas',
        //   component: InternalLicense,
        // },
      ],
    };

    const licores = {
      path: '/licores',
      exact: true,
      title: 'Licencia de Licores',
      icon: FolderAddOutlined,
      subMenu: [
        {
          path: 'tramite/licores',
          exact: true,
          title: 'Solicitud de Licencia Licores',
          component: LiqueurForm,
        },
      ],
    };

    // if (user?.tipoUsuario === UserType.Official) {
    //   subMenuImpuestos.push(
    //     liquidacionesEspeciales,
    //     liquidacionesPendientes,
    //     consultaDePagos
    //   );
    //   // subMenuInmuebles.push(consultarInmueble, registrarInmueble, editarInmueble);
    //   routes.push(
    //     nuevosContribuyentes,
    //     impuestos,
    //     recibos
    //     // certificados
    //   );
    //   routes.push(
    //     {
    //       path: 'contribuyente/consulta',
    //       exact: true,
    //       title: 'Consulta de Contribuyente',
    //       icon: UserOutlined,
    //       component: ConsultTaxpayer,
    //     }
    //   );
    //   routes.push(menuSoporte);
    // }

    if (user?.tipoUsuario === UserType.Official) {
      // subMenuImpuestos.push(
      //   liquidacionesEspeciales,
      //   liquidacionesPendientes,
      //   consultaDePagos
      // );
      // // subMenuInmuebles.push(consultarInmueble, registrarInmueble, editarInmueble);
      // routes.push(
      //   nuevosContribuyentes,
      //   impuestos,
      //   recibos
      //   // certificados
      // );
      // routes.push(
      //   {
      //     path: 'contribuyente/consulta',
      //     exact: true,
      //     title: 'Consulta de Contribuyente',
      //     icon: UserOutlined,
      //     component: ConsultTaxpayer,
      //   }
      // );
      // routes.push(menuSoporte);
    }

    // if (user?.tipoUsuario === UserType.OfficialChief) {
    //   subMenuReportes.push(
    //     reporteInternoAcumulado,
    //     reportIngresoTransf,
    //     reporteTransfBanco
    //     // reporteAcumuladoAlcaldia,
    //     // reportePlanillasVigentes,
    //     // reporteIVA,
    //     // reporteCondo,
    //     // reporteCondoDisclosed
    //   );
    //   subMenuCobranzas.push(asignarCarteras, fiscalizacion);
    //   subMenuProcesosDirectivos.push(beneficiosTributables, cambioAE, agentesRetencion);
    //   subMenuImpuestos.push(
    //     liquidacionesEspeciales,
    //     liquidacionesPendientes,
    //     consultaDePagos
    //     // liquidarImpuestos,
    //     // reparos,
    //     // liquidarRetenciones,
    //     // liquidacionComplementaria,
    //     // liquidacionSustitiva,
    //     // pagoConvenios,
    //     // consultaDeRetenciones,
    //   );
    //   subMenuCorrecciones.push(correccionLiquidaciones, correccionPagos, correccionUsuario, correcionContribuyente);
    //   subMenuInmuebles.push(consultarInmueble, registrarInmueble, editarInmueble);
    //   routes.push(
    //     // procesosDirectivos,
    //     // cobranzasFiscalizacion,
    //     // licores,
    //     // inmuebles,
    //     // condominiums,
    //     correcionesAdministrativas,
    //     // serviciosMunicipales,
    //     reportes,
    //     nuevosContribuyentes,
    //     impuestos,
    //     recibos
    //     // certificados
    //   );
    //   routes.push(
    //     {
    //       path: 'contribuyente/consulta',
    //       exact: true,
    //       title: 'Consulta de Contribuyente',
    //       icon: UserOutlined,
    //       component: ConsultTaxpayer,
    //     }
    //   );
    //   routes.push(menuSoporte);
    // }

    if (user?.tipoUsuario === UserType.GeneralChief) {
      subMenuReportes.push(
        reporteInternoAcumulado,
        reporteInternoAcumuladoPorMunicipio,
        reporteMetodoSeleccion,
        reporteAESeleccion,
        reporteCierredeCaja,
        reporteCierredeCajaTodas,
        reporteContribuyentes,
        reporteContribuyentesMunicipio,
        reporteContribuyentesAtendidos,
        reporteInstituciones,
        reporteEntidades,
        // reportIngresoTransf,
        // reporteTransfBanco,
        // reportePunto,
        // reportePuntoBanco,
        // reporteDeposito,
        // reporteDepositoBanco,
        // reportePagoGlobal,
        // reportePagoGlobalBanco,
        // reporteAcumuladoAlcaldia,
        // reportePlanillasVigentes,
        // reporteIVA,
        // reporteCondo,
        // reporteCondoDisclosed
      );
      subMenuImpuestos.push(
        liquidacionesEspeciales,
        liquidacionesPendientes,
        consultaDePagos
        // liquidarImpuestos,
        // reparos,
        // liquidarRetenciones,
        // liquidacionComplementaria,
        // liquidacionSustitiva,
        // pagoConvenios,
        // consultaDeRetenciones,
      );
      subMenuCorrecciones.push(correccionLiquidaciones, correccionPagos, correccionCompensaciones, correccionUsuario, correcionContribuyente, correcionAgente);
      routes.push({
        path: 'finanzas',
        exact: true,
        icon: DollarOutlined,
        title: 'Finanzas',
        subMenu: [
          {
            path: 'validacionDePagos',
            exact: true,
            title: 'Validación de Pagos',
            component: ValidatePayments,
          },
          {
            path: 'fechaBancaria',
            exact: false,
            title: 'Fechas Bancarias',
            component: TableBankCalendar,
          },
          {
            path: 'finanzas/baremo',
            exact: false,
            title: 'Actualizar Tasas',
            component: Scale,
          },
        ],
      });
      routes.push(
        // procesosDirectivos,
        cobranzasFiscalizacion,
        // licores,
        // inmuebles,
        // condominiums,
        correcionesAdministrativas,
        // serviciosMunicipales,
        reportes,
        // nuevosContribuyentes,
        // impuestos,
        recibos
        // certificados
      );
      // routes.push(
      //   {
      //     path: 'contribuyente',
      //     exact: true,
      //     title: 'Migración de Datos',
      //     icon: ImportOutlined,
      //     subMenu: [
      //       // {
      //       //   path: 'contribuyente/importar',
      //       //   exact: true,
      //       //   title: 'Importar contribuyente de GTIC',
      //       //   component: InternalImport,
      //       // },
      //       {
      //         path: 'contribuyente/enlace',
      //         exact: true,
      //         title: 'Enlace de Contribuyente',
      //         component: InternalLinking,
      //       },
      //     ],
      //   },
      //   )
      // routes.push(
      //   {
      //     path: 'contribuyente/consulta',
      //     exact: true,
      //     title: 'Consulta de Contribuyente',
      //     icon: UserOutlined,
      //     component: ConsultTaxpayer,
      //   }
      // );
      routes.push(menuSoporte);
    }

    switch (user?.institucion?.cargo?.id) {
      case CargoSedemat.Master:
        subMenuReportes.push(
          reporteInternoAcumulado,
          reporteInternoAcumuladoPorMunicipio,
          reporteMetodoSeleccion,
          reporteAESeleccion,
          reporteTimbres,
          reporteTimbresCivil,
          reporteReparos,
          reporteReparosPendientes,
          reporteConvenios,
          reporteConveniosPdf,
          reporteConveniosPendientes,
          reporteConveniosPendientesPdf,
          reporteMultas,
          reporteMultasPdf,
          reporteMultasPendientes,
          reporteMultasPendientesPdf,
          reporteMinas,
          reporteCierredeCaja,
          reporteCierredeCajaTodas,
          reporteContribuyentes,
          reporteContribuyentesMunicipio,
          reporteContribuyentesAtendidos,
          reporteInstituciones,
          reporteEntidades,
          reportePeajes,
          // reportIngresoTransf,
          // reporteTransfBanco,
          // reportePunto,
          // reportePuntoBanco,
          // reporteDeposito,
          // reporteDepositoBanco,
          // reportePagoGlobal,
          // reportePagoGlobalBanco,
          // reporteAcumuladoAlcaldia,
          // reportePlanillasVigentes,
          // reporteIVA,
          // reporteCondo,
          // reporteCondoDisclosed
        );
       moneySubmenu.push({
          path: 'presupuestos',
          exact: false,
          title: 'Principios Presupuestales',
          component: TableBudget,
        },
        );
        subMenuCobranzas.push(asignarCarteras, fiscalizacion);
        subMenuProcesosDirectivos.push(cambioAE, registroSucursal);
        subMenuImpuestos.push(
          liquidarImpuestos,
          pagoConvenios,
          beneficiosTributables,
          reparos,
          consultaReparos,
          multas,
          consultaMultas,
          liquidacionesEspeciales,
          liquidacionesMinerales,
          liquidacionesPendientes,
          liquidacionesVigentes,
          liquidacionesPeajes,
          consultaDePeajes,
          consultaDePagos,
          multasBombero,
          consultaMultasBomberos,
          tributosOmitidos,
          consultaTributosOmitidos,
          // liquidarImpuestos,
          // reparos,
          // liquidarRetenciones,
          // liquidacionComplementaria,
          // liquidacionSustitiva,
          // pagoConvenios,
          // consultaDeRetenciones,
        );
        subMenuCorrecciones.push(correccionLiquidaciones, correccionPagos, correccionDeclaraciones, correccionDeclaracionesCero, correccionCompensaciones, correccionUsuario, correcionContribuyente, correcionAgente);
        subMenuInmuebles.push(consultarInmueble, registrarInmueble, editarInmueble);
        routes.push(
          procesosDirectivos,
          cobranzasFiscalizacion,
          // licores,
          // inmuebles,
          // condominiums,
          correcionesAdministrativas,
          // serviciosMunicipales,
          reportes,
          nuevosContribuyentes,
          impuestos,
          recibos,
          actualizarLibro,
          bitacora,
          // certificados
        );
        routes.push({
          path: 'agente/consulta',
          exact: true,
          title: 'Consulta de Agentes',
          icon: UserOutlined,
          component: ConsultAgent,
        });
        routes.push({
          path: 'contribuyente/consulta',
          exact: true,
          title: 'Consulta de Contribuyente',
          icon: UserOutlined,
          component: ConsultTaxpayer,
        });
        break;
        case CargoSedemat.Intendente:
          subMenuReportes.push(
            reporteInternoAcumulado,
            reporteInternoAcumuladoPorMunicipio,
            reporteMetodoSeleccion,
            reporteAESeleccion,
            reporteTimbres,
            reporteTimbresCivil,
            reporteReparos,
            reporteReparosPendientes,
            reporteConvenios,
            reporteConveniosPdf,
            reporteConveniosPendientes,
            reporteConveniosPendientesPdf,
            reporteMultas,
            reporteMultasPdf,
            reporteMultasPendientes,
            reporteMultasPendientesPdf,
            reporteMinas,
            reporteCierredeCaja,
            reporteCierredeCajaTodas,
            reporteContribuyentes,
            reporteContribuyentesMunicipio,
            reporteContribuyentesAtendidos,
            reporteInstituciones,
            reporteEntidades,
            reportePeajes,
          );
          subMenuImpuestos.push(
            liquidacionesPendientes,
            consultaDePagos,
            pagoConvenios,
            reparos,
            consultaReparos,
            multas,
            consultaMultas,
            consultaDePeajes,
          );
          routes.push(
            reportes,
            nuevosContribuyentes,
            impuestos,
          );
          routes.push({
            path: 'agente/consulta',
            exact: true,
            title: 'Consulta de Agentes',
            icon: UserOutlined,
            component: ConsultAgent,
          });
          routes.push({
            path: 'contribuyente/consulta',
            exact: true,
            title: 'Consulta de Contribuyente',
            icon: UserOutlined,
            component: ConsultTaxpayer,
          });
          break;
      case CargoSedemat.MasterAdmin:
        routes.push({
          path: 'finanzas',
          exact: true,
          icon: DollarOutlined,
          title: 'Finanzas',
          subMenu: [
            {
              path: 'validacionDePagos',
              exact: true,
              title: 'Validación de Pagos',
              component: ValidatePayments,
            },
            {
              path: 'fechaBancaria',
              exact: false,
              title: 'Fechas Bancarias',
              component: TableBankCalendar,
            },
            {
              path: 'finanzas/baremo',
              exact: false,
              title: 'Actualizar Tasas',
              component: Scale,
            },
            {
              path: 'presupuestos',
              exact: false,
              title: 'Principios Presupuestales',
              component: TableBudget,
            }
          ],
        });
        subMenuReportes.push(
          reporteInternoAcumulado,
          reporteInternoAcumuladoPorMunicipio,
          reporteMetodoSeleccion,
          reporteAESeleccion,
          reporteTimbres,
          reporteTimbresCivil,
          reporteReparos,
          reporteReparosPendientes,
          reporteConvenios,
          reporteConveniosPdf,
          reporteConveniosPendientes,
          reporteConveniosPendientesPdf,
          reporteMultas,
          reporteMultasPdf,
          reporteMultasPendientes,
          reporteMultasPendientesPdf,
          reporteMinas,
          reporteCierredeCaja,
          reporteCierredeCajaTodas,
          reporteContribuyentes,
          reporteContribuyentesMunicipio,
          reporteContribuyentesAtendidos,
          reporteInstituciones,
          reporteEntidades,
          reportePeajes,
          // reportIngresoTransf,
          // reporteTransfBanco,
          // reportePunto,
          // reportePuntoBanco,
          // reporteDeposito,
          // reporteDepositoBanco,
          // reportePagoGlobal,
          // reportePagoGlobalBanco,
          // reporteAcumuladoAlcaldia,
          // reportePlanillasVigentes,
          // reporteIVA,
          // reporteCondo,
          // reporteCondoDisclosed
        );
        subMenuProcesosDirectivos.push(cambioAE, registroSucursal);
        subMenuCobranzas.push(asignarCarteras, fiscalizacion);
        subMenuImpuestos.push(
          liquidacionesEspeciales,
          liquidacionesMinerales,
          liquidacionesPendientes,
          liquidacionesVigentes,
          consultaDePagos,
          pagoConvenios,
          beneficiosTributables,
          reparos,
          consultaReparos,
          multas,
          consultaMultas,
          liquidacionesPeajes,
          consultaDePeajes,
          multasBombero,
          consultaMultasBomberos,
          tributosOmitidos,
          consultaTributosOmitidos,
          // liquidarImpuestos,
          // reparos,
          // liquidarRetenciones,
          // liquidacionComplementaria,
          // liquidacionSustitiva,
          // pagoConvenios,
          // consultaDeRetenciones,
        );
        subMenuCorrecciones.push(correccionLiquidaciones, correccionPagos, correccionDeclaraciones, correccionDeclaracionesCero, correccionUsuario, correccionCompensaciones, correcionContribuyente, correcionAgente);
        subMenuInmuebles.push(consultarInmueble, registrarInmueble, editarInmueble);
        routes.push(
          procesosDirectivos,
          cobranzasFiscalizacion,
          // licores,
          // inmuebles,
          // condominiums,
          correcionesAdministrativas,
          // serviciosMunicipales,
          reportes,
          nuevosContribuyentes,
          impuestos,
          recibos,
          bitacora
          // certificados
        );
        routes.push({
          path: 'contribuyente/consulta',
          exact: true,
          title: 'Consulta de Contribuyente',
          icon: UserOutlined,
          component: ConsultTaxpayer,
        });
        routes.push({
          path: 'agente/consulta',
          exact: true,
          title: 'Consulta de Agentes',
          icon: UserOutlined,
          component: ConsultAgent,
        });
        break;
      case CargoSedemat.FunSoporte:
        routes.push({
          path: 'finanzas',
          exact: true,
          icon: DollarOutlined,
          title: 'Finanzas',
          subMenu: [
            {
              path: 'finanzas/baremo',
              exact: false,
              title: 'Actualizar Tasas',
              component: Scale,
            },
          ],
        });
        subMenuReportes.push(
          reporteInternoAcumulado,
          reporteInternoAcumuladoPorMunicipio,
          reporteMetodoSeleccion,
          reporteAESeleccion,
          reporteTimbres,
          reporteTimbresCivil,
          reporteReparos,
          reporteReparosPendientes,
          reporteConvenios,
          reporteConveniosPdf,
          reporteConveniosPendientes,
          reporteConveniosPendientesPdf,
          reporteMultas,
          reporteMultasPdf,
          reporteMultasPendientes,
          reporteMultasPendientesPdf,
          reporteMinas,
          reporteCierredeCaja,
          reporteCierredeCajaTodas,
          reporteContribuyentes,
          reporteContribuyentesMunicipio,
          reporteContribuyentesAtendidos,
          reporteInstituciones,
          reporteEntidades,
          reportePeajes,
        );
        subMenuProcesosDirectivos.push(registroSucursal);
        subMenuImpuestos.push(
          liquidacionesEspeciales,
          liquidacionesMinerales,
          liquidacionesPendientes,
          consultaDePagos,
          tributosOmitidos,
          consultaTributosOmitidos,
        );
        subMenuCorrecciones.push(correccionPagos, correccionCompensaciones, correccionUsuario, correcionContribuyente, correcionAgente);
        routes.push(
          procesosDirectivos,
          correcionesAdministrativas,
          reportes,
          nuevosContribuyentes,
          impuestos,
        );
        routes.push({
          path: 'contribuyente/consulta',
          exact: true,
          title: 'Consulta de Contribuyente',
          icon: UserOutlined,
          component: ConsultTaxpayer,
        });
        routes.push({
          path: 'agente/consulta',
          exact: true,
          title: 'Consulta de Agentes',
          icon: UserOutlined,
          component: ConsultAgent,
        });
        break;
      case CargoSedemat.JefeIntimaciones:
        subMenuReportes.push(
          reporteTimbres,
          reporteConvenios,
          reporteConveniosPdf,
          reporteConveniosPendientes,
          reporteConveniosPendientesPdf,
          reporteMultas,
          reporteMultasPdf,
          reporteMultasPendientes,
          reporteMultasPendientesPdf,
          reporteCierredeCaja,
          reporteCierredeCajaTodas,
        );
        subMenuImpuestos.push(
          liquidacionesEspeciales,
          liquidacionesMinerales,
          liquidacionesPendientes,
          consultaDePagos,
          pagoConvenios,
          beneficiosTributables,
          multas,
          consultaMultas,
          tributosOmitidos,
          consultaTributosOmitidos,
        );
        routes.push(
          procesosDirectivos,
          reportes,
          nuevosContribuyentes,
          impuestos,
        );
        routes.push({
          path: 'contribuyente/consulta',
          exact: true,
          title: 'Consulta de Contribuyente',
          icon: UserOutlined,
          component: ConsultTaxpayer,
        });
        routes.push({
          path: 'agente/consulta',
          exact: true,
          title: 'Consulta de Agentes',
          icon: UserOutlined,
          component: ConsultAgent,
        });
        break;
      case CargoSedemat.Administrador:
        subMenuImpuestos.push(liquidacionesEspeciales, liquidacionesMinerales, liquidacionesPendientes, consultaDePagos, pagoConvenios, consultaMultas, tributosOmitidos);
        subMenuReportes.push(
          reporteCierredeCaja,
        );
        // subMenuInmuebles.push(consultarInmueble, registrarInmueble, editarInmueble);
        routes.push(
          nuevosContribuyentes,
          impuestos,
          recibos,
          reportes,
          // certificados
        );
        routes.push({
          path: 'contribuyente/consulta',
          exact: true,
          title: 'Consulta de Contribuyente',
          icon: UserOutlined,
          component: ConsultTaxpayer,
        });
        routes.push({
          path: 'agente/consulta',
          exact: true,
          title: 'Consulta de Agente',
          icon: UserOutlined,
          component: ConsultAgent,
        });
        // routes.push(menuSoporte);
        break;
      case CargoSedemat.JefeTimbre:
        subMenuImpuestos.push(liquidacionesEspeciales, liquidacionesPendientes, consultaDePagos, tributosOmitidos);
        subMenuReportes.push(
          reporteCierredeCaja,
          reporteCierredeCajaTodas,
          reporteMetodoSeleccion,
          reporteInternoAcumulado,
          reporteInternoAcumuladoPorMunicipio,
          reporteContribuyentesAtendidos,
          reporteTimbres,
          reporteTimbresCivil,
        );
        // subMenuInmuebles.push(consultarInmueble, registrarInmueble, editarInmueble);..
        routes.push(
          nuevosContribuyentes,
          impuestos,
          recibos,
          reportes,
          // certificados
        );
        routes.push({
          path: 'contribuyente/consulta',
          exact: true,
          title: 'Consulta de Contribuyente',
          icon: UserOutlined,
          component: ConsultTaxpayer,
        });
        routes.push({
          path: 'agente/consulta',
          exact: true,
          title: 'Consulta de Agente',
          icon: UserOutlined,
          component: ConsultAgent,
        });
        // routes.push(menuSoporte);
        break;
      case CargoSedemat.ConsultorJuridico:
        subMenuReportes.push(
          reporteReparos,
          reporteReparosPendientes,
          reporteConvenios,
          reporteConveniosPdf,
          reporteConveniosPendientes,
          reporteConveniosPendientesPdf,
          reporteMultas,
          reporteMultasPdf,
          reporteMultasPendientes,
          reporteMultasPendientesPdf,
          reporteContribuyentes,
          reporteContribuyentesMunicipio,
          reporteEntidades,
        );
        subMenuImpuestos.push(
          liquidacionesPendientes,
          consultaDePagos,
          beneficiosTributables,
          pagoConvenios,
          multas,
          consultaMultas,
        );
        routes.push(
          // procesosDirectivos,
          // cobranzasFiscalizacion,
          // licores,
          // inmuebles,
          // condominiums,
          // correcionesAdministrativas,
          // serviciosMunicipales,
          reportes,
          // nuevosContribuyentes,
          impuestos,
          // recibos,
          // bitacora
          // certificados
        );
        routes.push({
          path: 'contribuyente/consulta',
          exact: true,
          title: 'Consulta de Contribuyente',
          icon: UserOutlined,
          component: ConsultTaxpayer,
        });
        routes.push({
          path: 'agente/consulta',
          exact: true,
          title: 'Consulta de Agentes',
          icon: UserOutlined,
          component: ConsultAgent,
        });
        break;
      case CargoSedemat.JefeLicor:
        routes.push({
          path: 'finanzas',
          exact: true,
          icon: DollarOutlined,
          title: 'Finanzas',
          subMenu: [
            {
              path: 'finanzas/baremo',
              exact: false,
              title: 'Actualizar Tasas',
              component: Scale,
            },
            {
              path: 'presupuestos',
              exact: false,
              title: 'Principios Presupuestales',
              component: TableBudget,
            }
          ],
        });
        subMenuReportes.push(
          reporteInternoAcumulado,
          reporteInternoAcumuladoPorMunicipio,
          reporteMetodoSeleccion,
          reporteAESeleccion,
          reporteTimbres,
          reporteTimbresCivil,
          reporteReparos,
          reporteReparosPendientes,
          reporteConvenios,
          reporteConveniosPdf,
          reporteConveniosPendientes,
          reporteConveniosPendientesPdf,
          reporteMultas,
          reporteMultasPdf,
          reporteMultasPendientes,
          reporteMultasPendientesPdf,
          reporteMinas,
          reporteCierredeCaja,
          reporteCierredeCajaTodas,
          reporteContribuyentes,
          reporteContribuyentesMunicipio,
          reporteContribuyentesAtendidos,
          reporteEntidades,
          reportePeajes,
        );
        subMenuProcesosDirectivos.push(registroSucursal);
        subMenuCobranzas.push(asignarCarteras, fiscalizacion);
        subMenuImpuestos.push(
          liquidacionesEspeciales,
          liquidacionesPendientes,
          liquidacionesVigentes,
          consultaDePagos,
          pagoConvenios,
          consultaReparos,
          consultaMultas,
          liquidacionesPeajes,
          consultaDePeajes,
        );
        subMenuCorrecciones.push( correccionPagos, correccionCompensaciones);
        subMenuInmuebles.push(consultarInmueble, registrarInmueble, editarInmueble);
        routes.push(
          procesosDirectivos,
          // cobranzasFiscalizacion,
          // licores,
          // inmuebles,
          // condominiums,
          correcionesAdministrativas,
          // serviciosMunicipales,
          reportes,
          nuevosContribuyentes,
          impuestos,
          // recibos,
          // bitacora
          // certificados
        );
        routes.push({
          path: 'contribuyente/consulta',
          exact: true,
          title: 'Consulta de Contribuyente',
          icon: UserOutlined,
          component: ConsultTaxpayer,
        });
        routes.push({
          path: 'agente/consulta',
          exact: true,
          title: 'Consulta de Agentes',
          icon: UserOutlined,
          component: ConsultAgent,
        });
        break;
      case CargoSedemat.Certificador:
        routes.push({
          path: 'finanzas',
          exact: true,
          icon: DollarOutlined,
          title: 'Finanzas',
          subMenu: [
            {
              path: 'validacionDePagos',
              exact: true,
              title: 'Validación de Pagos',
              component: ValidatePayments,
            },
            {
              path: 'presupuestos',
              exact: false,
              title: 'Principios Presupuestales',
              component: TableBudget,
            }
          ],
        });
        subMenuReportes.push(
          reporteInternoAcumulado,
          reporteInternoAcumuladoPorMunicipio,
          reporteMetodoSeleccion,
          reporteAESeleccion,
          reporteCierredeCaja,
          reporteCierredeCajaTodas,
          reporteContribuyentes,
          reporteContribuyentesMunicipio,
          reporteContribuyentesAtendidos,
          reporteInstituciones,
          reporteEntidades,
        );
        subMenuCorrecciones.push(correccionLiquidaciones, correccionPagos, correccionCompensaciones);
        subMenuImpuestos.push(liquidacionesPendientes, liquidacionesVigentes, consultaDePagos);
        // subMenuInmuebles.push(consultarInmueble, registrarInmueble, editarInmueble);
        routes.push(
          correcionesAdministrativas,
          impuestos,
          reportes,  
        );
        // routes.push(menuSoporte);
        break;
        case CargoSedemat.JefeMina:
        subMenuImpuestos.push(liquidacionesMinerales, liquidacionesPendientes, consultaDePagos);
        subMenuReportes.push(
          reporteMinas,
          reporteCierredeCaja
        );
        // subMenuInmuebles.push(consultarInmueble, registrarInmueble, editarInmueble);
        routes.push(
          nuevosContribuyentes,
          impuestos,
          recibos,
          reportes,
          // certificados
        );
        routes.push({
          path: 'contribuyente/consulta',
          exact: true,
          title: 'Consulta de Contribuyente',
          icon: UserOutlined,
          component: ConsultTaxpayer,
        });
        // routes.push(menuSoporte);
        break;
        case CargoSedemat.DosPorCien:
          subMenuReportes.push(
            reporteMetodoSeleccion,
            reporteAESeleccion,
            reporteContribuyentes,
            reporteContribuyentesMunicipio,
            reporteContribuyentesAtendidos,
            reporteInstituciones,
            reporteEntidades,
          );
          subMenuImpuestos.push(liquidarImpuestos, consultaDePagos);
          subMenuCorrecciones.push(correccionDeclaracionesCero, correccionDeclaraciones, correccionUsuario, correcionAgente);
          // subMenuInmuebles.push(consultarInmueble, registrarInmueble, editarInmueble);
          routes.push(
            nuevosContribuyentes,
            correcionesAdministrativas,
            impuestos,
            reportes,
          );
          // routes.push(menuSoporte);
          routes.push({
            path: 'contribuyente/consulta',
            exact: true,
            title: 'Consulta de Contribuyente',
            icon: UserOutlined,
            component: ConsultTaxpayer,
          });
          routes.push({
            path: 'agente/consulta',
            exact: true,
            title: 'Consulta de Agente',
            icon: UserOutlined,
            component: ConsultAgent,
          });
          break;
        case CargoSedemat.Fiscal:
        subMenuImpuestos.push(liquidacionesPendientes, consultaDePagos, pagoConvenios, consultaMultas, consultaReparos, reparos);
        subMenuCobranzas.push(cobranzas, asignarCarteras, fiscalizacion);
        // subMenuInmuebles.push(consultarInmueble, registrarInmueble, editarInmueble);
        subMenuReportes.push(
          reporteContribuyentes,
          reporteContribuyentesMunicipio,
          reporteContribuyentesAtendidos,
          reporteInstituciones,
          reporteEntidades,
          reporteConvenios,
          reporteConveniosPdf,
          reporteConveniosPendientes,
          reporteConveniosPendientesPdf,
          reporteMultas,
          reporteMultasPdf,
          reporteMultasPendientes,
          reporteMultasPendientesPdf,
          reporteReparos,
          reporteReparosPendientes,
        );
        routes.push(
          // nuevosContribuyentes,
          impuestos,
          cobranzasFiscalizacion,
          reportes
          // certificados
        );
        routes.push({
          path: 'contribuyente/consulta',
          exact: true,
          title: 'Consulta de Contribuyente',
          icon: UserOutlined,
          component: ConsultTaxpayer,
        });
        routes.push({
          path: 'agente/consulta',
          exact: true,
          title: 'Consulta de Agente',
          icon: UserOutlined,
          component: ConsultAgent,
        });
        // routes.push(menuSoporte);
        break;
      case CargoSedemat.GerenteGeneral:
        routes.push({
          path: 'finanzas',
          exact: true,
          icon: DollarOutlined,
          title: 'Finanzas',
          subMenu: [
            {
              path: 'validacionDePagos',
              exact: true,
              title: 'Validación de Pagos',
              component: ValidatePayments,
            },
            {
              path: 'fechaBancaria',
              exact: false,
              title: 'Fechas Bancarias',
              component: TableBankCalendar,
            },
            {
              path: 'finanzas/baremo',
              exact: false,
              title: 'Actualizar Tasas',
              component: Scale,
            },
            {
              path: 'presupuestos',
              exact: false,
              title: 'Principios Presupuestales',
              component: TableBudget,
            }
          ],
        });
        subMenuReportes.push(
          reporteInternoAcumulado,
          reporteInternoAcumuladoPorMunicipio,
          reporteMetodoSeleccion,
          reporteAESeleccion,
          reporteCierredeCaja,
          reporteCierredeCajaTodas,
          reporteContribuyentes,
          reporteContribuyentesMunicipio,
          reporteContribuyentesAtendidos,
          reporteInstituciones,
          reporteEntidades,
          reportePeajes,
          // reportIngresoTransf,
          // reporteTransfBanco,
          // reportePunto,
          // reportePuntoBanco,
          // reporteDeposito,
          // reporteDepositoBanco,
          // reportePagoGlobal,
          // reportePagoGlobalBanco,
          // reporteAcumuladoAlcaldia,
          // reportePlanillasVigentes,
          // reporteIVA,
          // reporteCondo,
          // reporteCondoDisclosed
        );
        subMenuCobranzas.push(asignarCarteras, fiscalizacion);
        subMenuProcesosDirectivos.push(cambioAE, registroSucursal);
        subMenuImpuestos.push(
          liquidacionesEspeciales,
          liquidacionesPendientes,
          liquidacionesVigentes,
          consultaDePagos,
          liquidacionesPeajes,
          consultaDePeajes,
          // liquidarImpuestos,
          // reparos,
          // liquidarRetenciones,
          // liquidacionComplementaria,
          // liquidacionSustitiva,
          // pagoConvenios,
          // consultaDeRetenciones,
        );
        subMenuCorrecciones.push(correccionLiquidaciones, correccionPagos, correccionCompensaciones, correccionUsuario, correcionContribuyente, correcionAgente);
        subMenuInmuebles.push(consultarInmueble, registrarInmueble, editarInmueble);
        routes.push(
          procesosDirectivos,
          // cobranzasFiscalizacion,
          // licores,
          // inmuebles,
          // condominiums,
          // correcionesAdministrativas,
          // serviciosMunicipales,
          reportes,
          nuevosContribuyentes,
          impuestos,
          recibos,
          bitacora
          // certificados
        );
        routes.push({
          path: 'contribuyente/consulta',
          exact: true,
          title: 'Consulta de Contribuyente',
          icon: UserOutlined,
          component: ConsultTaxpayer,
        });
        routes.push({
          path: 'agente/consulta',
          exact: true,
          title: 'Consulta de Agente',
          icon: UserOutlined,
          component: ConsultAgent,
        });
        routes.push(menuSoporte);
        break;
      case CargoSedemat.GerenteGeneralA:
        routes.push({
          path: 'finanzas',
          exact: true,
          icon: DollarOutlined,
          title: 'Finanzas',
          subMenu: [
            {
              path: 'validacionDePagos',
              exact: true,
              title: 'Validación de Pagos',
              component: ValidatePayments,
            },
            {
              path: 'fechaBancaria',
              exact: false,
              title: 'Fechas Bancarias',
              component: TableBankCalendar,
            },
            {
              path: 'finanzas/baremo',
              exact: false,
              title: 'Actualizar Tasas',
              component: Scale,
            },
            {
              path: 'presupuestos',
              exact: false,
              title: 'Principios Presupuestales',
              component: TableBudget,
            }
          ],
        });
        subMenuReportes.push(
          reporteInternoAcumulado,
          reporteInternoAcumuladoPorMunicipio,
          reporteMetodoSeleccion,
          reporteAESeleccion,
          reporteTimbres,
          reporteTimbresCivil,
          reporteReparos,
          reporteReparosPendientes,
          reporteConvenios,
          reporteConveniosPdf,
          reporteConveniosPendientes,
          reporteConveniosPendientesPdf,
          reporteMultas,
          reporteMultasPdf,
          reporteMultasPendientes,
          reporteMultasPendientesPdf,
          reporteMinas,
          reporteCierredeCaja,
          reporteCierredeCajaTodas,
          reporteContribuyentes,
          reporteContribuyentesMunicipio,
          reporteContribuyentesAtendidos,
          reporteInstituciones,
          reporteEntidades,
          // reportIngresoTransf,
          // reporteTransfBanco,
          // reportePunto,
          // reportePuntoBanco,
          // reporteDeposito,
          // reporteDepositoBanco,
          // reportePagoGlobal,
          // reportePagoGlobalBanco,
          // reporteAcumuladoAlcaldia,
          // reportePlanillasVigentes,
          // reporteIVA,
          // reporteCondo,
          // reporteCondoDisclosed
        );
        subMenuCobranzas.push(asignarCarteras, fiscalizacion);
        subMenuProcesosDirectivos.push(cambioAE, registroSucursal);
        subMenuImpuestos.push(
          liquidarImpuestos,
          pagoConvenios,
          beneficiosTributables,
          reparos,
          consultaReparos,
          multas,
          consultaMultas,
          liquidacionesEspeciales,
          liquidacionesMinerales,
          liquidacionesPendientes,
          liquidacionesVigentes,
          consultaDePagos
          // liquidarImpuestos,
          // reparos,
          // liquidarRetenciones,
          // liquidacionComplementaria,
          // liquidacionSustitiva,
          // pagoConvenios,
          // consultaDeRetenciones,
        );
        subMenuCorrecciones.push(correccionLiquidaciones, correccionPagos, correccionCompensaciones, correccionUsuario, correcionContribuyente, correcionAgente);
        subMenuInmuebles.push(consultarInmueble, registrarInmueble, editarInmueble);
        routes.push(
          procesosDirectivos,
          cobranzasFiscalizacion,
          // licores,
          // inmuebles,
          // condominiums,
          correcionesAdministrativas,
          // serviciosMunicipales,
          reportes,
          nuevosContribuyentes,
          impuestos,
          recibos,
          bitacora
          // certificados
        );
        // routes.push(menuSoporte);
        routes.push({
          path: 'agente/consulta',
          exact: true,
          title: 'Consulta de Agentes',
          icon: UserOutlined,
          component: ConsultAgent,
        });
        routes.push({
          path: 'contribuyente/consulta',
          exact: true,
          title: 'Consulta de Contribuyente',
          icon: UserOutlined,
          component: ConsultTaxpayer,
        });
        break;
      case CargoSedemat.GerenteAdministracion:
        subMenuReportes.push(
          reporteInternoAcumulado, reporteAcumuladoAlcaldia, reportePlanillasVigentes, reporteIVA);
        routes.push(
          {
            path: 'finanzas',
            exact: true,
            icon: DollarOutlined,
            title: 'Finanzas',
            subMenu: moneySubmenu,
          },
          reportes
        );
        break;
      case CargoSedemat.GerenteCobranzas:
        subMenuReportes.push(reporteInternoAcumulado, reporteAcumuladoAlcaldia, reportePlanillasVigentes, reporteIVA);
        subMenuProcesosDirectivos.push(beneficiosTributables, agentesRetencion);
        subMenuImpuestos.push(
          liquidarImpuestos,
          liquidacionesEspeciales,
          liquidarRetenciones,
          liquidacionesPendientes,
          liquidacionComplementaria,
          liquidacionSustitiva,
          pagoConvenios,
          consultaDeRetenciones,
          consultaDePagos
        );
        subMenuCobranzas.push(asignarCarteras);
        routes.push(reportes, cobranzasFiscalizacion, procesosDirectivos, impuestos);
        break;
      case CargoSedemat.GerentePoliticasTributarias:
        subMenuCorrecciones.push(correccionUsuario, correcionContribuyente, correcionAgente, cambioAE);
        subMenuReportes.push(reporteInternoAcumulado, reporteAcumuladoAlcaldia, reportePlanillasVigentes, reporteIVA);
        subMenuInmuebles.push(consultarInmueble, registrarInmueble, editarInmueble);
        subMenuImpuestos.push(
          liquidarImpuestos,
          reparos,
          liquidacionesEspeciales,
          liquidacionesPendientes,
          pagoConvenios,
          liquidacionComplementaria,
          liquidacionSustitiva
        );
        routes.push(correcionesAdministrativas, reportes, inmuebles, nuevosContribuyentes, impuestos, condominiums);
        break;
      case CargoSedemat.GerenteRecaudacion:
        subMenuCorrecciones.push(correccionPagos, correccionCompensaciones);
        subMenuReportes.push(
          reporteInternoAcumulado,
          reporteAcumuladoAlcaldia,
          reportePlanillasVigentes,
          reporteIVA,
          ...subMenuReporteCaja,
          {
            path: 'reportes/6',
            exact: false,
            title: 'Cierre de Todas las Cajas',
            // component: Report,
          }
        );
        subMenuImpuestos.push(
          liquidarImpuestos,
          liquidacionesPendientes,
          liquidacionesEspeciales,
          liquidacionComplementaria,
          liquidacionSustitiva,
          reparos,
          pagoConvenios,
          consultaDePagos
        );
        routes.push(reportes, correcionesAdministrativas, impuestos);
        break;
      case CargoSedemat.DirectorUrbanismo:
        subMenuReportes.push(
          reporteInternoAcumulado,
          reporteAcumuladoAlcaldia,
          reportePlanillasVigentes,
          reporteIVA,
          reporteCondo,
          reporteCondoDisclosed
        );
        subMenuImpuestos.push(liquidacionesEspeciales, liquidacionesPendientes, pagoConvenios, consultaDePagos);
        routes.push(reportes, condominiums, nuevosContribuyentes, impuestos);
        break;
      case CargoSedemat.JefeServiciosMunicipales:
        subMenuImpuestos.push(liquidarImpuestos, liquidacionComplementaria, liquidacionSustitiva);
        routes.push(
          // serviciosMunicipales,
          impuestos
        );
        break;
      case CargoSedemat.JefeInmueblesUrbanos:
        subMenuImpuestos.push(liquidarImpuestos, liquidacionComplementaria, liquidacionSustitiva);
        routes.push(impuestos);
        break;
      case CargoSedemat.JefeActividadesEconomicas:
        subMenuCorrecciones.push(correccionUsuario, correcionContribuyente, correcionAgente);
        subMenuImpuestos.push(
          liquidarImpuestos,
          reparos,
          liquidacionComplementaria,
          liquidacionSustitiva,
          liquidacionesPendientes,
          pagoConvenios
        );
        routes.push(correcionesAdministrativas, nuevosContribuyentes, impuestos);
        break;
      case CargoSedemat.JefeAuditorias:
        //nada
        break;
      case CargoSedemat.JefeContribuyentesEspeciales:
        // subMenuProcesosDirectivos.push(agentesRetencion);
        // subMenuImpuestos.push(
        //   liquidarImpuestos,
        //   liquidacionesPendientes,
        //   liquidacionesEspeciales,
        //   liquidarRetenciones,
        //   pagoConvenios
        // );
        // routes.push(procesosDirectivos, impuestos);
        subMenuCorrecciones.push(correccionUsuario, correcionContribuyente, correcionAgente);
        subMenuImpuestos.push(liquidacionesEspeciales, liquidacionesPendientes, consultaDePagos);
        // subMenuInmuebles.push(consultarInmueble, registrarInmueble, editarInmueble);
        routes.push(
          nuevosContribuyentes,
          impuestos,
          recibos,
          correcionesAdministrativas
          // certificados
        );
        // routes.push(menuSoporte);
        routes.push({
          path: 'contribuyente/consulta',
          exact: true,
          title: 'Consulta de Contribuyente',
          icon: UserOutlined,
          component: ConsultTaxpayer,
        });
        routes.push({
          path: 'agente/consulta',
          exact: true,
          title: 'Consulta de Agente',
          icon: UserOutlined,
          component: ConsultAgent,
        });
        break;
      case CargoSedemat.JefeDeTransacciones:
        subMenuCorrecciones.push(correccionUsuario, correcionContribuyente, correcionAgente);
        subMenuImpuestos.push(
          liquidarImpuestos,
          reparos,
          liquidacionesEspeciales,
          liquidacionComplementaria,
          liquidacionSustitiva,
          liquidacionesPendientes,
          pagoConvenios
        );
        routes.push(correcionesAdministrativas, nuevosContribuyentes, impuestos);
        break;
      case CargoSedemat.JefeDeCaja:
        subMenuReporteCaja.push({
          path: 'reportes/6',
          exact: false,
          title: 'Cierre de Todas las Cajas',
          // component: Report,
        });
        subMenuReportes.push(...subMenuReporteCaja, reportIngresoTransf);
        subMenuImpuestos.push(
          liquidarImpuestos,
          reparos,
          liquidacionesEspeciales,
          liquidacionesPendientes,
          liquidacionComplementaria,
          liquidacionSustitiva,
          pagoConvenios,
          consultaDePagos
        );
        routes.push(pagosCpu, reportes, impuestos);
        break;
      case CargoSedemat.JefeContaduria:
        subMenuReporteCaja.push({
          path: 'reportes/6',
          exact: false,
          title: 'Cierre de Todas las Cajas',
          // component: Report,
        });
        subMenuReportes.push(...subMenuReporteCaja, reportIngresoTransf, reporteTransfBanco);
        routes.push(reportes);
        break;
      case CargoSedemat.JefeDeCuadra:
        subMenuReporteCaja.push({
          path: 'reportes/6',
          exact: false,
          title: 'Cierre de Todas las Cajas',
          // component: Report,
        });
        subMenuReportes.push(...subMenuReporteCaja);
        subMenuImpuestos.push(consultaDePagos);
        routes.push(reportes);
        break;
      case CargoSedemat.JefeFiscalizacion:
        subMenuReportes.push(reportePlanillasVigentes);
        subMenuImpuestos.push(liquidacionesPendientes, pagoConvenios);
        subMenuCobranzas.push(fiscalizacion);
        routes.push(reportes, cobranzasFiscalizacion, impuestos);
        break;
      case CargoSedemat.ConsultorLiquidaciones:
        subMenuImpuestos.push(liquidacionesPendientes);
        routes.push(impuestos);
        break;
      case CargoSedemat.Cajero:
        subMenuReportes.push(...subMenuReporteCaja);
        subMenuImpuestos.push(liquidacionesPendientes, pagoConvenios, consultaDePagos);
        routes.push(pagosCpu, reportes, impuestos);
        break;
      case CargoSedemat.CajeroIntegral:
        subMenuReportes.push(...subMenuReporteCaja);
        subMenuImpuestos.push(
          liquidarImpuestos,
          liquidacionesEspeciales,
          liquidacionesPendientes,
          liquidacionComplementaria,
          liquidacionSustitiva,
          pagoConvenios,
          consultaDePagos
        );
        routes.push(pagosCpu, reportes, nuevosContribuyentes, impuestos);
        break;
      case CargoSedemat.CajeroE:
        subMenuCorrecciones.push(correccionPagos, correccionCompensaciones, correcionContribuyente, correcionAgente);
        subMenuReportes.push(...subMenuReporteCaja);
        subMenuImpuestos.push(
          liquidarImpuestos,
          liquidacionesEspeciales,
          liquidacionesPendientes,
          liquidacionComplementaria,
          liquidacionSustitiva,
          pagoConvenios,
          consultaDePagos
        );
        routes.push(pagosCpu, reportes, correcionesAdministrativas, impuestos);
        break;
      case CargoSedemat.JefeDeZona:
        subMenuReporteCaja.push({
          path: 'reportes/6',
          exact: false,
          title: 'Cierre de Todas las Cajas',
          // component: Report,
        });
        subMenuReportes.push(reporteInternoAcumulado, reportIngresoTransf, ...subMenuReporteCaja);
        subMenuImpuestos.push(
          liquidarImpuestos,
          reparos,
          liquidacionesEspeciales,
          liquidacionesPendientes,
          liquidacionComplementaria,
          liquidacionSustitiva,
          pagoConvenios,
          consultaDePagos
        );
        routes.push(reportes, impuestos);
        break;
      case CargoSedemat.AnalistaCondominios:
        subMenuReportes.push(...subMenuReporteCaja);
        subMenuImpuestos.push(liquidacionesEspeciales, liquidacionesPendientes, pagoConvenios, consultaDePagos);
        routes.push(reportes, condominiums, nuevosContribuyentes, impuestos);
        break;
      case CargoSedemat.AnalistaPoliticasTributarias:
        subMenuCorrecciones.push(correccionUsuario, correcionContribuyente, correcionAgente);
        subMenuImpuestos.push(
          liquidarImpuestos,
          reparos,
          liquidacionComplementaria,
          liquidacionSustitiva,
          liquidacionesPendientes
        );
        routes.push(correcionesAdministrativas, nuevosContribuyentes, impuestos);
        break;
      case CargoSedemat.AnalistaCobranzas:
        subMenuImpuestos.push(
          liquidarImpuestos,
          liquidacionComplementaria,
          liquidacionSustitiva,
          liquidarRetenciones,
          liquidacionesPendientes,
          pagoConvenios,
          consultaDeRetenciones,
          consultaDePagos
        );
        subMenuCobranzas.push(cobranzas);
        routes.push(cobranzasFiscalizacion, impuestos);
        break;
      case CargoSedemat.AnalistaLicores:
        break;
      case CargoSedemat.Soporte:
        subMenuCorrecciones.push(correccionUsuario, correcionContribuyente, correcionAgente);
        subMenuInmuebles.push(registrarInmueble, editarInmueble);
        subMenuImpuestos.push(liquidarImpuestos, liquidacionComplementaria, liquidacionesPendientes, pagoConvenios);
        routes.push(correcionesAdministrativas, inmuebles, impuestos);
        break;
      case CargoSedemat.Auditor:
        //nada
        break;
      case CargoSedemat.Validador:
        subMenuReportes.push(...subMenuReporteCaja);
        routes.push(
          {
            path: 'finanzas',
            exact: true,
            icon: DollarOutlined,
            title: 'Finanzas',
            subMenu: [
              {
                path: 'validacionDePagos',
                exact: true,
                title: 'Validación de Pagos',
                component: ValidatePayments,
              },
              {
                path: 'fechaBancaria',
                exact: false,
                title: 'Fechas Bancarias',
                component: TableBankCalendar,
              },
            ],
          },
          reportes
        );
        break;
    }
    // if (user.institucion.cargo.id !== CargoSedemat.Validador && user.institucion.cargo.id !== CargoSedemat.Administrador)
    //   routes.push(
    //     {
    //       path: 'contribuyente/enlace',
    //       exact: true,
    //       title: 'Enlace de Contribuyente',
    //       icon: ImportOutlined,
    //       component: InternalLinking,
    //     },
    //     {
    //       path: 'contribuyente/consulta',
    //       exact: true,
    //       title: 'Consulta de Contribuyente',
    //       icon: UserOutlined,
    //       component: ConsultTaxpayer,
    //     },
    //   );

    if (canSupport.includes(user.institucion.cargo.id)) routes.push(menuSoporte);

    if (
      user.tipoUsuario === UserType.OfficialAdmin ||
      user.tipoUsuario === UserType.OfficialChief ||
      user.tipoUsuario === UserType.Official ||
      havePrcd.includes(user?.institucion?.cargo?.id || 0)
    ) {
      routes.push({
        path: 'bandejas',
        exact: false,
        icon: InboxOutlined,
        title: 'Bandejas',
        component: TableProcedure,
      });
    }
  }
  //FIN MENU INTERNO SEDEMAT

  //MENU DE RUTAS CPU
  if (user.institucion?.nombreCorto === 'CPU') {
    const inmueblesUrbanos = {
      path: 'inmueblesUrbanos',
      exact: true,
      icon: HomeOutlined,
      title: 'Inmuebles Urbanos',
      subMenu: [
        {
          path: 'inmueblesUrbanos/registrar',
          exact: false,
          title: 'Registrar Inmueble',
          component: RegisterProperty,
        },
        {
          path: 'inmueblesUrbanos/editar',
          exact: false,
          title: 'Editar Inmueble',
          component: RegisterProperty,
        },
      ],
    };

    switch (user.institucion.cargo.id) {
      case CargoCpu.Administrador:
        routes.push();
        break;
      case CargoCpu.AnalistaValuacionFiscal:
        routes.push(inmueblesUrbanos);
        break;
      case CargoCpu.JefeValuacionFiscal:
        routes.push(inmueblesUrbanos, {
          path: 'finanzas/valoresFiscales',
          exact: true,
          icon: BankOutlined,
          title: 'Valores Fiscales',
          component: TableFiscalValue,
        });
        break;
      case CargoCpu.JefeCodigoCatastral:
        break;
      case CargoCpu.AnalistaCodigoCatastral:
        // const instCpu = institutions.findIndex((i) => i.nombreCorto === 'CPU');
        // const codCat = institutions[instCpu]?.tramitesDisponibles?.filter((t) => t.titulo.startsWith('Codigo Catastral'));
        // routes.push(
        //   {
        //     path: '/',
        //     exact: true,
        //     title: 'Codigo Catastral',
        //     subMenu: [
        //       {
        //         path: `tramite/${institutions[instCpu]?.id}/${codCat ? codCat[0].id : 0}`,
        //         exact: true,
        //         title: codCat ? (codCat[0].titulo.includes('para Casas') ? 'Para Casas' : 'Para Apartamentos') : 'XD',
        //       },
        //       {
        //         path: `tramite/${institutions[instCpu]?.id}/${codCat ? codCat[1].id : 0}`,
        //         exact: true,
        //         title: codCat ? (codCat[1].titulo.includes('para Casas') ? 'Para Casas' : 'Para Apartamentos') : 'XD',
        //       },
        //     ],
        //   },
        // );
        break;
      case CargoCpu.AnalistaNomenclatura:
        // const instCpu2 = institutions.findIndex((i) => i.nombreCorto === 'CPU');
        // const nom = institutions[instCpu2]?.tramitesDisponibles?.filter((t) => t.titulo.startsWith('Constancia de Nomenclatura'));
        // routes.push(
        //   {
        //     path: '/',
        //     exact: true,
        //     title: 'Nomenclatura',
        //     subMenu: [
        //       {
        //         path: `tramite/${institutions[instCpu2]?.id}/${nom ? nom[0].id : 0}`,
        //         exact: true,
        //         title: nom ? nom[0].titulo : 'XD',
        //       },
        //     ],
        //   },
        // );
        break;
      case CargoCpu.JefeNomenclatura:
        break;
    }
  }
  //FIN MENU CPU

  if (user.tipoUsuario === UserType.OfficialAdmin && user.institucion.nombreCorto === 'SAGAS') {
    // routes.push({
    //   path: 'serviciosMunicipales',
    //   exact: true,
    //   icon: FireOutlined,
    //   title: 'Servicios Municipales',
    //   subMenu: [
    //     {
    //       path: 'serviciosMunicipales/contribuyente',
    //       exact: true,
    //       title: 'Servicios Municipales por Contribuyente',
    //       component: TaxpayerServices,
    //     },
    //   ],
    // });
    routes.push({
      path: 'liquidaciones/SAGAS',
      exact: true,
      icon: FileOutlined,
      title: 'Liquidaciones de Gas',
      component: SMSettlements,
    });
  }

  if (user.tipoUsuario === UserType.OfficialAdmin && user.institucion.nombreCorto === 'IMAU') {
    routes.push({
      path: 'liquidaciones/IMAU',
      exact: true,
      icon: FileOutlined,
      title: 'Liquidaciones de Aseo',
      component: SMSettlements,
    });
  }

  if (
    (user.tipoUsuario === UserType.OfficialAdmin || user.tipoUsuario === UserType.OfficialChief) &&
    user.institucion.nombreCorto === 'CPU'
  ) {
    const subMenu: RouteI[] = [
      {
        path: 'liquidaciones/CPU',
        exact: true,
        title: 'Liquidaciones de CPU',
        component: SMSettlements,
      },
    ];

    if (user.tipoUsuario === UserType.OfficialAdmin) {
      subMenu.push({
        path: ['impuestos/especiales', 'impuestos/especiales/:id'],
        exact: true,
        title: 'Liquidaciones de Timbres y/o Tasas',
        component: SpecialSettlements,
      });
    }

    // routes.push({
    //   path: 'procesosDirectivos',
    //   exact: false,
    //   icon: ReconciliationOutlined,
    //   title: 'Procesos Directivos',
    //   subMenu,
    // });
  }

  if (user.tipoUsuario === UserType.External) {
    const inst: RouteI[] = institutions
      .filter(
        (i) =>
          i.nombreCorto !== 'ABMM' &&
          i.nombreCorto !== 'PMM' &&
          i.nombreCorto !== 'IMA' &&
          i.nombreCorto !== 'SEDETEMA' &&
          i.nombreCorto !== 'SEDEMAT'
      )
      .map((i) => ({
        path: '/',
        exact: true,
        short: i.nombreCorto,
        title: i.nombreCompleto,
        subMenu: i.tramitesDisponibles
          ?.filter((t) => !t.titulo.includes('Permiso de Habitabilidad'))
          .filter((t) => !t.titulo.includes('Permiso de Condiciones Habitables'))
          .filter((t) => !t.titulo.includes('Codigo Catastral'))
          .filter((t) => !t.titulo.includes('Conformidad de la Edificación y Uso'))
          .map((t) => ({
            path: `tramite/${i.id}/${t.id}`,
            exact: true,
            title: t.titulo,
          })),
      }));

    const subMenuSedemat = [
      {
        path: '/',
        exact: true,
        title: 'Licencia de Actividades Económicas',
        icon: FolderAddOutlined,
        subMenu: [
          // {
          //   path: 'tramite/9/28',
          //   exact: true,
          //   title: 'Solicitud de Licencia Permanente',
          // },
          // {
          //   path: 'tramite/9/36',
          //   exact: true,
          //   title: 'Solicitud de Licencia Temporal',
          // },
        ],
      },
      // {
      //   path: 'contrib/consultar',
      //   exact: true,
      //   title: 'Consulta de Contribuyente',
      //   icon: UserOutlined,
      // },
      {
        path: 'impuestos/declarar',
        exact: true,
        title: 'Declarar Impuestos',
        icon: FileAddOutlined,
      },
      // {
      //   path: 'impuestos/sustitutiva',
      //   exact: true,
      //   title: 'Declaración Sustitutiva',
      //   icon: SwapOutlined,
      // },
      // {
      //   path: 'impuestos/complementaria',
      //   exact: true,
      //   title: 'Declaración Complementaria',
      //   icon: AppstoreAddOutlined,
      // },
      {
        path: 'impuestos/pagar',
        exact: true,
        title: 'Pagar Impuestos',
        icon: CreditCardFilled,
      },
      {
        path: 'impuestos/convenios',
        exact: false,
        title: 'Convenios',
        icon: CreditCardFilled,
      },
      {
        path: 'libro',
        exact: true,
        title: 'Actualizar Libro de Timbres',
        icon: CreditCardFilled,
      },
      // {
      //   path: 'impuestos/retenciones/consulta',
      //   exact: true,
      //   title: 'Consulta de Retenciones',
      //   icon: FolderViewOutlined,
      // },
      // {
      //   path: 'impuestos/convenios',
      //   exact: true,
      //   title: 'Convenios',
      //   icon: ScheduleOutlined,
      // },
      // {
      //   path: 'impuestos/vehiculos',
      //   exact: true,
      //   title: 'Vehículos',
      //   icon: CarOutlined,
      // },
      // {
      //   path: 'inmuebles/documentos/cargar',
      //   exact: true,
      //   title: 'Cargar documentos de Inmueble',
      //   icon: HomeOutlined,
      // },
    ];

    let instNew: RouteI[] = [
      {
        path: '/',
        exact: true,
        short: 'SAT',
        title: 'Servicio De Administración Tributaria Del Estado Anzoátegui',
        subMenu: [
          // {
          //   path: 'impuestos/enlazar',
          //   exact: true,
          //   title: 'Enlazar cuenta de SEDEMAT',
          //   icon: LinkOutlined
          // },
          !user?.contribuyente 
          ? {
              path: 'tramite/9/27',
              exact: true,
              title: 'Completar Registro',
              icon: UserAddOutlined,
            }
          : {
            path: '',
            exact: false,
            title: '',
            icon: LayoutFilled,
            },
          {
            path: 'contribuyente/registroSucursal',
            exact: true,
            title: 'Registro Sucursal',
            icon: UsergroupAddOutlined,
            component: InternalBranchRegister,
          },
          {
            path: ['impuestos/especiales', 'impuestos/especiales/:id'],
            exact: true,
            title: 'Liquidaciones de Timbres y/o Tasas',
            component: SpecialSettlements,
            icon: FileAddOutlined,
          },
          {
            path: 'impuestos/pagar',
            exact: true,
            title: 'Pagar Impuestos',
            icon: CreditCardFilled,
          },
          {
            path: 'impuestos/convenios',
            exact: false,
            title: 'Convenios',
            icon: CreditCardFilled,
          },
          user.contribuyente?.esAgenteRetencion === true ? {
            path: 'impuestos/declarar',
            exact: false,
            title: 'Declarar Impuesto 1x1000',
            icon: LayoutFilled,
          } : {
            path: '',
            exact: false,
            title: '',
            icon: LayoutFilled,
          },
          !user.contribuyente?.esAgenteRetencion ? {
            path: 'libro',
            exact: true,
            title: 'Actualizar Libro de Timbres',
            component: BookUpload,
            icon: FileAddOutlined,
          } : {
            path: '',
            exact: false,
            title: '',
            icon: LayoutFilled,
          },
          !user.contribuyente?.esAgenteRetencion ? {
            path: 'descargar/libro',
            exact: true,
            title: 'Descargar Libro de Timbres Autogenerado',
            component: BookDownload,
            icon: FileAddOutlined,
          } : {
            path: '',
            exact: false,
            title: '',
            icon: LayoutFilled,
          },
          {
            path: 'descargar/comprobante/registro',
            exact: true,
            title: 'Descargar Comprobante de Registro',
            component: VoucherDownload,
            icon: FileAddOutlined,
          },
          // {
          //   path: 'impuestos/vehiculos',
          //   exact: true,
          //   title: 'Vehículos',
          //   icon: CarOutlined,
          // },
        ],
        // subMenu: [
        //   {
        //     path: 'mantenimiento/mantenimiento',
        //     exact: true,
        //     title: 'Mantenimiento',
        //   },
        // ],
      },
    ];
    instNew.push(
      {
        path: ['impuestos/especiales', 'impuestos/especiales/:id'],
        exact: true,
        title: '',
        component: SpecialSettlements,
      },
      {
        path: 'impuestos/declarar',
        exact: false,
        title: '',
        component: DeclareAnyTaxes,
      },
      {
        path: 'impuestos/convenios',
        exact: false,
        title: '',
        component: AgreementTaxPayer,
      },
      {
        path: 'libro',
        exact: true,
        title: '',
        component: BookUpload,
      },
      {
        path: 'descargar/libro',
        exact: true,
        title: '',
        component: BookDownload,
      },
      {
        path: 'descargar/comprobante/registro',
        exact: true,
        title: '',
        component: VoucherDownload,
      },
      {
        path: 'contribuyente/registroSucursal',
        exact: true,
        title: '',
        component: InternalBranchRegister,
      },

    );

    // if (user.contribuyente?.esAgenteRetencion)
    //   subMenuSedemat.unshift({
    //     path: 'impuestos/retenciones',
    //     exact: true,
    //     title: 'Declarar Retenciones',
    //     icon: PercentageOutlined,
    //   });
    // if (!!user.contribuyente)
    //   subMenuSedemat.push({
    //     path: 'reportes/7',
    //     exact: false,
    //     title: 'Reporte de Cierre Definitivo',
    //     icon: BarChartOutlined,
    //   });
    // inst.push({
    //   path: '/',
    //   exact: true,
    //   short: 'SEDEMAT',
    //   title: 'Servicio Desconcentrado Municipal de Administración Tributaria',
    //   subMenu: user.contribuyente?.verificacionTelefono
    //     ? subMenuSedemat
    //     : [
    //         // {
    //         //   path: 'impuestos/enlazar',
    //         //   exact: true,
    //         //   title: 'Enlazar cuenta de SEDEMAT',
    //         //   icon: LinkOutlined
    //         // },
    //         {
    //           path: 'tramite/9/27',
    //           exact: true,
    //           title: 'Registro de Contribuyente',
    //           icon: UserAddOutlined,
    //         },
    //         // {
    //         //   path: 'impuestos/vehiculos',
    //         //   exact: true,
    //         //   title: 'Vehículos',
    //         //   icon: CarOutlined,
    //         // },
    //       ],
    //   // subMenu: [
    //   //   {
    //   //     path: 'mantenimiento/mantenimiento',
    //   //     exact: true,
    //   //     title: 'Mantenimiento',
    //   //   },
    //   // ],
    // });

    // const instSagas = institutions.findIndex((i) => i.nombreCorto === 'SAGAS');
    // const permisoHab = institutions[instSagas]?.tramitesDisponibles?.filter((t) =>
    //   t.titulo.startsWith('Permiso de Habitabilidad')
    // );
    // inst[instSagas]?.subMenu?.push(
    //   ...[
    //     {
    //       path: '/',
    //       exact: true,
    //       title: 'Permiso de Habitabilidad',
    //       subMenu: [
    //         {
    //           path: `tramite/${institutions[instSagas]?.id}/${permisoHab ? permisoHab[0].id : 0}`,
    //           exact: true,
    //           title: permisoHab
    //             ? permisoHab[0].titulo.includes('con Instalaciones')
    //               ? 'Con Instalación de Servicio de Gas'
    //               : 'Sin Instalación de Servicio de Gas'
    //             : 'XD',
    //         },
    //         {
    //           path: `tramite/${institutions[instSagas]?.id}/${permisoHab ? permisoHab[1].id : 0}`,
    //           exact: true,
    //           title: permisoHab
    //             ? permisoHab[1].titulo.includes('con Instalaciones')
    //               ? 'Con Instalación de Servicio de Gas'
    //               : 'Sin Instalación de Servicio de Gas'
    //             : 'XD',
    //         },
    //       ],
    //     },
    //   ]
    // );

    // const condHab = institutions
    //   .find((i) => i.nombreCorto === 'SAGAS')
    //   ?.tramitesDisponibles?.filter((t) => t.titulo.startsWith('Permiso de Condiciones Habitables'));
    // inst[instSagas]?.subMenu?.push(
    //   ...[
    //     {
    //       path: '/',
    //       exact: true,
    //       title: 'Permiso de Condiciones Habitables',
    //       subMenu: [
    //         {
    //           path: `tramite/${institutions[instSagas]?.id}/${condHab ? condHab[0].id : 0}`,
    //           exact: true,
    //           title: condHab
    //             ? condHab[0].titulo.includes('con Instalaciones')
    //               ? 'Con Instalación de Servicio de Gas'
    //               : 'Sin Instalación de Servicio de Gas'
    //             : 'XD',
    //         },
    //         {
    //           path: `tramite/${institutions[instSagas]?.id}/${condHab ? condHab[1].id : 0}`,
    //           exact: true,
    //           title: condHab
    //             ? condHab[1].titulo.includes('con Instalaciones')
    //               ? 'Con Instalación de Servicio de Gas'
    //               : 'Sin Instalación de Servicio de Gas'
    //             : 'XD',
    //         },
    //       ],
    //     },
    //   ]
    // );
    // const instCpu = institutions.findIndex((i) => i.nombreCorto === 'CPU');
    // const codCat = institutions[instCpu]?.tramitesDisponibles?.filter((t) => t.titulo.startsWith('Codigo Catastral'));
    // inst[instCpu]?.subMenu?.push(
    //   ...[
    //     {
    //       path: '/',
    //       exact: true,
    //       title: 'Codigo Catastral',
    //       subMenu: [
    //         {
    //           path: `tramite/${institutions[instCpu]?.id}/${codCat ? codCat[0].id : 0}`,
    //           exact: true,
    //           title: codCat ? (codCat[0].titulo.includes('para Casas') ? 'Para Casas' : 'Para Apartamentos') : 'XD',
    //         },
    //         {
    //           path: `tramite/${institutions[instCpu]?.id}/${codCat ? codCat[1].id : 0}`,
    //           exact: true,
    //           title: codCat ? (codCat[1].titulo.includes('para Casas') ? 'Para Casas' : 'Para Apartamentos') : 'XD',
    //         },
    //       ],
    //     },
    //   ]
    // );

    // const usoConforme = institutions[instCpu]?.tramitesDisponibles?.filter((t) =>
    //   t.titulo.startsWith('Conformidad de la Edificación y Uso')
    // );
    // inst[instCpu]?.subMenu?.push(
    //   ...[
    //     {
    //       path: '/',
    //       exact: true,
    //       title: 'Conformidad de la Edificación y Uso',
    //       subMenu: [
    //         {
    //           path: `tramite/${institutions[instCpu]?.id}/${usoConforme ? usoConforme[0].id : 0}`,
    //           exact: true,
    //           title: usoConforme
    //             ? usoConforme[0].titulo.includes('Industriales')
    //               ? 'Actividades Económicas Comerciales e Industriales'
    //               : usoConforme[0].titulo.includes('Educativas')
    //               ? 'Unidades Educativas'
    //               : 'Locales en Centros Comerciales'
    //             : 'XD',
    //         },
    //         {
    //           path: `tramite/${institutions[instCpu]?.id}/${usoConforme ? usoConforme[1].id : 0}`,
    //           exact: true,
    //           title: usoConforme
    //             ? usoConforme[1].titulo.includes('Industriales')
    //               ? 'Actividades Económicas Comerciales e Industriales'
    //               : usoConforme[1].titulo.includes('Educativas')
    //               ? 'Unidades Educativas'
    //               : 'Locales en Centros Comerciales'
    //             : 'XD',
    //         },
    //         {
    //           path: `tramite/${institutions[instCpu]?.id}/${usoConforme ? usoConforme[2].id : 0}`,
    //           exact: true,
    //           title: usoConforme
    //             ? usoConforme[2].titulo.includes('Industriales')
    //               ? 'Actividades Económicas Comerciales e Industriales'
    //               : usoConforme[2].titulo.includes('Educativas')
    //               ? 'Unidades Educativas'
    //               : 'Locales en Centros Comerciales'
    //             : 'XD',
    //         },
    //       ],
    //     },
    //   ]
    // );
    // instNew.push(inst[instCpu]);
    routes.push(...instNew);
  }

  if (
    user.tipoUsuario === UserType.OfficialAdmin ||
    user.tipoUsuario === UserType.SuperUser ||
    user.tipoUsuario === UserType.OfficialChief ||
    user.cuentaFuncionario?.id_cargo === CargoSedemat.FunSoporte
  ) {
    routes.unshift({
      path: 'funcionarios',
      exact: false,
      icon: TeamOutlined,
      title: 'Administrar Usuarios',
      component: TableOfficials,
    });
  }

  if (user.institucion && user.institucion.id === 0 && user.tipoUsuario === UserType.OfficialAdmin) {
    routes.push({
      path: ['casosSociales', 'casosSociales/:id'],
      exact: true,
      title: 'Casos Sociales',
      icon: FolderOpenFilled,
      component: AffairsForm,
    });
  }

  if (user.institucion && user.institucion.nombreCorto === 'SEDETEMA' && user.tipoUsuario === UserType.Official) {
    routes.push({
      path: 'tasaDeSalida',
      exact: true,
      title: 'Tasa de Salida',
      icon: FolderOpenFilled,
      component: OfficialPFForm,
    });
  }

  //Rutas para bandeja de multas 6 = Ima y 7 = POLICIA
  if (user.institucion && (user.institucion.id === 6 || user.institucion.id === 7)) {
    if (user.institucion?.cargo?.id !== 52) {
      routes.push({
        path: 'crearMulta',
        exact: false,
        title: 'Crear Multa',
        icon: AlertOutlined,
        component: user.institucion.id === 7 ? PoliceTicketForm : IMATicketForm,
      });
    }
  }

  if (user.institucion?.id === 7 && user.institucion?.cargo?.id !== 51) {
    routes.push({
      path: 'bandejas',
      exact: false,
      icon: InboxOutlined,
      title: 'Bandejas',
      component: TableProcedure,
    });
  }

  if (user.institucion?.id !== 6 && user.institucion?.id !== 7 && user.institucion?.id !== 9) {
    routes.push({
      path: 'bandejas',
      exact: false,
      icon: InboxOutlined,
      title: 'Bandejas',
      component: TableProcedure,
    });
  }

  return routes;
};
