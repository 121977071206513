import { States } from "sigt";
import { SET_UT, UTType } from "../actions/actionsTypes";

const initialState: States.UT = {
  ut: 0
};

export default (state = initialState, action: UTType): States.UT => {
  switch(action.type) {
    case SET_UT: return { ut: action.payload }
    default: return state;
  };
};