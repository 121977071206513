
const username = (rule, value, callback) =>
  value && !/^[a-z0-9._]{6,16}$/i.test(value)
    ? Promise.reject("Solo se permiten letras, numeros, puntos ( . ) y piso ( _ ).")
    : Promise.resolve();

const email = (rule, value, callback) =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? Promise.reject("Correo invalido.")
    : Promise.resolve();

const cedula = (rule, value, callback) =>
  value && !/([0-9])([0-9]{5,10}$)/i.test(value)
    ? Promise.reject("Formato invalido. Ej: V-123455")
    : Promise.resolve();

const rif = (rule, value, callback) => { 
  const isRif = /^[0-9]+-[0-9]/
  return value && !(isRif.test(value))
  ? Promise.reject("Formato invalido. Ej: J-11160194-0")
  : Promise.resolve();
}
const password = (rule, value, callback) =>{ 
  const isValid = /^(?=.*[a-zA-Z])(?=.*[\d]).{8,}$/g;
  return value && !(isValid.test(value))
  ? Promise.reject("Debe tener minimo una letra, un numero y 8 caracteres")
  : Promise.resolve();
}

const phoneNumber = (rule, value, callback) => {
  return value && !/([0-9]{10,11}$)/i.test(value)
  ? Promise.reject("Formato invalido. Ej: 4246170000")
  : Promise.resolve();
}

const isNumber = (value, lastValue) => {
  const isNumber = /^[0-9]+$/;
  return (isNumber.test(value) && !value.startsWith(0)) || value === '' ? value : lastValue || ''
}

const isCNumber = (value, lastValue) => {
  const isCNumber = /^[0-9]+$/;
  return (isCNumber.test(value)) || value === '' ? value : lastValue || ''
}

const isMoney = (value, lastValue) => (value !== '' || value === '') ? value.replace(/\D/g, "")
  .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ","): lastValue || '';

const isRif = (value, lastValue) => {
  if(value || value === ''){
    return (value.length === 9 && !value.includes('-')) ? `${value.slice(0,8)}-${value.slice(8,9)}`: value;
  } else {
    return lastValue
  }
}

const isDoc = (value, lastValue) => {
  const isNumber = /^[0-9]+$/;
  return (isNumber.test(value)) || value === '' ? value : lastValue || ''
}

const notDotTab = (value:string) => value ? value.replace(/[\s\t]/g,'') : undefined;

const toUpper = (value, lastValue)=> value.toLocaleUpperCase().trim();

const toUpperName = (value, lastValue)=> value.toLocaleUpperCase();

const toLower = (value, lastValue)=> value.toLocaleLowerCase().trim();

export const Utils = {
  validators: { username , email, cedula, phoneNumber, rif, password },
  normalize: { isNumber, isCNumber, isRif, isMoney, toUpper, isDoc, notDotTab, toLower, toUpperName }
}


    