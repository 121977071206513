import { Utils } from './validators';
import { Input, Select, DatePicker, Checkbox, Switch, Tooltip } from "antd";
import { InputProps, TextAreaProps } from "antd/lib/input";
import { SelectProps } from "antd/lib/select";
import { FormItemProps } from 'antd/lib/form/FormItem';
import { UserOutlined } from '@ant-design/icons';
import { AntdIconProps } from '@ant-design/icons/lib/components/AntdIcon';
import { DatePickerProps } from 'antd/lib/date-picker';
import moment from 'moment';
import OwnersFormList, { OwnersFormListProps } from '../components/Forms/components/OwnersFormList';
import ParishSelect from '../components/Forms/components/ParishSelect';
import FileUpload, { FileUploadProps } from '../components/Forms/components/FileUpload';
import SolvencyEstateType from '../components/Forms/components/SolvencyEstateType';
import SimpleEstimation from '../components/Forms/components/SimpleEstimation';
import SocietyTypeSelect from '../components/Forms/components/SocietyTypeSelect';
import VehicleTypeSelect from '../components/Forms/components/VehicleTypeSelect';
import PurposeSelect from '../components/Forms/components/PurposeSelect';
import NomenclatureCode from '../components/Forms/components/NomenclatureCode';
import ShiftSelect from '../components/Forms/components/ShiftSelect';
import DistroFormList from '../components/Forms/components/DistroFormList';
import TaxpayerTypeSelect from '../components/Forms/components/TaxpayerTypeSelect';
import EconomicActivities from '../components/Forms/components/EconomicActivities';
import TaxpayerSocietyType from '../components/Forms/components/TaxpayerSocietyType';
import LicenseTypeSelect from '../components/Forms/components/LicenseTypeSelect';
import MonotaxSwitch from '../components/Forms/components/MonotaxSwitch';
import ProcessSelect from '../components/Forms/components/ProcessSelect';
import { TooltipProps } from 'antd/lib/tooltip';
import AgentSelect from '../components/Forms/components/AgentSelect';
import EntitySelect from '../components/Forms/components/EntitySelect';
import SectorSelect from '../components/Forms/components/SectorSelect';
import DateDeclareSelect from '../components/Forms/components/DateDeclareSelect';
import MonthDeclareSelect from '../components/Forms/components/MonthDeclareSelect';

export const getValidators = (id: string, tipo: number, prcd?: number, natural?: boolean) => validations[id](tipo, prcd, natural);

const validations: Validations<Partial<FormItemProps>> = {
  cedula: (tipo) => ({
    validations: {
      rules: [
        { required: true, message: 'Por favor ingresa tu cedula' },
        { validator: Utils.validators.cedula }
      ],
      normalize: Utils.normalize.isCNumber
    },
    extension: {
      Component: Input,
      props: { placeholder: 'Cedula', maxLength: 10, readOnly: tipo !== 4 } as InputProps,
      icon: UserOutlined,
      addonBefore: {
        Component: Select,
        props: { disabled: tipo !== 4 } as InputProps,
        name: 'nacionalidad',
        options: ['V', 'E']
      }
    }
  }),
  nombre: (tipo) => ({
    validations: {
      rules: [
        { required: true, message: 'Por favor ingresa tu nombre' }
      ],
    },
    extension: {
      Component: Input,
      props: { placeholder: 'Nombre', readOnly: tipo !== 4 } as InputProps
    }
  }),
  cedulaORif: (tipo) => ({
    validations: {
      rules: [
        { required: true, message: 'Por favor ingresa el RIF o numero de cedula' },
        { validator: Utils.validators.cedula }
      ],
      normalize: Utils.normalize.isCNumber
    },
    extension: {
      Component: Input,
      props: { placeholder: 'Cedula o RIF', maxLength: 10, readOnly: tipo !== 4 } as InputProps,
      icon: UserOutlined,
      addonBefore: {
        Component: Select,
        props: {} as InputProps,
        name: 'prefix',
        options: ['V', 'E', 'J', 'G', 'C']
      }
    }
  }),
  tipoProceso: (tipo) => ({
    validations: {
      rules: [
        { required: true, message: 'Por favor especifique el tipo de proceso a realizar' },
      ],
    },
    extension: {
      Component: ProcessSelect,
      props: { } as InputProps,
    }
  }),
  nombreORazon: () => ({
    validations: {
      rules: [
        { required: true, message: 'Por favor ingresa el nombre o razon social' }
      ],
    },
    extension: {
      Component: Input,
      props: { placeholder: 'Nombre o Razon Social', style:{textTransform:'uppercase'} } as InputProps
    }
  }),
  direccion: () => ({
    validations: {
      rules: [
        { required: true, message: 'Por favor ingresa la direccion' }
      ],
    },
    extension: {
      Component: Input,
      props: { placeholder: 'Direccion' } as InputProps
    }
  }),
  puntoReferencia: () => ({
    validations: {
      rules: [
        { required: true, message: 'Por favor ingresa el punto de referencia' }
      ],
    },
    extension: {
      Component: Input,
      props: { placeholder: 'Punto de Referencia' } as InputProps
    }
  }), 
  sector: () => ({
    validations: {
      rules: [
        { required: true, message: 'Por favor ingresa el sector' }
      ],
    },
    extension: {
      Component: Input,
      props: { placeholder: 'Sector' } as InputProps
    }
  }),
  parroquia: () => ({
    validations: {
      rules: [
        { required: true, message: 'Por favor ingresa el municipio' }
      ],
    },
    extension: {
      Component: ParishSelect,
      props: { placeholder: 'Municipio' } as SelectProps<any>
    }
  }),
  esAgente: () => ({
    validations: {
      rules: [
        { required: true, message: 'Por favor seleccione si es agente de retención' }
      ],
    },
    extension: {
      Component: AgentSelect,
      props: { placeholder: '' } as SelectProps<any>
    }
  }),
  tipoEntidad: () => ({
    validations: {
      rules: [
        { required: true, message: 'Por favor seleccione el tipo de entidad.' }
      ],
    },
    extension: {
      Component: EntitySelect,
      props: { placeholder: '' } as SelectProps<any>
    }
  }),
  sectorClasificado: () => ({
    validations: {
      rules: [
        { required: true, message: 'Por favor seleccione el sector que corresponda.' }
      ],
    },
    extension: {
      Component: SectorSelect,
      props: { placeholder: '' } as SelectProps<any>
    }
  }),
  fechaDeclaracion: () => ({
    validations: {
      rules: [
        { required: true, message: 'Por favor seleccione el año desde la cual debe presentar las declaraciones.' }
      ],
    },
    extension: {
      Component: DateDeclareSelect,
      props: { placeholder: '' } as SelectProps<any>
    }
  }),
  mesDeclaracion: () => ({
    validations: {
      rules: [
        { required: true, message: 'Por favor seleccione el mes desde la cual debe presentar las declaraciones.' }
      ],
    },
    extension: {
      Component: MonthDeclareSelect,
      props: { placeholder: '' } as SelectProps<any>
    }
  }),
  metrosCuadrados: () => ({
    validations: {
      rules: [
        { required: true, message: 'Por favor ingresa los metros cuadrados' }
      ]
    },
    extension: {
      Component: Input,
      props: { placeholder: 'Metros Cuadrados', maxLength: 6, type: 'number' } as InputProps
    }
  }),
  telefono: (tipo) => ({
    validations: {
      rules: [
        { required: true, message: 'Por favor ingresa el numero de telefono' },
        { validator: Utils.validators.phoneNumber }
      ],
      normalize: Utils.normalize.isNumber
    },
    extension: {
      Component: Input,
      props: { placeholder: 'Telefono', readOnly: tipo !== 4 } as InputProps
    }
  }),
  correo: (tipo) => ({
    validations: {
      rules: [
        { required: true, message: 'Por favor ingresa el correo electronico' },
        { validator: Utils.validators.email }
      ],
    },
    extension: {
      Component: Input,
      props: { placeholder: 'Correo Electronico', readOnly: tipo !== 4 } as InputProps
    }
  }),
  contacto: () => ({
    validations: {
      rules: [
        { required: true, message: 'Por favor ingresa el nombre del contacto' }
      ],
    },
    extension: {
      Component: Input,
      props: { placeholder: 'Contacto' } as InputProps
    }
  }),
  codigoTramite: () => ({
    validations: {
      rules: [
        {required: true, message: 'Por favor ingresar el código del trámite'}
      ]
    },
    extension: {
      Component: Input,
      props: { placeholder: 'Ej: CPU-0000-0000'} as InputProps
    }
  }),
  horario: () => ({
    validations: {
      rules: [
        { required: true, message: 'Por favor ingresa el horario' }
      ],
    },
    extension: {
      Component: Input,
      props: { placeholder: 'Horario' } as InputProps
    }
  }),
  ubicadoEn: () => ({
    validations: {
      rules: [
        { required: true, message: 'Por favor ingresa la ubicacion' }
      ],
    },
    extension: {
      Component: Input,
      props: { placeholder: 'Ubicado en' } as InputProps
    }
  }),
  rif: () => ({
    validations: {
      rules: [
        { required: true, message: 'Por favor ingresa el RIF' },
        { validator: Utils.validators.rif }
      ],
      normalize: Utils.normalize.isRif
    },
    extension: {
      Component: Input,
      props: { placeholder: 'RIF', maxLength: 10 } as InputProps,
      icon: UserOutlined,
      addonBefore: {
        Component: Select,
        props: {},
        name: 'prefix',
        options: ['V', 'E', 'J', 'G', 'C']
      }
    }
  }),
  razonSocial: (a,b,natural) => ({
    validations: {
      rules: [
        natural ? { required: false } : { required: true, message: 'Por favor ingresa la razon social' }
      ]
    },
    extension: {
      Component: Input,
      props: { placeholder: 'Razon Social', style:{textTransform:'uppercase'} } as InputProps
    }
  }),
  razonSocialO: () => ({
    validations: {
      rules: [
        { required: false }
      ],
    },
    extension: {
      Component: Input,
      props: { placeholder: 'Razon Social', style:{textTransform:'uppercase'} } as InputProps
    }
  }),
  tipoOcupacion: () => ({
    validations: {
      rules: [
        { required: true, message: 'Por favor ingresa el tipo de ocupacion' }
      ],
    },
    extension: {
      Component: Input,
      props: { placeholder: 'Tipo de Ocupacion' } as InputProps
    }
  }),
  areaConstruccion: (_, id) => ({
    validations: {
      rules: [
        { required: true, message: 'Por favor ingresa el area de construcción' }
      ],
    },
    extension: {
      Component: Input,
      props: { placeholder: 'Area', readOnly: id !== 14 && id !== 15, type: 'number' } as InputProps
    }
  }),
  numeroProyecto: () => ({
    validations: {
      rules: [
        { required: true, message: 'Por favor ingresa el numero de proyecto' }
      ],
      normalize: Utils.normalize.isNumber
    },
    extension: {
      Component: Input,
      props: { placeholder: 'Numero de Proyecto', maxLength: 10 } as InputProps
    }
  }),
  fechaAprobacion: () => ({
    validations: {
      rules: [
        { required: true, message: 'Por favor ingresa el numero de proyecto' }
      ]
    },
    extension: {
      Component: DatePicker,
      props: { style: { width: '100%' }, disabledDate: (current) => current > moment().endOf('day') } as DatePickerProps
    }
  }),
  nombreObra: () => ({
    validations: {
      rules: [
        {  required: true, message: 'Por favor ingrese el nombre de la obra' }
      ]
    },
    extension: {
      Component: Input,
      props: { placeholder: 'Nombre de la Obra' } as InputProps
    }
  }),
  observaciones: (tipo) => ({
    validations: {
      rules: tipo !== 4 ? [
        { required: false, message: 'Por favor ingrese las observaciones del tramite' }
      ] : undefined
    },
    extension: {
      Component: Input.TextArea,
      props: { placeholder: 'Observaciones', style: { resize: 'none' } } as TextAreaProps
    }
  }),
  observacionProceso: () => ({
    validations: {
      rules:[
        { required: false }
      ] 
    },
    extension: {
      Component: Input.TextArea,
      props: { placeholder: 'Observaciones', style: { resize: 'none' } } as TextAreaProps
    }
  }),
  informe: () => ({
    validations: {},
    extension: {
      Component: Input,
      props: { style: { height: '200px', marginBottom: '50px' } },
      rte: true,
      valuePropName: 'x'
    }
  }),
  codigoPermisoConstruccion: () => ({
    validations: {
      rules: [
        { required: true, message: 'Por favor ingrese el codigo del permiso de construccion' }
      ]
    },
    extension: {
      Component: Input,
      props: { placeholder: 'Codigo de Permiso de Construccion' } as InputProps
    }
  }),
  fechaPermisoConstruccion: () => ({
    validations: {
      rules: [
        { required: true, message: 'Por favor ingrese la fecha del permiso de construccion' }
      ]
    },
    extension: {
      Component: DatePicker,
      props: { style: { width: '100%' }, disabledDate: (current) => current > moment().endOf('day') } as DatePickerProps
    }
  }),
  aforo: () => ({
    validations: {
      rules: [ 
        { required: true, message: 'Por favor ingrese el aforo' }
      ]
    },
    extension: {
      Component: Input,
      props: { placeholder: 'Aforo' } as InputProps
    }
  }),
  propietarios: () => ({
    validations: {
      rules: [
        { required: true, message: 'Debe ingresar los propietarios' }
      ]
    },
    extension: {
      Component: OwnersFormList,
      props: { name: 'propietarios' } as OwnersFormListProps
    }
  }),
  tipoInmueble: () => ({
    validations: {
      rules: [
        { required: true, message: 'Debe ingresar el tipo de inmueble' }
      ]
    },
    extension: {
      Component: Input,
      props: { placeholder: 'Tipo de Inmueble' } as InputProps
    }
  }),
  parroquiaEdificio: () => ({
    validations: {
      rules: [
        { required: true, message: 'Debe ingresar el municipio' }
      ]
    },
    extension: {
      Component: ParishSelect,
      props: {}
    }
  }),
  nombreConjunto: () => ({
    validations: {
      rules: [
        { required: true, message: 'Debe ingresar el nombre del conjunto residencial' }
      ]
    },
    extension: {
      Component: Input,
      props: { placeholder: 'Nombre del Conjunto Residencial' } as InputProps
    }
  }),
  cantidadEdificios: () => ({
    validations: {
      rules: [
        { required: true, message: 'Debe ingresar la cantidad de edificios' }
      ],
      normalize: Utils.normalize.isNumber
    },
    extension: {
      Component: Input,
      props: { placeholder: 'Cantidad de Edificios' } as InputProps
    }
  }),
  nombreEdificio: () => ({
    validations: {
      rules: [
        { required: true, message: 'Debe ingresar el nombre del edificio' }
      ]
    },
    extension: {
      Component: Input,
      props: { placeholder: 'Nombre del Edificio' } as InputProps
    }
  }),
  cantidadPisos: () => ({
    validations: {
      rules: [
        { required: true, message: 'Debe ingresar la cantidad de pisos del edificio' }
      ],
      normalize: Utils.normalize.isNumber
    },
    extension: {
      Component: Input,
      props: { placeholder: 'Cantidad de Pisos' } as InputProps
    }
  }),
  pisoApto: () => ({
    validations: {
      rules: [
        { required: true, message: 'Debe ingresar el piso del apartamento' }
      ],
      normalize: Utils.normalize.isNumber
    },
    extension: {
      Component: Input,
      props: { placeholder: 'Piso donde se encuentra el apartamento' } as InputProps
    }
  }),
  cantidadAptosPiso: () => ({
    validations: {
      rules: [
        { required: true, message: 'Debe ingresar la cantidad de apartamentos en el piso' }
      ],
      normalize: Utils.normalize.isNumber
    },
    extension: {
      Component: Input,
      props: { placeholder: 'Cantidad de Apartamentos en el Piso' } as InputProps
    }
  }),
  numeroApto: () => ({
    validations: {
      rules: [
        { required: true, message: 'Debe ingresar el numero del apartamento' }
      ]
    },
    extension: {
      Component: Input,
      props: { placeholder: 'Numero del Apartamento' } as InputProps
    }
  }),
  nomenclaturaEdificio: () => ({
    validations: {
      rules: [
        { required: true, message: 'Debe ingresar la nomenclatura del edificio' }
      ]
    },
    extension: {
      Component: Input,
      props: { placeholder: 'Nomenclatura del Edificio' } as InputProps
    }
  }),
  ubicacionEdificio: () => ({
    validations: {
      rules: [
        { required: true, message: 'Debe ingresar la ubicacion del edificio' }
      ],
    },
    extension: {
      Component: Input,
      props: { placeholder: 'Ubicacion del Edificio' } as InputProps
    }
  }),
  circuito: () => ({
    validations: {
      rules: [
        { required: true, message: 'Debe ingresar el circuito' }
      ]
    },
    extension: {
      Component: Input,
      props: { placeholder: 'Circuito' } as InputProps
    }
  }),
  datosRegistro: () => ({
    validations: {
      rules: [
        { required: true, message: 'Debe ingresar los datos del registro' }
      ]
    },
    extension: {
      Component: Input,
      props: { placeholder: 'Datos del Registro' } as InputProps
    }
  }),
  areaTerreno: () => ({
    validations: {
      rules: [
        { required: true, message: 'Debe ingresar el area del terreno' }
      ]
    },
    extension: {
      Component: Input,
      props: { placeholder: 'Area del Terreno', type: 'number' } as InputProps
    }
  }),
  plano: () => ({
    validations: {
      rules: [
        { required: true, message: 'Debe ingresar el plano' }
      ]
    },
    extension: {
      Component: Input,
      props: { placeholder: 'Plano' } as InputProps
    }
  }),
  croquis: () => ({
    validations: {
      rules: [
        { required: true, message: 'Debe ingresar el croquis de ubicacion' }
      ]
    },
    extension: {
      Component: FileUpload,
      props: { documentName: 'CROQUIS_UBICACION.png' } as FileUploadProps,
      valuePropName: 'fileList',
      getValueFromEvent: (e) => Array.isArray(e) ? e : e && e.fileList
    }
  }),
  codCat: () => ({
    validations: {
      rules: [
        { required: true, message: 'Debe ingresar el código catastral' }
      ]
    },
    extension: {
      Component: Input,
      props: { placeholder: 'Código Catastral' } as InputProps
    }
  }),
  tipoInmuebleSolvencia: () => ({
    validations: {
      rules: [
        { required: true, message: 'Debe indicar la existencia de terreno o construcciones en el inmueble o parcela' }
      ]
    },
    extension: {
      Component: SolvencyEstateType,
      props: { placeholder: 'Tipo de Inmueble' }
    }
  }),
  estimacionSimple: () => ({
    validations: {
      rules: [
        { required: true, message: 'Debe ingresar los datos de estimación simple' }
      ]
    },
    extension: {
      Component: SimpleEstimation,
      props: {}
    }
  }),
  numeroBohio: (tipo) => ({
    validations: {
      rules: tipo !== 4 ? [{ required: true, message: 'Por favor ingrese el numero de bohío' }] : undefined,
      normalize: Utils.normalize.isNumber
    },
    extension: {
      Component: Input,
      props: { placeholder: 'Numero de Bohío' }
    }
  }),
  detallesBohio: () => ({
    validations: {},
    extension: {
      Component: Input,
      props: { placeholder: 'Detalles del Bohío' }
    }
  }),
  fechaApartado: (tipo) => ({
    validations: {
      rules: [
        { required: true, message: 'Por favor ingrese la fecha del apartado del bohío' }
      ]
    },
    extension: {
      Component: DatePicker,
      props: { style: { width: '100%' }, disabledDate: (current) => current <= moment().startOf('day'), disabled: tipo !== 4 } as DatePickerProps
    }
  }),
  nombreOrganizacion: (tipo) => ({
    validations: {
      rules: [
        { required: true, message: 'Por favor ingrese el nombre de la organización' }
      ]
    },
    extension: {
      Component: Input,
      props: { placeholder: 'Nombre Legal de la Organización', readOnly: tipo !== 4 } as InputProps
    }
  }),
  tipoSociedad: (tipo) => ({
      validations: {
        rules: [
          { required: true, message: 'Por favor ingrese el tipo de sociedad de la organización' }
        ]
      },
      extension: {
        Component: SocietyTypeSelect,
        props: { placeholder: 'Tipo de Sociedad', disabled: tipo !== 4 }
      }
  }),
  tipoTransporte: (tipo) => ({
    validations: {
      rules: [
        { required: true, message: 'Por favor ingrese el tipo de transporte' }
      ]
    },
    extension: {
      Component: VehicleTypeSelect,
      props: { placeholder: 'Tipo de Transporte', disabled: tipo !== 4 }
    }
  }),
  nombreRepresentante: (tipo) => ({
    validations: {
      rules: [
        { required: true, message: 'Por favor ingresa el nombre del representante legal' }
      ],
      normalize: Utils.normalize.toUpper
    },
    extension: {
      Component: Input,
      props: { placeholder: 'Nombre del Representante Legal', readOnly: tipo !== 4 } as InputProps
    }
  }),
  cedulaRepresentante: (tipo) => ({
    validations: {
      rules: [
        { required: true, message: 'Por favor ingresa la cedula del representante legal' },
        { validator: Utils.validators.cedula }
      ],
      normalize: Utils.normalize.isNumber
    },
    extension: {
      Component: Input,
      props: { placeholder: 'Cedula del Representante Legal', maxLength: 10, readOnly: tipo !== 4 } as InputProps,
      icon: UserOutlined,
      addonBefore: {
        Component: Select,
        props: { disabled: tipo !== 4 } as InputProps,
        name: 'nacionalidadRepresentante',
        options: ['V', 'E']
      }
    }
  }),
  telefonoRepresentante: (tipo) => ({
    validations: {
      rules: [
        { required: true, message: 'Por favor ingresa el numero de telefono del representante legal' },
        { validator: Utils.validators.phoneNumber }
      ],
      normalize: Utils.normalize.isNumber
    },
    extension: {
      Component: Input,
      props: { placeholder: 'Telefono del Representante Legal', readOnly: tipo !== 4 } as InputProps
    }
  }),
  finalidad: () => ({
    validations: {
      rules: [
        { required: true, message: 'Por favor ingresa la finalidad de la solicitud' }
      ],
    },
    extension: {
      Component: PurposeSelect,
      props: { placeholder: 'Finalidad de la Solicitud' } as InputProps
    }
  }),
  frente: () => ({
    validations: {},
    extension: {
      Component: Input,
      props: { placeholder: 'Frente del Inmueble/Parcela' } as InputProps
    }
  }),
  linderoFrente: () => ({
    validations: {
      rules: [
        { required: true, message: 'Debe ingresar el lindero del frente' }
      ]
    },
    extension: {
      Component: Input,
      props: { placeholder: 'Frente' } as InputProps
    }
  }),
  linderoFondo: () => ({
    validations: {
      rules: [
        { required: true, message: 'Debe ingresar el lindero del fondo' }
      ]
    },
    extension: {
      Component: Input,
      props: { placeholder: 'Fondo' } as InputProps
    }
  }),
  linderoIzquierda: () => ({
    validations: {
      rules: [
        { required: true, message: 'Debe ingresar el lindero de la izquierda' }
      ]
    },
    extension: {
      Component: Input,
      props: { placeholder: 'Izquierda' } as InputProps
    }
  }),
  linderoDerecha: () => ({
    validations: {
      rules: [
        { required: true, message: 'Debe ingresar el lindero de la derecha' }
      ]
    },
    extension: {
      Component: Input,
      props: { placeholder: 'Derecha' } as InputProps
    }
  }),
  sitio: () => ({
    validations: {
      rules: [
        { required: true, message: 'Debe ingresar el sitio' }
      ]
    },
    extension: {
      Component: Input,
      props: { placeholder: 'Sitio' } as InputProps
    }
  }),
  numeroPlaca: () => ({
    validations: {
      rules: [
        { required: true, message: 'Debe ingresar el numero de placa' }
      ]
    },
    extension: {
      Component: Input,
      props: { placeholder: 'Numero de Placa' } as InputProps
    }
  }),
  codigoNomenclatura: () => ({
    validations: {},
    extension: {
      Component: NomenclatureCode,
      props: {}
    }
  }),
  denominacion: () => ({
    validations: {
      rules: [
        { required: true, message: 'Debe ingresar la denominación o razón social' }
      ],
      normalize: Utils.normalize.toUpper
    },
    extension: {
      Component: Input,
      props: { placeholder: 'Denominación o Razón Social' } as InputProps
    }
  }),
  actividadComercial: () => ({
    validations: {
      rules: [
        { required: true, message: 'Debe ingresar la actividad comercial' }
      ]
    },
    extension: {
      Component: Input,
      props: { placeholder: 'Actividad Comercial' } as InputProps
    }
  }),
  direccionInmueble: () => ({
    validations: {
      rules: [
        { required: true, message: 'Debe ingresar la dirección del inmueble' }
      ]
    },
    extension: {
      Component: Input,
      props: { placeholder: 'Dirección' } as InputProps
    }
  }),
  observacion: () => ({
    validations: {
      rules: [
        { required: false }
      ]
    },
    extension: {
      Component: Input,
      props: { placeholder: 'Observaciones' } as InputProps
    }
  }),
  parroquiaInmueble: () => ({
    validations: {
      rules: [
        { required: true, message: 'Por favor ingresa el municipio' }
      ],
    },
    extension: {
      Component: ParishSelect,
      props: { placeholder: 'Parroquia' } as SelectProps<any>
    }
  }),
  telefonoInmueble: () => ({
    validations: {
      rules: [
        { required: true, message: 'Por favor ingresa el numero de teléfono' },
        { validator: Utils.validators.phoneNumber }
      ],
      normalize: Utils.normalize.isNumber
    },
    extension: {
      Component: Input,
      props: { placeholder: 'Teléfono' } as InputProps
    }
  }),
  correoInmueble: () => ({
    validations: {
      rules: [
        { required: true, message: 'Por favor ingresa el correo electrónico' },
        { validator: Utils.validators.email }
      ],
    },
    extension: {
      Component: Input,
      props: { placeholder: 'Correo Electrónico' } as InputProps
    }
  }),
  nombreInstitucion: () => ({
    validations: {
      rules: [
        { required: true, message: 'Debe ingresar el nombre de la institución' }
      ]
    },
    extension: {
      Component: Input,
      props: { placeholder: 'Nombre de la Institución' } as InputProps
    }
  }),
  representanteInstitucion: () => ({
    validations: {
      rules: [
        { required: true, message: 'Debe ingresar el representante legal o director de la institución' }
      ]
    },
    extension: {
      Component: Input,
      props: { placeholder: 'Representante Legal o Director de la Institución' } as InputProps
    }
  }),
  turno: () => ({
    validations: {
      rules: [
        { required: true, message: 'Por favor ingresa el turno' }
      ],
    },
    extension: {
      Component: ShiftSelect,
      props: { placeholder: 'Turno' } as SelectProps<any>
    }
  }),
  nivelEducativo: () => ({
    validations: {
      rules: [
        { required: true, message: 'Debe ingresar el nivel educativo' }
      ]
    },
    extension: {
      Component: Input,
      props: { placeholder: 'Nivel Educativo' } as InputProps
    }
  }),
  direccionPlantel: () => ({
    validations: {
      rules: [
        { required: true, message: 'Debe ingresar la dirección del plantel' }
      ]
    },
    extension: {
      Component: Input,
      props: { placeholder: 'Dirección' } as InputProps
    }
  }),
  direccionEmpresa: () => ({
    validations: {
      rules: [
        { required: true, message: 'Debe ingresar la dirección de la empresa' }
      ]
    },
    extension: {
      Component: Input,
      props: { placeholder: 'Dirección' } as InputProps
    }
  }),
  parroquiaEmpresa: () => ({
    validations: {
      rules: [
        { required: true, message: 'Por favor ingresa el municipio' }
      ],
    },
    extension: {
      Component: ParishSelect,
      props: { placeholder: 'Municipio' } as SelectProps<any>
    }
  }),
  telefonoEmpresa: () => ({
    validations: {
      rules: [
        { required: true, message: 'Por favor ingresa el numero de teléfono' },
        { validator: Utils.validators.phoneNumber }
      ],
      normalize: Utils.normalize.isNumber
    },
    extension: {
      Component: Input,
      props: { placeholder: 'Teléfono' } as InputProps
    }
  }),
  correoEmpresa: () => ({
    validations: {
      rules: [
        { required: true, message: 'Por favor ingresa el correo electrónico' },
        { validator: Utils.validators.email }
      ],
    },
    extension: {
      Component: Input,
      props: { placeholder: 'Correo Electrónico' } as InputProps
    }
  }),
  nombreEmpresaComercio: () => ({
    validations: {
      rules: [
        { required: true, message: 'Debe ingresar el nombre del comercio o empresa' }
      ]
    },
    extension: {
      Component: Input,
      props: { placeholder: 'Nombre de la Empresa o Comercio' } as InputProps
    }
  }),
  usoConforme: () => ({
    validations: {
      rules: [
        { required: true, message: 'Debe ingresar el uso conforme' }
      ]
    },
    extension: {
      Component: Input,
      props: { placeholder: 'Uso Conforme' } as InputProps
    }
  }),
  metrosCuadradosConstruccion: () => ({
    validations: {
      rules: [
        { required: true, message: 'Debe ingresar el area de construcción' }
      ]
    },
    extension: {
      Component: Input,
      props: { placeholder: 'Area de Construcción', readOnly: true } as InputProps
    }
  }),
  planoConstruccion: () => ({
    validations: {
      rules: [
        { required: true, message: 'Debe ingresar el plano de construcción' }
      ]
    },
    extension: {
      Component: FileUpload,
      props: { documentName: 'PLANO_CONSTRUCCION.png' } as FileUploadProps,
      valuePropName: 'fileList',
      getValueFromEvent: (e) => Array.isArray(e) ? e : e && e.fileList
    }
  }),
  distribucion: () => ({
    validations: {
      rules: [
        { required: true, message: 'Debe ingresar la distribución' }
      ]
    },
    extension: {
      Component: DistroFormList,
      props: { name: 'distribucion' } as OwnersFormListProps
    }
  }),
  documentoIdentidad: (tipo) => ({
    validations: {
      rules: [
        { required: true, message: 'Debe ingresar su documento de identidad' },
      ]
    },
    extension: {
      Component: Input,
      props: { placeholder: 'Documento de Identidad', maxLength: 10, readOnly: tipo !== 4, title:'RIF para contribuyentes jurídicos. Cédula para contribuyentes naturales' } as InputProps,
      icon: UserOutlined,
      addonBefore: {
        Component: Select,
        name: 'tipoDocumento',
        options: ['V', 'J', 'E', 'G', 'P', 'C']
      }
    }
  }),
  denominacionComercial: (a,b,natural) => ({
    validations: {
      rules: [
        natural ? { required: false } : { required: true, message: 'Debe ingresar la denominación comercial' }
      ],
    },
    extension: {
      Component: Input,
      props: { placeholder: 'Denomincación Comercial', style:{textTransform:'uppercase'}, } as InputProps
    }
  }),
  denominacionComercialO: () => ({
    validations: {
      rules: [
        { required: false }
      ],
    },
    extension: {
      Component: Input,
      props: { placeholder: 'Denominación Comercial', style:{textTransform:'uppercase'} } as InputProps
    }
  }),
  siglas: () => ({
    validations: {
      rules: [
        { required: false}
      ]
    },
    extension: {
      Component: Input,
      props: { placeholder: 'Siglas' } as InputProps
    }
  }),
  tipoContribuyente: () => ({
    validations: {
      rules: [
        { required: true, message: 'Debe ingresar el tipo de contribuyente' }
      ],
    },
    extension: {
      Component: TaxpayerTypeSelect,
      props: { placeholder: 'Tipo de Contribuyente' } as SelectProps<any>
    }
  }),
  actividadesEconomicas: () => ({
    validations: {
      rules: [
        { required: true, message: 'Debe ingresar las actividades económicas' }
      ]
    },
    extension: {
      hidden: true,
      Component: EconomicActivities,
      props: {}
    }
  }),
  capitalSuscrito: () => ({
    validations: {
      rules: [
        { required: false, message: 'Debe ingresar el capital suscrito' }
      ]
    },
    extension: {
      Component: Input,
      props: { placeholder: 'Capital Suscrito', type: 'number' } as InputProps
    }
  }),
  tipoSociedadContrib: () => ({
    validations: {
      rules: [
        { required: true, message: 'Debe ingresar el tipo de sociedad' }
      ],
    },
    extension: {
      Component: TaxpayerSocietyType,
      props: { placeholder: 'Tipo de Sociedad' } as SelectProps<any>
    }
  }),
  estadoLicencia: () => ({
    validations: {
      rules: [
        { required: true, message: 'Debe ingresar el tipo de licencia' }
      ],
    },
    extension: {
      Component: LicenseTypeSelect,
      props: { placeholder: 'Tipo de Licencia' }
    }
  }),
  esMonotributo: () => ({
    validations: {
      rules: [
        { required: true, message: 'Debe indicar si el Documento de Identidad. declara montributo.' }
      ],
    },
    extension: {
      Component: MonotaxSwitch,
      props: { placeholder: 'Declara monotributo' }
    }
  })
}

interface Validations<T> {
  [P: string]: (type?: number, prcd?: number, natural?: boolean) => {
    validations: T,
    extension: ExtendedDecoratorOptions
  }
}

export interface ExtendedDecoratorOptions {
  Component: typeof Input | typeof DatePicker | typeof Input.TextArea | typeof OwnersFormList | typeof ParishSelect | typeof AgentSelect | typeof EntitySelect | typeof DateDeclareSelect | typeof MonthDeclareSelect | typeof SectorSelect | typeof ProcessSelect | typeof FileUpload | 
    typeof SolvencyEstateType | typeof SimpleEstimation | typeof SocietyTypeSelect | typeof VehicleTypeSelect | typeof PurposeSelect |
    typeof NomenclatureCode | typeof ShiftSelect | typeof DistroFormList | typeof TaxpayerTypeSelect | typeof EconomicActivities | typeof TaxpayerSocietyType |
    typeof LicenseTypeSelect | typeof Checkbox | typeof Switch 
  props: any
  rte?: boolean
  icon?: React.ForwardRefExoticComponent<AntdIconProps & React.RefAttributes<HTMLSpanElement>>
  valuePropName?: string
  hidden?: boolean
  getValueFromEvent?: (e: any) => any
  addonBefore?: {
    Component: typeof Select
    name: string
    props?: SelectProps<any>
    options?: string[]
  }
}