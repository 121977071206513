import React, { useEffect, useState, useRef } from "react";
import { SearchOutlined, FileDoneOutlined, CalendarOutlined } from '@ant-design/icons';
import { Table, Tooltip, Button, Card, Row, Input, Col, DatePicker, Tag, Form, Modal, InputNumber } from "antd";
import { connect } from "react-redux";
import { State, States } from 'sigt';
import { useWindowDimensions } from "../../utils/hooks";
import Highlighter from "react-highlight-words"
import { ColumnsType } from 'antd/lib/table';
import "../../assets/css/components/TableOfficial.css";
import moment, { Moment } from "moment";
import axios from "axios";
import _ from "lodash";
import form from "antd/lib/form";
const server = process.env.REACT_APP_SERVER_URL;

const TableBudget: React.FC<PropsTable> = ({ thm, auth }) => {
  const [searchText, setSearchText] = useState('');
  const [selectedBranch, setSelectedBranch] = useState();
  const [form] = Form.useForm();
  const [searchedColumn, setSearchedColum] = useState('');
  const searchInput = useRef<any>(null);
  const [loading, setLoading] = useState(true);
  const { width } = useWindowDimensions();
  const [data, setData] =  useState<DataArray[]>([])
  const [visible, setVisible] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [keys, setKeys] = useState<Moment[]>([]);

  const report = async (data, token) => {
    try {
      const response = await axios.post(`${server}/branches/reportBudget`, data, { headers: { Authorization: `Bearer ${token}` } });
      if (response.data.status === 200) {
        const win = window.open(response.data.data, '_blank');
        win?.focus();
      }
    } catch (error) {
      throw error;
    }
  };

  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder='Buscar'
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type='primary'
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size='small'
          style={{ width: 90, marginRight: 8 }}
        >
          Buscar
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size='small' style={{ width: 90 }}>
          Restablecer
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined onClick={() => {}} style={{ color: filtered ? thm.primaryColor : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        if (searchInput.current) {
          searchInput.current?.select();
        }
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#1e72c675', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });
  
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    if(dataIndex === "consumido")
      setSearchText(selectedKeys[0]);
    setSearchText(selectedKeys[0]);
    setSearchedColum(dataIndex);
  };

  const handleReset = clearFilters => {
    clearFilters();
    setSearchText('');
  };


  const columns: ColumnsType<any> = [
    {
      title: "Descripción",
      dataIndex: "descripcion",
      key: "descripcion",
      ...getColumnSearchProps("descripcion")
    },
    {
      title: "Ingresado",
      dataIndex: "ingresado",
      key: "ingresado",
      ...getColumnSearchProps("ingresado")
    },    
    {
      title: "Presupuesto",
      dataIndex: "presupuesto",
      key: "presupuesto",
      ...getColumnSearchProps("presupuesto")
    },   
    {
      title: "Diferencia",
      dataIndex: "diferencia",
      key: "diferencia",
      ...getColumnSearchProps("diferencia")
    },
    {
      title: "",
      key: "action",
      render: (_,record) => (
      <Button
        shape='round' 
        size='small'
        onClick={() => handleEditClick(record.id_ramo_ingreso) }
        >
        {width > 440 ? 'Editar Presupuesto' : null}
      </Button>
    )
    }
  ] 

  const handleEditClick = (id) => {
    setSelectedBranch(id);
    setModalVisible(true);
  }

  useEffect(() => {
    aux();
    // eslint-disable-next-line
  }, []);

  const aux = async() => {
    if(auth.token) {
      try {
        let dat : any[] = [];
          const response = await axios.post(
            `${server}/branches/budgetInfo`,
            {year:moment().get('year')},
            { headers: { Authorization: `Bearer ${auth.token}` } }
          );
          console.log(response.data)
          response.data.data.budgets.forEach(ele => {
            dat.push({...ele})
          })
        setData(dat)
      } catch (e) {
        throw e;
      }
    }
    setLoading(false);
  }

  const handleBudgetUpdate = async() => {
      try {
        const { presupuesto } = await form.validateFields();
          const response = await axios.post(
            `${server}/branches/budgetUpdate`,
            {branch: selectedBranch, budget: presupuesto, year:moment().get('year')},
            { headers: { Authorization: `Bearer ${auth.token}` } }
          );
          setModalVisible(false);
          aux();
      } catch (e) {
        throw e;
      }
  }

    // useEffect(() => {
    //   setLoading(data.length > 0 ? false : true)
    // }, [data]);
    
  return (
    <Card style={{ height: '100%' }} title='Principios Presupuestales' 
      headStyle={{ backgroundColor: thm.primaryColor, padding: width < 992 ? '0 10px' : '0 20px', color: "#fff" }} bodyStyle={{ overflowY: 'auto' }}>
      <Table
        style={{ height: '100%' }}
        size='middle'
        loading={loading}
        pagination={{ pageSize: 7 }}
        columns={columns}
        dataSource={data}
      />
        <Button onClick={() => report({year:moment().get('year')},auth.token)}>Descargar Reporte</Button>
    <Modal
        width='400px'
        bodyStyle={{ height: '25vh', overflowY: 'scroll' }}
        centered
        visible={modalVisible}
        closable
        title='Editar Presupuesto'
        maskClosable
        onOk={handleBudgetUpdate}
        onCancel={() => setModalVisible(false)}
      >
        <Form form={form} layout='vertical' onFinish={handleBudgetUpdate}>
          <Row style={{ paddingLeft: 4 }} gutter={16}>
            <Col span={24}>
              <Form.Item
                label='Presupuesto'
                name='presupuesto'
                rules={[{ required: true, message: 'Debe ingresar el presupuesto' }]}
              >
                <InputNumber placeholder='0.00'/>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </Card>
  );
};
const mapStateToProps = ({ auth, thm }: State) => ({ auth, thm });

export default connect( mapStateToProps, { })(TableBudget);

interface PropsTable {
  auth: States.Auth;
  thm: States.ThemeColors;
}

interface DataArray {
  ramo: string;
  codigo: string;
  liquidado: number;
  ingresado: number;
  presupuesto: number;
  diferencia:number;
}
