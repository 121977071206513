import axios from 'axios';
const server = process.env.REACT_APP_SERVER_URL;

export const sendSurvey = async (data, token) => {
  const response = await axios.post(`${server}/survey/`, data, {headers: { Authorization: `Bearer ${token}` }});
  return response.data;
};

export const getSurvey = async (id,token) => {
  console.log(token)
  const response = await axios.get(`${server}/survey/?id_contribuyente=${id}`, {headers: { Authorization: `Bearer ${token}` }});
  return response.data;
};
