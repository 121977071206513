import React, { useState, useEffect } from 'react';
import { Select, Input, Button,Form, Col, Row, Spin } from 'antd';
import { SearchOutlined, LoadingOutlined } from '@ant-design/icons';
import handlingMessage from '../utils/handlingMessage';
import { State, States } from 'sigt';
import { connect } from 'react-redux';
import { useWindowDimensions } from '../utils/hooks';
import { Utils } from '../utils/validators';

const SearchTaxPayer: React.FC<SearchTaxPayerProps> = ({setData, searchAction, requireRim = true, thm}) => {
  const [userType, setUserType] = useState('JURIDICO');
  const [loading, setLoading] = useState(false);
  const [searchForm] = Form.useForm();
  const { width } = useWindowDimensions();

  const onSearch = async () => {
    const values = await searchForm.validateFields();
    setLoading(true);
    handlingMessage({
      action: () => searchAction(values),
      key:'find',
      loadingMessage: 'Buscando...',
    
    cb: (data)=> {setData(data); setLoading(false); }
    })
  }

  useEffect(()=>{
    searchForm.setFieldsValue({docType: userType === 'JURIDICO' ? 'J' : 'V'});
  },[userType,searchForm])

  const docTypeSelect = (
    <Form.Item noStyle name='docType'>
      <Select>
        {/* {userType !== 'JURIDICO' ? null : 
          <>
            <Select.Option value='J'>J</Select.Option>
            <Select.Option value ='G'>G</Select.Option>
          </>
        } */}
        <Select.Option value='J'>J</Select.Option>
        <Select.Option value ='G'>G</Select.Option>
        <Select.Option value='V'>V</Select.Option>
        <Select.Option value='E'>E</Select.Option>
        <Select.Option value='P'>P</Select.Option>
        
      </Select>
    </Form.Item>
  );

  return (
    <Form form={searchForm} layout='vertical'  style={{ padding: width < 992 ? '0 10px' : '0px 20px', marginBottom: 8 }} onFinish={onSearch}>
      <Row gutter={24}>
        {loading && 
          <Col span={24} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Spin tip='Consultando contribuyente...' indicator={<LoadingOutlined style={{ fontSize: 50, color: thm.primaryColor, margin: '15px 0px' }} />} />
          </Col>}
        {!loading &&
        <React.Fragment>
          <Col xl={7} xs={24}>
            <Form.Item label='Tipo de Contribuyente' name='userType' rules={[{ required: true, message: 'Debe ingresar el tipo de contribuyente' }]}>
              <Select placeholder='Tipo de Contribuyente' onChange={(value:string) => setUserType(value)}>
                <Select.Option value='NATURAL'>Natural</Select.Option>
                <Select.Option value='JURIDICO'>Jurídico</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} xl={7}>
            <Form.Item label='Documento de Identidad' name='document' rules={[{ required: true, message: 'Debe ingresar el documento de identidad del contribuyente' }]} normalize={Utils.normalize.isCNumber}>
              <Input placeholder='Documento de Identidad' addonBefore={docTypeSelect} />
            </Form.Item>
          </Col>
          {/* {requireRim &&
            <Col xs={24} xl={7}>
              <Form.Item name='rim' label='Documento de Identidad' rules={[{ required: userType === 'JURIDICO', message: 'Debe ingresar un Documento de Identidad' }]} normalize={Utils.normalize.isNumber}>
                <Input placeholder='Referencia Municipal' />
              </Form.Item>
            </Col>} */}
          <Col xl={3} xs={12}>
            <Button style={{ marginTop: width < 1200 ? 0 : 40, width: '100%' }} loading={loading} type='primary' htmlType='submit' icon={<SearchOutlined />}>Buscar</Button>
          </Col>
        </React.Fragment>}
      </Row>
    </Form>);
};

const mapStateToProps = ({thm}:State) => ({thm});

export default connect(mapStateToProps)(SearchTaxPayer);

interface SearchTaxPayerProps {
  thm: States.ThemeColors
  setData: Function
  searchAction: Function
  requireRim?: boolean
}