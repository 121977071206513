import React, { useState } from 'react';
import { States, State } from 'sigt';
import { connect } from 'react-redux';
import { Card, Typography, Form, Divider, Select, Table, Row, Col, DatePicker, Button, Input, message } from 'antd';
import { useWindowDimensions } from '../utils/hooks';
import moment from 'moment';
import { useParams, useHistory } from 'react-router';
import { CreditCardOutlined, CloudDownloadOutlined } from '@ant-design/icons';
import { updateFine } from '../redux/actions/procedures';
import { getCurrency } from '../utils/currency';

const PayFine: React.FC<PayFineProps> = ({ banks, thm, fines, inst, auth, updateFine }) => {
  const [loading, setLoading] = useState<boolean>(false);

  const { width } = useWindowDimensions();
  const { idMulta } = useParams();
  const [form] = Form.useForm();
  const history = useHistory();

  const selectedFine = fines.find((f) => f.id === parseInt(idMulta || '0'));
  const selectedInst = inst?.find((i) => i.nombreCorto === selectedFine?.nombreCorto);

  const disabledDate = (current) => current > moment().endOf('day');

  const handleBankChange = (banco) => {
    form.setFieldsValue({
      banco,
    });
  };

  const formatCurrency = (number: number) => new Intl.NumberFormat('de-DE').format(number);

  const handleSubmit = async () => {
    const values = await form.validateFields();
    setLoading(true);
    Object.keys(values).forEach((k) => {
      if (moment.isMoment(values[k])) {
        values[k] = values[k].toISOString();
      }
    });
    const pago = {
      fecha: values.fecha,
      banco: values.banco,
      referencia: values.referencia,
      costo: selectedFine?.costo,
    };
    const tramite = {
      estado: 'ingresardatos',
      pago,
      idTramite: selectedFine?.id,
      tipoTramite: selectedFine?.tipoTramite,
    };
    try {
      await updateFine({ tramite }, auth.token || '');
      setLoading(false);
      history.push('/dashboard/bandejas?tipo=multas');
      message.success('Pago registrado de manera exitosa.');
    } catch (e) {
      message.error(
        (e.response.data.message as string).includes('referencia')
          ? 'La referencia bancaria ya ha sido utilizada.'
          : 'Error en el servidor'
      );
      setLoading(false);
    }
  };

  const columns: any = [
    {
      title: 'Banco',
      dataIndex: 'banco',
      key: 'banco',
      fixed: 'left',
    },
    {
      title: 'Numero de Cuenta',
      dataIndex: 'numeroCuenta',
      key: 'numeroCuenta',
    },
    {
      title: 'Titular',
      dataIndex: 'nombreTitular',
      key: 'nombreTitular',
    },
    {
      title: 'Documento de Identidad',
      dataIndex: 'documento',
      key: 'documento',
    },
  ];

  return (
    <Card
      style={{ height: '100%' }}
      title='Pagar Multa'
      bodyStyle={{ height: 'calc(100% - 88px)', overflowY: 'scroll', overflowX: 'hidden' }}
      headStyle={{ height: 64, backgroundColor: thm.primaryColor, padding: width < 992 ? '0 10px' : '0 20px', color: 'white' }}
    >
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography.Text strong>Codigo de Multa: {selectedFine?.codigoMulta}</Typography.Text>
        <Typography.Text strong>Fecha: {moment(selectedFine?.fechaCreacion).format('DD-MM-YYYY')}</Typography.Text>
      </div>
      <Form form={form} layout='vertical' style={{ padding: width < 992 ? '0 10px' : '0px 20px' }} onFinish={handleSubmit}>
        {selectedFine?.boleta && (
          <React.Fragment>
            <Divider orientation='left' style={{ marginLeft: -30, marginBottom: 0 }}>
              <Typography.Title level={4}>Boleta de la Multa</Typography.Title>
            </Divider>
            <Button type='primary' icon={<CloudDownloadOutlined />} target='_blank' href={selectedFine.boleta}>
              Ver Boleta
            </Button>
          </React.Fragment>
        )}
        <Divider orientation='left' style={{ marginLeft: -30, marginBottom: 0 }}>
          <Typography.Title level={4}>Datos de Pago</Typography.Title>
        </Divider>
        <Typography.Text strong type='danger'>
          La multa tiene un costo de Bs. {formatCurrency(selectedFine?.costo || 0)} (equivalente a{' '}
          {selectedFine?.datos?.funcionario?.utmm} {getCurrency()}) por motivo de{' '}
          {selectedFine?.datos?.funcionario?.motivo?.toUpperCase()}. El monto de la transferencia debe coincidir exactamente con
          el costo de la multa y debe ser realizada desde el mismo banco.
        </Typography.Text>
        <Table
          bordered
          style={{ margin: '20px 0px' }}
          pagination={false}
          columns={columns}
          dataSource={selectedInst?.cuentasBancarias?.map((c) => ({
            ...c,
            documento: `${c.rif ? 'RIF: ' : 'Cedula: '}${c.rif || c.cedula}`,
            banco: banks?.find((b) => b.id === c.banco)?.nombre,
            key: c.id,
          }))}
        />
        <Row gutter={24}>
          <Col xs={24} xl={8}>
            <Form.Item label='Banco' name='banco' rules={[{ required: true, message: 'Debe indicar el banco' }]}>
              {banks.length > 0 ? (
                <Select placeholder='Banco' onChange={handleBankChange}>
                  {banks
                    ?.filter((b) => selectedInst?.cuentasBancarias?.map((c) => c.banco).includes(b.id))
                    .map((b, i) => (
                      <Select.Option key={`b-${b.id}`} value={b.id}>
                        {b.nombre}
                      </Select.Option>
                    ))}
                </Select>
              ) : (
                <div></div>
              )}
            </Form.Item>
          </Col>
          <Col xs={24} xl={8}>
            <Form.Item
              label='Numero de Referencia'
              name='referencia'
              rules={[{ required: true, message: 'Por favor ingrese el numero de referencia.' }]}
            >
              <Input placeholder='Referencia' maxLength={20} />
            </Form.Item>
          </Col>
          <Col xs={24} xl={8}>
            <Form.Item
              label='Fecha de Pago'
              name='fecha'
              rules={[{ required: true, message: 'Por favor ingrese la fecha de pago.' }]}
            >
              <DatePicker style={{ width: '100%' }} format={'DD-MM-YYYY'} disabledDate={disabledDate} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Button loading={loading} type='primary' style={{ marginTop: 15 }} icon={<CreditCardOutlined />} onClick={form.submit}>
        Pagar
      </Button>
    </Card>
  );
};

const mapStateToProps = (state: State) => ({
  banks: state.bk.banks,
  thm: state.thm,
  fines: state.prcd.fines,
  inst: state.inst.institutions,
  auth: state.auth,
});

export default connect(mapStateToProps, { updateFine })(PayFine);

interface PayFineProps {
  banks: States.Banks['banks'];
  thm: States.ThemeColors;
  fines: States.Procedures['fines'];
  inst: States.Institutions['institutions'];
  auth: States.Auth;
  updateFine: (data: any, token: string) => void;
}
