import React, { useState, useRef } from "react";
import { SearchOutlined, CalendarOutlined, FileSearchOutlined } from '@ant-design/icons';
import { Table, Tooltip, Button, Row, DatePicker, Col, Input, Tag } from "antd";
import { connect } from "react-redux";
import { State, States, Procedure } from 'sigt';
import { useWindowDimensions } from "../../utils/hooks";
import { ColumnsType } from "antd/lib/table";
import { createFile } from "../../redux/actions/taxes";
import Highlighter from 'react-highlight-words';
import { useHistory } from "react-router";
import moment, { Moment } from "moment";


const TableBenefit: React.FC<PropsTableBenefit> = ({ thm, auth, prcd}) => {
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const inputRef = useRef<any>(null);
  const { width } = useWindowDimensions();
  const history = useHistory();
  const [keys, setKeys] = useState<Moment[]>([]);

  const cantApp = [103];

  const getData = () => {
    const aux = prcd.procedures.filter(e=> e.tipoTramite === 26);
    const data = aux.map((e)=> {
    return {
        ...e,
        rim: e.datos?.funcionario?.contribuyente?.registroMunicipal,
        rif: `${e.datos?.funcionario?.contribuyente?.tipoDocumento}-${e.datos?.funcionario?.contribuyente?.documento}`,
        razonSocial: e.datos?.funcionario?.contribuyente?.razonSocial
      }
    });
    return data;
  }

  const data:Procedure[] = getData();


  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = clearFilters => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={inputRef}
          placeholder='Buscar'
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type='primary'
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size='small'
          style={{ width: 90, marginRight: 8 }}
        >
          Buscar
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size='small' style={{ width: 90 }}>
          Restablecer
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined style={{ color: filtered ? thm.primaryColor : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ?.toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        if (inputRef.current) {
          inputRef.current?.select();
        }
      }
    },
    render: text =>  searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#1e72c675', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
    ),
  });


  const getColumnFilterDate = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      setKeys(selectedKeys);
      return(
      <Row style={{width:250, padding: 8}} gutter={[8,8]}>
        <Col span={24}>
          <DatePicker.RangePicker picker="month" style={{width:'100%'}} format='MMMM, YYYY'  placeholder={['Fecha inicio ','Fecha fin']} size='small' 
            onChange={(values)=>{ setSelectedKeys(values) }} value={selectedKeys} 
          />
        </Col>
        <Col span={12}>
          <Button style={{width:'100%'}} type='primary' icon={<SearchOutlined />} size='small' onClick={() => { confirm(); }}>
            Buscar
          </Button>
        </Col>
        <Col span={12} >
          <Button style={{width:'100%'}} onClick={() => clearFilters()} size='small'>
            Restablecer
          </Button>
        </Col>
      </Row> 
    )},
    filterIcon: filtered => (
      <CalendarOutlined style={{ color: filtered ? thm.primaryColor : undefined }} />
    ),
    onFilter: (value, record) => {
      return moment(record[dataIndex]).utc().format('DD-MM-YYYY') === keys[0]?.utc().format('DD-MM-YYYY') 
        || moment(record[dataIndex]).utc().isBetween(keys[0]?.utc(), keys[1]?.utc(), undefined, '[]')
    },  
  });

  const getState = (record) => {
    if(record.estado === 'enrevision') return { name: 'Por Revisar', color: 'warning' };
    else if (record.estado === 'finalizado') {
      return record.aprobado ? { name: 'Aprobado', color: 'green' } : { name: 'Rechazado', color: 'error'};
    }else return { name: ''};
  };
  
  const columns: ColumnsType<any> = [
    {
      title: 'RIM',
      dataIndex: 'rim',
      align: 'center',
      ...getColumnSearchProps('rim'),
      render: (value,record) => record.datos?.funcionario?.contribuyente?.registroMunicipal
    },
    {
      title: 'RIF',
      dataIndex: 'rif',
      align: 'center',
      ...getColumnSearchProps('rif'),
      render: (value,record) => `${record.datos?.funcionario?.contribuyente?.tipoDocumento}-${record.datos?.funcionario?.contribuyente?.documento}`
    },
    {
      title: "Razon Social", 
      ...getColumnSearchProps('razonSocial'),
      dataIndex: 'razonSocual',
      render: (value,record) => record.datos?.funcionario?.contribuyente?.razonSocial
    },
    {
      title: 'Fecha',
      dataIndex: 'fechaCreacion',
      align: 'center',
      ...getColumnFilterDate('fechaCreacion'),
      render: text => moment(text).format('DD/MM/YYYY'),
    },
    {
      title: 'Estado', dataIndex: 'estado', align: 'center',
      filters:[{value: 'enrevision', text:'Por Revisar'},{value: 'finalizado', text:'Aprobado'}],
      onFilter: (value, record) => record.estado.toLowerCase().indexOf((value as string).toLowerCase()) === 0,
      render: (value,record) => <Tag style={{ width: '100%', textAlign: 'center' }} color={getState(record).color}>{getState(record).name}</Tag>
    },
    {
      title: "",
      key: "action",
      dataIndex: 'action',
      render: (text,record) => record.estado === 'enrevision' && (auth.user?.tipoUsuario === 2 || auth.user?.tipoUsuario === 5) && !cantApp.includes(auth?.user?.institucion?.cargo?.id || 0) ? 
        <Tooltip title='Ver Detalles' visible={width > 440 ? false : true}>
          <Button  shape='round' type='link' 
            icon={<FileSearchOutlined />} onClick={()=> history.push(`/dashboard/benefitTributables/revision/${record.id}`)} size='small' >
            {width > 440 ? 'Ver Detalles' : null }
          </Button>
        </Tooltip> : null
    }
  ] 

  return (
      <Table
        style={{ height: '100%' }}
        size='middle'
        pagination={{ pageSize: 7 }}
        columns={columns}
        dataSource={data}
        rowKey={e => e.id}
      />
  );
};
const mapStateToProps = ({ thm, auth, prcd }: State) => ({ thm, auth, prcd });

export default connect( mapStateToProps, { createFile })(TableBenefit);

interface PropsTableBenefit {
  thm: States.ThemeColors;
  auth: States.Auth;
  prcd: States.Procedures;
  createFile: (id:number, tipoCertificado: string, token:string) => Promise<void>;
}
