import axios, { AxiosResponse } from 'axios';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { OfficialsTypes, SET_OFFICIALS, DELETE_OFFICIAL, CREATE_OFFICIAL, EDIT_OFFICIAL } from './actionsTypes';
import { User, Response } from 'sigt';
const server = process.env.REACT_APP_SERVER_URL;

export const setOfficial = (official: User): OfficialsTypes => ({ type: CREATE_OFFICIAL, payload: official });

export const updateOfficial = (official: User): OfficialsTypes => ({ type: EDIT_OFFICIAL, payload: official });

export const createOfficial =
  (formValues, token: string, id): ThunkAction<Promise<Response>, {}, {}, OfficialsTypes> =>
  async (dispatch: ThunkDispatch<{}, {}, OfficialsTypes>): Promise<Response> => {
    try {
      const route = id === 1 ? `${server}/auth/createAdmin` : `${server}/official`;
      const data = { usuario: { ...formValues } };
      const response: AxiosResponse = await axios.post(route, data, { headers: { Authorization: `Bearer ${token}` } });
      dispatch(setOfficial(response.data?.usuario));
      return response.data;
    } catch (error) {
      throw error;
    }
  };

export const editOfficial =
  (formValues, token: string, id): ThunkAction<Promise<Response>, {}, {}, OfficialsTypes> =>
  async (dispatch: ThunkDispatch<{}, {}, OfficialsTypes>): Promise<Response> => {
    try {
      const data = { usuario: { ...formValues } };
      const response: AxiosResponse = await axios.put(`${server}/official/${id}`, data, {
        headers: { Authorization: `Bearer ${token}` },
      });
      dispatch(updateOfficial(response.data?.usuario));
      return response.data;
    } catch (error) {
      throw error;
    }
  };

export const setOfficials = (officials: User[]): OfficialsTypes => ({ type: SET_OFFICIALS, payload: officials });

export const fetchOfficials =
  (token: string, id: number): ThunkAction<Promise<Response>, {}, {}, OfficialsTypes> =>
  async (dispatch: ThunkDispatch<{}, {}, OfficialsTypes>): Promise<Response> => {
    try {
      const route = id === 1 ? `${server}/official/all` : `${server}/official`;
      const response: AxiosResponse<FetchOfficialsResponse> = await axios.get(route, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const userFiltered: User[] = filterUserMaster(response, 92714);
      dispatch(setOfficials(userFiltered));
      return response.data;
    } catch (error) {
      throw error;
    }
  };

type OficcialsResponse = AxiosResponse<FetchOfficialsResponse>;

const filterUserMaster = (users: OficcialsResponse, idNumber: number) => {
  const { data } = users;
  const { funcionarios } = data;
  return funcionarios.filter((user) => user.id !== idNumber);
};

export const deleteOfficial =
  (id, bloqueado, token: string): ThunkAction<Promise<Response>, {}, {}, OfficialsTypes> =>
  async (dispatch: ThunkDispatch<{}, {}, OfficialsTypes>): Promise<Response> => {
    try {
      const response: AxiosResponse = await axios.patch(
        `${server}/official/${id}`,
        { bloqueado },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      dispatch({ type: DELETE_OFFICIAL, payload: id });
      return response.data;
    } catch (error) {
      throw error;
    }
  };

interface FetchOfficialsResponse extends Response {
  funcionarios: User[];
}
