import React from "react";
import { Select, Divider, Typography, Row, Col, Input, Form, Checkbox, DatePicker } from "antd";
import { Utils } from "../../../utils/validators";
import { FormInstance } from "antd/lib/form";
import { connect } from 'react-redux'
import ParishSelect from "./ParishSelect";
import VehiclesFormList from "./VehiclesFormList";
import { State, States } from "sigt";
import moment from "moment";

const LiqueurDataFields : React.FC<LiqueurDataFieldsProps> = ({selectedProcedureId, form, hasVehicles, setHasVehicles, auth}) => {

  const requireExtraFields = [29, 30, 31];

  const rifAddon = (
    <Form.Item noStyle name='tipoDocumento'>
      <Select>
        <Select.Option value='J'>J</Select.Option>
        <Select.Option value='E'>E</Select.Option>
        <Select.Option value='V'>V</Select.Option>
        <Select.Option value='G'>G</Select.Option>
        <Select.Option value='P'>P</Select.Option>
      </Select>
    </Form.Item>
  );

  const cedulaAddon = (
    <Form.Item noStyle name='tipoDocumentoRepresentante'>
      <Select>
        <Select.Option value='E'>E</Select.Option>
        <Select.Option value='V'>V</Select.Option>
      </Select>
    </Form.Item>
  );
  
  return (<>
    <Divider orientation='left' style={{ marginLeft: -30 }}>
    <Typography.Title level={4}>Datos del Solicitante</Typography.Title>
    </Divider>
    <Row gutter={24}>
      <Col xs={24} xl={6}>
        <Form.Item label='RIM' name='referenciaMunicipal' rules={[{required: true, message: 'Debe ingresar una Referencia Municipal'}]}>
          <Input placeholder='2900000000'/>
        </Form.Item>
      </Col>
      <Col xs={24} xl={7}>
        <Form.Item label='RIF' name='documento' rules={[{ required: true, message: 'Debe ingresar el documento de identidad' }]} normalize={Utils.normalize.isNumber}>
          <Input placeholder='Documento de Identidad' addonBefore={rifAddon} />
        </Form.Item>
      </Col>
      <Col xs={24} xl={7}>
        <Form.Item label='Telefono Empresa' name='telefono' rules={[{ required: true, message: 'Por favor ingresar tu telefono!' }, { validator: Utils.validators.phoneNumber }]}
          normalize={Utils.normalize.isNumber}>
          <Input maxLength={11} placeholder="Telefono" />
        </Form.Item>
      </Col>
      <Col xs={24} xl={6}>
        <Form.Item label='Representante Legal' name='representanteLegal' rules={[{required: true, message: 'Debe ingresar una Referencia Municipal'}]}>
          <Input placeholder='Representante Legal'/>
        </Form.Item>
      </Col>
      <Col xs={24} xl={7}>
        <Form.Item label='Cédula' name='cedula' normalize={Utils.normalize.isNumber} rules={[{ required: true, message: 'Debe ingresar el documento de identidad' }, { validator: Utils.validators.cedula}]}>
          <Input placeholder='Cédula' addonBefore={cedulaAddon} />
        </Form.Item>
      </Col>
      <Col xs={24} xl={7}>
        <Form.Item label='Telefono Movil' name='telefonoMovil' rules={[{ required: true, message: 'Por favor ingresar tu telefono!' }, { validator: Utils.validators.phoneNumber }]}
          normalize={Utils.normalize.isNumber}>
          <Input maxLength={11} placeholder="Telefono" />
        </Form.Item>
      </Col>
      <Col xs={24} xl={13}>
        <Form.Item label='Domicilio' name='domicilio' rules={[{ required: true, message: 'Por favor ingresar su domicilio!' }]}>
          <Input placeholder="Domicilio" />
        </Form.Item>
      </Col>
    </Row>
    <Divider orientation='left' style={{ marginLeft: -30 }}>
      <Typography.Title level={4}>Datos del Establecimiento</Typography.Title>
    </Divider>
    <Row gutter={24}>
      <Col xs={24} xl={7}>
        <Form.Item label='Denominación Comercial' name='denomComercial' rules={[{required: true, message: 'Debe ingresar una denominacion comercial'}]}>
          <Input placeholder='Denominación Comercial'/>
        </Form.Item>
      </Col>
      <Col xs={24} xl={7}>
        <Form.Item label='Razón Social' name='razonSocial' rules={[{ required: true, message: 'Debe ingresar el documento de identidad' }]}>
          <Input placeholder='Razón Social'/>
        </Form.Item>
      </Col>
      <Col xs={24} xl={6}>
        <Form.Item label='Parroquia' name='parroquia' rules={[{required: true, message: 'Seleccione la parroquia correspondiente'}]}>
          <ParishSelect/>
        </Form.Item>
      </Col>
      <Col xs={24} xl={10}>
        <Form.Item label='Dirección del establecimiento' name='direccionEstablecimiento' rules={[{ required: true, message: 'Por favor ingresar su dirección!' }]}>
          <Input.TextArea placeholder='Dirección' autoSize={{maxRows: 2, minRows: 1}}/>
        </Form.Item>
      </Col>
      {requireExtraFields.includes(selectedProcedureId) && 
        <Col xs={24} xl={10}>
          <Form.Item label='A menos de 100 metros existen' name='entorno' rules={[{ required: true, message: 'Este campo es requirido!' }]}>
            <Input.TextArea placeholder='A menos de 100 metros existen' autoSize={{maxRows: 2, minRows: 1}}/>
          </Form.Item>
        </Col>
      }
      <Col xs={24} xl={10}>
        <Form.Item label='Observaciones' name='observaciones'>
          <Input.TextArea placeholder='Observaciones' autoSize={{maxRows: 2, minRows: 1}}/>
        </Form.Item>
      </Col>
      {requireExtraFields.includes(selectedProcedureId) && 
        <Col span={20}> 
          <Form.Item valuePropName="checked" name='poseiaLicencia'>
            <Checkbox> Existio una licencia de licores en este establemiento?</Checkbox>
          </Form.Item>
        </Col>}
      {(selectedProcedureId === 32 ) && 
        <Divider orientation='left' style={{ marginLeft: -18 }}>
          <Typography.Title level={4}>Datos de licencia</Typography.Title>
        </Divider>}
      {selectedProcedureId === 32 && <>
        <Col style={{marginLeft: 12}} xs={24} xl={7}>
          <Form.Item label='Codigo de espetaculo' name='codEspetaculo' rules={[{ required: true, message: 'Es requirido un codigo de espetaculo para realizar esta licencia.' }]}>
            <Input placeholder='Codigo de espectaculo'/>
          </Form.Item>
        </Col>
        <Col style={{marginLeft: 12}} xs={24} xl={7}>
          <Form.Item label='Fecha Vencimiento de la licencia' name='fechaFin' rules={[{ required: true, message: 'Es requirido un codigo de espetaculo para realizar esta licencia.' }]}>
            <DatePicker format='DD/MM/YYYY' disabledDate={current => current < moment().endOf('day').subtract(1,'day')} placeholder='Fecha Vencimiento'/>
          </Form.Item>
        </Col>
      </>}
      {selectedProcedureId === 32 && 
        <Col xs={24} xl={8}>
          <Form.Item label='Horario Establecido' name='horario' rules={[{ required: true, message: 'Por favor ingresar el horario establecido!' }]}>
            <Input placeholder="Horario Establecido" />
          </Form.Item>
        </Col>}
      {((selectedProcedureId === 30 || selectedProcedureId === 34) && setHasVehicles) && 
       <Col xs={24}>
         <Checkbox checked={hasVehicles} onChange={(e) => setHasVehicles(e.target.checked)}>Posee vehiculos de distribución?</Checkbox>
      </Col>}
      {((selectedProcedureId === 30 || selectedProcedureId === 34) && hasVehicles) && <div style={{marginLeft: 12}}>
          <Divider orientation='left' style={{ marginLeft: -30 }}>
            <Typography.Title level={4}>Vehiculos</Typography.Title>
          </Divider>
          <VehiclesFormList form={form}/>
        </div>}
    </Row>
  </>);
}

const mapStateToProps = (state: State) => ({ auth: state.auth });

export default connect(mapStateToProps)(LiqueurDataFields);

interface LiqueurDataFieldsProps {
  selectedProcedureId: number, 
  form: FormInstance, 
  hasVehicles: boolean, 
  setHasVehicles?: Function,
  auth?: States.Auth;
}