import React, { useEffect, useState } from 'react';
import { States, State } from 'sigt';
import { connect } from 'react-redux';
import { Card, Form, Row, Divider, Typography, Col, Input, Select, Button, message } from 'antd';
import { useWindowDimensions } from '../../../utils/hooks';
import ParishSelect from '../../Forms/components/ParishSelect';
import TaxpayerTypeSelect from '../../Forms/components/TaxpayerTypeSelect';
import { Utils } from '../../../utils/validators';
import axios from 'axios';
import { useHistory } from 'react-router';
import AgentSelect from '../../Forms/components/AgentSelect';
import EntitySelect from '../../Forms/components/EntitySelect';
import SectorSelect from '../../Forms/components/SectorSelect';
import DateDeclareSelect from '../../Forms/components/DateDeclareSelect';
import MonthDeclareSelect from '../../Forms/components/MonthDeclareSelect';
const server = process.env.REACT_APP_SERVER_URL;

const InternalRegister: React.FC<InternalRegisterProps> = ({ thm, auth }) => {
  const [tipoDocumento, setTipoDocumento] = useState('J');
  const [loading, setLoading] = useState(false);
  const [isAgent, setIsAgent] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const { width } = useWindowDimensions();
  const history = useHistory();
  const [form] = Form.useForm();

  useEffect(() => {
    let agentStatus = form.getFieldValue('esAgente')
    if(agentStatus === 'Aplica' ) {
      setIsAgent(true);
    }
    else {
      setIsAgent(false);
    }
  },[form, refresh]);

  const tipoAddon = (
    <Select value={tipoDocumento} onChange={(e) => setTipoDocumento(e)}>
      <Select.Option value='J'>J</Select.Option>
      <Select.Option value='E'>E</Select.Option>
      <Select.Option value='V'>V</Select.Option>
      <Select.Option value ='G'>G</Select.Option>
      <Select.Option value ='P'>P</Select.Option>
      <Select.Option value='C'>C</Select.Option>
    </Select>
  );

  const submit = async () => {
    const values = await form.validateFields();
    values.tipoDocumento = tipoDocumento;
    values.esAgente = values.esAgente === 'Aplica' ? true : false ;
    values.sector = "NA"
    if (!values.siglas)
      values.siglas = ''
    try {
      setLoading(true);
      const response = await axios.post(`${server}/settlements/internal`, values, { headers: { Authorization: `Bearer ${auth.token}` } });
      message.success('Contribuyente registrado de manera exitosa')
      // history.push(`/dashboard/contribuyente/licencia/${response.data.contribuyente?.id}`, { contribuyente: response.data.contribuyente });
      history.push(`/dashboard/impuestos/especiales`, { contribuyente: response.data.contribuyente });
    } catch(e) {
      message.error(e.response?.data?.message || 'Error al registrar contribuyente');
    } finally {
      setLoading(false);
    }
  };

  const firstListItem = (fields, add) => {
    if(fields.length === -1) {
      setTimeout(() => add(), 50);
    }
  };

  return (
    <Card style={{ height: '100%' }} title='Registro de Contribuyente' bodyStyle={{ height: 'calc(100% - 88px)', overflowY: 'scroll', paddingTop: 5, overflowX: 'hidden', border: '1px solid #f0f0f0' }}
      headStyle={{ height: 64, backgroundColor: thm.primaryColor, padding: width < 1200 ? '0 10px' : '0 20px', color: 'white'}}>
      <Form layout='vertical' form={form} scrollToFirstError onFinish={submit} onClick={() => setRefresh(!refresh)}>
        <Row gutter={24} style={{ paddingLeft: 15, paddingRight: 15 }}>
          <Divider orientation='left' style={{ marginLeft: -10 }}>
            <Typography.Title level={4} style={{ marginLeft: 5 }}>
              Datos del Contribuyente
            </Typography.Title>
          </Divider>
          <Col xs={24} xl={8}>
            <Form.Item label='Documento de Identidad (Cédula o RIF)' name='doc' rules={[{ required: true, message: 'Por favor ingrese el documento de identidad.' }]} normalize={Utils.normalize.isCNumber}>
              <Input addonBefore={tipoAddon} placeholder='Documento de Identidad' />
            </Form.Item>
          </Col>
          <Col xs={24} xl={8}>
            <Form.Item label='Nombre y Apellido / Razón Social' name='razonSocial' rules={[{ required: true, message: 'Por favor ingrese la razón social.' }]}>
              <Input style={{textTransform:'uppercase'}} placeholder='Razón Social' />
            </Form.Item>
          </Col>
          <Col xs={24} xl={8}>
            <Form.Item label='Denominación Comercial' name='denominacionComercial' rules={[{ required: true, message: 'Por favor ingrese la denominación comercial.' }]}>
              <Input style={{textTransform:'uppercase'}} placeholder='Denominación Comercial' />
            </Form.Item>
          </Col>
          <Col xs={24} xl={8}>
            <Form.Item label='Siglas' name='siglas' rules={[{ required: false }]}>
              <Input placeholder='Siglas' />
            </Form.Item>
          </Col>
          <Col xs={24} xl={8}>
            <Form.Item label='Tipo' name='tipoContribuyente' rules={[{ required: true, message: 'Por favor ingrese el tipo.' }]}>
              <TaxpayerTypeSelect placeholder='Tipo' />
            </Form.Item>
          </Col>
          <Col xs={24} xl={8}>
            <Form.Item label='Clasificación' name='esAgente' rules={[{ required: true, message: 'Por favor seleccione la clasificación.' }]}>
              <AgentSelect  />
            </Form.Item>
          </Col>
          { isAgent &&
            <Col xs={24} xl={8}>
              <Form.Item label='Tipo de Entidad' name='tipoEntidad' rules={[{ required: true, message: 'Por favor seleccione el tipo de entidad.' }]}>
                <EntitySelect placeholder='Tipo de Entidad' />
              </Form.Item>
            </Col>
          }
          { isAgent &&
            <Col xs={24} xl={8}>
              <Form.Item label='Sector Clasificado' name='sectorClasificado' rules={[{ required: true, message: 'Por favor seleccione el sector que corresponda.' }]}>
                <SectorSelect placeholder='Sector Clasificado' />
              </Form.Item>
            </Col>
          }
          { isAgent &&
            <Col xs={24} xl={8}>
              <Form.Item label='Año Declaración' name='fechaDeclaracion' rules={[{ required: true, message: 'Por favor seleccione el año desde la cual va a declarar.' }]}>
                <DateDeclareSelect placeholder='Año Declaración' />
              </Form.Item>
            </Col>
          }
          { isAgent &&
            <Col xs={24} xl={8}>
              <Form.Item label='Mes Declaración' name='mesDeclaracion' rules={[{ required: true, message: 'Por favor seleccione el mes desde la cual va a declarar.' }]}>
                <MonthDeclareSelect placeholder='Mes Declaración' />
              </Form.Item>
            </Col>
          }
        </Row>
        <Row gutter={24} style={{ paddingLeft: 15, paddingRight: 15 }}>
          <Divider orientation='left' style={{ marginLeft: -10 }}>
            <Typography.Title level={4} style={{ marginLeft: 5 }}>
              Datos de Ubicación
            </Typography.Title>
          </Divider>
          <Col xs={24} xl={14}>
            <Form.Item label='Dirección' name='direccion' rules={[{ required: true, message: 'Por favor ingrese la dirección.' }]}>
              <Input placeholder='Dirección' />
            </Form.Item>
          </Col>
          <Col xs={24} xl={10}>
            <Form.Item label='Punto de Referencia' name='puntoReferencia' rules={[{ required: true, message: 'Por favor ingrese el punto de referencia.' }]}>
              <Input placeholder='Punto de Referencia' />
            </Form.Item>
          </Col>
           <Col xs={24} xl={8}>
            <Form.Item label='Municipio' name='parroquia' rules={[{ required: true, message: 'Por favor ingrese el Municipio.' }]}>
              <ParishSelect  />
            </Form.Item>
          </Col>
          {/*<Col xs={24} xl={8}>
            <Form.Item label='Sector' name='sector' rules={[{ required: true, message: 'Por favor ingrese el sector.' }]}>
              <Input placeholder='Sector' />
            </Form.Item>
          </Col> */}
        </Row>
        <Row gutter={24} style={{ paddingLeft: 15, paddingRight: 15 }}>
          <Divider orientation='left' style={{ marginLeft: -10 }}>
            <Typography.Title level={4} style={{ marginLeft: 5 }}>
              Datos de Contacto
            </Typography.Title>
          </Divider>
          <Col xs={24} xl={8}>
            <Form.Item label='Nombre' name='nombre' rules={[{ required: true, message: 'Por favor ingrese el nombre.' }]}>
              <Input placeholder='Nombre' />
            </Form.Item>
          </Col>
          <Col xs={24} xl={8}>
            <Form.Item label='Correo Electrónico' name='correo' rules={[{ required: true, message: 'Por favor ingrese el correo electrónico.' }, { validator: Utils.validators.email }]}>
              <Input style={{textTransform:'lowercase'}} placeholder='Correo Electrónico' />
            </Form.Item>
          </Col>
          <Col xs={24} xl={8}>
            <Form.Item label='Teléfono' name='telefono' rules={[{ required: true, message: 'Por favor ingrese el teléfono.' }]} normalize={Utils.normalize.isNumber}>
              <Input placeholder='Teléfono' maxLength={10} />
            </Form.Item>
          </Col>
        </Row>
        { isAgent && <Form.List name='contactos'>
        {(fields, { add, remove }) => (
          <div>
            {firstListItem(fields, add)}
            {fields.map((field, index) => { 
              return (
              <React.Fragment key={field.key}>
                <Divider style={{ margin: 5, marginLeft: -5 }} orientation='left'>
                  <Typography.Text strong style={{ marginLeft: 5 }}>
                    Contacto Adicional {index + 1}
                  </Typography.Text>
                </Divider>
                <Form.Item required key={field.key} style={{ width: '100%', margin: 0 }}>
                  <Row gutter={24} style={{ padding: '0 20px' }}>
                    <Col xs={24} xl={8}>
                      <Form.Item label='Nombre' name={[field.name, 'nombre']} fieldKey={field.fieldKey} rules={[{ required: true, message: 'Por favor ingrese el nombre.' }]}>
                        <Input placeholder='Nombre'/>
                      </Form.Item>
                    </Col>
                    <Col xs={24} xl={8}>
                      <Form.Item label='Correo Electrónico' name={[field.name, 'correo']} fieldKey={field.fieldKey} rules={[{ required: true, message: 'Por favor ingrese el correo.' }]}>
                        <Input style={{textTransform:'lowercase'}} placeholder='Correo Electrónico'/>
                      </Form.Item>
                    </Col>
                    <Col xs={24} xl={8}>
                      <Form.Item label='Teléfono' name={[field.name, 'telefono']} fieldKey={field.fieldKey} rules={[{ required: true, message: 'Por favor ingrese el número.' }]}>
                        <Input placeholder='Teléfono' maxLength={12} />
                      </Form.Item>
                    </Col>
                    <Col xs={24} xl={8}>
                      <Form.Item label='Cargo' name={[field.name, 'cargo']} fieldKey={field.fieldKey} rules={[{ required: true, message: 'Por favor ingrese el cargo.' }]}>
                        <Input placeholder='Cargo' maxLength={12} />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form.Item>
              </React.Fragment>
            )})}
            <Row gutter={[16, 16]} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              {fields.length > 0 && 
              <Col>
                <Button type='danger' onClick={() => remove(fields.length - 1)}>Eliminar Contacto Adicional</Button>
              </Col>}
              <Col>
                <Button type='dashed' onClick={() => add()}>Agregar Contacto Adicional</Button>
              </Col>
            </Row>
          </div>
        )}
      </Form.List>}
        <Button type='primary' loading={loading} onClick={form.submit}>Registrar</Button>
      </Form>
    </Card>
  );
};

const mapStateToProps = (state: State) => ({ thm: state.thm, auth: state.auth });

export default connect(mapStateToProps)(InternalRegister);

interface InternalRegisterProps {
  thm: States.ThemeColors
  auth: States.Auth
}