import React, { useState, useEffect, useRef, useCallback } from 'react';
import { States, State } from 'sigt';
import { connect } from 'react-redux';
import {
  Card,
  Table,
  message,
  Row,
  Col,
  Form,
  Input,
  Select,
  Button,
  Tooltip,
  Spin,
  Descriptions,
  Popover,
  Modal,
  Timeline,
} from 'antd';
import { useWindowDimensions } from '../../utils/hooks';
import axios from 'axios';
import Highlighter from 'react-highlight-words';
import { generateCertificade } from '../../services/receipt';
import { ColumnsType } from 'antd/lib/table';
import {
  SearchOutlined,
  DeleteOutlined,
  LoadingOutlined,
  UserAddOutlined,
  FileDoneOutlined,
  FileProtectOutlined,
  FolderOutlined,
  FolderOpenOutlined,
  FireOutlined,
  HomeOutlined,
} from '@ant-design/icons';
import { useParams, useHistory } from 'react-router';
import handlingMessage from '../../utils/handlingMessage';
import moment from 'moment';
import { Utils } from '../../utils/validators';
const server = process.env.REACT_APP_SERVER_URL;

const RegisterOwners: React.FC<RegisterOwnersProps> = ({ thm, auth, prsh }) => {
  const [loading, setLoading] = useState(false);
  const [creating, setCreating] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [condominio, setCondominio] = useState<Condomium>();
  const [tipoCondominio, setTipoCondominio] = useState<CondoType>({
    tipo_condominio: 'N/A',
    tarifa_gas: 0,
    tarifa_aseo: 0,
    tarifa_inmueble_urbano: 0,
  });
  const [infoTiposCondominio, setInfoTiposCondominio] = useState();
  const [modalTipoCondominio, setModalTipoCondominio] = useState<boolean>(false);
  const [selectTipo, setSelectTipo] = useState<string>('');
  const [owners, setOwners] = useState<Owner[]>([]);
  const [tipoDocumento, setTipoDocumento] = useState('J');
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [visible, setVisible] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [popoverVisible, setpopoverVisible] = useState<{ [id: string]: boolean }>({});
  const [selectedRecord, setSelectedRecord] = useState<{ record: any; type: 'IU' | 'SM' } | undefined>();
  const inputRef = useRef<any>(null);
  const history = useHistory();
  const { id } = useParams<{ id: string }>();

  const { width } = useWindowDimensions();
  const [form] = Form.useForm();

  const tipoAddon = (
    <Select defaultValue='V' value={tipoDocumento} onChange={(e) => setTipoDocumento(e)}>
      <Select.Option value='V'>V</Select.Option>
      <Select.Option value='E'>E</Select.Option>
      <Select.Option value='J'>J</Select.Option>
      <Select.Option value='G'>G</Select.Option>
      <Select.Option value='P'>P</Select.Option>
    </Select>
  );

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={inputRef}
          placeholder='Buscar'
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type='primary'
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size='small'
          style={{ width: 90, marginRight: 8 }}
        >
          Buscar
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size='small' style={{ width: 90 }}>
          Restablecer
        </Button>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? thm.primaryColor : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        if (inputRef.current) {
          inputRef.current?.select();
        }
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#1e72c675', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  const getCertificate = async (record: Owner, type) => {
    const { dirOwner, parishOwner } = await form.validateFields(['dirOwner', 'parishOwner']);
    setDeleting(true);
    const fechaLetra = `${moment().endOf('M').format('DD')} de ${moment().format('MMMM')} del ${moment().year()}.`;
    const mes = moment().format('MM');
    const anio = moment().format('YYYY');
    const [tipoDocumento, cedulaORif] = record.documento?.split('-');
    const data = {
      contribuyente: record.razonSocial,
      parroquia: parishOwner,
      direccion: dirOwner,
      cedulaORif: `${tipoDocumento}-${cedulaORif}`,
      tipoDocumento,
      fechaLetra,
      mes,
      anio,
      valorFiscal: 0,
    };

    handlingMessage({
      action: () => generateCertificade(data, false, type, auth.token),
      key: 'submit',
      loadingMessage: 'Realizando operacion...',
      cb: (data) => {
        if (data.status === 200) {
          const win = window.open(data.url, '_blank');
          win?.focus();
        }
        setDeleting(false);
        setModalVisible(false);
        form.setFieldsValue({ dirOnwer: null, parishOwner: null });
      },
    });
  };

  const contentPopup = (record, idContribuyente) => (
    <div>
      <Row gutter={[8, 8]}>
        <Tooltip title='Recibo SM' visible={visible}>
          <Col span={24}>
            <Button
              style={{ width: '100%' }}
              onClick={() => {
                setSelectedRecord({ record, type: 'SM' });
                setModalVisible(true);
                setpopoverVisible({ ...popoverVisible, [idContribuyente]: false });
              }}
              shape='round'
              type='primary'
              icon={<FileDoneOutlined />}
              size='small'
            >
              {width > 440 ? 'Recibo SM' : null}
            </Button>
          </Col>
        </Tooltip>
      </Row>
      <Row gutter={8}>
        <Tooltip title='Recibo IU' visible={visible}>
          <Col span={24}>
            <Button
              style={{ width: '100%' }}
              onClick={() => {
                setSelectedRecord({ record, type: 'IU' });
                setModalVisible(true);
                setpopoverVisible({ ...popoverVisible, [idContribuyente]: false });
              }}
              shape='round'
              type='primary'
              icon={<FileProtectOutlined />}
              size='small'
            >
              {width > 440 ? 'Recibo IU' : null}
            </Button>
          </Col>
        </Tooltip>
      </Row>
    </div>
  );

  const columns: ColumnsType<Owner> = [
    {
      title: 'Razón Social',
      dataIndex: 'razonSocial',
      ...getColumnSearchProps('razonSocial'),
    },
    {
      title: 'Documento de Identidad',
      dataIndex: 'documento',
      ...getColumnSearchProps('documento'),
      width: 170,
    },
    {
      title: 'Acciones',
      dataIndex: 'idContribuyente',
      render: (idContribuyente, record) => (
        <>
          {!deleting ? (
            <span>
              <Popover
                content={contentPopup(record, idContribuyente)}
                placement='bottom'
                trigger='click'
                visible={popoverVisible[idContribuyente]}
              >
                <Tooltip title='Timbres Fiscales' visible={visible}>
                  <Button
                    shape='round'
                    type='primary'
                    icon={!visible ? <FolderOutlined /> : <FolderOpenOutlined />}
                    onMouseOver={() => (width > 440 ? setVisible(false) : setVisible(true))}
                    onMouseLeave={() => setVisible(false)}
                    onClick={() => setpopoverVisible({ ...popoverVisible, [idContribuyente]: true })}
                    size='small'
                  >
                    {width > 440 ? 'Timbres Fiscales' : null}
                  </Button>
                </Tooltip>
              </Popover>
              <Tooltip style={{ marginLeft: 8 }} title='Eliminar Propietario'>
                <Button
                  type='link'
                  icon={<DeleteOutlined />}
                  onClick={() => deleteOwner(idContribuyente)}
                  style={{ border: 'none' }}
                />
              </Tooltip>
            </span>
          ) : (
            <Spin indicator={<LoadingOutlined />} style={{ marginLeft: 20 }} />
          )}
        </>
      ),
    },
  ];

  useEffect(() => {
    fetchCondominium();
    // eslint-disable-next-line
  }, []);

  const editCondoType = async () => {
    const type = selectTipo === 'tipoA' ? 1 : selectTipo === 'tipoB' ? 2 : 3;
    try {
      console.log(auth.token);
      const response = await axios.put(
        `${server}/condominiumType/edit/${id}`,
        { type },
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      );
      console.log(response.data);
    } catch (e) {
      console.log(e);
    }
  };

  const fetchCondoType = useCallback(async () => {
    if (auth.token) {
      setLoading(true);
      try {
        const res = await axios.get(`${server}/condominiumType/search/${id}`, {
          headers: { Authorization: `Bearer ${auth.token}` },
        });
        if (res) {
          setTipoCondominio(res.data.tipoCondominio);
        }

        setInfoTiposCondominio(res.data.infoCondominios);
        setSelectTipo(
          res.data.tipoCondominio.tipo_condominio === 'TIPO A'
            ? 'tipoA'
            : res.data.tipoCondominio.tipo_condominio === 'TIPO B'
            ? 'tipoB'
            : res.data.tipoCondominio.tipo_condominio === 'TIPO B'
            ? 'tipoC'
            : 'N/A'
        );
      } catch (e) {
        message.error(e.response?.data?.message || 'Error al obtener tipo de condominio');
      } finally {
        setLoading(false);
      }
    }
  }, [auth.token, id]);

  const editCondoTypeHandler = () => {
    if (['tipoA', 'tipoB', 'tipoC'].some((elem) => elem === selectTipo)) {
      console.log(selectTipo);
      handlingMessage({
        action: () => editCondoType(),
        key: 'submit',
        loadingMessage: 'Realizando operacion...',
        cb: () => {
          setTipoCondominio((oldState) => {
            return {
              ...oldState,
              tipo_condominio: selectTipo === 'tipoA' ? 'TIPO A' : selectTipo === 'tipoB' ? 'TIPO B' : 'TIPO C',
            };
          });
          setModalTipoCondominio(false);
        },
      });
    } else {
      message.error('debe ingresar un tipo para ser asignado!');
    }
  };

  useEffect(() => {
    fetchCondoType();
  }, [fetchCondoType, auth]);

  const fetchCondominium = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${server}/condominium/${id}`, { headers: { Authorization: `Bearer ${auth.token}` } });
      setCondominio(response.data.data);
      setOwners(response.data.data.owners);
    } catch (e) {
      message.error(e.response?.data?.message || 'Error al obtener condominios');
    } finally {
      setLoading(false);
    }
  };

  const addOwner = async () => {
    const values = await form.validateFields();
    if (!tipoDocumento) return message.error('Debe incluir el tipo de documento');
    setCreating(true);
    try {
      const response = await axios.post(
        `${server}/condominium/${id}/owner`,
        { condo_id: +id, type_doc: tipoDocumento, doc: values.documento },
        { headers: { Authorization: `Bearer ${auth.token}` } }
      );
      message.success('Propietario agregado de manera exitosa');
      setOwners(response.data.data.owners);
    } catch (e) {
      message.error(e.response?.data?.message || 'Error al agregar propietario');
    } finally {
      setCreating(false);
    }
  };

  const deleteOwner = async (idContribuyente: number) => {
    setDeleting(true);
    try {
      const response = await axios.delete(`${server}/condominium/${id}/owner/${idContribuyente}`, {
        headers: { Authorization: `Bearer ${auth.token}` },
      });
      message.success('Propietario eliminado de manera exitosa');
      const _owners = owners.filter((o) => o.idContribuyente !== idContribuyente);
      setOwners(_owners);
    } catch (e) {
      message.error(e.response?.data?.message || 'Error al eliminar propietario');
    } finally {
      setDeleting(false);
    }
  };

  return (
    <Card
      style={{ height: '100%' }}
      title='Propietarios'
      bodyStyle={{ height: 'calc(100% - 88px)', overflowY: 'scroll', overflowX: 'hidden', border: '1px solid #f0f0f0' }}
      headStyle={{ height: 64, backgroundColor: thm.primaryColor, padding: width < 1200 ? '0 10px' : '0 20px', color: 'white' }}
    >
      <Modal
        visible={modalTipoCondominio}
        onCancel={() => setModalTipoCondominio(false)}
        onOk={editCondoTypeHandler}
        bodyStyle={{ height: '240px' }}
      >
        <h2>Seleccionar tipo de Condominio</h2>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'space-around' }}>
          <section style={{ margin: '1rem' }}>
            <Select defaultValue={tipoCondominio?.tipo_condominio || 'N/A'} onChange={setSelectTipo}>
              <Select.Option value='tipoA'>TIPO A</Select.Option>
              <Select.Option value='tipoB'>TIPO B</Select.Option>
              <Select.Option value='tipoC'>TIPO C</Select.Option>
            </Select>
          </section>
          <section style={{ margin: '1.5rem', marginLeft: '3rem' }}>
            <Timeline>
              <Timeline.Item dot={<FireOutlined style={{ color: 'orange' }} />}>
                tarifa gas: {infoTiposCondominio?.[selectTipo]?.[0].tarifa_gas || 'N/A'}
                {infoTiposCondominio?.[selectTipo]?.[0].tarifa_gas &&
                  (infoTiposCondominio?.[selectTipo]?.[0].tarifa_gas === 1 ? ' petro' : ' petros')}
              </Timeline.Item>
              <Timeline.Item dot={<DeleteOutlined style={{ color: 'gray' }} />}>
                tarifa aseo: {infoTiposCondominio?.[selectTipo]?.[0].tarifa_aseo || 'N/A'}
                {infoTiposCondominio?.[selectTipo]?.[0].tarifa_aseo &&
                  (infoTiposCondominio?.[selectTipo]?.[0].tarifa_aseo === 1 ? ' petro' : ' petros')}
              </Timeline.Item>
              <Timeline.Item dot={<HomeOutlined style={{ color: 'lightblue' }} />}>
                tarifa inmueble: {infoTiposCondominio?.[selectTipo]?.[0].tarifa_inmueble_urbano || 'N/A'}
                {infoTiposCondominio?.[selectTipo]?.[0].tarifa_inmueble_urbano &&
                  (infoTiposCondominio?.[selectTipo]?.[0].tarifa_inmueble_urbano === 1 ? ' petro' : ' petros')}
              </Timeline.Item>
            </Timeline>
          </section>
        </div>
      </Modal>
      <Descriptions
        title='Condominio'
        size='middle'
        column={width > 992 ? 3 : 1}
        layout={width > 768 ? 'horizontal' : 'vertical'}
        bordered
      >
        <Descriptions.Item label='Razón Social'>{condominio?.razonSocial || ''}</Descriptions.Item>
        <Descriptions.Item label='Documento'>{condominio?.documento || ''}</Descriptions.Item>
        <Descriptions.Item label='Tipo de Condominio' style={{ width: '250px' }}>
          {tipoCondominio?.tipo_condominio || 'N/A'}
          <Button type='primary' onClick={() => setModalTipoCondominio(true)} style={{ marginLeft: '2rem' }}>
            Cambiar Tipo
          </Button>
        </Descriptions.Item>
      </Descriptions>
      <Form style={{ marginTop: 16 }} scrollToFirstError layout='vertical' form={form}>
        <Row gutter={24}>
          <Col xs={24} xl={8}>
            <Form.Item
              name='documento'
              label='Documento de Identidad'
              rules={[{ required: true, message: 'Debe ingresar el documento de identidad' }]}
              normalize={Utils.normalize.isCNumber}
            >
              <Input placeholder='Documento de Identidad' addonBefore={tipoAddon} />
            </Form.Item>
          </Col>
          <Col xs={24} xl={8}>
            <Button
              loading={creating}
              style={{ marginTop: width < 1200 ? 0 : 40 }}
              onClick={() => addOwner()}
              type='primary'
              icon={<UserAddOutlined />}
            >
              Agregar Propietario
            </Button>
          </Col>
        </Row>
        <Table loading={loading} columns={columns} dataSource={owners} rowKey={({ idContribuyente }) => idContribuyente} />
        <Button type='primary' onClick={() => history.push('/dashboard/condominios/registro')}>
          Atrás
        </Button>
      </Form>
      <Modal
        visible={modalVisible}
        title='Direccion de Inmueble'
        cancelText='Atrás'
        onCancel={() => setModalVisible(false)}
        okText='Generar solvencia'
        onOk={() => {
          getCertificate(selectedRecord?.record, selectedRecord?.type);
        }}
        width={450}
      >
        <Form form={form} layout='vertical'>
          <Row gutter={24}>
            <Col span={24}>
              <Form.Item
                label='Municipio'
                name='parishOwner'
                rules={[{ required: true, message: 'Debe seleccionar el municipio de la propiedad correspondiente' }]}
              >
                <Select placeholder='Municipio'>
                  {prsh.parish.map((p) => (
                    <Select.Option value={p.nombre} key={p.id}>
                      {p.nombre}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label='Dirección'
                name='dirOwner'
                rules={[{ required: true, message: 'Es obligatorio ingresar la direccion de la propiedad correspondiente' }]}
              >
                <Input.TextArea rows={4} placeholder='Dirección de la propiedad' />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </Card>
  );
};

const mapStateToProps = (state: State) => ({ thm: state.thm, auth: state.auth, prsh: state.prsh });

export default connect(mapStateToProps)(RegisterOwners);

interface RegisterOwnersProps {
  thm: States.ThemeColors;
  auth: States.Auth;
  prsh: States.Parish;
}

interface Condomium {
  idCondominio: number;
  documento: string;
  razonSocial: string;
  owners: Owner[];
}

interface Owner {
  idCondominio: number;
  idContribuyente: number;
  razonSocial: string;
  documento: string;
  parroquia: string;
  direccion: string;
}

interface CondoType {
  tipo_condominio: string;
  tarifa_gas: number;
  tarifa_aseo: number;
  tarifa_inmueble_urbano: number;
}
