import React, { useState, useEffect } from 'react';
import { Form, Spin, Checkbox, Row, Col, Input, Divider, Typography, Select } from 'antd';
import axios from 'axios';
import { States, State, Parroquia } from 'sigt';
import { connect } from 'react-redux';
import { LoadingOutlined } from '@ant-design/icons';
import { FormInstance } from 'antd/lib/form';
import { useParams } from 'react-router';
import { Utils } from '../../../utils/validators';
const server = process.env.REACT_APP_SERVER_URL;

const SimpleEstimation: React.FC<SimpleEstimationProps> = ({ auth, form, procedures, data, setData }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [resources, setResources] = useState<FiscalResources | null>(null);
  const [sections, setSections] = useState<{ [P: string]: ParishExtended | undefined }[]>([]);
  const [years, setYears] = useState<string[]>([]);
  const [constructValue, setConstructValue] = useState<{ [P: string]: number }[]>([]);
  const [landValue, setLandValue] = useState<{ [P: string]: number }[]>([]);

  const { idTramite } = useParams();
  const procedureInstance = procedures.find(p => p.id === parseInt(idTramite || '0'));

  const formatCurrency = (number: number) => new Intl.NumberFormat('de-DE').format(number);

  useEffect(() => {
    console.log(resources, "resources");
  }, [resources])

  const getResources = async () => {
    if(!loading) {
      setLoading(true);
      const result = await axios.get<{ datos: FiscalResources }>(`${server}/taxValues/resources`, { headers: { Authorization: `Bearer ${auth.token}` } });
      console.log(result, "res")
      setResources(() => {
        setLoading(false);
        return result.data.datos;
      });
    }
  };

  const calculateLandValues = (section: string, area: number) => {
    setLandValue(years.map((y) => ({
      [y]: (resources?.anos[y].parroquias.find(c => c.descripcion === data.parroquiaEdificio)?.sectores.find(s => s.descripcion === section)?.terreno.valorFiscal || 0) * area
    })));
  };

  const calculateConstructionValue = (model: string, area: number) => {
    setConstructValue(years.map(y => ({
      [y]: (resources?.anos[y]?.construcciones.find(c => c.tipoConstruccion.modeloConstruccion === model)?.valorFiscal || 0) * area
    })));
  };

  useEffect(() => {
    if(years.length > 0 && constructValue.length > 0 && landValue.length > 0) {
      form.setFieldsValue({
        estimacionSimple: {
          valoresFiscales: {
            [years[0]]: `Petros ${(constructValue[0][years[0]] + landValue[0][years[0]])}`,
            [years[1]]: `Petros ${(constructValue[1][years[1]] + landValue[1][years[1]])}`,
            [years[2]]: `Petros ${(constructValue[2][years[2]] + landValue[2][years[2]])}`,
            [years[3]]: `Petros ${(constructValue[3][years[3]] + landValue[3][years[3]])}`,
            [years[4]]: `Petros ${(constructValue[4][years[4]] + landValue[4][years[4]])}`
          }
        }
      });
    }
    // eslint-disable-next-line
  }, [constructValue, landValue]);

  useEffect(() => {
    const type: string = procedureInstance?.datos.usuario?.tipoInmuebleSolvencia;
    form.setFieldsValue({
      estimacionSimple: {
        esTerreno: type === 'terreno' || type === 'terrenoConst',
        esConstruccion: type === 'construccion' || type === 'terrenoConst'
      }
    })
    // eslint-disable-next-line
  }, [procedureInstance]);

  useEffect(() => {
    if(!resources) {
      getResources();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if(Object.keys(data).length === 0 && form.getFieldsValue().propietarios) {
      setData(form.getFieldsValue());
    }
    if(resources) {
      const _years = Object.keys(resources.anos).sort((a, b) => {
        if(a > b) return -1;
        if(a < b) return 1;
        return 0;
      });
      setYears(_years);
      if(constructValue.length === 0) {
        setConstructValue(_years.map(y => ({ [y]: 0 })));
        setLandValue(_years.map(y => ({ [y]: 0 })));
      };
      const _sections = _years.map(y => ({
        [y]: resources.anos[y].parroquias.find(p  => p.descripcion === form.getFieldValue('parroquiaEdificio'))
      })).sort((a, b) => {
        if(Object.keys(a)[0] > Object.keys(b)[0]) return -1;
        if(Object.keys(a)[0] < Object.keys(b)[0]) return 1;
        return 0;
      });
      setSections(_sections);
    }
    if(sections.length > 0 && years.length > 0 && sections[0][years[0]]?.descripcion !== data.parroquiaEdificio && data.estimacionSimple) {
      form.setFieldsValue({ ...data, estimacionSimple: {  ...data.estimacionSimple, terreno: { ...data.estimacionSimple.terreno, sector: undefined, valorSector: undefined } } });
      setLandValue(years.map(y => ({ [y]: 0 })));
    }
    // eslint-disable-next-line
  }, [form, resources, data.parroquiaEdificio]);

  useEffect(() => {
    if(data && sections[0]){
    if(data.estimacionSimple?.terreno?.sector && data.estimacionSimple?.terreno?.area) {
      const valorSector = (sections[0][years[0]]?.sectores.find(s => s.descripcion === data.estimacionSimple?.terreno?.sector)?.terreno?.valorFiscal || 0)
        * data.estimacionSimple?.terreno?.area;
      calculateLandValues(data.estimacionSimple?.terreno?.sector, data.estimacionSimple?.terreno?.area);
      form.setFieldsValue({
        estimacionSimple: {
          terreno: {
            valorSector: `Petros ${formatCurrency(valorSector)}`
          }
        }
      })
    } else if(!data.estimacionSimple?.terreno?.area && data.estimacionSimple?.terreno?.sector) {
      setLandValue(years.map(y => ({ [y]: 0 })))
      form.setFieldsValue({
        estimacionSimple: {
          terreno: {
            valorSector: 'Petros 0'
          }
        }
      })
    }
  }
    // eslint-disable-next-line
  }, [data.estimacionSimple?.terreno?.sector, data.estimacionSimple?.terreno?.area, sections]);

  useEffect(() => {
    if(data.estimacionSimple?.construccion?.modelo && data.estimacionSimple?.construccion?.area) {
      const valorModelo = (parseFloat(resources?.anos[years[0]].construcciones?.find(c => c.tipoConstruccion.modeloConstruccion === data.estimacionSimple?.construccion?.modelo)?.valorFiscal.toString() || '0') *
        data.estimacionSimple?.construccion?.area);
      calculateConstructionValue(data.estimacionSimple?.construccion?.modelo, data.estimacionSimple?.construccion?.area);
      form.setFieldsValue({
        estimacionSimple: {
          construccion: {
            valorModelo: `Petros ${formatCurrency(valorModelo)}`
          }
        }
      });
    } else if(data.estimacionSimple?.construccion?.modelo && !data.estimacionSimple?.construccion?.area) {
      setConstructValue(years.map(y => ({ [y]: 0 })));
      form.setFieldsValue({
        estimacionSimple: {
          construccion: {
            valorModelo: `Petros 0`
          }
        }
      })
    }
    // eslint-disable-next-line
  }, [data.estimacionSimple?.construccion?.modelo, data.estimacionSimple?.construccion?.area]);

  return (
    <React.Fragment>
      {loading && 
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', margin: '20px 0px' }}>
          <Spin indicator={<LoadingOutlined style={{ fontSize: 50, color: '#1890ff' }} spin />} size='large' tip='Cargando valores fiscales...' />
        </div>
      }
      {!loading &&
      <Row gutter={24}>
        <Col xs={24} xl={6}>
          <Form.Item name={['estimacionSimple', 'esTerreno']} valuePropName='checked'>
            <Checkbox>Terreno</Checkbox>
          </Form.Item>
        </Col>
        <Col xs={24} xl={6}>
          <Form.Item name={['estimacionSimple', 'esConstruccion']} valuePropName='checked'>
            <Checkbox>Construcción</Checkbox>
          </Form.Item>
        </Col>
      </Row>}
      {!loading && form.getFieldValue(['estimacionSimple', 'esTerreno']) && 
      <React.Fragment>
        <Divider orientation='left' style={{ marginLeft: -35 }}>
          <Typography.Title level={4}>Terreno</Typography.Title>
        </Divider>
        <Row gutter={24}>
          <Col xs={24} xl={8}>
            <Form.Item label='Área' name={['estimacionSimple', 'terreno', 'area']} rules={[{ required: true, message: 'Debe ingresar el área del terreno' }]}
              normalize={Utils.normalize.isNumber}>
              <Input placeholder='Área'></Input>
            </Form.Item>
          </Col> 
          <Col xs={24} xl={8}>
            <Form.Item label='Sector' name={['estimacionSimple', 'terreno', 'sector']} rules={[{ required: true, message: 'Debe ingresar el sector del terreno' }]}>
              <Select placeholder='Sector'>
                {years.length > 0 && sections.length > 0 && sections[0][years[0]]?.sectores.map(s =>
                  <Select.Option value={s.descripcion} key={s.id}>
                    {s.descripcion}
                  </Select.Option>  
                )}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} xl={8}>
            <Form.Item label='Valor Fiscal' name={['estimacionSimple', 'terreno', 'valorSector']}>
              <Input readOnly placeholder='Valor Fiscal' />
            </Form.Item>
          </Col>
        </Row>
      </React.Fragment>}
      {!loading && form.getFieldValue(['estimacionSimple', 'esConstruccion']) &&
      <React.Fragment>
        <Divider orientation='left' style={{ marginLeft: -35 }}>
          <Typography.Title level={4}>Construcción</Typography.Title>
        </Divider>
        <Row gutter={24}>
          <Col xs={24} xl={8}>
            <Form.Item label='Área' name={['estimacionSimple', 'construccion', 'area']} rules={[{ required: true, message: 'Debe ingresar el área del terreno' }]}
                normalize={Utils.normalize.isNumber}>
              <Input placeholder='Área'></Input>
            </Form.Item>
          </Col>
          <Col xs={24} xl={8}>
            <Form.Item label='Modelo' name={['estimacionSimple', 'construccion', 'modelo']} rules={[{ required: true, message: 'Debe ingresar el sector del terreno' }]}>
              <Select placeholder='Modelo'>
                {years.length > 0 && resources?.anos[years[0]].construcciones.map(s =>
                  <Select.Option value={s.tipoConstruccion.modeloConstruccion} key={s.tipoConstruccion.id}>
                    {s.tipoConstruccion.modeloConstruccion}
                  </Select.Option>  
                )}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} xl={8}>
            <Form.Item label='Valor Fiscal' name={['estimacionSimple', 'construccion', 'valorModelo']}>
              <Input readOnly placeholder='Valor Fiscal' />
            </Form.Item>
          </Col>
        </Row>
      </React.Fragment>}
      {!loading && 
      <React.Fragment>
        <Divider orientation='left' style={{ marginLeft: -35 }}>
          <Typography.Title level={4}>Valor Fiscal</Typography.Title>
        </Divider>
        <Row gutter={24}>
          {years.map(y => 
            <Col xs={24} xl={4} key={y}>
              <Form.Item name={['estimacionSimple', 'valoresFiscales', y]} label={y}>
                <Input readOnly placeholder='Valor Fiscal' />
              </Form.Item>
            </Col>
          )}
        </Row>
      </React.Fragment>}
    </React.Fragment>
  );
};

const mapStateToProps = (state: State) => ({ auth: state.auth, procedures: state.prcd.procedures });

export default connect(mapStateToProps)(SimpleEstimation);

interface SimpleEstimationProps {
  auth: States.Auth
  form: FormInstance
  procedures: States.Procedures['procedures']
  data: any
  setData: (d: any) => any
};

export interface FiscalResources {
  parroquias: Parroquia[]
  anos: FiscalYear
  tiposConstruccion: BuildingType[]
};

interface FiscalYear {
  [P: string]: {
    id: number
    parroquias: ParishExtended[]
    construcciones: Building[]
  }
}

interface ParishExtended {
  id: number
  descripcion: string
  sectores: Section[]
  key?: number
}

interface Section {
  id: number
  descripcion: string
  terreno: Land
  key?: number
}

interface Land {
  id: number
  valorFiscal: number
}

interface Building {
  id: number
  valorFiscal: number
  tipoConstruccion: BuildingType
  key?: number
}

interface BuildingType {
  id: number
  modeloConstruccion: string
}