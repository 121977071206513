import { Button, Card, Col, Form, Input, message, Modal, Row, Select, Typography } from 'antd';
import React, { useState, useRef, createRef, RefObject } from 'react';
import { connect } from 'react-redux';
import { State } from 'sigt';
import { useWindowDimensions } from '../../utils/hooks';
import axios from 'axios';
import { useHistory } from 'react-router';
import { DeleteOutlined } from '@ant-design/icons';
import handlingMessage from '../../utils/handlingMessage';
const server = process.env.REACT_APP_SERVER_URL;

const BookDownload = ({ thm, auth }) => {
  const { width } = useWindowDimensions();
  const [form] = Form.useForm();
  const [modalVisible, setModalVisible] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [filter, setFilter] = useState(false);
  const history = useHistory();

  const getBook = async (data, token) => {
    try {
      const response = await axios.post(`${server}/branches/userBook`, data, { headers: { Authorization: `Bearer ${token}` } });
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    let values = await form.validateFields();
    values = {...values, id: auth?.user?.contribuyente?.id}
    handlingMessage({
      action: () => {
        return getBook(values, auth.token);
      },
      key: 'submit',
      loadingMessage: 'Realizando operacion...',
      cb: (data) => {
        if (data.status === 200) {
          const win = window.open(data.data, '_blank');
          console.log(data.data);
          win?.focus();
        }
        setLoading(false);
      },
    });
  };

  return (
    <React.Fragment>
      <Modal
        width='600px'
        bodyStyle={{ height: '45vh', overflowY: 'scroll' }}
        centered
        visible={modalVisible}
        footer={null}
        closable
        title='Descargar Libro de Timbres Autogenerado'
        maskClosable
        onCancel={() => history.goBack()}
      >
        <Form form={form} layout='vertical' onFinish={handleSubmit}>
          <Row style={{ paddingLeft: 16, paddingBottom:'10px' }} gutter={16}>
            <Col span={16}>
              <Button type='primary' onClick={() => setFilter(!filter)} loading={loading}>
                {filter ? 'No filtrar por sucursal' : 'Filtrar por sucursal'}
              </Button>
            </Col>
          </Row>
          { filter && auth?.user?.contribuyente?.sucursales && auth?.user?.contribuyente?.sucursales?.length > 0 &&
          <Row style={{ paddingLeft: 16, paddingBottom:'10px' }} gutter={16}>
            <Col span={16}>
              <Form.Item name='rim'>
                <Select placeholder={'Seleccione la sucursal'} defaultActiveFirstOption>
                  {auth?.user?.contribuyente?.sucursales.map((s) => 
                    <Select.Option value={s.id}>{`${s.denomComercial} - ${s.direccion}`}</Select.Option>
                  )}
                </Select>
              </Form.Item>
            </Col>
          </Row>}
          <Row style={{ paddingLeft: 16 }} gutter={16}>
            <Col span={16}>
              <Button type='primary' htmlType='submit' loading={loading}>
                Descargar 
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

const mapStateToProps = (state: State) => ({ thm: state.thm, auth: state.auth });

export default connect(mapStateToProps)(BookDownload);
