import React from 'react';
import { Select } from 'antd';

const SectorSelect: React.FC<SectorSelectProps> = ({ onChange, value, placeholder, disabled = false }) => {
  return (
    <Select value={value} onChange={onChange} placeholder={placeholder} disabled={disabled}>
      <Select.Option value='SECTOR ENTES NACIONALES'>SECTOR ENTES NACIONALES</Select.Option>
      <Select.Option value='SECTOR ENTES MUNICIPALES'>SECTOR ENTES MUNICIPALES</Select.Option>
      <Select.Option value='SECTOR ENTES GOBERNACION'>SECTOR ENTES GOBERNACION</Select.Option>
      <Select.Option value='SECTOR EDUCACION'>SECTOR EDUCACION</Select.Option>
      <Select.Option value='SECTOR MILITAR'>SECTOR MILITAR</Select.Option>
      <Select.Option value='SECTOR FINANCIERO'>SECTOR FINANCIERO</Select.Option>
      <Select.Option value='EMPRESAS DE PRODUCCION SOCIAL EPS'>EMPRESAS DE PRODUCCION SOCIAL EPS</Select.Option>
    </Select>
  );
};

export default SectorSelect;

interface SectorSelectProps {
  onChange?: (e: any) => any
  value?: any
  placeholder?: string
  disabled?: boolean
}