import React, { Fragment, useEffect, useState } from 'react';
import axios from 'axios';
import { Modal, Row, Form, DatePicker, Col, Select, Input, Button, Divider, message } from 'antd';
import { useHistory, useParams } from 'react-router';
import handlingMessage from '../utils/handlingMessage';
import { BarChartOutlined } from '@ant-design/icons';
import { States, State } from 'sigt';
import { connect } from 'react-redux';
import {
  newReportPdf,
  reportCondo,
  newReportExcel,
  closureCash,
  clouserAllCash,
  newReportExcelIVA,
  reportDAE,
  reportTransf,
  reportTransfBank,
  reportCondoDisclosed,
  newReportPdfForCity,
  reportContributors,
  reportContributorsbyCity,
  reportInstitucions,
  reportEntities,
  clouserOneCash,
  reportCas,
  reportCasBank,
  reportDep,
  reportDepBank,
  reportAllMethods,
  reportAllMethodsBank,
  reportSelection,
  reportAESelection,
  reportContributorsRequest,
  reportMines,
  reportStamp,
  reportRepairs,
  reportAgreements,
  reportPendingAgreements,
  reportFines,
  reportCivilStamp,
  reportPendingFines,
  reportPendingRepairs,
  reportTolls,
  reportAgreementPdf,
  reportPendingAgreementPdf,
  reportFinesPdf,
  reportPendingFinesPdf
} from '../services/report';
import moment from 'moment';
import { Utils } from '../utils/validators';
import { useWindowDimensions } from '../utils/hooks';
import ParishSelect from './Forms/components/ParishSelect';
const server = process.env.REACT_APP_SERVER_URL;

const Report: React.FC<ReportProps> = ({ auth, brch, banks, institutions, parish }) => {
  const [bankState, setBankState] = useState();
  const [includeBank, setIncludeBank] = useState(false);
  const [methodState, setMethodState] = useState();
  const [cityState, setCityState] = useState(false);
  const [aeReportState, setAEReportState] = useState();
  const [form] = Form.useForm();
  const disabledDate = (current) => current > moment().endOf('day');
  const [loading, setLoading] = useState(false);
  const params: any = useParams();
  const history = useHistory();
  const { width } = useWindowDimensions();
  const { RangePicker } = DatePicker;
  const { Option } = Select;
  const selectedInstitution = institutions?.find((i) => i.id === 9);

  const monthlyAEReports = [5,7,8];
  const rangedMonthlyAEReports = [11];

  useEffect(() => {
    setBankState(undefined);
  },[includeBank]);

  const handlerResquest = (type, token, values) => {
    switch (type) {
      case 3:
        return newReportExcel(values, token ? token : '');
      case 4:
        return newReportExcelIVA(values, token ? token : '');
      case 5:
        // return closureCash(values.rango);
        return clouserOneCash(values);
      case 6:
        return clouserAllCash(values);
      case 7:
        return reportDAE(auth.user?.contribuyente, values.rim, token);
      case 8:
        const [from, to] = values.rango;
        return reportTransf(
          { from: !monthlyAEReports.includes(aeReportState || 0) ? moment(from).utcOffset(-4).startOf('day') : null, to: !monthlyAEReports.includes(aeReportState || 0) ? moment(to).utcOffset(-4).endOf('day') : null },
          token
        );
      case 9:
        return reportCondo({ from: values.rango[0], to: values.rango[1] }, token);
      case 10:
        return reportTransfBank({ from: values.rango[0], to: values.rango[1] }, token, bankState);
      case 11:  return reportCondoDisclosed({ from: values.rango[0], to: values.rango[1] }, token);
      case 12: return newReportPdfForCity(values, token ? token : '');
      case 13:
        return reportContributors(
          { 
            from: values.rango[0], to: values.rango[1] 
          },
            token
        );
      case 14:
        return reportContributors(
          { from: values.rango[0], to: values.rango[1], city: parish.find((p) => p.nombre === values.parroquia)?.id },
          token
        );
      case 15:
        return reportInstitucions({}, token);
      case 16:
        return reportEntities({}, token);
      case 17:
        return reportCas({ from: values.rango[0], to: values.rango[1] }, token);
      case 18:
        return reportCasBank({ from: values.rango[0], to: values.rango[1] }, token, bankState);
      case 19:
        return reportDep({ from: values.rango[0], to: values.rango[1] }, token);
      case 20:
        return reportDepBank({ from: values.rango[0], to: values.rango[1] }, token, bankState);
      case 21:
        return reportAllMethods({ from: values.rango[0], to: values.rango[1] }, token);
      case 22:
        return reportAllMethodsBank({ from: values.rango[0], to: values.rango[1] }, token, bankState);
      case 23:
        return reportSelection({ from: values.rango[0], to: values.rango[1] }, token, methodState, bankState);
      case 24:
        return reportAESelection({ from: !monthlyAEReports.includes(aeReportState || 0) ? values.rango[0] : moment(values.rango).startOf('month'), 
        to: !monthlyAEReports.includes(aeReportState || 0) ? values.rango[1] : moment(values.rango).endOf('month'), city: parish.find((p) => p.nombre === values.parroquia)?.id }, token, aeReportState);
      case 25:
        return reportContributorsRequest({from: values.rango[0], to: values.rango[1]}, token);
      case 26:
        return reportMines({ from: values.rango[0], to: values.rango[1] }, token);
      case 27:
        return reportStamp({ from: values.rango[0], to: values.rango[1] }, token);
      case 28:
        return reportRepairs({ from: values.rango[0], to: values.rango[1] }, token);
      case 29:  
        return reportAgreements({ from: values.rango[0], to: values.rango[1] }, token);
      case 30:
        return reportPendingAgreements({ from: values.rango[0], to: values.rango[1] }, token);
      case 31:
        return reportFines({ from: values.rango[0], to: values.rango[1] }, token);
      case 32:
        return reportCivilStamp({ from: values.rango[0], to: values.rango[1] }, token);
      case 33:
        return reportPendingFines({ from: values.rango[0], to: values.rango[1] }, token);
      case 34:
        return reportPendingRepairs({ from: values.rango[0], to: values.rango[1] }, token);   
      case 35:
        return reportTolls({ from: values.rango[0], to: values.rango[1], type: values.toll === 'Todos' ? null : values.toll}, token);   
      case 36:
        return reportAgreementPdf({ from: values.rango[0], to: values.rango[1] }, token);
      case 37:
        return reportPendingAgreementPdf({ from: values.rango[0], to: values.rango[1] }, token);
      case 38:
        return reportFinesPdf({ from: values.rango[0], to: values.rango[1] }, token);
      case 39:
        return reportPendingFinesPdf({ from: values.rango[0], to: values.rango[1] }, token);
      default:
        return newReportPdf(values, token ? token : '');
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    const values = await form.validateFields();
    values.alcaldia = +params.type === 2 ? true : false;
    handlingMessage({
      action: () => {
        return handlerResquest(+params.type, auth.token, values);
      },
      key: 'submit',
      loadingMessage: 'Realizando operacion...',
      cb: (data) => {
        if (data.status === 200) {
          const win = window.open(data.data, '_blank');
          console.log(data.data);
          win?.focus();
        }
        setLoading(false);
      },
    });
  };

  const getReportDefault = (type: 'day' | 'month') => {
    form.setFieldsValue({ rango: [moment().startOf(type), moment().endOf(type).utcOffset("+04:00",true)] });
    form.submit();
  };

  const getMethod = (num) => {
    switch (num) {
      case 1:
        return 'TODOS';
      case 2:
        return 'TRANSFERENCIA';
      case 3:
        return 'PUNTO DE VENTA';
      case 4:
        return 'DEPOSITO';
      default:
        return 'TODOS';
    }
  }

  return (
    <React.Fragment>
      <Row style={{ height: '100%', width: '100%' }} justify='center' align='middle'>
        <BarChartOutlined style={{ color: 'lightgrey', fontSize: 250, textAlign: 'center' }} />
      </Row>
      <Modal
        maskClosable
        onCancel={() => history.goBack()}
        title='Reporte'
        okText='Generar'
        confirmLoading={loading}
        onOk={() => form.submit()}
        visible
        bodyStyle={{ margin: '0 35px' }}
      >
        {+params.type !== 11 &&
         +params.type !== 15 && +params.type !== 16 &&(
          <>
            <Row justify='space-between' gutter={[16, 8]}>
              <Col style={width < 768 ? { width: '100%' } : {}}>
                <Button
                  loading={loading}
                  style={{ width: '100%' }}
                  onClick={() => getReportDefault('day')}
                  size={width < 768 ? 'middle' : 'small'}
                  type='primary'
                >
                  Obtener reporte del día
                </Button>
              </Col>
              <Col style={width < 768 ? { width: '100%' } : {}}>
                <Button
                  loading={loading}
                  style={{ width: '100%' }}
                  onClick={() => getReportDefault('month')}
                  size={width < 768 ? 'middle' : 'small'}
                >
                  Obtener reporte del mes
                </Button>
              </Col>
            </Row>
            <Divider />
          </>
        )}
        <Form onFinish={handleSubmit} form={form} layout='vertical'>
          <Row gutter={16}>
            <Col flex={1}>
              {+params.type === 7 ? ( +params.type !== 15 && +params.type !== 16 &&
                <Form.Item
                  label='Documento de Identidad'
                  rules={[{ required: true, message: 'Por favor ingresar Documento de Identidad!' }]}
                  normalize={Utils.normalize.isCNumber}
                  name='rim'
                >
                  <Input placeholder='Documento de Identidad' />
                </Form.Item>
              ) : ( +params.type !== 15 && +params.type !== 16 &&
                <Form.Item
                  label={+params.type === 60 ? null : (!monthlyAEReports.includes(aeReportState || 0) ? 'Rango de fechas' : 'Mes seleccionado')}
                  rules={[
                    {
                      required: true,
                      message: 'Por favor ingresar fecha!',
                    },
                  ]}
                  name='rango'
                >
                  {+params.type === 60 ? (
                    <DatePicker format='DD/MM/YYYY' style={{ width: '100%' }} disabledDate={disabledDate} />
                  ) : (monthlyAEReports.includes(aeReportState || 0) ) ? (
                    <DatePicker format='MM/YYYY' picker='month' style={{ width: '100%' }} disabledDate={disabledDate} />
                  ) : (rangedMonthlyAEReports.includes(aeReportState || 0) ) ? (
                    <RangePicker format='MM/YYYY' picker='month' style={{ width: '100%' }} disabledDate={disabledDate} />
                  ) : (
                    <RangePicker format='DD/MM/YYYY' style={{ width: '100%' }} disabledDate={disabledDate} />
                  )}
                </Form.Item>
              )}
              {(+params.type === 23) && (
                <Form.Item>
                  <Select placeholder={'Seleccione un método de pago'} onChange={(e) => setMethodState(e)}>
                    <Select.Option value={1}>TODOS</Select.Option>
                    <Select.Option value={2}>TRANSFERENCIA</Select.Option>
                    <Select.Option value={3}>PUNTO DE VENTA</Select.Option>
                    <Select.Option value={4}>DEPOSITO</Select.Option>
                  </Select>
                </Form.Item>
              )}
              {(+params.type === 24) && (
                <Form.Item>
                  <Select placeholder={'Seleccione un reporte de declaraciones 1x1000'} onChange={(e) => {setAEReportState(e); form.resetFields(['rango'])}}>
                    <Select.Option value={1}>Agentes al dia</Select.Option>
                    <Select.Option value={2}>Agentes sin pagar</Select.Option>
                    <Select.Option value={3}>Declaraciones a tiempo</Select.Option>
                    <Select.Option value={4}>Declaraciones fuera de plazo</Select.Option>
                    <Select.Option value={5}>Declaraciones por mes</Select.Option>
                    <Select.Option value={6}>Declaraciones Globales</Select.Option>
                    <Select.Option value={7}>Declaraciones en 0 por mes</Select.Option>
                    <Select.Option value={8}>Declaraciones por mes sin conciliar</Select.Option>
                    <Select.Option value={9}>Declaraciones sin conciliar</Select.Option>
                    <Select.Option value={10}>Declaraciones pendientes por pago</Select.Option>
                    <Select.Option value={11}>Declaraciones por mes declarado</Select.Option>
                    <Select.Option value={12}>Declaraciones con pago excedente</Select.Option>
                    <Select.Option value={13}>Declaraciones pagadas solo por compensaciones</Select.Option>
                  </Select>
                </Form.Item>
              )}
              {(+params.type === 35) && (
                <Form.Item name='toll'>
                  <Select placeholder={'Seleccione el peaje'} defaultActiveFirstOption >
                    <Select.Option value={'Todos'}>Todos</Select.Option>
                    <Select.Option value={'Potocos'}>Potocos</Select.Option>
                    <Select.Option value={'Mesones'}>Mesones</Select.Option>
                    <Select.Option value={'Guanipa'}>Guanipa</Select.Option>
                    <Select.Option value={'Unare'}>Unare</Select.Option>
                  </Select>
                </Form.Item>
              )}
              {+params.type === 23 && <Button type='primary' style={{'marginBottom':'10px'}} onClick={() => setIncludeBank(!includeBank)} >{includeBank ? 'Sin banco específico' : 'Especificar banco'}</Button> }
              {(+params.type === 10 || +params.type === 18 || +params.type === 20 || +params.type === 22 || (+params.type === 23 && includeBank)) && (
                <Form.Item>
                  <Select placeholder={'Seleccione un banco'} onChange={(e) => setBankState(e)}>
                  {
                    selectedInstitution?.cuentasBancarias?.filter((b) => methodState && methodState !== 1 ? b.metodoPago?.includes( getMethod(methodState) === 'PUNTO DE VENTA' ? 'PUNTO' : getMethod(methodState) ) : true).map((b, i) =>
                      <Select.Option key={`b-${b.id}`} value={b.id}>{ b.numeroCuenta?.slice(b.numeroCuenta?.length - 4) + ' - ' + banks.find((bank) => bank.id === b.banco)?.nombre}</Select.Option>
                    )
                  }
                  </Select>
                </Form.Item>
              )}
              {(+params.type === 24) && 
              <Button type='primary' style={{'marginBottom':'10px'}} onClick={() => setCityState(!cityState)} >{cityState ? 'Sin municipio específico' : 'Especificar municipio'}</Button> }
              {(+params.type === 12 || +params.type === 14 || cityState) && (
                <Form.Item rules={[{ required: true, message: 'Por favor ingresar municipio!' }]} name="parroquia" label="Municipio">
                  <ParishSelect  />
                </Form.Item> 
              )}
            </Col>
          </Row>
          {+params.type === 3 ? (
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item name='ramo'>
                  <Select placeholder='Ramo'>
                    {brch.branches?.map((option) => (
                      <Option key={option.id} value={option.id}>
                        {option.descripcion}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          ) : null}
        </Form>
      </Modal>
    </React.Fragment>
  );
};
interface ReportProps {
  auth: States.Auth;
  brch: States.Branches;
  banks: States.Banks['banks']
  institutions: States.Institutions['institutions']
  parish: States.Parish['parish'],
}

const mapStateToProps = ({ auth, brch, bk, inst, prsh }: State) => ({ auth, brch, banks: bk.banks, institutions: inst.institutions, parish: prsh.parish  });

export default connect(mapStateToProps)(Report);
