import { createStore, applyMiddleware, combineReducers, compose } from "redux";
import thunk from "redux-thunk";
import logger from 'redux-logger';

import auth from "./reducers/auth";
import inst from './reducers/institutions';
import ofc from './reducers/official';
import cst from './reducers/cost';
import bk from './reducers/banks';
import prcd from './reducers/procedures';
import prsh from './reducers/parish';
import thm from './reducers/themeColor';
import ordc from './reducers/ordinance';
import st from './reducers/stats';
import notf from './reducers/notifications';
import req from './reducers/requests';
import tax from './reducers/taxes';
import hlds from './reducers/holidays';
import ae from './reducers/activities';
import agrm from './reducers/agreements';
import brch from './reducers/branches';
import vhc from './reducers/vehicles';
import coin from './reducers/coins';
import chrg from './reducers/charges';
import ut from './reducers/ut';

const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export default createStore(
  combineReducers({
    auth,
    inst,
    thm,
    ofc,
    cst,
    bk,
    prcd,
    prsh,
    ordc,
    notf,
    st,
    req,
    tax,
    hlds,
    ae,
    agrm, 
    brch,
    vhc,
    chrg,
    coin,
    ut
  }),
  composeEnhancers(applyMiddleware(thunk))
);
