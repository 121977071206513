import React from 'react';
import { Select } from 'antd';

const MonotaxSwitch: React.FC<any> = (props) => {
  return (
    <Select {...props}>
      <Select.Option value='false'>El Documento de Identidad. <b>NO</b> declara monotributo</Select.Option>
      <Select.Option value='true'>El Documento de Identidad. declara monotributo</Select.Option>
    </Select>
  );
};

export default MonotaxSwitch;