import React, { useEffect } from 'react';
import { Row, Col, Form, Input } from 'antd';
import { FormInstance } from 'antd/lib/form';

const NomenclatureCode: React.FC<NomenclatureCodeProps> = ({ form }) => {
  useEffect(() => {
    form.setFieldsValue({
      codigoNomenclatura: {
        edo: 23,
        mun: 13
      }
    }); 
    // eslint-disable-next-line
  }, []);

  return (
    <React.Fragment>
      <Row gutter={24}>
        <Col xl={2} md={4} xs={6}>
          <Form.Item name={['codigoNomenclatura', 'edo']} label='Edo.'>
            <Input placeholder='Edo.' />
          </Form.Item>
        </Col>
        <Col xl={2} md={4} xs={6}>
          <Form.Item name={['codigoNomenclatura', 'mun']} label='Mun.'>
            <Input placeholder='Mun.' />
          </Form.Item>
        </Col>
        <Col xl={2} md={4} xs={6}>
          <Form.Item name={['codigoNomenclatura', 'parr']} label='Parr.'>
            <Input placeholder='Parr.' />
          </Form.Item>
        </Col>
        <Col xl={2} md={4} xs={6}>
          <Form.Item name={['codigoNomenclatura', 'amb']} label='Amb.'>
            <Input placeholder='Amb.' />
          </Form.Item>
        </Col>
        <Col xl={2} md={4} xs={6}>
          <Form.Item name={['codigoNomenclatura', 'sec']} label='Sec.'>
            <Input placeholder='Sec.' />
          </Form.Item>
        </Col>
        <Col xl={2} md={4} xs={6}>
          <Form.Item name={['codigoNomenclatura', 'man']} label='Man.'>
            <Input placeholder='Man.' />
          </Form.Item>
        </Col>
        <Col xl={2} md={4} xs={6}>
          <Form.Item name={['codigoNomenclatura', 'parc']} label='Parc.'>
            <Input placeholder='Parc.' />
          </Form.Item>
        </Col>
        <Col xl={2} md={4} xs={6}>
          <Form.Item name={['codigoNomenclatura', 'subp']} label='Subp.'>
            <Input placeholder='Subp.' />
          </Form.Item>
        </Col>
        <Col xl={2} md={4} xs={6}>
          <Form.Item name={['codigoNomenclatura', 'niv']} label='Niv.'>
            <Input placeholder='Niv.' />
          </Form.Item>
        </Col>
        <Col xl={2} md={4} xs={6}>
          <Form.Item name={['codigoNomenclatura', 'uni']} label='Uni.'>
            <Input placeholder='Uni.' />
          </Form.Item>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default NomenclatureCode;

interface NomenclatureCodeProps {
  form: FormInstance
}