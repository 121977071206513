import React, { useEffect, useState } from 'react';
import { LockOutlined, UserOutlined, QuestionCircleOutlined, FrownOutlined } from '@ant-design/icons';
import { Input, Button, Card, Divider, Row, Form, Modal, Result } from 'antd';
import { useHistory, Link, useRouteMatch } from 'react-router-dom';
import { connect } from 'react-redux';
import { States, State } from 'sigt';
import { signIn, setUser } from '../../../redux/actions/auth';
import { Utils } from '../../../utils/validators';
import handlingMessage from '../../../utils/handlingMessage';
import Logo from '../../../assets/images/LOGO SUT/PNG/6.png';
import '../../../assets/css/components/LoginForm.css';
import awsImg from '../../../assets/images/aws.png';
import bomberos from '../../../assets/images/AvisoBomberos.png';
import Col from 'antd/lib/grid/col';

// const server = process.env.REACT_APP_SERVER_URL;

const LoginForm: React.FC<LoginFormProps> = ({ signIn, auth, setUser }) => {
  const history = useHistory();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const matchIngresar = useRouteMatch('/ingresar/funcionarios');
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    if (auth.token && auth.user) {
      history.push('/dashboard');
    }
  }, [auth.token, history, auth.user]);

  useEffect(() => {
    if (auth.user && !auth.token) {
      setUser(null);
    }
  }, [auth.token, auth.user, setUser]);

  const handleSubmit = async () => {
    setLoading(true);
    const value = await form.validateFields();
    handlingMessage({
      action: () => signIn(value),
      key: 'submit',
      loadingMessage: 'Realizando operacion...',
      cb: () => {
        setLoading(false);
      },
    });
  };
  return (
    <Row>
      <Col xl={8} xs={24}>
        <Card style={{ borderColor: 'rgba(3, 2, 2, 0.15)', padding: '15px' }} className='card-login'>
          <Form className='login-form' onFinish={handleSubmit} form={form}>
            {matchIngresar ? null : (
              <React.Fragment>
                <Row align='middle' justify='center'>
                  <img style={{ cursor: 'pointer' }} className='logo-sistema' alt='logo-sistema' src={Logo} />
                </Row>
                {/* <Form.Item>
                  <Button
                    size="large"
                    style={{ width: "100%", marginTop: "25px" }}
                    href={`${server}/auth/google`}
                  >
                    <Row align="middle">
                      <Col span={2}>
                        <GoogleIcon />
                      </Col>
                      <Col span={22}>Ingresar con Google</Col>
                    </Row>
                  </Button>
                </Form.Item> */}
                <Divider style={{ marginBottom: 50 }} />
              </React.Fragment>
            )}
            <Form.Item
              normalize={Utils.normalize.toLower}
              rules={[{ required: true, message: 'Por favor ingresa tu correo' }, { validator: Utils.validators.email }]}
              name='nombreUsuario'
            >
              <Input prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder='Correo' />
            </Form.Item>
            <Form.Item rules={[{ required: true, message: 'Ingresa tu contraseña' }]} name='password'>
              <Input.Password
                prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                type='password'
                placeholder='Contraseña'
              />
            </Form.Item>
            <Button type='primary' loading={loading} htmlType='submit' style={{ width: '100%' }}>
              Ingresar
            </Button>
            <Divider />
            <Row justify='space-between' align='middle'>
              <Link to='/olvidoContraseña'>
                <Button type='link' size='small'>
                  ¿Olvidó su contraseña?
                </Button>
              </Link>
              {matchIngresar ? (
                <div></div>
              ) : (
                <Link to='/registro'>
                  <Button size='small'>Registro</Button>
                </Link>
              )}
            </Row>
            {/* <Row>
              <Button
                target='_blank'
                href='https://sigat-yaracuy-gob.s3.amazonaws.com/leyes/SISTEMA+UNICO+DE+TRAMITES+%E2%80%93+GOBERNACION+USUARIO+CONTRIBUYENTE.pdf'
                type='link'
                style={{ border: 'none' }}
                icon={<QuestionCircleOutlined />}
              >
                Ayuda
              </Button>
            </Row> */}
          </Form>
          {/* <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
            <div
              style={{
                marginTop: '1rem',
                width: 20 * 6,
                height: 20 * 1,
                border: 'none',
                position: 'absolute',
                top: '90%',
              }}
            >
              <img src={awsImg} alt='asd' width={'100%'} height={'100%'} />
            </div>
          </div> */}
          {(auth.block || localStorage.blockMessage) && (
            <Modal
              footer={null}
              onCancel={() => {
                setVisible(false);
                localStorage.clear();
              }}
              visible={visible}
            >
              <Result
                icon={<FrownOutlined />}
                title={auth.block || localStorage.blockMessage}
                subTitle='Comuniquese con SAT para más información.'
              />
            </Modal>
          )}
        </Card>
      </Col>
      <Col xl={16} xs={0}>
        <Row align='bottom' style={{ height: '100%', alignSelf: 'end' }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row-reverse',
              justifyContent: 'center',
              alignContent: 'center',
              justifyItems: 'center',
            }}
          >
            <div
              style={
                {
                  // marginTop: '1rem',
                  // width: 20 * 6,
                  // height: 20 * 1,
                  // border: 'none',
                  // position: 'absolute',
                  // top: '90%',
                }
              }
            >
              <img
                style={{ display: 'block', marginLeft: 'auto', marginTop: 'auto', width: '85%' }}
                src={bomberos}
                alt='asd'
                width={'100%'}
                height={'100%'}
              />
            </div>
          </div>
        </Row>
      </Col>
    </Row>
  );
};

// const GoogleColored = () => (
// 	<svg aria-hidden="true" width="1em" height="1em" viewBox="0 0 18 18">
// 		<path
// 			d="M16.51 8H8.98v3h4.3c-.18 1-.74 1.48-1.6 2.04v2.01h2.6a7.8 7.8 0 0 0 2.38-5.88c0-.57-.05-.66-.15-1.18z"
// 			fill="#4285F4"
// 		></path>
// 		<path
// 			d="M8.98 17c2.16 0 3.97-.72 5.3-1.94l-2.6-2a4.8 4.8 0 0 1-7.18-2.54H1.83v2.07A8 8 0 0 0 8.98 17z"
// 			fill="#34A853"
// 		></path>
// 		<path
// 			d="M4.5 10.52a4.8 4.8 0 0 1 0-3.04V5.41H1.83a8 8 0 0 0 0 7.18l2.67-2.07z"
// 			fill="#FBBC05"
// 		></path>
// 		<path
// 			d="M8.98 4.18c1.17 0 2.23.4 3.06 1.2l2.3-2.3A8 8 0 0 0 1.83 5.4L4.5 7.49a4.77 4.77 0 0 1 4.48-3.3z"
// 			fill="#EA4335"
// 		></path>
// 	</svg>
// );

// const GoogleIcon = props => <Icon component={GoogleColored} {...props} />;

const mapStateToProps = ({ auth }: State) => ({ auth });

export default connect(mapStateToProps, { signIn, setUser })(LoginForm);

interface LoginFormProps {
  signIn: Function;
  setUser: Function;
  auth: States.Auth;
}
