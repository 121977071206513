import React, { useEffect, useState } from 'react';
import { Card, Typography, Divider, Table, Row, Col, Form, Select, Input, DatePicker, Button, message } from 'antd';
import { useWindowDimensions } from '../utils/hooks';
import { useRouteMatch, useParams, useHistory } from 'react-router';
import { States, State } from 'sigt';
import { connect } from 'react-redux';
import moment from 'moment';
import { CreditCardOutlined } from '@ant-design/icons';
import { updateProcedure } from '../redux/actions/procedures';
import BillTable from './OrdinanceBill/BillTable';

const PayProcedure: React.FC<PayProcedureProps> = ({ thm, prcd, banks, institutions, auth, updateProcedure }) => {
  useRouteMatch('/dashboard/pagarTramite/:idTramite');
  const { width } = useWindowDimensions();
  const { idTramite } = useParams<{idTramite:string}>();
  const selectedPrcd = prcd.procedures.find(p => p.id === parseInt(idTramite || "0"));
  const selectedInstitution = institutions?.find(i => i.tramitesDisponibles?.find(t => t.id === selectedPrcd?.tipoTramite));
  const selectedPrcdClass = selectedInstitution?.tramitesDisponibles?.find(t => t.id === selectedPrcd?.tipoTramite);
  const [form] = Form.useForm();
  const history = useHistory();
  const [loading, setLoading] = useState<boolean>(false);
  const cashierSedemat: number[] = [22, 25, 40, 41];

  useEffect(() => {
    if(!cashierSedemat.includes(auth.user?.institucion?.cargo?.id || 0) && auth.user?.tipoUsuario !== 4){
      history.push('/dashboard');
    }
    // eslint-disable-next-line
  }, [auth.user]);

  useEffect(() => {
    if(prcd.procedures.length > 0 && !selectedPrcd) {
      history.push('/dashboard');
    }
    // eslint-disable-next-line
  }, [prcd]);

  const disabledDate = current => current > moment().endOf('day');

  const handleBankChange = banco => {
    form.setFieldsValue({   
      banco
    });
  };

  const handleSubmit = async () => {
    const values = await form.validateFields();
    setLoading(true);
    Object.keys(values).forEach(k => {
      if(moment.isMoment(values[k])) {
        values[k] = values[k].toISOString();
      }
    });
    const pago = {
      fecha: values.fecha,
      banco: values.banco,
      referencia: values.referencia,
      destino: values.destino,
      costo: selectedPrcd?.bill?.totalBs
    };
    const tramite = {
      estado: selectedPrcd?.estado === 'pagocajero' ? 'pagocajero' : 'ingresardatos',
      pago, 
      idTramite: parseInt(idTramite || '0'),
      tipoTramite: selectedPrcdClass?.id
    };
    try {
      await updateProcedure({ tramite }, auth.token || '');
      setLoading(false);
      auth.user?.tipoUsuario === 4 ? history.push('/dashboard/bandejas') : history.push('/dashboard/tramites/pagosCpu');
      message.success('Pago registrado de manera exitosa.');
    } catch(e) {
      message.error((e?.response?.data?.message as string).includes('referencia') ? 'La referencia bancaria ya ha sido utilizada.' : 'Error en el servidor');
      setLoading(false);
    }
  };

  const columns: any = [
    {
      title: 'Banco',
      dataIndex: 'banco',
      key: 'banco',
      fixed: 'left'
    },
    {
      title: 'Numero de Cuenta',
      dataIndex: 'numeroCuenta',
      key: 'numeroCuenta'
    },
    {
      title: 'Titular',
      dataIndex: 'nombreTitular',
      key: 'nombreTitular'
    },
    {
      title: 'Documento de Identidad',
      dataIndex: 'documento',
      key: 'documento',
    }
  ];

  return (
    <Card style={{ height: '100%' }} title='Pagar Trámite' bodyStyle={{ height: 'calc(100% - 88px)', overflowY: 'scroll', overflowX: 'hidden' }}
    headStyle={{ height: 64, backgroundColor: thm.primaryColor, padding: width < 992 ? '0 10px' : '0 20px', color: 'white'}}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography.Text strong>Codigo de Tramite: {selectedPrcd?.codigoTramite}</Typography.Text>
        <Typography.Text strong>Fecha: {moment(selectedPrcd?.fechaCreacion).format('DD-MM-YYYY')}</Typography.Text>
      </div>
      <Form form={form} layout='vertical'  style={{ padding: width < 992 ? '0 10px' : '0px 20px' }} onFinish={handleSubmit}>
        <Divider orientation='left' style={{ marginLeft: -30, marginBottom: 0 }}>
          <Typography.Title level={4}>Datos de Pago</Typography.Title>
        </Divider>
        <Typography.Text strong type='danger'>El trámite tiene un costo de {`${selectedPrcd?.bill?.totalBs} Bs`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}.</Typography.Text>
        <BillTable data={selectedPrcd?.bill?.items || []} />
        <Divider/>
        {selectedPrcd?.estado !== 'pagocajero' ? <>
            <Typography.Text strong type='danger'>El monto de la transferencia debe coincidir exactamente con el costo del tramite y debe ser realizada desde el mismo banco.</Typography.Text>
            <Table bordered style={{ margin: '20px 0px' }} pagination={false} columns={columns} dataSource={selectedInstitution?.cuentasBancarias?.map((c) => 
              ({ ...c, documento: `${(c.rif) ? 'RIF: ' : 'Cedula: '}${(c.rif || c.cedula)}`, banco: banks?.find(b => b.id === c.banco)?.nombre, key: c.id })
            )}/>
          </> 
          : <Typography.Text strong type='danger'>El monto del pago debe coincidir exactamente con el costo del trámite.</Typography.Text>
        }
        <Row gutter={24}>
          <Col xs={24} xl={8}>
            <Form.Item label={selectedPrcd?.estado === 'pagocajero' ? 'Banco Origen' : 'Banco'} name='banco' rules={[{ required: true, message: 'Debe indicar el banco' }]}>
              {banks.length > 0 ? <Select placeholder={selectedPrcd?.estado === 'pagocajero' ? 'Banco Origen' : 'Banco'} onChange={handleBankChange}>
                {selectedPrcd?.estado !== 'pagocajero' ? 
                  banks.filter((b) => selectedInstitution?.cuentasBancarias?.map((c) => c.banco).includes(b.id)).map((b, i) =>
                    <Select.Option key={`b-${b.id}`} value={b.id}>{b.nombre}</Select.Option>) 
                : banks.map(b => <Select.Option key={`b-${b.id}`} value={b.id}>{b.nombre}</Select.Option>)}
                {/* <Select.Option value={11}>Banco de Venezuela S.A.C.A. Banco Universal</Select.Option> */}
              </Select> : <div></div>}
            </Form.Item>
          </Col>
          <Col xs={24} xl={8}>
            <Form.Item label='Numero de Referencia' name='referencia' rules={[{ required: true, message: 'Por favor ingrese el numero de referencia.' }]}>
              <Input placeholder='Referencia' maxLength={20} />
            </Form.Item>
          </Col>
          {selectedPrcd?.estado === 'pagocajero' && <Col xs={24} xl={8}>
            <Form.Item label='Banco de Destino' name='destino' rules={[{ required: true, message: 'Debe indicar el banco' }]}>
              {banks.length > 0 ? <Select placeholder='Banco de Destino'>
                {/* {banks?.map((b, i) =><Select.Option key={`b-${b.id}`} value={b.id}>{b.nombre}</Select.Option>)} */}
                <Select.Option value={11}>Banco de Venezuela S.A.C.A. Banco Universal</Select.Option>
                <Select.Option value={3}>Banco Nacional de Crédito</Select.Option>
              </Select> : <div></div>}
            </Form.Item>
          </Col>}
          <Col xs={24} xl={8}>
            <Form.Item label='Fecha de Pago' name='fecha' rules={[{ required: true, message: 'Por favor ingrese la fecha de pago.' }]}>
              <DatePicker style={{ width: '100%' }} format={"DD-MM-YYYY"} disabledDate={disabledDate} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Button loading={loading} type='primary' icon={<CreditCardOutlined />} onClick={form.submit}>Pagar</Button>
    </Card>
  );
};

interface PayProcedureProps {
  prcd: States.Procedures
  banks: States.Banks["banks"]
  institutions: States.Institutions["institutions"]
  auth: States.Auth
  thm: States.ThemeColors
  updateProcedure: (data: any, token: string) => Promise<void>
}

const mapStateToProps = (state: State) => ({ thm: state.thm, prcd: state.prcd, banks: state.bk.banks, institutions: state.inst.institutions, auth: state.auth })

export default connect(mapStateToProps, { updateProcedure })(PayProcedure)
