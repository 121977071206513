import axios, { AxiosResponse } from 'axios';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { CoinsType, SET_CURRENCY, SET_DOLAR, SET_EURO, SET_PETRO, SET_UT, UTType } from './actionsTypes';
const server = process.env.REACT_APP_SERVER_URL;

export const setPetro = (petro: number): CoinsType => ({ type: SET_PETRO, payload: petro });
export const setMoneda = (moneda: string): CoinsType => ({ type: SET_CURRENCY, payload: moneda });

export const setUT = (ut: number): UTType => ({ type: SET_UT, payload: ut });

export const fetchPetro =
  (): ThunkAction<Promise<any>, {}, {}, CoinsType> =>
  async (dispatch: ThunkDispatch<{}, {}, CoinsType>): Promise<any> => {
    try {
      const response: AxiosResponse = await axios.get(`${server}/values/petro`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      dispatch(setPetro(parseFloat(response.data.petro)));
      dispatch(setMoneda(response.data.moneda));
      return response.data;
    } catch (e) {
      throw e;
    }
  };

export const updatePetro =
  (value: number, currency: string): ThunkAction<Promise<any>, {}, {}, CoinsType> =>
  async (dispatch: ThunkDispatch<{}, {}, CoinsType>): Promise<any> => {
    try {
      const response: AxiosResponse = await axios.patch(
        `${server}/values/petro`,
        { value, currency },
        { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
      );
      dispatch(setPetro(parseFloat(response.data.petro)));
      dispatch(setMoneda(response.data.moneda));
      return response.data;
    } catch (e) {
      throw e;
    }
  };

export const fetchUT =
  (): ThunkAction<Promise<any>, {}, {}, UTType> =>
  async (dispatch: ThunkDispatch<{}, {}, UTType>): Promise<any> => {
    try {
      const response: AxiosResponse = await axios.get(`${server}/scales/getUT`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      dispatch(setUT(parseFloat(response.data.ut)));
      return response.data;
    } catch (e) {
      throw e;
    }
  };

export const setDolar = (dolar: number): CoinsType => ({ type: SET_DOLAR, payload: dolar });

export const fetchDolar =
  (): ThunkAction<Promise<any>, {}, {}, CoinsType> =>
  async (dispatch: ThunkDispatch<{}, {}, CoinsType>): Promise<any> => {
    try {
      const response: AxiosResponse = await axios.get(`${server}/values/usd`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      dispatch(setDolar(parseFloat(response.data.usd)));
      return response.data;
    } catch (e) {
      throw e;
    }
  };

export const updateDolar =
  (value: number): ThunkAction<Promise<any>, {}, {}, CoinsType> =>
  async (dispatch: ThunkDispatch<{}, {}, CoinsType>): Promise<any> => {
    try {
      const response: AxiosResponse = await axios.patch(
        `${server}/values/usd`,
        { value },
        { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
      );
      if (response.data.usd) dispatch(setDolar(parseFloat(response.data.usd)));
      return response.data;
    } catch (e) {
      throw e;
    }
  };

export const setEuro = (euro: number): CoinsType => ({ type: SET_EURO, payload: euro });

export const fetchEuro =
  (): ThunkAction<Promise<any>, {}, {}, CoinsType> =>
  async (dispatch: ThunkDispatch<{}, {}, CoinsType>): Promise<any> => {
    try {
      const response: AxiosResponse = await axios.get(`${server}/values/euro`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      dispatch(setEuro(parseFloat(response.data.euro)));
      return response.data;
    } catch (e) {
      throw e;
    }
  };

export const updateEuro =
  (value: number): ThunkAction<Promise<any>, {}, {}, CoinsType> =>
  async (dispatch: ThunkDispatch<{}, {}, CoinsType>): Promise<any> => {
    try {
      const response: AxiosResponse = await axios.patch(
        `${server}/values/euro`,
        { value },
        { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
      );
      if (response.data.euro) dispatch(setEuro(parseFloat(response.data.euro)));
      return response.data;
    } catch (e) {
      throw e;
    }
  };
