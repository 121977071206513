import axios from 'axios';
import moment from 'moment';
const server = process.env.REACT_APP_SERVER_URL;

export const newReportPdf = async (data, token) => {
  try {
    const body = {
      from: moment(data.rango[0]).utcOffset('-4').startOf('day').toISOString(true),
      to: moment(data.rango[1]).utcOffset('-4').endOf('day').toISOString(true),
      alcaldia: data.alcaldia,
    };
    const response = await axios.post(`${server}/branches`, body, { headers: { Authorization: `Bearer ${token}` } });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const newReportPdfForCity = async (data, token) => {
  try {
    const body = {
      from: moment(data.rango[0]).utcOffset('-4').startOf('day').toISOString(true),
      to: moment(data.rango[1]).utcOffset('-4').endOf('day').toISOString(true),
      alcaldia: data.alcaldia,
      parroquia: data.parroquia
    };
    const response = await axios.post(`${server}/branches/reportCity`, body, { headers: { Authorization: `Bearer ${token}` } });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const reportAgreementPdf = async (data, token) => {
  try {
    const response = await axios.post(`${server}/branches/pdf/reportAgreement`, data, { headers: { Authorization: `Bearer ${token}` } });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const reportPendingAgreementPdf = async (data, token) => {
  try {
    const response = await axios.post(`${server}/branches/pdf/reportPendingAgreement`, data, { headers: { Authorization: `Bearer ${token}` } });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const reportFinesPdf = async (data, token) => {
  try {
    const response = await axios.post(`${server}/branches/pdf/reportFines`, data, { headers: { Authorization: `Bearer ${token}` } });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const reportPendingFinesPdf = async (data, token) => {
  try {
    const response = await axios.post(`${server}/branches/pdf/reportPendingFines`, data, { headers: { Authorization: `Bearer ${token}` } });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const clouserAllCash = async (data) => {
  try {
    const response = await axios.post(
      `${server}/cashier/all`,
      {
        from: moment(data.rango[0]).utcOffset('-4').startOf('day').toISOString(true),
        to: moment(data.rango[1]).utcOffset('-4').endOf('day').toISOString(true),
      },
      { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const clouserOneCash = async (data) => {
  try {
    const response = await axios.post(
      `${server}/cashier/one`,
      {
        from: moment(data.rango[0]).utcOffset('-4').startOf('day').toISOString(true),
        to: moment(data.rango[1]).utcOffset('-4').endOf('day').toISOString(true),
      },
      { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const closureCash = async (day) => {
  try {
    const response = await axios.post(
      `${server}/cashier`,
      { day },
      { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const newReportExcel = async (data, token) => {
  try {
    const body = { from: data.rango[0], to: data.rango[1], ramo: data.ramo };
    const response = await axios.post(`${server}/settlements/report`, body, { headers: { Authorization: `Bearer ${token}` } });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const newReportExcelIVA = async (data, token) => {
  try {
    const body = { from: data.rango[0], to: data.rango[1], ramo: data.ramo };
    const response = await axios.post(`${server}/settlements/report/iva`, body, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const reportDAE = async (data, rim, token) => {
  try {
    const response = await axios.get(
      `${server}/definitive-declaration?tipoDocumento=${data.tipoDocumento}&documento=${data.documento}&referencia=${rim}`,
      { headers: { Authorization: `Bearer ${token}` } }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const reportStamp = async (data, token) => {
  try {
    const response = await axios.post(`${server}/branches/reportStamps`, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (e) {
    throw e;
  }
};

export const reportCivilStamp = async (data, token) => {
  try {
    const response = await axios.post(`${server}/branches/reportCivilStamps`, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (e) {
    throw e;
  }
};

export const reportRepairs = async (data, token) => {
  try {
    const response = await axios.post(`${server}/branches/reportRepairs`, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (e) {
    throw e;
  }
};

export const reportPendingRepairs = async (data, token) => {
  try {
    const response = await axios.post(`${server}/branches/reportPendingRepairs`, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (e) {
    throw e;
  }
};

export const reportAgreements = async (data, token) => {
  try {
    const response = await axios.post(`${server}/branches/reportAgreement`, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (e) {
    throw e;
  }
};

export const reportPendingAgreements = async (data, token) => {
  try {
    const response = await axios.post(`${server}/branches/reportPendingAgreement`, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (e) {
    throw e;
  }
};

export const reportFines = async (data, token) => {
  try {
    const response = await axios.post(`${server}/branches/reportFines`, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (e) {
    throw e;
  }
};

export const reportPendingFines = async (data, token) => {
  try {
    const response = await axios.post(`${server}/branches/reportPendingFines`, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (e) {
    throw e;
  }
};

export const reportAEUpToDate = async (data, token) => {
  try {
    const response = await axios.post(`${server}/branches/reportAEUpToDate`, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (e) {
    throw e;
  }
};

export const reportAENotPayed = async (data, token) => {
  try {
    const response = await axios.post(`${server}/branches/reportAENotPayed`, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (e) {
    throw e;
  }
};

export const reportAEOnTime = async (data, token) => {
  try {
    const response = await axios.post(`${server}/branches/reportAEOnTime`, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (e) {
    throw e;
  }
};

export const reportAENotOnTime = async (data, token) => {
  try {
    const response = await axios.post(`${server}/branches/reportAENotOnTime`, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (e) {
    throw e;
  }
};

export const reportAEOnlyComp = async (data, token) => {
  try {
    const response = await axios.post(`${server}/branches/reportAEOnlyComp`, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (e) {
    throw e;
  }
};

export const reportAEByMonth = async (data, token) => {
  try {
    const response = await axios.post(`${server}/branches/reportAEMonth`, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (e) {
    throw e;
  }
};

export const reportAEByMonthNoApproved = async (data, token) => {
  try {
    const response = await axios.post(`${server}/branches/reportAEMonthNoApp`, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (e) {
    throw e;
  }
};

export const reportAENoApproved = async (data, token) => {
  try {
    const response = await axios.post(`${server}/branches/reportAENoApp`, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (e) {
    throw e;
  }
};

export const reportAEPendingPay = async (data, token) => {
  try {
    const response = await axios.post(`${server}/branches/reportAEPendingPay`, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (e) {
    throw e;
  }
};

export const reportAEZeroByMonth = async (data, token) => {
  try {
    const response = await axios.post(`${server}/branches/reportAEZeroMonth`, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (e) {
    throw e;
  }
};

export const reportAE = async (data, token) => {
  try {
    const response = await axios.post(`${server}/branches/reportAEMonth`, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (e) {
    throw e;
  }
};

export const reportAEExtra = async (data, token) => {
  try {
    const response = await axios.post(`${server}/branches/reportAEExtra`, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (e) {
    throw e;
  }
};

export const reportAEByDeclaredDate = async (data, token) => {
  try {
    const response = await axios.post(`${server}/branches/reportAEDeclaredMonth`, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (e) {
    throw e;
  }
};
export const reportMines = async (data, token) => {
  try {
    const response = await axios.post(`${server}/branches/reportMines`, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (e) {
    throw e;
  }
};

export const reportTolls = async (data, token) => {
  try {
    const response = await axios.post(`${server}/branches/reportTolls`, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (e) {
    throw e;
  }
};

export const reportTransf = async (data, token) => {
  try {
    const response = await axios.post(`${server}/branches/reportTransf`, data, { headers: { Authorization: `Bearer ${token}` } });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const reportTransfBank = async (data, token, bankState) => {
  try {
    const response = await axios.post(`${server}/branches/reportTransfBank/${bankState}`, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (e) {
    throw e;
  }
};

export const reportCas = async (data, token) => {
  try {
    const response = await axios.post(`${server}/branches/reportCas`, data, { headers: { Authorization: `Bearer ${token}` } });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const reportCasBank = async (data, token, bankState) => {
  try {
    const response = await axios.post(`${server}/branches/reportCasBank/${bankState}`, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (e) {
    throw e;
  }
};

export const reportDep = async (data, token) => {
  try {
    const response = await axios.post(`${server}/branches/reportDep`, data, { headers: { Authorization: `Bearer ${token}` } });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const reportDepBank = async (data, token, bankState) => {
  try {
    const response = await axios.post(`${server}/branches/reportDepBank/${bankState}`, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (e) {
    throw e;
  }
};

export const reportAllMethods = async (data, token) => {
  try {
    const response = await axios.post(`${server}/branches/reportAllMethods`, data, { headers: { Authorization: `Bearer ${token}` } });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const reportAllMethodsBank = async (data, token, bankState) => {
  try {
    const response = await axios.post(`${server}/branches/reportAllMethodsBank/${bankState}`, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (e) {
    throw e;
  }
};

export const reportSelection = async (data, token, methodState, bankState?) => {
  try {
    switch (methodState) {
      case 1: 
        if(bankState) {
          const response = await axios.post(`${server}/branches/reportAllMethodsBank/${bankState}`, data, {
            headers: { Authorization: `Bearer ${token}` },
          });
          return response.data;
        }
        else{
          const response = await axios.post(`${server}/branches/reportAllMethods`, data, {
            headers: { Authorization: `Bearer ${token}` },
          });
          return response.data;
        };
      case 2:
        if(bankState) {
          const response = await axios.post(`${server}/branches/reportTransfBank/${bankState}`, data, {
            headers: { Authorization: `Bearer ${token}` },
          });
          return response.data;
        }
        else{
          const response = await axios.post(`${server}/branches/reportTransf`, data, {
            headers: { Authorization: `Bearer ${token}` },
          });
          return response.data;
        };
      case 3:
        if(bankState) {
          const response = await axios.post(`${server}/branches/reportCasBank/${bankState}`, data, {
            headers: { Authorization: `Bearer ${token}` },
          });
          return response.data;
        }
        else{
          const response = await axios.post(`${server}/branches/reportCas`, data, {
            headers: { Authorization: `Bearer ${token}` },
          });
          return response.data;
        };
      case 4:
        if(bankState) {
          const response = await axios.post(`${server}/branches/reportDepBank/${bankState}`, data, {
            headers: { Authorization: `Bearer ${token}` },
          });
          return response.data;
        }
        else{
          const response = await axios.post(`${server}/branches/reportDep`, data, {
            headers: { Authorization: `Bearer ${token}` },
          });
          return response.data;
        };
    }
  } catch (e) {
    throw e;
  }
};

export const reportAESelection = async (data, token, aeReportState) => {
  try {
    switch (aeReportState) {
      case 1: 
        return await reportAEUpToDate(data,token);
      case 2: 
        return await reportAENotPayed(data,token);
      case 3: 
        return await reportAEOnTime(data,token);
      case 4: 
        return await reportAENotOnTime(data,token);
      case 5: 
        return await reportAEByMonth(data,token);
      case 6: 
        return await reportAE(data,token);
      case 7: 
        return await reportAEZeroByMonth(data,token);
      case 8: 
        return await reportAEByMonthNoApproved(data,token);
      case 9: 
        return await reportAENoApproved(data,token);
      case 10: 
        return await reportAEPendingPay(data,token);
      case 11: 
        return await reportAEByDeclaredDate(data,token);
      case 12: 
        return await reportAEExtra(data,token);
      case 13: 
        return await reportAEOnlyComp(data,token);
    }
  } catch (e) {
    throw e;
  }
};

export const reportContributors = async (data, token) => {
  try {
    const response = await axios.post(`${server}/branches/reportContributors`, data, { headers: { Authorization: `Bearer ${token}` } });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const reportContributorsbyCity = async (data, token) => {
  try {
    const response = await axios.post(`${server}/branches/reportContributorsByCity`, data, { headers: { Authorization: `Bearer ${token}` } });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const reportContributorsRequest = async (data, token) => {
  try {
    const response = await axios.post(`${server}/branches/reportContributorsRequest`, data, { headers: { Authorization: `Bearer ${token}` } });
    return response.data;
  } catch (error) {
    throw error;
  }
};


export const reportInstitucions = async (data, token) => {
  try {
    const response = await axios.post(`${server}/branches/reportInstitucions`, data, { headers: { Authorization: `Bearer ${token}` } });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const reportEntities = async (data, token) => {
  try {
    const response = await axios.post(`${server}/branches/reportEntities`, data, { headers: { Authorization: `Bearer ${token}` } });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const reportCondo = async (data, token) => {
  try {
    const response = await axios.post(`${server}/branches/condoReport`, data, { headers: { Authorization: `Bearer ${token}` } });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const reportCondoDisclosed = async (data, token) => {
  try {
    const response = await axios.post(`${server}/branches/condoReportDisclosed`, data, { headers: { Authorization: `Bearer ${token}` } });
    return response.data;
  } catch (error) {
    throw error;
  }
};
