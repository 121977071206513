import { message } from 'antd';
import { Utils } from 'sigt';
const server = process.env.REACT_APP_SERVER_URL;

const handlingMessage = (props: Utils.handlingProps) => {
  const { cb, loadingMessage, key, action } = props;
  message.loading({ content: loadingMessage, key, duration: 0 });
  action()
    .then((response) => {
      console.log(response);
      message.success({ content: response?.message || 'Operación exitosa!', key, duration: 2.5 });
      if (cb) cb(response);
    })
    .catch((err) => {
      const block = localStorage.block;
      console.log(err.status);
      message.error({
        content:
          err.response?.data?.message ||
          err.response?.message ||
          (err.request?.status === 401 && err.request?.responseURL === `${server}/auth/login`
            ? 'Usuario y/o contraseña incorrectos'
            : block
            ? block
            : 'Error en el servidor'),
        key,
        duration: 2.5,
      });
      if (cb) cb(err);
    });
};

export default handlingMessage;
