import { Button, Card, Col, Form, Input, message, Modal, Row, Typography } from 'antd';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { State } from 'sigt';
import { useWindowDimensions } from '../../utils/hooks';
import _, { result } from 'lodash';
import axios from 'axios';
import { useHistory } from 'react-router';
import { QrReader } from 'react-qr-reader';
const server = process.env.REACT_APP_SERVER_URL;

const Receipt = ({ thm, auth }) => {
  const { width } = useWindowDimensions();
  const [form] = Form.useForm();
  const [modalVisible, setModalVisible] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [qrVisible, setQrVisible] = useState(false);
  const history = useHistory();

  const handleFormFinish = async () => {
    // setModalVisible(true);
    const { codCatSearch } = await form.validateFields();
    const id_consumidor = auth?.user?.id;

    try {
      setLoading(true);
      const response = await axios.patch(
        `${server}/receipt/consume`,
        { id_consumidor, id_recibo: codCatSearch },
        { headers: { Authorization: `Bearer ${auth.token}` } }
      );
      if (response.data.status === 200) {
        setLoading(false);
        message.success(response.data.message);
      } else {
        setLoading(false);
        message.error(response.data.message);
      }
    } catch (e) {
      setLoading(false);
      message.error('Error. Verifique si el número de Timbre Fiscal es correcto.');
    }
  };

  const handleResult = async (result, error) => {
    if (!!result) {
      const codCatSearch = result?.getText()
      const id_consumidor = auth?.user?.id;
      try {
        setLoading(true);
        setQrVisible(false);
        const response = await axios.patch(
          `${server}/receipt/consumeURL`,
          { id_consumidor, recibo: codCatSearch },
          { headers: { Authorization: `Bearer ${auth.token}` } }
        );
        if (response.data.status === 200) {
          setLoading(false);
          message.success(response.data.message);
        } else {
          setLoading(false);
          message.error(response.data.message);
        }
      } catch (e) {
        setLoading(false);
        message.error('Error. No se encontró el Timbre Fiscal.');
      } 
    }
    // if (!!error) {
    //   console.info(error);
    // }
  }

  return (
    <React.Fragment>
      <Modal
        width='600px'
        bodyStyle={{ height: '45vh', overflowY: 'scroll' }}
        centered
        visible={modalVisible}
        footer={null}
        closable
        title='Consumir Timbre Fiscal'
        maskClosable
        onCancel={() => history.goBack()}
      >
        <Form form={form} layout='vertical' onFinish={handleFormFinish}>
          <Row style={{ paddingLeft: 16 }} gutter={16}>
            <Col span={16}>
              <Form.Item
                label='Código del Timbre Fiscal'
                name='codCatSearch'
                rules={[{ required: true, message: 'Debe ingresar el Código del Timbre Fiscal' }]}
              >
                <Input placeholder='Código del Timbre Fiscal' />
              </Form.Item>
            </Col>
          </Row>
          <Row style={{ paddingLeft: 16 }} gutter={16}>
            <Col span={16}>
              <Button type='primary' htmlType='submit' loading={loading}>
                Consumir
              </Button>
            </Col>
          </Row>
          <Row style={{ paddingLeft: 16, paddingTop:20}} gutter={16}>
            <Col span={16}>
            <Typography.Text>Consumir con QR</Typography.Text>
              <QrReader
                onResult={(error,result) => handleResult(error,result)}
                constraints={{ facingMode: 'user' }}
                videoStyle={{width: '100%'}}
                containerStyle={{width: '100%'}}
                videoContainerStyle={{outerHeight:'0%', innerHeight:'0%'}}
              /> 
            </Col>
          </Row>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

const mapStateToProps = (state: State) => ({ thm: state.thm, auth: state.auth });

export default connect(mapStateToProps)(Receipt);
