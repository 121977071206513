import React, { useState, useEffect } from 'react';
import { States, State } from 'sigt';
import { connect } from 'react-redux';
import { Card, Form, Row, Col, Input, Select, Button, Table, message, Divider, Typography, Modal, Result, DatePicker, Alert } from 'antd';
import { useWindowDimensions } from '../../utils/hooks';
import { SearchOutlined, EditFilled } from '@ant-design/icons';
import axios from 'axios';
import TaxpayerSocietyType from '../Forms/components/TaxpayerSocietyType';
import { Utils } from '../../utils/validators';
import { ColumnsType } from 'antd/lib/table';
import LicenseTypeSelect from '../Forms/components/LicenseTypeSelect';
import EconomicActivitiesChange from '../Forms/components/EconomicActivitiesChange';
import _ from 'lodash';
import moment from 'moment';
const server = process.env.REACT_APP_SERVER_URL;

const ActivityChange: React.FC<ActivityChangeProps> = ({ thm, auth }) => {
  const [refs, setRefs] = useState<any[]>([]);
  const [selectedRef, setSelectedRef] = useState<any>(null);
  const [tipoDocumento, setTipoDocumento] = useState('J');
  const [loading, setLoading] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [data, setData] = useState<any>({});
  const [visible, setVisible] = useState(false)
  const { width } = useWindowDimensions();
  const [form] = Form.useForm();
  const msgWarning = `Ejecutar un cambio de fecha de inicio de actividad económica implica eliminar todas las 
  liquidaciones de los cuatro ramos principales del contribuyente; esto representa un barrido TOTAL del historial del contribuyente. 
  ¿Está seguro(a) que desea continuar?`;
  const SMmsg = `Cambiar la fecha de inicio de la declaración de Servicios Municipales del contribuyente implica
  eliminar TODAS sus liquidaciones de los ramos modificados.`;
  const PPmsg = `Cambiar la fecha de inicio de la declaración de Publicidad y Propaganda del contribuyente implica
  eliminar TODAS sus liquidaciones de los ramos modificados.`;
  const RD0msg = `Cambiar la fecha de inicio de la declaración de Retenciones Decreto 048 del contribuyente implica
  eliminar TODAS sus liquidaciones de los ramos modificados.`;

  const tipoAddon = (
    <Select defaultValue='J' value={tipoDocumento} onChange={(e) => setTipoDocumento(e)}>
      <Select.Option value='V'>V</Select.Option>
      <Select.Option value='E'>E</Select.Option>
      <Select.Option value='J'>J</Select.Option>
      <Select.Option value='G'>G</Select.Option>
      <Select.Option value='P'>P</Select.Option>
    </Select>
  );

  const columns: ColumnsType<any> = [
    {
      title: 'Referencia Municipal',
      dataIndex: 'referenciaMunicipal',
      key: 'id'
    },
    {
      title: 'Nombre Representante',
      dataIndex: 'nombreRepresentante'
    },
    {
      title: 'Denominación Comercial',
      dataIndex: 'denomComercial'
    },
    {
      title: 'Acciones',
      dataIndex: 'actualizado',
      render: (_, record) => <Button type='primary' icon={<EditFilled />} onClick={() => setSelectedRef(record)}>Actualizar</Button>
    }
  ];

  useEffect(() => {
    if(selectedRef) {
      selectedRef.esMonotributo = JSON.stringify(selectedRef.esMonotributo);
      form.setFieldsValue(selectedRef);
      const highestActivity = selectedRef.actividadesEconomicas?.sort((a, b) => a.desde.isBefore(b.desde) ? 1 : b.desde.isBefore(a.desde) ? -1 : 0);
      const SM = selectedRef.otrosImpuestos.find(f => f && f.ramo === 'SM');
      const PP = selectedRef.otrosImpuestos.find(f => f && f.ramo === 'PP');
      const RD0 = selectedRef.otrosImpuestos.find(f => f && f.ramo === 'RD0');
      const hasDate = !!highestActivity[0]?.desde;
      if(SM && SM.desde) {
        form.setFieldsValue({ serviciosMunicipales: moment(SM.desde) })
      } else {
        form.setFieldsValue({ serviciosMunicipales: hasDate ? moment(highestActivity[0]?.desde) : null });
      }
      if(PP && PP.desde) {
        form.setFieldsValue({ publicidad: moment(PP.desde) })
      } else {
        form.setFieldsValue({ publicidad: hasDate ? moment(highestActivity[0]?.desde) : null });
      }
      if(RD0 && RD0.desde) {
        form.setFieldsValue({ retencion: moment(RD0.desde) })
      } else {
        form.setFieldsValue({ retencion: hasDate ? moment(highestActivity[0]?.desde) : null });
      }
    }
    // eslint-disable-next-line
  }, [selectedRef]);

  const handleUpdate = async()=>{
    const values = await form.validateFields();
    const nuevasActividades: any[] = [];
    const aux: any[] = values.actividadesEconomicas?.filter(ae => ae.id && ae.desde)
    let check = false; 
    await aux?.forEach(async(ele)=> {
      if (typeof _.find(selectedRef?.actividadesEconomicas, (e) => e.codigo === ele.codigo) !== 'object' ){
        nuevasActividades.push(ele)
      }
      if (typeof _.find(selectedRef?.actividadesEconomicas, (e) => {
        const eDesde = typeof e?.desde === 'string' ? e?.desde :e?.desde?.toISOString();
        const eleDesde = typeof e?.desde === 'string' ? e?.desde :ele?.desde?.toISOString();
        return eDesde  === eleDesde}) !== 'object' && !nuevasActividades.length){
          await setVisible(true);
          check = true;
      }
    })
    if(!check){
      update()
    }
}
  const update = async () => {
    const values = await form.validateFields();
    setUpdating(true);
    const otrosImpuestos: any[] = [];
    const highestActivity = selectedRef.actividadesEconomicas?.sort((a, b) => a.desde.isBefore(b.desde) ? 1 : b.desde.isBefore(a.desde) ? -1 : 0);
    const SM = selectedRef.otrosImpuestos.find(f => f && f.ramo === 'SM')?.desde;
    const PP = selectedRef.otrosImpuestos.find(f => f && f.ramo === 'PP')?.desde;
    const RD0 = selectedRef.otrosImpuestos.find(f => f && f.ramo === 'RD0')?.desde;
    const updateSM = values.serviciosMunicipales ? values.serviciosMunicipales.utc().format('DD-MM-YYYY') !== (SM ? moment(SM).utc().format('DD-MM-YYYY') : moment(highestActivity[0]?.desde).utc().format('DD-MM-YYYY')) : false;
    const updatePP = values.publicidad ? values.publicidad.utc().format('DD-MM-YYYY') !== (PP ? moment(PP).format('DD-MM-YYYY') : moment(highestActivity[0]?.desde).utc().format('DD-MM-YYYY')) : false;
    const updateRD0 = values.retencion ? values.retencion.utc().format('DD-MM-YYYY') !== (RD0 ? moment(RD0).format('DD-MM-YYYY') : moment(highestActivity[0]?.desde).utc().format('DD-MM-YYYY')) : false;
    if(values.serviciosMunicipales && updateSM) 
      otrosImpuestos.push({ ramo: 'SM', desde: values.serviciosMunicipales.toISOString() });
    if(values.publicidad && updatePP) 
      otrosImpuestos.push({ ramo: 'PP', desde: values.publicidad.toISOString() });
    if(values.retencion && updateRD0) 
      otrosImpuestos.push({ ramo: 'RD0', desde: values.retencion.toISOString() })
    const datosSucursal = {
      denomComercial: values.denomComercial,
      nombreRepresentante: values.nombreRepresentante,
      telefonoMovil: values.telefono,
      capitalSuscrito: values.capitalSuscrito,
      tipoSociedad: values.tipoSociedad,
      actualizado: true,
      otrosImpuestos,
      estadoLicencia: values.estadoLicencia,
      esMonotributo: false
    };
    const nuevasActividades: any[] = [];
    const aux: any[] = values.actividadesEconomicas?.filter(ae => ae.id && ae.desde).map(ae => {
      ae.desde = ae.desde.toISOString();
      return ae;
    });
    aux.forEach((ele)=> {
      if (typeof _.find(selectedRef?.actividadesEconomicas, (e) => e.codigo === ele.codigo) !== 'object' ){
        nuevasActividades.push(ele)
      }
      if (typeof _.find(selectedRef?.actividadesEconomicas, (e) => e.desde.toISOString() === ele.desde) !== 'object' && !nuevasActividades.length){
        datosSucursal.actualizado = false;
      }
    })
    const actividades = aux;
    try {
      await axios.put(`${server}/activities/rim/${selectedRef.id}`, { datosSucursal, actividades }, { headers: { Authorization: `Bearer ${auth.token}` } });
      message.success('Licencia actualizada de manera exitosa');
      setRefs([]);
      setSelectedRef(null); 
    } catch(e) {
      message.error(e.response?.data?.message || 'Error al actualizar sucursal');
    } finally {
      setUpdating(false);
      setVisible(false)
    }
  };

  const search = async () => {
    const values = await form.validateFields();
    setLoading(true);
    try {
      const response = await axios.get(`${server}/activities/rim?tipoDocumento=${tipoDocumento}&documento=${values.doc}`, { headers: { Authorization: `Bearer ${auth.token}` } });
      setRefs(response.data.data.sucursales);
      message.success('Sucursales obtenidas de manera exitosa');
    } catch(e) {
      message.error(e.response?.data?.message || 'Error al buscar contribuyente');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card style={{ height: '100%' }} title='Cambio de Actividades Económicas' bodyStyle={{ height: 'calc(100% - 88px)', overflowY: 'scroll', overflowX: 'hidden', border: '1px solid #f0f0f0' }}
      headStyle={{ height: 64, backgroundColor: thm.primaryColor, padding: width < 1200 ? '0 10px' : '0 20px', color: 'white'}}>
      <Form form={form} layout='vertical' scrollToFirstError onFinish={search} onValuesChange={(c, v) => { form.setFieldsValue(v); setData(v) }}>
        {!selectedRef &&
        <Row gutter={24}>
          <Col xs={24} xl={7}>
            <Form.Item label='Documento de Identidad' name='doc' rules={[{ required: true, message: 'Debe ingresar el documento de identidad' }]} 
            // normalize={Utils.normalize.isNumber}
            >
              <Input placeholder='Documento de Identidad' addonBefore={tipoAddon} type='number' />
            </Form.Item>
          </Col>
          <Col xl={6} xs={12}>
            <Button onClick={form.submit} style={{ marginTop: width < 1200 ? 0 : 38, marginBottom: width < 1200 ? 20 : 0 }} loading={loading} icon={<SearchOutlined />} type='primary'>Buscar</Button>
          </Col>
          <Col span={24}>
            <Table loading={loading} columns={columns} dataSource={refs.map(r => {
              r.key = r.id;
              return r;
            })} bordered />
          </Col>
        </Row>}
        {selectedRef &&
        <>
          <Divider orientation='left' style={{ marginLeft: -10 }}>
            <Typography.Title level={4}>Datos de la Sucursal</Typography.Title>
          </Divider>
          <Row gutter={24} style={{ paddingLeft: 10, paddingRight: 10 }}>
            <Col xs={24} xl={8}>
              <Form.Item label='Denominación Comercial' name='denomComercial' rules={[{ required: true, message: 'Debe ingresar la denominación comercial' }]}>
                <Input placeholder='Denominación Comercial' />
              </Form.Item>
            </Col>
            <Col xs={24} xl={8}>
              <Form.Item label='Nombre del Representante Legal' name='nombreRepresentante' rules={[{ required: true, message: 'Debe ingresar el nombre del representante legal' }]}>
                <Input placeholder='Nombre del Representante Legal' />
              </Form.Item>
            </Col>
            <Col xs={24} xl={8}>
              <Form.Item label='Capital Suscrito' name='capitalSuscrito' rules={[{ required: true, message: 'Debe ingresar el capital suscrito' }]} normalize={Utils.normalize.isNumber}>
                <Input placeholder='Capital Suscrito' type='numeric' />
              </Form.Item>
            </Col>
            <Col xs={24} xl={8}>
              <Form.Item label='Tipo de Sociedad' name='tipoSociedad' rules={[{ required: true, message: 'Debe ingresar el tipo de sociedad' }]}>
                <TaxpayerSocietyType placeholder='Tipo de Sociedad' />
              </Form.Item>
            </Col>
            <Col xs={24} xl={8}>
              <Form.Item label='Teléfono' name='telefono' rules={[{ required: true, message: 'Debe ingresar el teléfono' }, { validator: Utils.validators.phoneNumber }]}>
                <Input placeholder='Teléfono' maxLength={10} />
              </Form.Item>
            </Col>
          </Row>
          <Divider orientation='left' style={{ marginLeft: -10 }}>
            <Typography.Title level={4}>Actividades Económicas</Typography.Title>
          </Divider>
          <Row gutter={24} style={{ paddingLeft: 10, paddingRight: 10 }}>
            <Col span={24}>
              <Form.Item name='actividadesEconomicas' rules={[{ required: !selectedRef.referenciaMunicipal.startsWith('AR'), message: 'Debe ingresar las actividades económicas' }]}>
                <EconomicActivitiesChange form={form} data={data} preloaded={selectedRef.actividadesEconomicas} />
              </Form.Item>
            </Col>
          </Row>
          {/* {!selectedRef?.referenciaMunicipal.startsWith('AR') ? 
          <>
            <Divider orientation='left' style={{ marginLeft: -10 }}>
              <Typography.Title level={4}>Servicios Municipales</Typography.Title>
            </Divider>
            <Alert message={SMmsg} type='warning' style={{ marginBottom: 10, fontWeight: 'bold' }} />
            <Row gutter={24} style={{ paddingLeft: 10, paddingRight: 10 }}>
              <Col span={6}>
                <Form.Item name='serviciosMunicipales' label='Desde'>
                  <DatePicker placeholder='Fecha' style={{ width: '100%' }}  format='DD-MM-YYYY' />
                </Form.Item>
              </Col>
            </Row>
            <Divider orientation='left' style={{ marginLeft: -10 }}>
              <Typography.Title level={4}>Publicidad y Propaganda</Typography.Title>
            </Divider>
            <Alert message={PPmsg} type='warning' style={{ marginBottom: 10, fontWeight: 'bold' }} />
            <Row gutter={24} style={{ paddingLeft: 10, paddingRight: 10 }}>
              <Col span={6}>
                <Form.Item name='publicidad' label='Desde'>
                  <DatePicker placeholder='Fecha' style={{ width: '100%' }} format='DD-MM-YYYY' />
                </Form.Item>
              </Col>
            </Row>
          </> :
          <>
            <Divider orientation='left' style={{ marginLeft: -10 }}>
              <Typography.Title level={4}>Retenciones Decreto 048</Typography.Title>
            </Divider>
            <Alert message={RD0msg} type='warning' style={{ marginBottom: 10, fontWeight: 'bold' }} />
            <Row gutter={24} style={{ paddingLeft: 10, paddingRight: 10 }}>
              <Col span={6}>
                <Form.Item name='retencion' label='Desde'>
                  <DatePicker placeholder='Fecha' style={{ width: '100%' }}  format='DD-MM-YYYY' />
                </Form.Item>
              </Col>
            </Row>
          </>} */}
          <Divider orientation='left' style={{ marginLeft: -10 }}>
            <Typography.Title level={4}>Datos de la Licencia</Typography.Title>
          </Divider>
          <Row gutter={24} style={{ paddingLeft: 10, paddingRight: 10 }}>
            <Col xl={6} xs={24}>
              <Form.Item label='Tipo de Licencia' name='estadoLicencia' rules={[{ required: true, message: 'Debe ingresar el estado de la licencia' }]}>
                <LicenseTypeSelect placeholder='Tipo de Licencia' showCesante />
              </Form.Item>
            </Col>
          </Row>
          {/* <Divider orientation='left' style={{ marginLeft: -10 }}>
            <Typography.Title level={4}>Monotributo</Typography.Title>
          </Divider>
          <Row gutter={24} style={{ paddingLeft: 10, paddingRight: 10 }}>
            <Col xl={6} xs={24}>
              <Form.Item label='Monotributo' name='esMonotributo' rules={[{ required: true, message: 'Debe indicar si el Documento de Identidad. declara montributo.' }]}>
                <MonotaxSwitch placeholder='Monotributo' />
              </Form.Item>
            </Col>
          </Row> */}
          <Button type='primary' loading={updating && !visible} onClick={() => handleUpdate()}>Actualizar</Button>
        </>}
      </Form>
      {visible && 
        <Modal visible 
          footer={[
          <Button key='close' onClick={()=> setVisible(false)}>No</Button>,
          <Button key='submit' type='primary' loading={updating && visible} onClick={() => update()}>Si</Button>]} 
          onCancel={()=> setVisible(false)}>
          <Result style={{padding:'15px 10px'}} title='Advertencia!'  subTitle={<div style={{textAlign:'justify'}}>{msgWarning}</div>} status='warning' />
        </Modal>}
    </Card>
  );
};

const mapStateToProps = (state: State) => ({ thm: state.thm, auth: state.auth });

export default connect(mapStateToProps)(ActivityChange);

interface ActivityChangeProps {
  thm: States.ThemeColors
  auth: States.Auth
};