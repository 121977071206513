import React, { useState, useEffect, useRef } from 'react';
import { States, State } from 'sigt';
import { connect } from 'react-redux';
import { Card, Table, message, Switch, Row, Col, Form, Input, Select, Button, Tooltip } from 'antd';
import { useWindowDimensions } from '../../../utils/hooks';
import axios from 'axios';
import Highlighter from 'react-highlight-words';
import { ColumnsType } from 'antd/lib/table';
import { UserAddOutlined, CheckOutlined, CloseOutlined, EditOutlined, SearchOutlined } from '@ant-design/icons';
import { Utils } from '../../../utils/validators';
const server = process.env.REACT_APP_SERVER_URL;

const EditableCell:React.FC<any> = ({ editing, onSave, onCancel, dataIndex, title, inputType, record, index, children, ...restProps}) => {
  const inputNode = <Input placeholder='Documento de Identidad.' />;
  return (
    <td {...restProps}>
      {editing ? (<Row>
          <Col span={14}>
            <Form.Item
              name={dataIndex}
              style={{margin: 0}}
              rules={[{required: true,message: `Por favor ingresar ${title}!`,}]}>
                {inputNode}
            </Form.Item>
          </Col>
          <Col span={10}>
            <Tooltip placement="bottom" title="Confirmar">
              <Button type="link" icon={<CheckOutlined />} onClick={() => onSave(record.id,record.idRim)} />
            </Tooltip>
            <Tooltip placement="bottom" title="Cancelar">
              <Button type="link" icon={<CloseOutlined />} onClick={() => onCancel('')} />
            </Tooltip>  
          </Col>
        </Row>
      ) : (
        children
      )}
    </td>
  );
};

const Agents: React.FC<AgentsProps> = ({ thm, auth }) => {
  const [loading, setLoading] = useState(false);
  const [creating, setCreating] = useState(false);
  const [updating, setUpdating] = useState(0);
  const [agents, setAgents] = useState<any[]>([]);
  const [tipoDocumento, setTipoDocumento] = useState('J');
  const [editingKey, setEditingKey] = useState('');
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const isEditing = record => record.key === editingKey;
  const inputRef = useRef<any>(null);
  const canEditRim = [26,24,35];

  const { width } = useWindowDimensions();
  const [form] = Form.useForm();

  const tipoAddon = (
    <Select defaultValue='V' value={tipoDocumento} onChange={(e) => setTipoDocumento(e)}>
      <Select.Option value='V'>V</Select.Option>
      <Select.Option value='E'>E</Select.Option>
      <Select.Option value='J'>J</Select.Option>
      <Select.Option value='G'>G</Select.Option>
      <Select.Option value='P'>P</Select.Option>
      <Select.Option value='C'>C</Select.Option>
    </Select>
  );

  const updateRimAgent = async (id,idRim) => {
    const {referenciaMunicipal} = await form.validateFields();
    setUpdating(id);
    const complete = message.loading('Actualizando rim del agente');
    try {
      await axios.put(`${server}/retentions/rim/${idRim}`, { referencia: referenciaMunicipal }, { headers: { Authorization: `Bearer ${auth.token}` } });
      message.success('Agente actualizado de manera exitosa');
      const index = agents.findIndex(a => a.id === id);
      const _agents = [...agents];
      _agents[index].referenciaMunicipal = referenciaMunicipal;
      setAgents(_agents);
      setEditingKey('');
    } catch(e) {
      message.error(e.response?.data?.message || 'Error al actualizar rim del agente');
    } finally {
      complete();
      setUpdating(0);
    }
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = clearFilters => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={inputRef}
          placeholder='Buscar'
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type='primary'
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size='small'
          style={{ width: 90, marginRight: 8 }}
        >
          Buscar
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size='small' style={{ width: 90 }}>
          Restablecer
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined style={{ color: filtered ? thm.primaryColor : undefined }} />
    ),
    onFilter: (value, record) => 
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        if (inputRef.current) {
          inputRef.current?.select();
        }
      }
    },
    render: text =>  searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#1e72c675', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
    ),
  });

  const columns: ColumnsType<any> | any = [
    {
      title: 'Razón Social', 
      dataIndex: 'razonSocial',
      ...getColumnSearchProps('razonSocial'),
    },
    {
      title: 'Documento de Identidad',
      dataIndex: 'documento',
      ...getColumnSearchProps('documento'),
      render: (doc, record) => <span>{record.tipoDocumento}-{doc}</span>
    },
    {
      title: 'Referencia Municipal',
      dataIndex: 'referenciaMunicipal',
      editable: true,
      onSave: updateRimAgent,
      onCancel: setEditingKey,
      ...getColumnSearchProps('referenciaMunicipal'),
      render: (text, record) => {
        const editable = isEditing(record);
        return editable ? null : <span>
          {text}
          {canEditRim.includes(auth.user?.institucion?.cargo?.id || 0) &&
            <Tooltip placement="bottom" title="Editar">
              <Button type="link" icon={<EditOutlined />} onClick={() => edit(record)} />
            </Tooltip>
          }
        </span>
      },
    },
    {
      title: 'Agente de Retención',
      dataIndex: 'esAgenteRetencion',
      render: (isAgent, record) => <Switch onChange={(checked) => updateAgent(record.id, checked)} checked={isAgent} loading={record.id === updating} disabled={updating !== 0} />
    }
  ];

  const mergedColumns = columns.map(col => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: record => ({
        record,
        onSave: col.onSave,
        onCancel: col.onCancel,
        inputType: 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record)
      })
    };
  });

  useEffect(() => {
    fetchAgents();
    // eslint-disable-next-line
  }, []);

  const fetchAgents = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${server}/retentions/agents`, { headers: { Authorization: `Bearer ${auth.token}` } });
      setAgents(response.data.agentesRetencion);
    } catch(e) {
      message.error(e.response?.data?.message || 'Error al obtener agentes');
    } finally {
      setLoading(false);
    }
  };

  const addAgent = async () => {
    const values = await form.validateFields();
    if(!tipoDocumento) return message.error('Debe incluir el tipo de documento');
    setCreating(true);
    try {
      const response = await axios.post(`${server}/retentions/agent`, { tipoDocumento, documento: values.documento }, { headers: { Authorization: `Bearer ${auth.token}` } });
      message.success('Agente registrado de manera exitosa');
      setAgents([response.data.agenteRetencion, ...agents]);
    } catch(e) { 
      message.error(e.response?.data?.message || 'Error al agregar agente');
    } finally {
      setCreating(false);
    }
  };

  const updateAgent = async (id, status) => {
    setUpdating(id);
    const complete = message.loading('Actualizando estado del agente');
    try {
      await axios.put(`${server}/retentions/agent/${id}`, { status }, { headers: { Authorization: `Bearer ${auth.token}` } });
      message.success('Agente actualizado de manera exitosa');
      const index = agents.findIndex(a => a.id === id);
      const _agents = [...agents];
      _agents[index].esAgenteRetencion = status;
      setAgents(_agents);
    } catch(e) {
      message.error(e.response?.data?.message || 'Error al actualizar estado del agente');
    } finally {
      complete();
      setUpdating(0);
    }
  };

  const edit = (record) => {
    form.setFieldsValue({ ...record });
    setEditingKey(record.key);
  };

  const components = {
    body: {
      cell: EditableCell,
    }
  };

  return (
    <Card style={{ height: '100%' }} title='Agentes de Retención' bodyStyle={{ height: 'calc(100% - 88px)', overflowY: 'scroll', overflowX: 'hidden', border: '1px solid #f0f0f0' }}
      headStyle={{ height: 64, backgroundColor: thm.primaryColor, padding: width < 1200 ? '0 10px' : '0 20px', color: 'white'}}>
      <Form scrollToFirstError layout='vertical' form={form}>
        <Row gutter={24}>
          <Col xs={24} xl={8}>
            <Form.Item name='documento' label='Documento de Identidad' rules={[{ required: true, message: 'Debe ingresar el documento de identidad' }]} normalize={Utils.normalize.isNumber}>
              <Input placeholder='Documento de Identidad' addonBefore={tipoAddon} />
            </Form.Item>
          </Col>
          <Col xs={24} xl={8}>
            <Button loading={creating} style={{ marginTop: width < 1200 ? 0 : 40 }} onClick={() => addAgent()} type='primary' icon={<UserAddOutlined />}>
              Agregar
            </Button>
          </Col>
        </Row>
        <Table components={components} loading={loading} columns={mergedColumns} dataSource={agents.map(a => { a.key = a.id; return a; })} />
      </Form>
    </Card>
  );
};

const mapStateToProps = (state: State) => ({ thm: state.thm, auth: state.auth });

export default connect(mapStateToProps)(Agents);

interface AgentsProps {
  thm: States.ThemeColors
  auth: States.Auth
}